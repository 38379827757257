import { Injectable } from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import {catchError, tap} from 'rxjs/operators';

import { AuthService } from '../auth.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
      if ((event instanceof HttpResponse)) {
      }
    }, (err: any) => {
      if (err instanceof HttpErrorResponse) {
        if ((err.status === 401) || (err.status === 403)) {
          // auto logout if 401 response returned from api
          this.authenticationService.logout();
          location.replace('/login');
        }

        const error = err.error.message || err.statusText;
        return throwError(error);
      }
    }));
  }
}
