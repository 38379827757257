import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Categorization} from './categorization';
import {Updateposition} from './updateposition';
import {Verification} from './verification';

@Injectable({
  providedIn: 'root'
})
export class ArticleService {
  private articlesUrl = 'https://newstrack.ugent.be/backend/comparison';
  private articlesVerificationUrl = 'https://newstrack.ugent.be/backend/verify';
  private feedbackArticlesUrl = 'https://newstrack.ugent.be/backend/feedbackComparison';
  private categorizationUrl = 'https://newstrack.ugent.be/backend/categorization';
  private verificationUrl = 'https://newstrack.ugent.be/backend/verification';
  private scoreUrl = 'https://newstrack.ugent.be/backend/calculateScores';
  private solutionUrl = 'https://newstrack.ugent.be/backend/feedbackSolutions';
  private reloadUrl = 'https://newstrack.ugent.be/backend/reload';
  private finishUrl = 'https://newstrack.ugent.be/backend/finishArticle';
  private finishVerificationUrl = 'https://newstrack.ugent.be/backend/finishVerification';
/*
  private articlesUrl = 'https://localhost:443/comparison';
  private feedbackArticlesUrl = 'https://localhost:443/feedbackComparison';
  private categorizationUrl = 'https://localhost:443/categorization';
  private scoreUrl = 'https://localhost:443/calculateScores';
  private solutionUrl = 'https://localhost:443/feedbackSolutions';
  private reloadUrl = 'https://localhost:443/reload';
  private finishUrl = 'https://localhost:443/finishArticle';
  */
  constructor(private http: HttpClient) { }
  getArticlesVerification(email: string): Observable<any> {
    /*tslint:disable:object-literal-key-quotes */
    const response = this.http.get<any>(this.articlesVerificationUrl, {params: new HttpParams().set('email', email), responseType: 'json',
      headers: { 'Accept': 'application/json', 'rejectUnauthorized': 'false', 'insecure': 'true'}});
    return response;
  }
  getArticles(email: string): Observable<any> {
    /*tslint:disable:object-literal-key-quotes */
    const response = this.http.get<any>(this.articlesUrl, {params: new HttpParams().set('email', email), responseType: 'json',
      headers: { 'Accept': 'application/json', 'rejectUnauthorized': 'false', 'insecure': 'true'}});
    return response;
  }
  reload(email: string, newspaper: string, url: string, version: string): Observable<any> {
    /*tslint:disable:object-literal-key-quotes */
    const response = this.http.get<any>(this.reloadUrl, {params: new HttpParams().set('email', email)
        .set('newspaper', newspaper).set('url', url).set('version', version), responseType: 'json',
      headers: { 'Accept': 'application/json', 'rejectUnauthorized': 'false', 'insecure': 'true'}});
    return response;
  }
  getFeedbackArticles(email: string): Observable<any> {
    /*tslint:disable:object-literal-key-quotes */
    const response = this.http.get<any>(this.feedbackArticlesUrl, {params: new HttpParams().set('email', email), responseType: 'json',
      headers: { 'Accept': 'application/json', 'rejectUnauthorized': 'false', 'insecure': 'true'}});
    return response;
  }
  postCategorization(categorization: Categorization): Observable<any> {
    const response = this.http.post<any>(this.categorizationUrl, categorization, {headers: { 'Accept': 'application/json', 'rejectUnauthorized': 'false', 'insecure': 'true'}});
    return response;
  }
  postVerification(verification: Verification): Observable<any> {
    const response = this.http.post<any>(this.verificationUrl, verification, {headers: { 'Accept': 'application/json', 'rejectUnauthorized': 'false', 'insecure': 'true'}});
    return response;
  }
  calculateScores(email: string): Observable<any> {
    const response = this.http.get<any>(this.scoreUrl, {params: new HttpParams().set('email', email), responseType: 'json',
      headers: { 'Accept': 'application/json', 'rejectUnauthorized': 'false', 'insecure': 'true'}});
    return response;
  }
  getFeedbacksolution(email: string, url: string): Observable<any> {
    const response = this.http.get<any>(this.solutionUrl, {params: new HttpParams().set('email', email)
        .set('url', url), responseType: 'json',
      headers: { 'Accept': 'application/json', 'rejectUnauthorized': 'false', 'insecure': 'true'}});
    return response;
  }
  finish(email: string, newspaper: string, url: string, version: string): Observable<any> {
    /*tslint:disable:object-literal-key-quotes */
    const response = this.http.get<any>(this.finishUrl, {params: new HttpParams().set('email', email)
        .set('newspaper', newspaper).set('url', url).set('version', version), responseType: 'json',
      headers: { 'Accept': 'application/json', 'rejectUnauthorized': 'false', 'insecure': 'true'}});
    return response;
  }
  finishVerification(url: string, version: string): Observable<any> {
    /*tslint:disable:object-literal-key-quotes */
    const response = this.http.get<any>(this.finishVerificationUrl, {params: new HttpParams().set('url', url)
        .set('version', version),
      headers: { 'Accept': 'application/json', 'rejectUnauthorized': 'false', 'insecure': 'true'}});
    return response;
  }
}
