<!doctype html>
<html lang="en">
<head>
  <title>UGent Newstracker - Pasword recovery</title>
</head>
<body>
  <nav class="navbar navbar-expand-lg fixed-top navbar-dark bg-company-grey">
    <a class="navbar-brand" href="#"></a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <a class="navbar-brand" target="_blank" href="https://www.ugent.be/" style="padding:0px;">
      <img src="assets/ugentlogo.png" width="163" height="130">
    </a>
    <a class="navbar-brand" target="_blank" href="https://ddcmsite.ugent.be/" style="padding:0px;">
      <img src="assets/ddcmlogo.png" width="272" height="60">
    </a>
    <div class="collapse navbar-collapse text-right" id="navbarright">
      <ul class="navbar-nav ml-auto flex-nowrap">
        <li class="nav-item">
          <a class="nav-link" href="/login" (click)="booleanService.resetLoginBooleans()">Home</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="/project" (click)="booleanService.resetLoginBooleans()">Over dit project</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="/intro" (click)="booleanService.resetLoginBooleans()">Hoe werkt het?</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="/signin" (click)="booleanService.resetSigninBooleans()">Maak een account!</a>
        </li>
      </ul>
    </div>
  </nav>
  <div class="card-body" *ngIf="currentRecovertokenCreatedValue===false">
    <h5 class="card-header">Paswoord vergeten?</h5>
    <br>
    <div>Ben je jouw paswoord vergeten? Geef je e-mailadres hieronder op, en we sturen je een e-mail met verdere instructies!</div>
    <br>
    <form [formGroup]="recoverForm" (ngSubmit)="onRecoverSubmit()">
      <div class="form-group">
        <label for="emailRecover">Email</label>
        <input type="text" id="emailRecover" [readonly]="currentLoadingRecoverValue===true" formControlName="emailRecover" class="form-control" [ngClass]="{ 'is-invalid': (currentEmptyEmailRecoverValue && recoverf.emailRecover.errors) || currentFailedMalformedRecoverValue }" />
        <div *ngIf="currentEmptyEmailRecoverValue && recoverf.emailRecover.errors" class="invalid-feedback-recover">
        </div>
      </div>
      <div style="color:red" *ngIf="currentFailedMalformedRecoverValue" class="failedInternalRecover">Geen geldig e-mailadres!</div>
      <button [disabled]="currentLoadingRecoverValue" class="btn btn-primary">
        <span *ngIf="currentLoadingRecoverValue" class="spinner-border spinner-border-sm mr-1"></span>
        Herstel je paswoord!
      </button>
      <div style="color:red" *ngIf="currentFailedExistingRecoverValue" class="failedExistingRecover">Er bestaat geen account met dit e-mailadres!</div>
      <div style="color:red" *ngIf="currentFailedInternalRecoverValue" class="failedInternalRecover">Interne fout opgetreden, gelieve opnieuw te proberen.</div>
    </form>
  </div>
  <div *ngIf="currentRecovertokenCreatedValue" class="recovertokenCreated">We hebben een mail naar jou gestuurd met verdere instructies!</div>
</body>
</html>
