import {Component, Input, OnInit} from '@angular/core';
import { Article } from '../article';
import { Sentence } from '../sentence';
import { ArticleService } from '../article.service';
import {Updateposition} from '../updateposition';
import {Categorization} from '../categorization';
import {CategorizationOption} from '../categorizationOption';
import {AuthService} from '../auth.service';
import {Router} from '@angular/router';
import {NgForm} from '@angular/forms';
import {Title} from '@angular/platform-browser';
import {changedPart} from '../changedPart';
import {CategorizationPart} from '../categorizationPart';
import {ChangedSentencePart} from '../changedSentencePart';
import {escapeRegExp} from 'tslint/lib/utils';
import {Mistake} from '../Mistake';
import {Verification} from '../verification';
@Component({
  selector: 'app-articles',
  templateUrl: './articles.component.html',
  styleUrls: ['./articles.component.css']
})
export class ArticlesComponent implements OnInit {
  oldArticle: Article;
  newArticle: Article;
  titlepositions: Updateposition[];
  intropositions: Updateposition[];
  textpositions: Updateposition[];
  tagsremoved: string[];
  tagsadded: string[];
  mistakes: Mistake[];
  verifications: Verification[];
  failed: boolean;
  profileUrl = '/profile';
  email: string;
  firstname: string;
  lastname: string;
  totalCount: number;
  hasLoggedIn: boolean;
  imageLoaded: boolean;
  hasFeedbackCompleted: boolean;
  allArticlesCompleted: boolean;
  feedbackStage: number;

  constructor(private articleService: ArticleService, public authService: AuthService, private router: Router,
              private titleService: Title) { }
  replaceAll(str, find, replace) {
    return str.split(find).join(replace);
  }
  getArticles(): void{
      let email = null;
      if (this.authService.currentUserValue){
          email = this.authService.currentUserValue.email;
      }
      if (email === 'antoon.bronselaer@ugent.be' || email === 'yoram1291@hotmail.com' || email === 'ddcmnewsproject@gmail.com'){
        this.articleService.getArticlesVerification(email).subscribe(data => {
            /* tslint:disable:no-string-literal */
            /* tslint:disable:object-literal-shorthand */
            this.imageLoaded = false;
            const response = data;
            this.oldArticle = response['originalArticle'];
            this.newArticle = response['newArticle'];
            this.titlepositions = [];
            this.verifications = [];
            const originaltitlelengths = this.oldArticle.titleParts.map(a => a.length);
            const originaltitlenumbers = [];
            let sum = 0;
            this.mistakes = response['mistakes'];
            for (const mistake of this.mistakes){
              mistake.clicked = false;
              const verification = {
                categorizationid: mistake.id,
                newspaper: this.oldArticle.newspaper,
                url: this.oldArticle.url,
                newVersion: this.newArticle.versionNumber,
                trueMistake: false,
                otherType: null,
                subtype: null,
                opmerkingen: null
              };
              this.verifications.push(verification);
            }
            for (length of originaltitlelengths) {
              originaltitlenumbers.push(sum);
              sum = sum + length;
            }
            const newtitlelengths = this.newArticle.titleParts.map(a => a.length);
            const newtitlenumbers = [];
            sum = 0;
            for (length of newtitlelengths) {
              newtitlenumbers.push(sum);
              sum = sum + length;
            }
            this.intropositions = [];
            const originalintrolengths = this.oldArticle.introParts.map(a => a.length);
            const originalintronumbers = [];
            sum = 0;
            for (length of originalintrolengths) {
              originalintronumbers.push(sum);
              sum = sum + length;
            }
            const newintrolengths = this.newArticle.introParts.map(a => a.length);
            const newintronumbers = [];
            sum = 0;
            for (length of newintrolengths) {
              newintronumbers.push(sum);
              sum = sum + length;
            }
            this.textpositions = [];
            const originaltextlengths = this.oldArticle.textParts.map(a => a.length);
            const originaltextnumbers = [];
            sum = 0;
            for (length of originaltextlengths) {
              originaltextnumbers.push(sum);
              sum = sum + length;
            }
            const newtextlengths = this.newArticle.textParts.map(a => a.length);
            const newtextnumbers = [];
            sum = 0;
            for (length of newtextlengths) {
              newtextnumbers.push(sum);
              sum = sum + length;
            }
            this.wait(1000);
            this.imageLoaded = true;
          },
          error => {
            if ((error.status === 400) && (error.error.message === 'All articles completed!')){
              this.allArticlesCompleted = true;
            }
            else {
              this.failed = true;
            }
          }
        );
      }
      else {
        this.articleService.getArticles(email).subscribe(data => {
            /* tslint:disable:no-string-literal */
            /* tslint:disable:object-literal-shorthand */
            this.imageLoaded = false;
            const response = data.articleDiff;
            this.oldArticle = response['originalArticle'];
            this.newArticle = response['newArticle'];
            this.titlepositions = [];
            const originaltitlelengths = this.oldArticle.titleParts.map(a => a.length);
            const originaltitlenumbers = [];
            let sum = 0;
            let groupnrOriginal = 0;
            let groupnrNew = 0;
            for (length of originaltitlelengths) {
              originaltitlenumbers.push(sum);
              sum = sum + length;
            }
            const newtitlelengths = this.newArticle.titleParts.map(a => a.length);
            const newtitlenumbers = [];
            sum = 0;
            for (length of newtitlelengths) {
              newtitlenumbers.push(sum);
              sum = sum + length;
            }
            for (const titledelta of response['titlePatch'].deltas) {
              const removed = [];
              const added = [];
              for (let i = 0; i < titledelta.source.lines.length; i++) {
                const smallernumbers = originaltitlenumbers.filter(a => a <= titledelta.source.position + i);
                const changedParts = [];
                for (const changed of titledelta.source.lines[i].changedParts) {
                  const changedObj: changedPart = {start: changed.start, end: changed.end};
                  changedParts.push(changedObj);
                }
                const sen: Sentence = {
                  sentence: titledelta.source.lines[i].sentence,
                  sentencenumber: titledelta.source.position + i - smallernumbers[smallernumbers.length - 1],
                  parnumber: smallernumbers.length - 1, changedParts: changedParts
                };
                removed.push(sen);
              }
              for (let i = 0; i < titledelta.target.lines.length; i++) {
                const smallernumbers = newtitlenumbers.filter(a => a <= titledelta.target.position + i);
                const changedParts = [];
                for (const changed of titledelta.target.lines[i].changedParts) {
                  const changedObj: changedPart = {start: changed.start, end: changed.end};
                  changedParts.push(changedObj);
                }
                const sen: Sentence = {
                  sentence: titledelta.target.lines[i].sentence,
                  sentencenumber: titledelta.target.position + i - smallernumbers[smallernumbers.length - 1],
                  parnumber: smallernumbers.length - 1, changedParts: changedParts
                };
                added.push(sen);
              }
              if (titledelta.type === 'CHANGE') {
                const position: Updateposition = {
                  textpart: 'title',
                  removedSentences: removed,
                  addedSentences: added,
                  groupnrOriginal: groupnrOriginal,
                  groupnrNew: groupnrNew,
                  categorizations: null,
                  warning: false,
                  warningAnders: false,
                  failed: false,
                  otherPositionsSelected: false,
                  currentSelected: false,
                  newpartsSelected: false,
                  oldpartsSelected: false,
                  categorizationSelected: false,
                  categorized: false,
                  warningSelectAtLeastOne: false
                };
                this.titlepositions.push(position);
                groupnrOriginal = groupnrOriginal + 1;
                groupnrNew = groupnrNew + 1;
              }
              if (titledelta.type === 'INSERT') {
                const position: Updateposition = {
                  textpart: 'title', removedSentences: null, addedSentences: added, groupnrOriginal: null, groupnrNew: groupnrNew,
                  categorizations: null, warning: false, warningAnders: false, failed: false,
                  otherPositionsSelected: false, currentSelected: false, newpartsSelected: false,
                  oldpartsSelected: false, categorizationSelected: false,
                  categorized: false, warningSelectAtLeastOne: false
                };
                this.titlepositions.push(position);
                groupnrNew = groupnrNew + 1;
              }
              if (titledelta.type === 'DELETE') {
                const position: Updateposition = {
                  textpart: 'title', removedSentences: removed, addedSentences: null,
                  groupnrOriginal: groupnrOriginal, groupnrNew: null,
                  categorizations: null, warning: false, warningAnders: false, failed: false,
                  otherPositionsSelected: false, currentSelected: false, newpartsSelected: false,
                  oldpartsSelected: false, categorizationSelected: false,
                  categorized: false, warningSelectAtLeastOne: false
                };
                this.titlepositions.push(position);
                groupnrOriginal = groupnrOriginal + 1;
              }
            }
            this.intropositions = [];
            const originalintrolengths = this.oldArticle.introParts.map(a => a.length);
            const originalintronumbers = [];
            sum = 0;
            for (length of originalintrolengths) {
              originalintronumbers.push(sum);
              sum = sum + length;
            }
            const newintrolengths = this.newArticle.introParts.map(a => a.length);
            const newintronumbers = [];
            sum = 0;
            for (length of newintrolengths) {
              newintronumbers.push(sum);
              sum = sum + length;
            }
            for (const introdelta of response['introPatch'].deltas) {
              const removed = [];
              const added = [];
              for (let i = 0; i < introdelta.source.lines.length; i++) {
                const smallernumbers = originalintronumbers.filter(a => a <= introdelta.source.position + i);
                const changedParts = [];
                for (const changed of introdelta.source.lines[i].changedParts) {
                  const changedObj: changedPart = {start: changed.start, end: changed.end};
                  changedParts.push(changedObj);
                }
                const sen: Sentence = {
                  sentence: introdelta.source.lines[i].sentence,
                  sentencenumber: introdelta.source.position + i - smallernumbers[smallernumbers.length - 1],
                  parnumber: smallernumbers.length - 1, changedParts: changedParts
                };
                removed.push(sen);
              }
              for (let i = 0; i < introdelta.target.lines.length; i++) {
                const smallernumbers = newintronumbers.filter(a => a <= introdelta.target.position + i);
                const changedParts = [];
                for (const changed of introdelta.target.lines[i].changedParts) {
                  const changedObj: changedPart = {start: changed.start, end: changed.end};
                  changedParts.push(changedObj);
                }
                const sen: Sentence = {
                  sentence: introdelta.target.lines[i].sentence,
                  sentencenumber: introdelta.target.position + i - smallernumbers[smallernumbers.length - 1],
                  parnumber: smallernumbers.length - 1, changedParts: changedParts
                };
                added.push(sen);
              }
              if (introdelta.type === 'CHANGE') {
                const position: Updateposition = {
                  textpart: 'intro', removedSentences: removed, addedSentences: added,
                  groupnrNew: groupnrNew, groupnrOriginal: groupnrOriginal,
                  categorizations: null, warning: false, warningAnders: false, failed: false,
                  otherPositionsSelected: false, currentSelected: false,
                  newpartsSelected: false, oldpartsSelected: false, categorizationSelected: false,
                  categorized: false, warningSelectAtLeastOne: false
                };
                this.intropositions.push(position);
                groupnrOriginal = groupnrOriginal + 1;
                groupnrNew = groupnrNew + 1;
              }
              if (introdelta.type === 'INSERT') {
                const position: Updateposition = {
                  textpart: 'intro', removedSentences: null, addedSentences: added,
                  groupnrOriginal: null, groupnrNew: groupnrNew,
                  categorizations: null, warning: false, warningAnders: false, failed: false,
                  otherPositionsSelected: false, currentSelected: false,
                  newpartsSelected: false, oldpartsSelected: false, categorizationSelected: false,
                  categorized: false, warningSelectAtLeastOne: false
                };
                this.intropositions.push(position);
                groupnrNew = groupnrNew + 1;
              }
              if (introdelta.type === 'DELETE') {
                const position: Updateposition = {
                  textpart: 'intro', removedSentences: removed, addedSentences: null,
                  groupnrOriginal: groupnrOriginal, groupnrNew: null,
                  categorizations: null, warning: false, warningAnders: false, failed: false,
                  otherPositionsSelected: false, currentSelected: false,
                  newpartsSelected: false, oldpartsSelected: false, categorizationSelected: false,
                  categorized: false, warningSelectAtLeastOne: false
                };
                this.intropositions.push(position);
                groupnrOriginal = groupnrOriginal + 1;
              }
            }
            this.textpositions = [];
            const originaltextlengths = this.oldArticle.textParts.map(a => a.length);
            const originaltextnumbers = [];
            sum = 0;
            for (length of originaltextlengths) {
              originaltextnumbers.push(sum);
              sum = sum + length;
            }
            const newtextlengths = this.newArticle.textParts.map(a => a.length);
            const newtextnumbers = [];
            sum = 0;
            for (length of newtextlengths) {
              newtextnumbers.push(sum);
              sum = sum + length;
            }
            for (const textdelta of response['textPatch'].deltas) {
              const removed = [];
              const added = [];
              for (let i = 0; i < textdelta.source.lines.length; i++) {
                const smallernumbers = originaltextnumbers.filter(a => a <= textdelta.source.position + i);
                const changedParts = [];
                for (const changed of textdelta.source.lines[i].changedParts) {
                  const changedObj: changedPart = {start: changed.start, end: changed.end};
                  changedParts.push(changedObj);
                }
                const sen: Sentence = {
                  sentence: textdelta.source.lines[i].sentence,
                  sentencenumber: textdelta.source.position + i - smallernumbers[smallernumbers.length - 1],
                  parnumber: smallernumbers.length - 1, changedParts: changedParts
                };
                removed.push(sen);
              }
              for (let i = 0; i < textdelta.target.lines.length; i++) {
                const smallernumbers = newtextnumbers.filter(a => a <= textdelta.target.position + i);
                const changedParts = [];
                for (const changed of textdelta.target.lines[i].changedParts) {
                  const changedObj: changedPart = {start: changed.start, end: changed.end};
                  changedParts.push(changedObj);
                }
                const sen: Sentence = {
                  sentence: textdelta.target.lines[i].sentence,
                  sentencenumber: textdelta.target.position + i - smallernumbers[smallernumbers.length - 1],
                  parnumber: smallernumbers.length - 1, changedParts: changedParts
                };
                added.push(sen);
              }
              if (textdelta.type === 'CHANGE') {
                const position: Updateposition = {
                  textpart: 'text', removedSentences: removed, addedSentences: added,
                  groupnrOriginal: groupnrOriginal, groupnrNew: groupnrNew,
                  categorizations: null, warning: false, warningAnders: false, failed: false,
                  otherPositionsSelected: false, currentSelected: false,
                  newpartsSelected: false, oldpartsSelected: false, categorizationSelected: false,
                  categorized: false, warningSelectAtLeastOne: false
                };
                this.textpositions.push(position);
                groupnrOriginal = groupnrOriginal + 1;
                groupnrNew = groupnrNew + 1;
              }
              if (textdelta.type === 'INSERT') {
                const position: Updateposition = {
                  textpart: 'text', removedSentences: null, addedSentences: added,
                  groupnrOriginal: null, groupnrNew: groupnrNew,
                  categorizations: null, warning: false, warningAnders: false, failed: false,
                  otherPositionsSelected: false, currentSelected: false,
                  newpartsSelected: false, oldpartsSelected: false, categorizationSelected: false,
                  categorized: false, warningSelectAtLeastOne: false
                };
                this.textpositions.push(position);
                groupnrNew = groupnrNew + 1;
              }
              if (textdelta.type === 'DELETE') {
                const position: Updateposition = {
                  textpart: 'text', removedSentences: removed, addedSentences: null,
                  groupnrOriginal: groupnrOriginal, groupnrNew: null,
                  categorizations: null, warning: false, warningAnders: false, failed: false,
                  otherPositionsSelected: false, currentSelected: false,
                  newpartsSelected: false, oldpartsSelected: false, categorizationSelected: false,
                  categorized: false, warningSelectAtLeastOne: false
                };
                this.textpositions.push(position);
                groupnrOriginal = groupnrOriginal + 1;
              }
            }
            this.tagsadded = response['tagsadded'];
            this.tagsremoved = response['tagsremoved'];
            this.wait(1000);
            this.imageLoaded = true;
          },
          error => {
            if ((error.status === 400) && (error.error.message === 'All articles completed!')) {
              this.allArticlesCompleted = true;
            } else {
              this.failed = true;
            }
          }
        );
      }
  }
  getTicket(): string {
    if (this.totalCount >= 0) {
      if (this.totalCount < 50) {
        return 'assets/ticket_' + ((50 - this.getRemaining()) * 2).toString() + '.png';
      }
      else{
        return 'assets/ticket_' + ((25 - this.getRemaining()) * 4).toString() + '.png';
      }
    }
    else{
      return 'assets/ticket_0.png';
    }
  }
  getFullTicketBlack(): string {
    return 'assets/ticket_black.png';
  }
  getFullTicketWhite(): string {
    return 'assets/ticket_100.png';
  }
  getRange(nrTickets: number): number[] {
    const ticketRange = [];
    for (let i = 0; i < nrTickets; i++){
      ticketRange[i] = i;
    }
    return ticketRange;
  }
  getRemaining(): number {
    if (this.totalCount < 50){
      return 50 - this.totalCount;
    }
    if ((this.totalCount === 50) || (this.totalCount > 50 && ((this.totalCount - 50) % 25) === 0)){
      return 25;
    }
    else {
      return Math.ceil((this.totalCount - 50) / 25) * 25 - (this.totalCount - 50);
    }
  }
  getNrTickets(): number {
    if (this.totalCount < 50){
      return 0;
    }
    else{
      return Math.floor((this.totalCount - 50) / 25) + 1;
    }
  }
  getImageLoaded(): boolean {
    return this.imageLoaded;
  }
  charInUpdate(positions: Updateposition[], parnumber: number, sentencenumber: number, charnumber: number, type: string): boolean {
    switch (type) {
      case 'original': {
        for (const sentence of [].concat(...positions.map(position => position.removedSentences).filter(a => a))){
          if ((sentence.parnumber === parnumber) && (sentence.sentencenumber === sentencenumber)){
            for (const changedObj of sentence.changedParts) {
              if (charnumber >= changedObj.start && charnumber <= changedObj.end){
                return true;
              }
            }
          }
        }
        break;
      }
      case 'new': {
        for (const sentence of [].concat(...positions.map(position => position.addedSentences).filter(a => a))){
          if ((sentence.parnumber === parnumber) && (sentence.sentencenumber === sentencenumber)){
            for (const changedObj of sentence.changedParts) {
              if (charnumber >= changedObj.start && charnumber <= changedObj.end){
                return true;
              }
            }
          }
        }
        break;
      }
      default: {
        break;
      }
    }
    return false;
  }
  sentenceInUpdate(positions: Updateposition[], parnumber: number, sentencenumber: number, type: string): boolean {
    switch (type) {
      case 'original': {
        for (const sentence of [].concat(...positions.map(position => position.removedSentences).filter(a => a))){
          if ((sentence.parnumber === parnumber) && (sentence.sentencenumber === sentencenumber)){
            return true;
          }
        }
        break;
      }
      case 'new': {
        for (const sentence of [].concat(...positions.map(position => position.addedSentences).filter(a => a))){
          if ((sentence.parnumber === parnumber) && (sentence.sentencenumber === sentencenumber)){
            return true;
          }
        }
        break;
      }
      default: {
        break;
      }
    }
    return false;
  }
  sentenceInVerification(textpart: string, parnumber: number, sentencenumber: number, type: string): boolean {
    switch (type) {
      case 'original': {
        for (const sentence of [].concat(...this.mistakes.filter(mistake => (mistake.clicked === true) &&
          (mistake.textPart === textpart))
          .map(mistake => mistake.originalParts))) {
          if ((sentence.parnumber === parnumber) && (sentence.sentencenumber === sentencenumber)) {
            return true;
          }
        }
        break;
      }
      case 'new': {
        for (const sentence of [].concat(...this.mistakes.filter(mistake => (mistake.clicked === true) &&
          (mistake.textPart === textpart))
          .map(mistake => mistake.newParts))) {
          if ((sentence.parnumber === parnumber) && (sentence.sentencenumber === sentencenumber)) {
            return true;
          }
        }
        break;
      }
      default: {
        break;
      }
    }
    return false;
  }
  charInVerification(textpart: string, parnumber: number, sentencenumber: number, charnumber: number, type: string): boolean {
    switch (type) {
      case 'original': {
        for (const sentence of [].concat(...this.mistakes.filter(mistake => (mistake.clicked === true) &&
          (mistake.textPart === textpart))
          .map(mistake => mistake.originalParts))) {
          if ((sentence.parnumber === parnumber) && (sentence.sentencenumber === sentencenumber)){
              if (charnumber >= sentence.start && charnumber <= sentence.end){
                return true;
              }
          }
        }
        break;
      }
      case 'new': {
        for (const sentence of [].concat(...this.mistakes.filter(mistake => (mistake.clicked === true) &&
          (mistake.textPart === textpart))
          .map(mistake => mistake.newParts))) {
          if ((sentence.parnumber === parnumber) && (sentence.sentencenumber === sentencenumber)) {
              if (charnumber >= sentence.start && charnumber <= sentence.end) {
                return true;
            }
          }
        }
        break;
      }
      default: {
        break;
      }
    }
    return false;
  }
  contentInSentence(sentence: string): boolean {
    if ((sentence.length < 1) || (sentence.length === 1 && (sentence === ' ' || sentence === '\n'))){
      return false;
    }
    else{
      return true;
    }
  }
  firstUpdateSentence(positions: Updateposition[], parnumber: number, sentencenumber: number, type: string): boolean {
    switch (type) {
      case 'original': {
        for (const sentence of [].concat(...positions.filter(a => a.removedSentences).map(a => a.removedSentences[0]))){
          if ((sentence.parnumber === parnumber) && (sentence.sentencenumber === sentencenumber)){
            return true;
          }
        }
        break;
      }
      case 'new': {
        for (const sentence of [].concat(...positions.filter(a => a.addedSentences).map(a => a.addedSentences[0]))){
          if ((sentence.parnumber === parnumber) && (sentence.sentencenumber === sentencenumber)){
            return true;
          }
        }
        break;
      }
      default: {
        break;
      }
    }
    return false;
  }
  getCorrespondingPosition(positions: Updateposition[], parnumber: number, sentencenumber: number, type: string): Updateposition {
    if (type === 'original') {
      for (const position of positions) {
        if (position.removedSentences) {
          for (const sentence of position.removedSentences) {
            if ((sentence.parnumber === parnumber) && (sentence.sentencenumber === sentencenumber)) {
              return position;
            }
          }
        }
      }
    }
    if (type === 'new') {
      for (const position of positions) {
        if (position.addedSentences) {
          for (const sentence of position.addedSentences) {
            if ((sentence.parnumber === parnumber) && (sentence.sentencenumber === sentencenumber)) {
              return position;
            }
          }
        }
      }
    }
  }
  getCorrespondingVerification(id: number): Verification {
    for (const verification of this.verifications) {
        if (id === verification.categorizationid) {
              return verification;
            }
        }
      }
  getLastCategorizationOption(positions: Updateposition[], parnumber: number, sentencenumber: number, type: string): CategorizationOption {
    const position = this.getCorrespondingPosition(positions, parnumber, sentencenumber, type);
    return position.categorizations.categorizations;
  }
submit(position: Updateposition): void {
    if (position.categorizations.categorizations.anders &&
      !position.categorizations.categorizations.text){
      position.warningAnders = true;
      position.warning = false;
      position.failed = false;
    }
    else {
      if (position.categorizations.categorizations.subjectief ||
        position.categorizations.categorizations.herformulering
        || position.categorizations.categorizations.update
        || position.categorizations.categorizations.objectief
        || position.categorizations.categorizations.toevoeging ||
        position.categorizations.categorizations.verwijdering
        || position.categorizations.categorizations.verplaatsing
        || position.categorizations.categorizations.spelfout
        || (position.categorizations.categorizations.anders
          && position.categorizations.categorizations.text)) {
        const categorization: Categorization = {feedback: false,
          email: this.authService.currentUserValue.email, newspaper: this.oldArticle.newspaper,
          url: this.oldArticle.url, timeSubmitted: Date.now(), versionOriginal: this.oldArticle.versionNumber,
          versionNew: this.newArticle.versionNumber, groupnrOriginal: position.groupnrOriginal,
          groupnrNew: position.groupnrNew, textpart: position.textpart, removedSentences: position.removedSentences,
          addedSentences: position.addedSentences, categorizations: position.categorizations
        };
        this.articleService.postCategorization(categorization).subscribe(data => {
            let foundOriginal = false;
            if (position.removedSentences) {
              for (const sent2 of position.removedSentences) {
                if (sent2.changedParts.length > 0) {
                  foundOriginal = true;
                  break;
                }
              }
            }
            let foundNew = false;
            if (position.addedSentences) {
              for (const sent2 of position.addedSentences) {
                if (sent2.changedParts.length > 0) {
                  foundNew = true;
                  break;
                }
              }
            }
            switch (position.textpart) {
              case 'title': {
                if ((foundOriginal === false) && (foundNew === false)) {
                  this.titlepositions = this.titlepositions.filter(a => a !== position);
                  position.categorized = true;
                }
                break;
              }
              case 'intro': {
                if ((foundOriginal === false) && (foundNew === false)) {
                  this.intropositions = this.intropositions.filter(a => a !== position);
                  position.categorized = true;
                }
                break;
              }
              case 'text': {
                if ((foundOriginal === false) && (foundNew === false)) {
                  this.textpositions = this.textpositions.filter(a => a !== position);
                  position.categorized = true;
                }
                break;
              }
              default: {
                break;
              }
            }
            if (this.titlepositions.length + this.intropositions.length + this.textpositions.length === 0) {
              this.articleService.finish(this.email, this.oldArticle.newspaper, this.oldArticle.url,
                this.newArticle.versionNumber.toString()).subscribe(data2 => {
                this.oldArticle = null;
                this.newArticle = null;
                this.totalCount = this.totalCount + 1;
                this.getArticles();

                position.categorizations.categorizations.anders = false;
                position.categorizations.categorizations.verwijdering = false;
                position.categorizations.categorizations.toevoeging = false;
                position.categorizations.categorizations.subjectief = false;
                position.categorizations.categorizations.objectief = false;
                position.categorizations.categorizations.update = false;
                position.categorizations.categorizations.herformulering = false;
                position.categorizations.categorizations.verplaatsing = false;
                position.categorizations.categorizations.spelfout = false;
                position.categorizations.categorizations.text = null;
                position.categorizations.categorizations.remarks = null;
                position.oldpartsSelected = false;
                position.newpartsSelected = false;
                position.warningSelectAtLeastOne = false;
                position.categorizationSelected = false;
                position.otherPositionsSelected = false;
                position.currentSelected = false;
                for (const otherPos of this.titlepositions) {
                  otherPos.otherPositionsSelected = false;
                  otherPos.currentSelected = false;
                }

                for (const otherPos of this.intropositions) {
                  otherPos.otherPositionsSelected = false;
                  otherPos.currentSelected = false;
                }

                for (const otherPos of this.textpositions) {
                  otherPos.otherPositionsSelected = false;
                  otherPos.currentSelected = false;
                }
              });
            } else {
              position.categorizations.categorizations.anders = false;
              position.categorizations.categorizations.verwijdering = false;
              position.categorizations.categorizations.toevoeging = false;
              position.categorizations.categorizations.subjectief = false;
              position.categorizations.categorizations.objectief = false;
              position.categorizations.categorizations.update = false;
              position.categorizations.categorizations.herformulering = false;
              position.categorizations.categorizations.verplaatsing = false;
              position.categorizations.categorizations.text = null;
              position.categorizations.categorizations.spelfout = false;
              position.categorizations.categorizations.remarks = null;
              position.oldpartsSelected = false;
              position.newpartsSelected = false;
              position.warningSelectAtLeastOne = false;
              position.categorizationSelected = false;
              position.otherPositionsSelected = false;
              position.currentSelected = false;
              for (const otherPos of this.titlepositions) {
                otherPos.otherPositionsSelected = false;
                otherPos.currentSelected = false;
              }

              for (const otherPos of this.intropositions) {
                otherPos.otherPositionsSelected = false;
                otherPos.currentSelected = false;
              }

              for (const otherPos of this.textpositions) {
                otherPos.otherPositionsSelected = false;
                otherPos.currentSelected = false;
              }

            }
          },
          error => {
            position.failed = true;
            position.warning = false;
            position.warningAnders = false;
          });
      } else {
        position.warning = true;
        position.warningAnders = false;
        position.failed = false;
      }
    }
}
  submitVerifications(): void {
    for (let i = 0; i < this.verifications.length; i++){
      const verification = this.verifications[i];
      this.articleService.postVerification(verification).subscribe(data => {
      this.verifications.filter(a => a !== verification);
      this.mistakes.filter(a => a.id !== verification.categorizationid);
      if (i === this.verifications.length - 1) {
        this.articleService.finishVerification(verification.url, verification.newVersion.toString()).subscribe(data2 => {
          this.getArticles();
        });
      }
    });
    }
  }
  wait(ms: number) {
    const start = Date.now();
    let now = start;
    while (now - start < ms) {
      now = Date.now();
    }
  }

  originalPartsExist(sentence: string, positions: Updateposition[]): boolean {
    const position = positions.find(a => a.addedSentences && a.addedSentences
      .map(s => s.sentence).indexOf(sentence) === 0);
    if (position.removedSentences) {
      for (const sent of position.removedSentences) {
        if (sent.changedParts.length > 0) {
          return true;
        }
      }
    }
    return false;
  }
  close(position: Updateposition): void {
    if (position.removedSentences) {
      for (const sent of position.removedSentences) {
        const changedPrts = position.categorizations.originalChangedParts.filter(changedPrt =>
          sent.parnumber === changedPrt.parnumber && sent.sentencenumber === changedPrt.sentencenumber);
        for (const changedPrt of changedPrts) {
          sent.changedParts.push(changedPrt);
        }
      }
    }
    if (position.addedSentences) {
      for (const sent of position.addedSentences) {
        const changedPrts = position.categorizations.newChangedParts.filter(changedPrt =>
          sent.parnumber === changedPrt.parnumber && sent.sentencenumber === changedPrt.sentencenumber);
        for (const changedPrt of changedPrts) {
          sent.changedParts.push(changedPrt);
        }
      }
    }
    position.categorizations.categorizations.anders = false;
    position.categorizations.categorizations.verwijdering = false;
    position.categorizations.categorizations.toevoeging = false;
    position.categorizations.categorizations.subjectief = false;
    position.categorizations.categorizations.objectief = false;
    position.categorizations.categorizations.update = false;
    position.categorizations.categorizations.herformulering = false;
    position.categorizations.categorizations.verplaatsing = false;
    position.categorizations.categorizations.text = null;
    position.categorizations.categorizations.spelfout = false;
    position.categorizations.categorizations.remarks = null;
    position.oldpartsSelected = false;
    position.newpartsSelected = false;
    position.warningSelectAtLeastOne = false;
    position.categorizationSelected = false;
    position.otherPositionsSelected = false;
    position.currentSelected = false;
    for (const otherPos of this.titlepositions) {
      otherPos.otherPositionsSelected = false;
      otherPos.currentSelected = false;
    }

    for (const otherPos of this.intropositions) {
      otherPos.otherPositionsSelected = false;
      otherPos.currentSelected = false;
    }

    for (const otherPos of this.textpositions) {
      otherPos.otherPositionsSelected = false;
      otherPos.currentSelected = false;
    }

  }
  startmessageSelected(sentence: string, positions: Updateposition[], type: string): boolean {
    let currentValue;
    switch (type) {
      case 'original': {
        const position = positions.find(a => a.removedSentences && a.removedSentences
          .map(s => s.sentence).indexOf(sentence) === 0);
        let foundOriginal = false;

        const atLeastChosenOne = positions.filter(a => a.otherPositionsSelected === true);
        if (position.removedSentences) {
          for (const sent of position.removedSentences) {
            if (sent.changedParts.length > 0) {
              foundOriginal = true;
              break;
            }
          }
        }
        let foundNew = false;
        if (position.addedSentences) {
          for (const sent2 of position.addedSentences) {
            if (sent2.changedParts.length > 0) {
              foundNew = true;
              break;
            }
          }
        }
        if ((position.newpartsSelected === true) || (position.otherPositionsSelected === true) || (position.categorized === true)) {
          currentValue = false;
        }
        else {
          if ((position.categorizationSelected === true) &&
            (position.categorizations.originalChangedParts.length === 0)) {
            currentValue = false;
          }
          else {
            if (position.currentSelected === false && foundOriginal === false && foundNew === true){
              currentValue = false;
            }
            else {
              currentValue = true;
            }
          }
        }
        break;
      }
      case 'new': {
        const position = positions.find(a => a.addedSentences && a.addedSentences
          .map(s => s.sentence).indexOf(sentence) === 0);
        const atLeastChosenOne = positions.filter(a => a.otherPositionsSelected === true);
        if ((position.newpartsSelected === true) ||
          ((position.categorizationSelected === true) &&
            (position.categorizations.originalChangedParts.length === 0))
          || ((position.otherPositionsSelected === false) && (position.removedSentences == null))) {
          currentValue = true;
        }
        else {
          let foundOriginal = false;
          if (position.removedSentences) {
            for (const sent of position.removedSentences) {
              if (sent.changedParts.length > 0) {
                foundOriginal = true;
                break;
              }
            }
          }
          if ((position.removedSentences && (position.removedSentences.length > 0) && (foundOriginal === true)
            && (position.categorizationSelected === false)) ||
            (position.otherPositionsSelected === true) ||
            (position.categorized === true)) {
            currentValue = false;
          } else {
            if ((position.categorizationSelected === true) &&
              (position.categorizations.originalChangedParts.length > 0)) {
              currentValue = false;
            }
            else{
              if (position.currentSelected === false && foundOriginal === false){
                currentValue = true;
              }
              else{
                currentValue = false;
              }
            }
          }
        }
        break;
      }
      default: {
        break;
      }
    }
    return currentValue;
  }


  positionSelected(sentence: string, positions: Updateposition[], type: string): void {
    let position;
    switch (type) {
      case 'original': {
        position = positions.find(a => a.removedSentences && a.removedSentences
          .map(s => s.sentence).indexOf(sentence) === 0);
        position.oldpartsSelected = true;
        const categorizationOption: CategorizationOption = {subjectief: false, objectief: false,
          toevoeging: false, verwijdering: false, herformulering: false, update: false,
          verplaatsing: false, spelfout: false, anders: false, text: null, remarks: null};
        const categorizationPart: CategorizationPart = {originalChangedParts: [], newChangedParts: [],
          categorizations: categorizationOption};
        position.categorizations = categorizationPart;
        break;
      }
      case 'new': {
        position = positions.find(a => a.addedSentences && a.addedSentences
          .map(s => s.sentence).indexOf(sentence) === 0);
        position.newpartsSelected = true;
        const categorizationOption: CategorizationOption = {subjectief: false, objectief: false,
          toevoeging: false, verwijdering: false, herformulering: false, update: false,
          verplaatsing: false, spelfout: false, anders: false, text: null, remarks: null};
        const categorizationPart: CategorizationPart = {originalChangedParts: [], newChangedParts: [],
          categorizations: categorizationOption};
        position.categorizations = categorizationPart;
        break;
      }
      default: {
        break;
      }
    }

    position.currentSelected = true;
    for (const otherPos of this.titlepositions) {
      if ((otherPos.oldpartsSelected === false) && (otherPos.newpartsSelected === false)) {
        otherPos.otherPositionsSelected = true;
      }
    }

    for (const otherPos of this.intropositions) {
      if ((otherPos.oldpartsSelected === false) && (otherPos.newpartsSelected === false)) {
        otherPos.otherPositionsSelected = true;
      }
    }

    for (const otherPos of this.textpositions) {
      if ((otherPos.oldpartsSelected === false) && (otherPos.newpartsSelected === false)) {
        otherPos.otherPositionsSelected = true;
      }
    }
  }
  isPositionSelected(position: Updateposition) {
    if ((position.oldpartsSelected === true) || (position.newpartsSelected === true) || (position.categorizationSelected === true)) {
      return false;
    }
    else {
      return true;
    }
  }

  partsSelected(sentence: string, positions: Updateposition[], type: string){
    let position;
    switch (type) {
      case 'original': {
        position = positions.find(a => a.removedSentences && a.removedSentences
          .map(s => s.sentence).indexOf(sentence) === 0);
        position.oldpartsSelected = false;
        let foundNew = false;
        if (position.addedSentences) {
          for (const sent of position.addedSentences) {
            if (sent.changedParts.length > 0) {
              foundNew = true;
              break;
            }
          }
        }
        if (position.addedSentences && position.addedSentences.length > 0 && (foundNew === true)) {
          position.newpartsSelected = true;
          position.warningSelectAtLeastOne = false;
        }
        else {
          if (position.categorizations.originalChangedParts.length === 0){
            position.oldpartsSelected = true;
            position.warningSelectAtLeastOne = true;
          }
          else {
            position.warningSelectAtLeastOne = false;
            position.categorizationSelected = true;
          }
        }
        break;
      }
      case 'new': {
        position = positions.find(a => a.addedSentences && a.addedSentences
          .map(s => s.sentence).indexOf(sentence) === 0);
        let foundOriginal = false;
        if (position.removedSentences) {
          for (const sent of position.removedSentences) {
            if (sent.changedParts.length > 0) {
              foundOriginal = true;
              break;
            }
          }
        }
        if ((position.categorizations.originalChangedParts.length === 0) &&
          (position.categorizations.newChangedParts.length === 0)) {
          if (foundOriginal) {
            position.oldpartsSelected = true;
            position.newpartsSelected = false;
          }
          else {
            position.newpartsSelected = true;
            position.oldpartsSelected = false;
          }
          position.warningSelectAtLeastOne = true;
        }
        else {
          position.newpartsSelected = false;
          position.categorizationSelected = true;
          position.warningSelectAtLeastOne = false;
        }
        break;
      }
      default: {
        break;
      }
    }
  }

  searchFullPrevious(position: Updateposition, changedPrt: ChangedSentencePart, type: string): ChangedSentencePart {
    if (type === 'original') {
      for (const prevChangedPrt of position.categorizations.originalChangedParts) {
        if (prevChangedPrt.start === 0 && prevChangedPrt.end === prevChangedPrt.sentence.length - 1) {
          if ((prevChangedPrt.parnumber === changedPrt.parnumber) &&
            ((prevChangedPrt.sentencenumber === changedPrt.sentencenumber - 1))) {
            return prevChangedPrt;
          }
        }
      }
      let maxSentenceNumber = -1;
      for (const sent of position.removedSentences) {
        if (sent.parnumber === changedPrt.parnumber - 1) {
          maxSentenceNumber = Math.max(maxSentenceNumber, sent.sentencenumber);
        }
      }
      if (maxSentenceNumber > -1) {
        for (const prevChangedPrt of position.categorizations.originalChangedParts) {
          if (prevChangedPrt.start === 0 && prevChangedPrt.end === prevChangedPrt.sentence.length - 1) {
            if ((prevChangedPrt.parnumber === changedPrt.parnumber - 1) &&
              ((prevChangedPrt.sentencenumber === maxSentenceNumber))) {
              return prevChangedPrt;
            }
          }
        }
      }
      return null;
    }
    if (type === 'new') {
      for (const prevChangedPrt of position.categorizations.newChangedParts) {
        if (prevChangedPrt.start === 0 && prevChangedPrt.end === prevChangedPrt.sentence.length - 1) {
          if ((prevChangedPrt.parnumber === changedPrt.parnumber) &&
            ((prevChangedPrt.sentencenumber === changedPrt.sentencenumber - 1))) {
            return prevChangedPrt;
          }
        }
      }
      let maxSentenceNumber = -1;
      for (const sent of position.addedSentences) {
        if (sent.parnumber === changedPrt.parnumber - 1) {
          maxSentenceNumber = Math.max(maxSentenceNumber, sent.sentencenumber);
        }
      }
      if (maxSentenceNumber > -1) {
        for (const prevChangedPrt of position.categorizations.newChangedParts) {
          if (prevChangedPrt.start === 0 && prevChangedPrt.end === prevChangedPrt.sentence.length - 1) {
            if ((prevChangedPrt.parnumber === changedPrt.parnumber - 1) &&
              ((prevChangedPrt.sentencenumber === maxSentenceNumber))) {
              return prevChangedPrt;
            }
          }
        }
      }
      return null;
    }
    return null;
  }

  searchFullNext(position: Updateposition, changedPrt: ChangedSentencePart, type: string) {
    if (type === 'original') {
      for (const followingChangedPrt of position.categorizations.originalChangedParts) {
        if (followingChangedPrt.start === 0 && followingChangedPrt.end === followingChangedPrt.sentence.length - 1) {
          if ((followingChangedPrt.parnumber === changedPrt.parnumber) &&
            ((followingChangedPrt.sentencenumber === changedPrt.sentencenumber + 1))) {
            return followingChangedPrt;
          }
        }
      }

      let maxSentenceNumber = -1;
      for (const sent of position.removedSentences) {
        if (sent.parnumber === changedPrt.parnumber) {
          maxSentenceNumber = Math.max(maxSentenceNumber, sent.sentencenumber);
        }
      }
      if (maxSentenceNumber === changedPrt.sentencenumber) {
        for (const previousChangedPrt of position.categorizations.originalChangedParts) {
          if (previousChangedPrt.start === 0 && previousChangedPrt.end === previousChangedPrt.sentence.length - 1) {
            if ((previousChangedPrt.parnumber === changedPrt.parnumber + 1) &&
              ((previousChangedPrt.sentencenumber === 0))) {
              return previousChangedPrt;
            }
          }
        }
      }
      return null;
    }
    if (type === 'new') {
      for (const followingChangedPrt of position.categorizations.newChangedParts) {
        if (followingChangedPrt.start === 0 && followingChangedPrt.end === followingChangedPrt.sentence.length - 1) {
          if ((followingChangedPrt.parnumber === changedPrt.parnumber) &&
            ((followingChangedPrt.sentencenumber === changedPrt.sentencenumber + 1))) {
            return followingChangedPrt;
          }
        }
      }
      let maxSentenceNumber = -1;
      for (const sent of position.addedSentences) {
        if (sent.parnumber === changedPrt.parnumber) {
          maxSentenceNumber = Math.max(maxSentenceNumber, sent.sentencenumber);
        }
      }
      if (maxSentenceNumber === changedPrt.sentencenumber) {
        for (const followingChangedPrt of position.categorizations.newChangedParts) {
          if (followingChangedPrt.start === 0 && followingChangedPrt.end === followingChangedPrt.sentence.length - 1) {
            if ((followingChangedPrt.parnumber === changedPrt.parnumber + 1) &&
              ((followingChangedPrt.sentencenumber === 0))) {
              return followingChangedPrt;
            }
          }
        }
      }
      return null;
    }
    return null;
  }

  previous(sentence: string, positions: Updateposition[], type: string){
    let position;
    switch (type) {
      case 'original': {
        position = positions.find(a => a.removedSentences && a.removedSentences
          .map(s => s.sentence).indexOf(sentence) === 0);
        const changedPrt = position.categorizations.originalChangedParts.pop();
        for (let i = 0; i < position.removedSentences.length; i++) {
          const sent = position.removedSentences[i];
          if ((sent.parnumber === changedPrt.parnumber) && (sent.sentencenumber === changedPrt.sentencenumber)
            && (sent.sentence === changedPrt.sentence)) {
              sent.changedParts.push(changedPrt);
              if ((position.categorizations.originalChangedParts.length > 0)
                && changedPrt.start === 0 && changedPrt.end === sent.sentence.length - 1) {
                let prevChangedPrt = this.searchFullPrevious(position, changedPrt, type);
                let followingChangedPrt = this.searchFullNext(position, changedPrt, type);
                let q = i;
                while (prevChangedPrt !== null) {
                  position.categorizations.originalChangedParts =
                    position.categorizations.originalChangedParts.filter(a => a !== prevChangedPrt);
                  position.removedSentences[q - 1].changedParts.push(prevChangedPrt);
                  q = q - 1;
                  prevChangedPrt = this.searchFullPrevious(position, prevChangedPrt, type);
                }
                let k = i;
                while (followingChangedPrt !== null) {
                  position.categorizations.originalChangedParts =
                    position.categorizations.originalChangedParts.filter(a => a !== followingChangedPrt);
                  position.removedSentences[k + 1].changedParts.push(followingChangedPrt);
                  k = k + 1;
                  followingChangedPrt = this.searchFullNext(position, followingChangedPrt, type);
                }
              }
            }
        }
        break;
      }
      case 'new': {
        position = positions.find(a => a.addedSentences && a.addedSentences
          .map(s => s.sentence).indexOf(sentence) === 0);
        if (position.categorizations.newChangedParts.length > 0) {
          const changedPrt = position.categorizations.newChangedParts.pop();
          for (let i = 0; i < position.addedSentences.length; i++) {
            const sent = position.addedSentences[i];
            if ((sent.parnumber === changedPrt.parnumber) && (sent.sentencenumber === changedPrt.sentencenumber)
              && (sent.sentence === changedPrt.sentence)) {
              sent.changedParts.push(changedPrt);
              if ((position.categorizations.newChangedParts.length > 0)
                && changedPrt.start === 0 && changedPrt.end === sent.sentence.length - 1) {
                let prevChangedPrt = this.searchFullPrevious(position, changedPrt, type);
                let followingChangedPrt = this.searchFullNext(position, changedPrt, type);
                let q = i;
                while (prevChangedPrt !== null) {
                  position.categorizations.newChangedParts =
                    position.categorizations.newChangedParts.filter(a => a !== prevChangedPrt);
                  position.addedSentences[q - 1].changedParts.push(prevChangedPrt);
                  q = q - 1;
                  prevChangedPrt = this.searchFullPrevious(position, prevChangedPrt, type);
                }
                let k = i;
                while (followingChangedPrt !== null) {
                  position.categorizations.newChangedParts =
                    position.categorizations.newChangedParts.filter(a => a !== followingChangedPrt);
                  position.addedSentences[k + 1].changedParts.push(followingChangedPrt);
                  k = k + 1;
                  followingChangedPrt = this.searchFullNext(position, followingChangedPrt, type);
                }
              }
            }
          }

        }
        else{
          position.oldpartsSelected = true;
          position.newpartsSelected = false;
        }
        break;
      }
      default: {
        break;
      }
    }
  }
  previousCategory(sentence: string, positions: Updateposition[], type: string){
    switch (type) {
      case 'original': {
        const position = positions.find(a => a.removedSentences && a.removedSentences
          .map(s => s.sentence).indexOf(sentence) === 0);
        if (position.categorizations.newChangedParts.length > 0) {
          position.oldpartsSelected = false;
          position.newpartsSelected = true;
          position.categorizationSelected = false;
       }
        else {
          let found = false;
          for (const sent of position.addedSentences) {
            if (sent.changedParts.length > 0){
              found = true;
              break;
            }
          }
          if (found === true){
            position.oldpartsSelected = false;
            position.newpartsSelected = true;
            position.categorizationSelected = false;
          }
          else{
            position.oldpartsSelected = true;
            position.newpartsSelected = false;
            position.categorizationSelected = false;
          }
        }
        position.categorizations.categorizations.anders = false;
        position.categorizations.categorizations.verwijdering = false;
        position.categorizations.categorizations.toevoeging = false;
        position.categorizations.categorizations.subjectief = false;
        position.categorizations.categorizations.objectief = false;
        position.categorizations.categorizations.update = false;
        position.categorizations.categorizations.herformulering = false;
        position.categorizations.categorizations.verplaatsing = false;
        position.categorizations.categorizations.text = null;
        position.categorizations.categorizations.spelfout = false;
        position.categorizations.categorizations.remarks = null;
        position.warningSelectAtLeastOne = false;
        position.warning = false;

        break;
      }
      case 'new': {
        const position = positions.find(a => a.addedSentences && a.addedSentences
          .map(s => s.sentence).indexOf(sentence) === 0);
        position.oldpartsSelected = false;
        position.newpartsSelected = true;
        position.categorizationSelected = false;
        position.categorizations.categorizations.anders = false;
        position.categorizations.categorizations.verwijdering = false;
        position.categorizations.categorizations.toevoeging = false;
        position.categorizations.categorizations.subjectief = false;
        position.categorizations.categorizations.objectief = false;
        position.categorizations.categorizations.update = false;
        position.categorizations.categorizations.herformulering = false;
        position.categorizations.categorizations.verplaatsing = false;
        position.categorizations.categorizations.text = null;
        position.categorizations.categorizations.spelfout = false;
        position.categorizations.categorizations.remarks = null;
        position.warningSelectAtLeastOne = false;
        position.warning = false;
        break;
      }
      default: {
        break;
      }
    }
  }
addChangedPartToCategorizationPart(position: Updateposition, sentence: Sentence, changedPrt: changedPart, type: string) {
    const categorizationPart = position.categorizations;
    if (type === 'original') {
      const originalChangedPart: ChangedSentencePart = {sentence: sentence.sentence, parnumber: sentence.parnumber,
        sentencenumber: sentence.sentencenumber, start: changedPrt.start, end: changedPrt.end};
      categorizationPart.originalChangedParts.push(originalChangedPart);
    }
    else {
      const newChangedPart: ChangedSentencePart = {sentence: sentence.sentence, parnumber: sentence.parnumber,
        sentencenumber: sentence.sentencenumber, start: changedPrt.start, end: changedPrt.end};
      categorizationPart.newChangedParts.push(newChangedPart);
    }
}
  clickMistake(mistake: Mistake): void {
    if (mistake.clicked === false) {
      for (const mistake2 of this.mistakes) {
        mistake2.clicked = false;
      }
      mistake.clicked = true;
    }
    else{
      mistake.clicked = false;
    }
  }
  clickCharacter(positions: Updateposition[], parnumber: number, sentencenumber: number, charnumber: number, type: string): void {
    switch (type) {
      case 'original': {
        let position;
        let found = false;
        for (const pos of positions) {
          if (pos.removedSentences && (found === false)) {
            for (const removedSentence of pos.removedSentences) {
              if ((removedSentence.parnumber === parnumber) && (removedSentence.sentencenumber === sentencenumber)) {
                position = pos;
                found = true;
                break;
              }
            }
          }
        }
        if (position && (position.oldpartsSelected === true)) {
          for (const sentence of position.removedSentences) {
            if ((sentence.parnumber === parnumber) && (sentence.sentencenumber === sentencenumber)) {
              for (const changedPrt of sentence.changedParts) {
                if ((changedPrt.start <= charnumber) && (changedPrt.end >= charnumber)) {
                  this.addChangedPartToCategorizationPart(position, sentence, changedPrt, type);
                  sentence.changedParts = sentence.changedParts.filter(a => a !== changedPrt);
                  const sentenceIndex = position.removedSentences.findIndex(a => a === sentence);
                  if ((sentenceIndex < position.removedSentences.length - 1) && (changedPrt.end === sentence.sentence.length - 1)
                    && (changedPrt.start === 0)
                    && (position.removedSentences[sentenceIndex + 1].changedParts.filter(a => a.start === 0).length > 0))
                  {
                    const followingChangedPart = position.removedSentences[sentenceIndex + 1].changedParts.filter(a => a.start === 0)[0];
                    if (followingChangedPart.end === position.removedSentences[sentenceIndex + 1].sentence.length - 1) {
                      this.clickCharacter(positions, position.removedSentences[sentenceIndex + 1].parnumber,
                        position.removedSentences[sentenceIndex + 1].sentencenumber, 0, 'original');
                    }
                  }
                  if ((sentenceIndex > 0) && (changedPrt.start === 0) && (changedPrt.end === sentence.sentence.length - 1) &&
                    (position.removedSentences[sentenceIndex - 1].changedParts.filter(a => a.start === 0).length > 0)) {
                    const followingChangedPart = position.removedSentences[sentenceIndex - 1].changedParts.filter(a => a.start === 0)[0];
                    if (followingChangedPart.end === position.removedSentences[sentenceIndex - 1].sentence.length - 1) {
                      this.clickCharacter(positions, position.removedSentences[sentenceIndex - 1].parnumber,
                        position.removedSentences[sentenceIndex - 1].sentencenumber, 0, 'original');
                    }
                  }
                  break;
                }
              }
            }
          }
        }
        break;
      }
      case 'new': {
        let position;
        let found = false;
        for (const pos of positions) {
          if (pos.addedSentences && (found === false)) {
            for (const addedSentence of pos.addedSentences) {
              if ((addedSentence.parnumber === parnumber) && (addedSentence.sentencenumber === sentencenumber)) {
                position = pos;
                found = true;
                break;
              }
            }
          }
        }
        if (position && (position.newpartsSelected === true)) {
          for (const sentence of position.addedSentences) {
            if ((sentence.parnumber === parnumber) && (sentence.sentencenumber === sentencenumber)) {
              for (const changedPrt of sentence.changedParts) {
                if ((changedPrt.start <= charnumber) && (changedPrt.end >= charnumber)) {
                  this.addChangedPartToCategorizationPart(position, sentence, changedPrt, type);
                  sentence.changedParts = sentence.changedParts.filter(a => a !== changedPrt);
                  const sentenceIndex = position.addedSentences.findIndex(a => a === sentence);
                  if ((sentenceIndex < position.addedSentences.length - 1) && (changedPrt.end === sentence.sentence.length - 1)
                    && (changedPrt.start === 0)
                    && (position.addedSentences[sentenceIndex + 1].changedParts.filter(a => a.start === 0).length > 0)) {
                    const followingChangedPart = position.addedSentences[sentenceIndex + 1].changedParts.filter(a => a.start === 0)[0];
                    if (followingChangedPart.end === position.addedSentences[sentenceIndex + 1].sentence.length - 1) {
                      this.clickCharacter(positions, position.addedSentences[sentenceIndex + 1].parnumber,
                        position.addedSentences[sentenceIndex + 1].sentencenumber, 0, 'new');
                    }
                  }
                  if ((sentenceIndex > 0) && (changedPrt.start === 0) && (changedPrt.end === sentence.sentence.length - 1) &&
                    (position.addedSentences[sentenceIndex - 1].changedParts.filter(a => a.start === 0).length > 0)) {
                    const followingChangedPart = position.addedSentences[sentenceIndex - 1].changedParts.filter(a => a.start === 0)[0];
                    if (followingChangedPart.end === position.addedSentences[sentenceIndex - 1].sentence.length - 1) {
                      this.clickCharacter(positions, position.addedSentences[sentenceIndex - 1].parnumber,
                        position.addedSentences[sentenceIndex - 1].sentencenumber, 0, 'new');
                    }
                  }
                  break;
                }
              }
            }
          }
        }
        break;
      }
      default: {
        break;
      }
    }
  }

onLogoutClick(){
  this.authService.logout().subscribe(a => {
    localStorage.setItem('submittedLogin', JSON.stringify(false));
    localStorage.setItem('loadingLogin', JSON.stringify(false));
    localStorage.setItem('failedLogin', JSON.stringify(false));
    localStorage.setItem('submittedSignup', JSON.stringify(false));
    localStorage.setItem('loadingSignup', JSON.stringify(false));
    localStorage.setItem('failedSignup', JSON.stringify(false));
    localStorage.setItem('accountCreated', JSON.stringify(false));
    this.router.navigate([this.authService.loginUrl]);
  });
}
  clickReloadButton(){
    this.articleService.reload(this.email, this.newArticle.newspaper, this.newArticle.url,
      this.newArticle.versionNumber.toString()).subscribe(a =>
    {this.router.navigate([this.authService.loginUrl]);
    });
  }
  getNewspaper(newspaper: string): string{
    switch (newspaper) {
      case'vrt': {
        return 'VRT';
      }
      case 'demorgen': {
        return 'De Morgen';
      }
      case 'hln': {
        return 'Het Laatste Nieuws';
      }
      case 'nieuwsblad': {
        return 'Het Nieuwsblad';
      }
      case 'standaard': {
        return 'De Standaard';
      }
      case 'knack': {
        return 'Knack';
      }
      default: {
        return null;
      }
    }
  }
  getPublicationTime(publicationTime: string): string{
    const regex = /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})/;
    return publicationTime.match(regex)[3] + ' ' + this.getMonth(Number(publicationTime.match(regex)[2])) +
      ' ' + publicationTime.match(regex)[1]
      + ' om ' + publicationTime.match(regex)[4] + 'u' + publicationTime.match(regex)[5];
  }
  getMonth(month: number): string{
    const monthStrings = ['januari', 'februari', 'maart', 'april', 'mei', 'juni', 'juli', 'augustus', 'september',
  'oktober', 'november', 'december'];
    return monthStrings[month - 1];
}
  getTooluser(): void {
    this.email = this.authService.currentUserValue.email;
    this.authService.getUser(this.email).subscribe(data => {
      this.firstname = data.firstname;
      this.lastname = data.lastname;
      this.totalCount = data.totalCount;
      this.hasLoggedIn = data.hasLoggedIn;
      this.hasFeedbackCompleted = data.hasFeedbackCompleted;
      this.allArticlesCompleted = data.allArticlesCompleted;
      this.feedbackStage = data.feedbackStage;
      if (this.hasLoggedIn === false) {
        this.router.navigate([this.authService.introUrl]);
      }
      else{
        if (this.hasFeedbackCompleted === false) {
          this.router.navigate([this.authService.feedbackUrl]);
        }
      }
    });
  }
  ngOnInit(): void {
    this.setTitle('UGent Newstracker');
    if (this.authService.currentUserValue) {
      this.getTooluser();
      this.getArticles();
    }
  }

  public setTitle(newTitle: string) {
    this.titleService.setTitle( newTitle );
  }
}
