<!doctype html>
<html lang="en">
<head>
  <title>UGent Newstracker - Signin</title>
</head>
<body>
<nav class="navbar navbar-expand-lg fixed-top navbar-dark bg-company-grey">
  <a class="navbar-brand" href="#"></a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <a class="navbar-brand" target="_blank" href="https://www.ugent.be/" style="padding:0px;">
    <img src="assets/ugentlogo.png" width="163" height="130">
  </a>
  <a class="navbar-brand" target="_blank" href="https://ddcmsite.ugent.be/" style="padding:0px;">
    <img src="assets/ddcmlogo.png" width="272" height="60">
  </a>
  <div class="collapse navbar-collapse text-right" id="navbarright">
    <ul class="navbar-nav ml-auto flex-nowrap">
      <li class="nav-item">
        <a class="nav-link" href="/login" (click)="booleanService.resetLoginBooleans()">Home<span class="sr-only"></span></a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="/project" (click)="booleanService.resetLoginBooleans()">Over dit project</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="/intro" (click)="booleanService.resetLoginBooleans()">Hoe werkt het?</a>
      </li>
      <li class="nav-item">
        <a class="nav-link active" href="/signin" (click)="booleanService.resetSigninBooleans()">Maak een account!<span class="sr-only">(current)</span></a>
      </li>
    </ul>
  </div>
</nav>
<div class="card-body" *ngIf="currentAccountCreatedValue===false">
  <h5 class="card-header">Maak een account aan</h5>
  <div>
    <p>Met het aanmaken van een account op dit platform bevestig ik, ondergetekende, hierbij dat ik: </p>
    <ul>
      <li>de informatie over het onderzoek heb gelezen en begrepen. Ik heb de gelegenheid gehad om de informatie en mijn deelname te overwegen en vragen te stellen die naar mijn tevredenheid werden beantwoord.</li>
      <li>begrijp dat mijn deelname aan dit onderzoek geheel vrijwillig is en dat ik mijn deelname op elk moment tijdens de dataverzameling kan stopzetten zonder dat ik hiervoor een reden moet opgeven. </li>
      <li>begrijp dat ik bij stopzetting van mijn deelname aan dit onderzoek, mijn reeds verzamelde gegevens kan terugtrekken tot en met 1 november 2021. Een eenvoudige e-mail of telefoon naar de onderzoeker volstaat hiervoor. Ik hoef geen reden op te geven voor de terugtrekking van mijn gegevens. </li>
      <li>begrijp dat stopzetting van mijn deelname geen enkele invloed zal hebben op mijn relatie met de onderzoekers of de Universiteit Gent.</li>
      <li>begrijp dat ik mogelijks een financiële compensatie zal ontvangen voor mijn deelname aan dit onderzoek onder de vorm van een cinematicket, maar dat dit bepaald wordt door loting en dus niet gegarandeerd is. </li>
      <li>begrijp dat de data die worden verzameld gedurende deze studie zullen worden verwerkt in overeenstemming met de wettelijke bepalingen en de informatie die mij werd verstrekt.</li>
      <li>ervan op de hoogte ben dat ik een samenvatting van de onderzoeksresultaten kan krijgen.</li>
      <li>hierbij toestemming geef aan de onderzoekers om de onderzoeksresultaten op basis van de informatie die ik verstrek, te publiceren in wetenschappelijke tijdschriften en te bespreken tijdens wetenschappelijke bijeenkomsten op voorwaarde dat mijn identiteit daarbij niet achterhaald kan worden.</li>
      <li>ermee instem deel te nemen aan het onderzoek.</li>
    </ul>
  </div>
  <div>
  <form [formGroup]="signupForm" (ngSubmit)="onSignupSubmit()">
    <div class="form-group">
      <label for="emailSignup">E-mail</label>
      <input type="text" id="emailSignup" [readonly]="currentLoadingSignupValue===true" formControlName="emailSignup" class="form-control" [ngClass]="{ 'is-invalid': currentEmptyEmailSignupValue && signupf.emailSignup.errors }" />
      <div *ngIf="currentEmptyEmailSignupValue && signupf.emailSignup.errors" class="invalid-feedback-signup">
      </div>
      <div style="color:red" *ngIf="currentIncorrectEmailSignupValue" class="IncorrectEmailSignup">Het opgegeven e-mailadres is incorrect!</div>
    </div>
    <div class="form-group">
      <label for="password1Signup">Paswoord</label>
      <input type="password" id="password1Signup" [readonly]="currentLoadingSignupValue===true" formControlName="password1Signup" class="form-control" [ngClass]="{ 'is-invalid': (currentEmptyPassword1SignupValue && signupf.password1Signup.errors) || currentFailedEqualPasswordSignupValue || currentFailedPasswordSignupValue}" />
      <div *ngIf="currentEmptyPassword1SignupValue && signupf.password1Signup.errors" class="invalid-feedback-signup">
      </div>
    </div>
    <div class="form-group">
      <label for="password2Signup">Herhaal paswoord</label>
      <input type="password" id="password2Signup" [readonly]="currentLoadingSignupValue===true" formControlName="password2Signup" class="form-control" [ngClass]="{ 'is-invalid': (currentEmptyPassword2SignupValue && signupf.password2Signup.errors) || currentFailedEqualPasswordSignupValue || currentFailedPasswordSignupValue}" />
      <div *ngIf="currentEmptyPassword2SignupValue && signupf.password2Signup.errors" class="invalid-feedback-signup">
      </div>
      <div style="color:red" *ngIf="currentFailedEqualPasswordSignupValue && !currentEmptyPassword1SignupValue && !currentEmptyPassword2SignupValue" class="failedPasswordSignup">Paswoorden moeten gelijk zijn aan elkaar!</div>
      <div style="color:red" *ngIf="currentFailedPasswordSignupValue" class="failedPasswordSignup">Paswoord moet op zijn minst 8 karakters bevatten en bestaan uit een combinatie van kleine letters, hoofdletters en cijfers!</div>
    </div>
    <div class="form-group">
      <label for="firstname">Voornaam</label>
      <input type="text" id="firstname" [readonly]="currentLoadingSignupValue===true" formControlName="firstname" class="form-control" [ngClass]="{ 'is-invalid': currentEmptyFirstnameSignupValue && signupf.firstname.errors }" />
      <div *ngIf="currentEmptyFirstnameSignupValue && signupf.firstname.errors" class="invalid-feedback-signup">
      </div>
    </div>
    <div class="form-group">
      <label for="lastname">Achternaam</label>
      <input type="text" id="lastname" [readonly]="currentLoadingSignupValue===true" formControlName="lastname" class="form-control" [ngClass]="{ 'is-invalid': currentEmptyLastnameSignupValue && signupf.lastname.errors }" />
      <div *ngIf="currentEmptyLastnameSignupValue && signupf.lastname.errors" class="invalid-feedback-signup">
      </div>
    </div>
    <div class="form-group">
      <input type="checkbox" id="akkoord" [readonly]="currentLoadingSignupValue===true" formControlName="akkoord" class="form-control" [ngClass]="{ 'is-invalid': currentEmptyAkkoordSignupValue && signupf.akkoord.errors }" />
      <label for="akkoord" [ngClass]="{'redclass': currentEmptyAkkoordSignupValue && signupf.akkoord.errors}">Ik bevestig hierbij dat ik bovenstaande informatie heb doorgenomen en weet dat ik, door het aanmaken van een account op deze website, hiermee akkoord ga.</label>
    </div>
    <div class="form-group">
      <input type="checkbox" id="interesse" [readonly]="currentLoadingSignupValue===true" formControlName="interesse" class="form-control" />
      <label for="interesse">Ik wil op de hoogte blijven van de resultaten van het onderzoek.</label>
    </div>
    <button [disabled]="currentLoadingSignupValue" class="btn btn-primary">
      <span *ngIf="currentLoadingSignupValue" class="spinner-border spinner-border-sm mr-1"></span>
      Creëer account!
    </button>
    <div style="color:red" *ngIf="currentFailedExistingSignupValue" class="failedExistingSignup">Er is reeds een account geregistreerd dat geassocieerd is met dit e-mailadres!</div>
    <div style="color:red" *ngIf="currentFailedInternalSignupValue" class="failedInternalSignup">Interne fout opgetreden, gelieve opnieuw te proberen.</div>
  </form>
  </div>
</div>
<div *ngIf="currentAccountCreatedValue" class="accountCreated">Je account werd geregistreerd. Er werd een bevestigingsmail gestuurd naar je e-mailadres. Gelieve op de link in deze mail te klikken om je account te activeren!</div>
</body>
</html>
