import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import { of } from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {map} from 'rxjs/operators';
import { Tooluser } from './tooluser';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private currentUserSubject: BehaviorSubject<Tooluser>;

  public currentUser: Observable<Tooluser>;

  public loginServerUrl = 'https://newstrack.ugent.be/backend/login';
  public signupServerUrl = 'https://newstrack.ugent.be/backend/sign-up';
  public recoverServerUrl = 'https://newstrack.ugent.be/backend/recover';
  public recoverConfirmValidServerUrl = 'https://newstrack.ugent.be/backend/recoverConfirmValid';
  public recoverConfirmServerUrl = 'https://newstrack.ugent.be/backend/recoverConfirm';
  public profileServerUrl = 'https://newstrack.ugent.be/backend/getUser';
  public setLoggedInServerUrl = 'https://newstrack.ugent.be/backend/setLoggedIn';
  public setHasFeedbackCompletedServerUrl = 'https://newstrack.ugent.be/backend/setHasFeedbackCompleted';
  public setFeedbackStageServerUrl = 'https://newstrack.ugent.be/backend/setFeedbackStage';
  /*
  public loginServerUrl = 'https://localhost:443/login';
  public signupServerUrl = 'https://localhost:443/sign-up';
  public recoverServerUrl = 'https://localhost:443/recover';
  public recoverConfirmValidServerUrl = 'https://localhost:443/recoverConfirmValid';
  public recoverConfirmServerUrl = 'https://localhost:443/recoverConfirm';
  public profileServerUrl = 'https://localhost:443/getUser';
  public setLoggedInServerUrl = 'https://localhost:443/setLoggedIn';
  public setHasFeedbackCompletedServerUrl = 'https://localhost:443/setHasFeedbackCompleted';
  public setFeedbackStageServerUrl = 'https://localhost:443/setFeedbackStage';
  */
  public articlesUrl = '/articles';
  public introUrl = '/intro';
  public loginUrl = '/';
  public feedbackUrl = '/feedback';


  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<Tooluser>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): Tooluser {
    return this.currentUserSubject.value;
  }

  getUser(email: string): Observable<any> {
    /*tslint:disable:object-literal-key-quotes */
    return this.http.get<any>(this.profileServerUrl, {params: new HttpParams().set('email', email),
      headers: { 'Accept': 'application/json', 'rejectUnauthorized': 'false', 'insecure': 'true'}});
  }

  setHasLoggedIn(email: string): Observable<any> {
    /*tslint:disable:object-literal-key-quotes */
    const response = this.http.post<any>(this.setLoggedInServerUrl, {},
      {params: new HttpParams().set('email', email), headers: { 'Accept': 'application/json', 'rejectUnauthorized': 'false', 'insecure': 'true'}});
    return response;
  }

  setHasFeedbackCompleted(email: string): Observable<any> {
    /*tslint:disable:object-literal-key-quotes */
    const response = this.http.post<any>(this.setHasFeedbackCompletedServerUrl, {},
      {params: new HttpParams().set('email', email), headers: { 'Accept': 'application/json', 'rejectUnauthorized': 'false', 'insecure': 'true'}});
    return response;
  }

  setFeedbackStage(email: string, feedbackStage: number): Observable<any> {
    /*tslint:disable:object-literal-key-quotes */
    const response = this.http.post<any>(this.setFeedbackStageServerUrl, {},
      {params: new HttpParams().set('email', email).set('feedbackStage', feedbackStage.toString()), headers: { 'Accept': 'application/json', 'rejectUnauthorized': 'false', 'insecure': 'true'}});
    return response;
  }

  signup(email: string, password: string, firstname: string, lastname: string, interesse: boolean): Observable<any> {
    /*tslint:disable:object-literal-key-quotes */
    const response = this.http.post<any>(this.signupServerUrl, {
      'email': email, 'password': password,
      'firstname': firstname, 'lastname': lastname, 'interesse': interesse
    }, {headers: { 'Accept': 'application/json', 'rejectUnauthorized': 'false', 'insecure': 'true'}});
    return response;
  }

  login(email: string, password: string): Observable<any> {
    return this.http.post<any>(this.loginServerUrl, {'email': email, 'password': password}, {headers: { 'Accept': 'application/json', 'rejectUnauthorized': 'false', 'insecure': 'true'}}).pipe(map(
      user => {
        const dateString = user.expiration.year.toString() + '-' + user.expiration.month.toString() + '-' + user.expiration.day.toString()
          + 'T' + user.expiration.hour.toString() + ':' + user.expiration.minute.toString() + ':' + user.expiration.second.toString()
          + '+0200';
        const newUser = {
          'email': user.email, 'password': user.password, 'token': user.token,
          'expiration': new Date(dateString), 'hasLoggedIn': null
        };
        localStorage.setItem('currentUser', JSON.stringify(newUser));
        this.currentUserSubject.next(newUser);
        return newUser;
      }));
  }

  logout(): Observable<any> {
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
    return of('succeeded');
  }

  recover(email: string): Observable<any> {
    /*tslint:disable:object-literal-key-quotes */
    return this.http.post<any>(this.recoverServerUrl, null, {params: new HttpParams().set('email', email),
      headers: { 'Accept': 'application/json', 'rejectUnauthorized': 'false', 'insecure': 'true'}});
  }
  recoverConfirmValid(email: string, token: string): Observable<any> {
    return this.http.get<any>(this.recoverConfirmValidServerUrl, {params: new HttpParams()
        .set('email', email).set('token', token), responseType: 'json',
      headers: { 'Accept': 'application/json', 'rejectUnauthorized': 'false', 'insecure': 'true'}});
  }
  recoverConfirm(email: string, token: string, password: string): Observable<any> {
    return this.http.get<any>(this.recoverConfirmServerUrl, {params: new HttpParams()
        .set('email', email).set('token', token).set('password', password), responseType: 'json',
      headers: { 'Accept': 'application/json', 'rejectUnauthorized': 'false', 'insecure': 'true'}});
  }
}
