<!doctype html>
<html lang="en">
<head>
  <title>UGent Newstracker - Profiel</title>
</head>
<body *ngIf="this.firstname && this.lastname">
<nav class="navbar navbar-expand-lg fixed-top navbar-dark bg-company-grey">
  <a class="navbar-brand" href="#"></a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <a class="navbar-brand" target="_blank" href="https://www.ugent.be/" style="padding:0px;">
    <img src="assets/ugentlogo.png" width="163" height="130">
  </a>
  <a class="navbar-brand" target="_blank" href="https://ddcmsite.ugent.be/" style="padding:0px;">
    <img src="assets/ddcmlogo.png" width="272" height="60">
  </a>
    <div class="collapse navbar-collapse text-right" *ngIf="getImageLoaded()" id="navbarright">
      <div class="abs-center-x" *ngIf="allArticlesCompleted===false && authService.currentUserValue && this.hasLoggedIn===true && this.hasFeedbackCompleted===true" style="display:inline-block" name="ticket">
        <div *ngIf="getNrTickets() < 10" style="display:inline; color:white">
          <ng-container *ngFor="let number of getRange(getNrTickets());">
            <img [src]="getFullTicketWhite()" width="40" height="40">
          </ng-container>
        </div>
        <div *ngIf="getNrTickets() >= 10" style="display:inline; color:white">
          ...
          <ng-container *ngFor="let number of getRange(10);">
            <img [src]="getFullTicketWhite()" width="40" height="40">
          </ng-container>
          ...
        </div>
        <br>
        <img [src]="getTicket()" />
        <p class="completed">Nog {{getRemaining()}} artikels te gaan tot lotje {{getNrTickets() + 1}}!</p>
      </div>
    <ul class="navbar-nav ml-auto flex-nowrap">
      <li class="nav-item">
        <a class="nav-link" href="/articles" *ngIf="authService.currentUserValue && this.hasLoggedIn===true">Nieuw artikelpaar</a>
      </li>
      <li class="nav-item active">
        <a class="nav-link" href="/profile" *ngIf="authService.currentUserValue">{{this.firstname}} {{this.lastname}}<span class="sr-only">(current)</span></a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="/project" target="_blank" *ngIf="authService.currentUserValue">Over dit project</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="/intro" target="_blank" *ngIf="authService.currentUserValue">Hoe werkt het?</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#" *ngIf="authService.currentUserValue" (click)="onLogoutClick()">Uitloggen</a>
      </li>
    </ul>
  </div>
</nav>

<form>
  <p class="center-align"><b>Persoonlijke gegevens</b></p>
   <br>
  <div class="form-group">
    <label for="email">E-mail</label>
    <input type="text" id="email" class="form-control" name="email" [(ngModel)]="this.email" [readonly]="true"/>
  </div>
  <div class="form-group">
  <label for="firstname">Voornaam</label>
  <input type="text" id="firstname" class="form-control" name="firstname" [(ngModel)]="this.firstname" [readonly]="true"/>
  </div>
  <div class="form-group">
  <label for="lastname">Familienaam</label>
  <input type="text" id="lastname" class="form-control" name="lastname" [(ngModel)]="this.lastname" [readonly]="true"/>
  </div>
  <div class="form-group" *ngIf="authService.currentUserValue && this.hasLoggedIn===true">
    <label>Aantal verzamelde lotjes</label>
    <br>
    <ng-container *ngFor="let number of getRange(getNrTickets());">
    <img [src]="getFullTicketBlack()" width="70" height="70">
    </ng-container>
  </div>
  <br>
</form>

</body>
</html>
