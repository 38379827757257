import { Component, OnInit } from '@angular/core';
import {AuthService} from '../auth.service';
import {Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {range} from 'rxjs';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  public email: string;
  public firstname: string;
  public lastname: string;
  public failed: boolean;
  public totalCount: number;
  public imageLoaded: boolean;
  public hasLoggedIn: boolean;
  public hasFeedbackCompleted: boolean;
  public allArticlesCompleted: boolean;
  public feedbackStage: number;

  constructor(public authService: AuthService, private router: Router,
              private titleService: Title) { }

  ngOnInit(): void {
    this.setTitle('UGent Newstracker - Jouw profiel');
    if (this.authService.currentUserValue) {
      this.email = this.authService.currentUserValue.email;
      this.authService.getUser(this.email).subscribe(data => {
          this.imageLoaded = false;
          this.firstname = data.firstname;
          this.lastname = data.lastname;
          this.totalCount = data.totalCount;
          this.imageLoaded = true;
          this.hasLoggedIn = data.hasLoggedIn;
          this.hasFeedbackCompleted = data.hasFeedbackCompleted;
          this.allArticlesCompleted = data.allArticlesCompleted;
          this.feedbackStage = data.feedbackStage;
        },
        error => {
          this.failed = true;
        });
    }
    else{
      this.router.navigate([this.authService.loginUrl]);
    }
  }
  onLogoutClick(){
    this.authService.logout().subscribe(a => {
      localStorage.setItem('submittedLogin', JSON.stringify(false));
      localStorage.setItem('loadingLogin', JSON.stringify(false));
      localStorage.setItem('failedLogin', JSON.stringify(false));
      localStorage.setItem('submittedSignup', JSON.stringify(false));
      localStorage.setItem('loadingSignup', JSON.stringify(false));
      localStorage.setItem('failedSignup', JSON.stringify(false));
      localStorage.setItem('accountCreated', JSON.stringify(false));
      this.router.navigate([this.authService.loginUrl]);
    });
  }

  public setTitle( newTitle: string) {
    this.titleService.setTitle( newTitle );
  }
  getTicket(): string {
    if (this.totalCount >= 0) {
      if (this.totalCount < 50) {
        return 'assets/ticket_' + ((50 - this.getRemaining()) * 2).toString() + '.png';
      }
      else{
        return 'assets/ticket_' + ((25 - this.getRemaining()) * 4).toString() + '.png';
      }
    }
    else{
      return 'assets/ticket_0.png';
    }
  }
  getFullTicketBlack(): string {
    return 'assets/ticket_black.png';
  }
  getFullTicketWhite(): string {
    return 'assets/ticket_100.png';
  }
  getRange(nrTickets: number): number[] {
    const ticketRange = [];
    for (let i = 0; i < nrTickets; i++){
      ticketRange[i] = i;
    }
    return ticketRange;
  }
  getRemaining(): number {
    if (this.totalCount < 50){
      return 50 - this.totalCount;
    }
    if ((this.totalCount === 50) || (this.totalCount > 50 && ((this.totalCount - 50) % 25) === 0)){
      return 25;
    }
    else {
      return Math.ceil((this.totalCount - 50) / 25) * 25 - (this.totalCount - 50);
    }
  }
  getNrTickets(): number {
    if (this.totalCount < 50) {
      return 0;
    } else {
      return Math.floor((this.totalCount - 50) / 25) + 1;
    }
  }
  setImageLoaded(): void {
    this.imageLoaded = true;
  }
  getImageLoaded(): boolean {
    return this.imageLoaded;
  }
}
