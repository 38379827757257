import {Component, Input, OnInit} from '@angular/core';
import { Article } from '../article';
import { Sentence } from '../sentence';
import { ArticleService } from '../article.service';
import {Updateposition} from '../updateposition';
import {Categorization} from '../categorization';
import {CategorizationOption} from '../categorizationOption';
import {AuthService} from '../auth.service';
import {Router} from '@angular/router';
import {NgForm} from '@angular/forms';
import {Title} from '@angular/platform-browser';
import {changedPart} from '../changedPart';
import {CategorizationPart} from '../categorizationPart';
import {ChangedSentencePart} from '../changedSentencePart';
import {FeedbackSolution} from '../feedbackSolution';
import {FeedbackSolutionDict} from '../feedbackSolutionDict';
@Component({
  selector: 'app-feedbacj',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css']
})
export class FeedbackComponent implements OnInit {
  oldArticle: Article;
  newArticle: Article;
  titlepositions: Updateposition[];
  intropositions: Updateposition[];
  textpositions: Updateposition[];
  tagsremoved: string[];
  tagsadded: string[];
  failed: boolean;
  profileUrl = '/profile';
  email: string;
  firstname: string;
  lastname: string;
  hasLoggedIn: boolean;
  feedbackCompleted: boolean;
  scores: number[];
  correctsolutionChosen: boolean[];
  yoursolutionChosen: boolean[];
  feedbackChosen: boolean[];
  feedbackUrls: string[];
  feedbacksolutions: FeedbackSolutionDict[];
  hasFeedbackCompleted: boolean;
  feedbackStage: number;
  allArticlesCompleted: boolean;
  totalCount: number;
  seriesnumber: number;
  imageLoaded: boolean;
  constructor(private articleService: ArticleService, public authService: AuthService, private router: Router,
              private titleService: Title) { }
  getArticles(): void{
    this.feedbackCompleted = false;
    this.correctsolutionChosen = [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false];
    this.yoursolutionChosen = [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false];
    this.feedbackChosen = [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false];
    this.feedbackUrls = ['https://www.knack.be/nieuws/wereld/donald-trump-waarschuwt-belgie-betalen-zullen-ze/article-news-1539459.html',
      'https://www.demorgen.be/nieuws/emmanuel-andre-en-maggie-de-block-botsen-over-wereldwijd-slechtste-belgische-aanpak-coronacrisis~bc4c1c7d/',
      'https://www.vrt.be/vrtnws/nl/2019/12/13/wat-komt-er-straks-uit-de-bus-op-de-klimaatconferentie/',
      'https://www.vrt.be/vrtnws/nl/2019/10/09/nobelprijs-voor-scheikunde/',
      'https://www.vrt.be/vrtnws/nl/2019/10/14/nepfilmpje-met-moordende-president-donald-trump-laait-gemoederen/',
      'https://www.standaard.be/cnt/dmf20200130_04827598',
      'https://www.knack.be/nieuws/belgie/vivaldi-ploeg-wil-vanaf-2026-alleen-nog-elektrische-bedrijfswagens/article-news-1642505.html',
      'https://www.hln.be/binnenland/versoepelingen-steeds-verder-weg-door-slechte-cijfers-als-we-blijven-hangen-zijn-er-eventueel-andere-maatregelen-nodig~af3565cd/',
      'https://sporza.be/nl/matches/voetbal/buitenland/fra/ligue-1-uber-eats/2020-2021/regulier/11/stade-rennes-girondins-de-bordeaux-invaller-jeremy-doku-treft-de-paal-en-verliest-opnieuw-met-rennes~1594309682473/?linkId=sporza%7Cvrtnws%7C%2Fnl%2Fnet-binnen%7Cteaser',
      'https://www.nieuwsblad.be/cnt/dmf20200115_04806821',
      'https://www.demorgen.be/nieuws/430-000-sms-en-voor-zieke-baby-pia-proximus-en-telenet-laten-kosten-vallen~bb5c3b48/',
      'https://www.hln.be/wetenschap-planeet/medisch/amerikaans-biotechbedrijf-start-eind-deze-maand-laatste-fase-klinische-studie-voor-coronavaccin~a47c4846/',
      'https://www.nieuwsblad.be/cnt/dmf20191126_04738694',
      'https://www.standaard.be/cnt/dmf20191129_04743838',
      'https://www.hln.be/in-de-buurt/antwerpen/auto-rijdt-in-op-vrachtwagen-e313-in-ranst-volledig-versperd~a553742c/'];
    this.scores = null;
    this.feedbacksolutions = [];
    let email = null;
    if (this.authService.currentUserValue){
      email = this.authService.currentUserValue.email;
    }
    this.articleService.getFeedbackArticles(email).subscribe(data => {
        /* tslint:disable:no-string-literal */
        /* tslint:disable:object-literal-shorthand */
        this.totalCount = data.feedback_number % 5;
        this.seriesnumber = data.feedback_number / 5;
        this.imageLoaded = false;
        const response = data.articleDiff;
        this.oldArticle = response['originalArticle'];
        this.newArticle = response['newArticle'];
        this.titlepositions = [];
        const originaltitlelengths = this.oldArticle.titleParts.map(a => a.length);
        const originaltitlenumbers = [];
        let sum = 0;
        let groupnrOriginal = 0;
        let groupnrNew = 0;
        for (length of originaltitlelengths) {
          originaltitlenumbers.push(sum);
          sum = sum + length;
        }
        const newtitlelengths = this.newArticle.titleParts.map(a => a.length);
        const newtitlenumbers = [];
        sum = 0;
        for (length of newtitlelengths) {
          newtitlenumbers.push(sum);
          sum = sum + length;
        }
        for (const titledelta of response['titlePatch'].deltas) {
          const removed = [];
          const added = [];
          for (let i = 0; i < titledelta.source.lines.length; i++) {
            const smallernumbers = originaltitlenumbers.filter(a => a <= titledelta.source.position + i);
            const changedParts = [];
            for (const changed of titledelta.source.lines[i].changedParts) {
              const changedObj: changedPart = {start: changed.start, end: changed.end};
              changedParts.push(changedObj);
            }
            const sen: Sentence = {
              sentence: titledelta.source.lines[i].sentence,
              sentencenumber: titledelta.source.position + i - smallernumbers[smallernumbers.length - 1],
              parnumber: smallernumbers.length - 1, changedParts: changedParts
            };
            removed.push(sen);
          }
          for (let i = 0; i < titledelta.target.lines.length; i++) {
            const smallernumbers = newtitlenumbers.filter(a => a <= titledelta.target.position + i);
            const changedParts = [];
            for (const changed of titledelta.target.lines[i].changedParts) {
              const changedObj: changedPart = {start: changed.start, end: changed.end};
              changedParts.push(changedObj);
            }
            const sen: Sentence = {
              sentence: titledelta.target.lines[i].sentence,
              sentencenumber: titledelta.target.position + i - smallernumbers[smallernumbers.length - 1],
              parnumber: smallernumbers.length - 1, changedParts: changedParts
            };
            added.push(sen);
          }
          if (titledelta.type === 'CHANGE') {
            const position: Updateposition = {
              textpart: 'title', removedSentences: removed, addedSentences: added, groupnrOriginal: groupnrOriginal, groupnrNew: groupnrNew,
              categorizations: null, warning: false, warningAnders: false, failed: false,
              otherPositionsSelected: false, currentSelected: false,
              newpartsSelected: false, oldpartsSelected: false, categorizationSelected: false,
              categorized: false, warningSelectAtLeastOne: false
            };
            this.titlepositions.push(position);
            groupnrOriginal = groupnrOriginal + 1;
            groupnrNew = groupnrNew + 1;
          }
          if (titledelta.type === 'INSERT') {
            const position: Updateposition = {
              textpart: 'title', removedSentences: null, addedSentences: added, groupnrOriginal: null, groupnrNew: groupnrNew,
              categorizations: null, warning: false, warningAnders: false, failed: false,
              otherPositionsSelected: false, currentSelected: false,
              newpartsSelected: false, oldpartsSelected: false, categorizationSelected: false,
              categorized: false, warningSelectAtLeastOne: false
            };
            this.titlepositions.push(position);
            groupnrNew = groupnrNew + 1;
          }
          if (titledelta.type === 'DELETE') {
            const position: Updateposition = {
              textpart: 'title', removedSentences: removed, addedSentences: null,
              groupnrOriginal: groupnrOriginal, groupnrNew: null,
              categorizations: null, warning: false, warningAnders: false, failed: false,
              otherPositionsSelected: false, currentSelected: false,
              newpartsSelected: false, oldpartsSelected: false, categorizationSelected: false,
              categorized: false, warningSelectAtLeastOne: false
            };
            this.titlepositions.push(position);
            groupnrOriginal = groupnrOriginal + 1;
          }
        }
        this.intropositions = [];
        const originalintrolengths = this.oldArticle.introParts.map(a => a.length);
        const originalintronumbers = [];
        sum = 0;
        for (length of originalintrolengths) {
          originalintronumbers.push(sum);
          sum = sum + length;
        }
        const newintrolengths = this.newArticle.introParts.map(a => a.length);
        const newintronumbers = [];
        sum = 0;
        for (length of newintrolengths) {
          newintronumbers.push(sum);
          sum = sum + length;
        }
        for (const introdelta of response['introPatch'].deltas) {
          const removed = [];
          const added = [];
          for (let i = 0; i < introdelta.source.lines.length; i++) {
            const smallernumbers = originalintronumbers.filter(a => a <= introdelta.source.position + i);
            const changedParts = [];
            for (const changed of introdelta.source.lines[i].changedParts) {
              const changedObj: changedPart = {start: changed.start, end: changed.end};
              changedParts.push(changedObj);
            }
            const sen: Sentence = {
              sentence: introdelta.source.lines[i].sentence,
              sentencenumber: introdelta.source.position + i - smallernumbers[smallernumbers.length - 1],
              parnumber: smallernumbers.length - 1, changedParts: changedParts
            };
            removed.push(sen);
          }
          for (let i = 0; i < introdelta.target.lines.length; i++) {
            const smallernumbers = newintronumbers.filter(a => a <= introdelta.target.position + i);
            const changedParts = [];
            for (const changed of introdelta.target.lines[i].changedParts) {
              const changedObj: changedPart = {start: changed.start, end: changed.end};
              changedParts.push(changedObj);
            }
            const sen: Sentence = {
              sentence: introdelta.target.lines[i].sentence,
              sentencenumber: introdelta.target.position + i - smallernumbers[smallernumbers.length - 1],
              parnumber: smallernumbers.length - 1, changedParts: changedParts
            };
            added.push(sen);
          }
          if (introdelta.type === 'CHANGE') {
            const position: Updateposition = {
              textpart: 'intro', removedSentences: removed, addedSentences: added,
              groupnrNew: groupnrNew, groupnrOriginal: groupnrOriginal,
              categorizations: null, warning: false, warningAnders: false, failed: false,
              otherPositionsSelected: false, currentSelected: false,
              newpartsSelected: false, oldpartsSelected: false, categorizationSelected: false,
              categorized: false, warningSelectAtLeastOne: false
            };
            this.intropositions.push(position);
            groupnrOriginal = groupnrOriginal + 1;
            groupnrNew = groupnrNew + 1;
          }
          if (introdelta.type === 'INSERT') {
            const position: Updateposition = {
              textpart: 'intro', removedSentences: null, addedSentences: added,
              groupnrOriginal: null, groupnrNew: groupnrNew,
              categorizations: null, warning: false, warningAnders: false, failed: false,
              otherPositionsSelected: false, currentSelected: false,
              newpartsSelected: false, oldpartsSelected: false, categorizationSelected: false,
              categorized: false, warningSelectAtLeastOne: false
            };
            this.intropositions.push(position);
            groupnrNew = groupnrNew + 1;
          }
          if (introdelta.type === 'DELETE') {
            const position: Updateposition = {
              textpart: 'intro', removedSentences: removed, addedSentences: null,
              groupnrOriginal: groupnrOriginal, groupnrNew: null,
              categorizations: null, warning: false, warningAnders: false, failed: false,
              otherPositionsSelected: false, currentSelected: false,
              newpartsSelected: false, oldpartsSelected: false, categorizationSelected: false,
              categorized: false, warningSelectAtLeastOne: false
            };
            this.intropositions.push(position);
            groupnrOriginal = groupnrOriginal + 1;
          }
        }
        this.textpositions = [];
        const originaltextlengths = this.oldArticle.textParts.map(a => a.length);
        const originaltextnumbers = [];
        sum = 0;
        for (length of originaltextlengths) {
          originaltextnumbers.push(sum);
          sum = sum + length;
        }
        const newtextlengths = this.newArticle.textParts.map(a => a.length);
        const newtextnumbers = [];
        sum = 0;
        for (length of newtextlengths) {
          newtextnumbers.push(sum);
          sum = sum + length;
        }
        for (const textdelta of response['textPatch'].deltas) {
          const removed = [];
          const added = [];
          for (let i = 0; i < textdelta.source.lines.length; i++) {
            const smallernumbers = originaltextnumbers.filter(a => a <= textdelta.source.position + i);
            const changedParts = [];
            for (const changed of textdelta.source.lines[i].changedParts) {
              const changedObj: changedPart = {start: changed.start, end: changed.end};
              changedParts.push(changedObj);
            }
            const sen: Sentence = {
              sentence: textdelta.source.lines[i].sentence,
              sentencenumber: textdelta.source.position + i - smallernumbers[smallernumbers.length - 1],
              parnumber: smallernumbers.length - 1, changedParts: changedParts
            };
            removed.push(sen);
          }
          for (let i = 0; i < textdelta.target.lines.length; i++) {
            const smallernumbers = newtextnumbers.filter(a => a <= textdelta.target.position + i);
            const changedParts = [];
            for (const changed of textdelta.target.lines[i].changedParts) {
              const changedObj: changedPart = {start: changed.start, end: changed.end};
              changedParts.push(changedObj);
            }
            const sen: Sentence = {
              sentence: textdelta.target.lines[i].sentence,
              sentencenumber: textdelta.target.position + i - smallernumbers[smallernumbers.length - 1],
              parnumber: smallernumbers.length - 1, changedParts: changedParts
            };
            added.push(sen);
          }
          if (textdelta.type === 'CHANGE') {
            const position: Updateposition = {
              textpart: 'text', removedSentences: removed, addedSentences: added,
              groupnrOriginal: groupnrOriginal, groupnrNew: groupnrNew,
              categorizations: null, warning: false, warningAnders: false, failed: false,
              otherPositionsSelected: false, currentSelected: false,
              newpartsSelected: false, oldpartsSelected: false, categorizationSelected: false,
              categorized: false, warningSelectAtLeastOne: false
            };
            this.textpositions.push(position);
            groupnrOriginal = groupnrOriginal + 1;
            groupnrNew = groupnrNew + 1;
          }
          if (textdelta.type === 'INSERT') {
            const position: Updateposition = {
              textpart: 'text', removedSentences: null, addedSentences: added,
              groupnrOriginal: null, groupnrNew: groupnrNew,
              categorizations: null, warning: false, warningAnders: false, failed: false,
              otherPositionsSelected: false, currentSelected: false,
              newpartsSelected: false, oldpartsSelected: false, categorizationSelected: false,
              categorized: false, warningSelectAtLeastOne: false
            };
            this.textpositions.push(position);
            groupnrNew = groupnrNew + 1;
          }
          if (textdelta.type === 'DELETE') {
            const position: Updateposition = {
              textpart: 'text', removedSentences: removed, addedSentences: null,
              groupnrOriginal: groupnrOriginal, groupnrNew: null,
              categorizations: null, warning: false, warningAnders: false, failed: false,
              otherPositionsSelected: false, currentSelected: false,
              newpartsSelected: false, oldpartsSelected: false, categorizationSelected: false,
              categorized: false, warningSelectAtLeastOne: false
            };
            this.textpositions.push(position);
            groupnrOriginal = groupnrOriginal + 1;
          }
        }
        this.tagsadded = response['tagsadded'];
        this.tagsremoved = response['tagsremoved'];
        this.wait(1000);
        this.imageLoaded = true;
      },
      error => {
        if ((error.status === 400) && (error.error.message === 'Feedback 1 completed.')) {
          this.feedbackCompleted = true;
          this.totalCount = 5;
          this.seriesnumber = 1;
          this.wait(1000);
          this.imageLoaded = true;
          this.getFeedback();
        } else {
          if ((error.status === 400) && (error.error.message === 'Feedback 2 completed.')) {
            this.feedbackCompleted = true;
            this.totalCount = 5;
            this.seriesnumber = 2;
            this.wait(1000);
            this.imageLoaded = true;
            this.getFeedback();
          } else {
            if ((error.status === 400) && (error.error.message === 'Feedback 3 completed.')) {
              this.feedbackCompleted = true;
              this.totalCount = 5;
              this.seriesnumber = 3;
              this.wait(1000);
              this.imageLoaded = true;
              this.getFeedback();
            } else {
              this.failed = true;
            }
          }
        }
      }
    );
  }
  getFeedback(): void {
    this.articleService.calculateScores(this.email).subscribe(data => {
      this.scores = data.scores;
      for (const url of this.feedbackUrls){
        this.articleService.getFeedbacksolution(this.email, url).subscribe(data2 => {
          const feedbackdict: FeedbackSolutionDict = {url: url, feedbackSolutions: data2.solutions};
          this.feedbacksolutions.push(feedbackdict);
        });
      }
    }
    );
  }
  charInUpdate(positions: Updateposition[], parnumber: number, sentencenumber: number, charnumber: number, type: string): boolean {
    switch (type) {
      case 'original': {
        for (const sentence of [].concat(...positions.map(position => position.removedSentences).filter(a => a))){
          if ((sentence.parnumber === parnumber) && (sentence.sentencenumber === sentencenumber)){
            for (const changedObj of sentence.changedParts) {
              if (charnumber >= changedObj.start && charnumber <= changedObj.end){
                return true;
              }
            }
          }
        }
        break;
      }
      case 'new': {
        for (const sentence of [].concat(...positions.map(position => position.addedSentences).filter(a => a))){
          if ((sentence.parnumber === parnumber) && (sentence.sentencenumber === sentencenumber)){
            for (const changedObj of sentence.changedParts) {
              if (charnumber >= changedObj.start && charnumber <= changedObj.end){
                return true;
              }
            }
          }
        }
        break;
      }
      default: {
        break;
      }
    }
    return false;
  }
  sentenceInUpdate(positions: Updateposition[], parnumber: number, sentencenumber: number, type: string): boolean {
    switch (type) {
      case 'original': {
        for (const sentence of [].concat(...positions.map(position => position.removedSentences).filter(a => a))){
          if ((sentence.parnumber === parnumber) && (sentence.sentencenumber === sentencenumber)){
            return true;
          }
        }
        break;
      }
      case 'new': {
        for (const sentence of [].concat(...positions.map(position => position.addedSentences).filter(a => a))){
          if ((sentence.parnumber === parnumber) && (sentence.sentencenumber === sentencenumber)){
            return true;
          }
        }
        break;
      }
      default: {
        break;
      }
    }
    return false;
  }
  contentInSentence(sentence: string): boolean {
    if (sentence.length <= 1){
      return false;
    }
    else{
      return true;
    }
  }
  firstUpdateSentence(positions: Updateposition[], parnumber: number, sentencenumber: number, type: string): boolean {
    switch (type) {
      case 'original': {
        for (const sentence of [].concat(...positions.filter(a => a.removedSentences).map(a => a.removedSentences[0]))){
          if ((sentence.parnumber === parnumber) && (sentence.sentencenumber === sentencenumber)){
            return true;
          }
        }
        break;
      }
      case 'new': {
        for (const sentence of [].concat(...positions.filter(a => a.addedSentences).map(a => a.addedSentences[0]))){
          if ((sentence.parnumber === parnumber) && (sentence.sentencenumber === sentencenumber)){
            return true;
          }
        }
        break;
      }
      default: {
        break;
      }
    }
    return false;
  }
  getCorrespondingPosition(positions: Updateposition[], parnumber: number, sentencenumber: number, type: string): Updateposition {
    if (type === 'original') {
      for (const position of positions) {
        if (position.removedSentences) {
          for (const sentence of position.removedSentences) {
            if ((sentence.parnumber === parnumber) && (sentence.sentencenumber === sentencenumber)) {
              return position;
            }
          }
        }
      }
    }
    if (type === 'new') {
      for (const position of positions) {
        if (position.addedSentences) {
          for (const sentence of position.addedSentences) {
            if ((sentence.parnumber === parnumber) && (sentence.sentencenumber === sentencenumber)) {
              return position;
            }
          }
        }
      }
    }
  }
  getLastCategorizationOption(positions: Updateposition[], parnumber: number, sentencenumber: number, type: string): CategorizationOption {
    const position = this.getCorrespondingPosition(positions, parnumber, sentencenumber, type);
    return position.categorizations.categorizations;
  }
  submit(position: Updateposition): void {
    if (position.categorizations.categorizations.anders &&
      !position.categorizations.categorizations.text){
      position.warningAnders = true;
      position.warning = false;
      position.failed = false;
    }
    else {
      if (position.categorizations.categorizations.subjectief ||
        position.categorizations.categorizations.herformulering
        || position.categorizations.categorizations.update
        || position.categorizations.categorizations.objectief
        || position.categorizations.categorizations.toevoeging ||
        position.categorizations.categorizations.verwijdering
        || position.categorizations.categorizations.verplaatsing
        || position.categorizations.categorizations.spelfout
        || (position.categorizations.categorizations.anders
          && position.categorizations.categorizations.text)) {
        const categorization: Categorization = {feedback: true,
          email: this.authService.currentUserValue.email, newspaper: this.oldArticle.newspaper,
          url: this.oldArticle.url, timeSubmitted: Date.now(), versionOriginal: this.oldArticle.versionNumber,
          versionNew: this.newArticle.versionNumber, groupnrOriginal: position.groupnrOriginal,
          groupnrNew: position.groupnrNew, textpart: position.textpart, removedSentences: position.removedSentences,
          addedSentences: position.addedSentences, categorizations: position.categorizations
        };
        this.articleService.postCategorization(categorization).subscribe(data => {
            let foundOriginal = false;
            if (position.removedSentences) {
              for (const sent2 of position.removedSentences) {
                if (sent2.changedParts.length > 0) {
                  foundOriginal = true;
                  break;
                }
              }
            }
            let foundNew = false;
            if (position.addedSentences) {
              for (const sent2 of position.addedSentences) {
                if (sent2.changedParts.length > 0) {
                  foundNew = true;
                  break;
                }
              }
            }
            switch (position.textpart) {
              case 'title': {
                if ((foundOriginal === false) && (foundNew === false)) {
                  this.titlepositions = this.titlepositions.filter(a => a !== position);
                  position.categorized = true;
                }
                break;
              }
              case 'intro': {
                if ((foundOriginal === false) && (foundNew === false)) {
                  this.intropositions = this.intropositions.filter(a => a !== position);
                  position.categorized = true;
                }
                break;
              }
              case 'text': {
                if ((foundOriginal === false) && (foundNew === false)) {
                  this.textpositions = this.textpositions.filter(a => a !== position);
                  position.categorized = true;
                }
                break;
              }
              default: {
                break;
              }
            }
            if (this.titlepositions.length + this.intropositions.length + this.textpositions.length === 0) {
              this.oldArticle = null;
              this.newArticle = null;
              this.getArticles();
            }
            position.categorizations.categorizations.anders = false;
            position.categorizations.categorizations.spelfout = false;
            position.categorizations.categorizations.verwijdering = false;
            position.categorizations.categorizations.toevoeging = false;
            position.categorizations.categorizations.subjectief = false;
            position.categorizations.categorizations.objectief = false;
            position.categorizations.categorizations.update = false;
            position.categorizations.categorizations.herformulering = false;
            position.categorizations.categorizations.verplaatsing = false;
            position.categorizations.categorizations.text = null;
            position.categorizations.categorizations.remarks = null;
            position.oldpartsSelected = false;
            position.newpartsSelected = false;
            position.warningSelectAtLeastOne = false;
            position.categorizationSelected = false;
            position.otherPositionsSelected = false;
            position.currentSelected = false;
            for (const otherPos of this.titlepositions) {
              otherPos.otherPositionsSelected = false;
              otherPos.currentSelected = false;
            }

            for (const otherPos of this.intropositions) {
              otherPos.otherPositionsSelected = false;
              otherPos.currentSelected = false;
            }

            for (const otherPos of this.textpositions) {
              otherPos.otherPositionsSelected = false;
              otherPos.currentSelected = false;
            }

          },
          error => {
            position.failed = true;
            position.warning = false;
            position.warningAnders = false;
          });
      } else {
        position.warning = true;
        position.warningAnders = false;
        position.failed = false;
      }
    }
  }
  wait(ms: number) {
    const start = Date.now();
    let now = start;
    while (now - start < ms) {
      now = Date.now();
    }
  }

  startmessageSelected(sentence: string, positions: Updateposition[], type: string): boolean {
    let currentValue;
    switch (type) {
      case 'original': {
        const position = positions.find(a => a.removedSentences && a.removedSentences
          .map(s => s.sentence).indexOf(sentence) === 0);
        let foundOriginal = false;

        const atLeastChosenOne = positions.filter(a => a.otherPositionsSelected === true);
        if (position.removedSentences) {
          for (const sent of position.removedSentences) {
            if (sent.changedParts.length > 0) {
              foundOriginal = true;
              break;
            }
          }
        }
        let foundNew = false;
        if (position.addedSentences) {
          for (const sent2 of position.addedSentences) {
            if (sent2.changedParts.length > 0) {
              foundNew = true;
              break;
            }
          }
        }
        if ((position.newpartsSelected === true) || (position.otherPositionsSelected === true) || (position.categorized === true)) {
          currentValue = false;
        }
        else {
          if ((position.categorizationSelected === true) &&
            (position.categorizations.originalChangedParts.length === 0)) {
            currentValue = false;
          }
          else {
            if (position.currentSelected === false && foundOriginal === false && foundNew === true){
              currentValue = false;
            }
            else {
              currentValue = true;
            }
          }
        }
        break;
      }
      case 'new': {
        const position = positions.find(a => a.addedSentences && a.addedSentences
          .map(s => s.sentence).indexOf(sentence) === 0);
        const atLeastChosenOne = positions.filter(a => a.otherPositionsSelected === true);
        if ((position.newpartsSelected === true) ||
          ((position.categorizationSelected === true) &&
            (position.categorizations.originalChangedParts.length === 0))
          || ((position.otherPositionsSelected === false) && (position.removedSentences == null))) {
          currentValue = true;
        }
        else {
          let foundOriginal = false;
          if (position.removedSentences) {
            for (const sent of position.removedSentences) {
              if (sent.changedParts.length > 0) {
                foundOriginal = true;
                break;
              }
            }
          }
          if ((position.removedSentences && (position.removedSentences.length > 0) && (foundOriginal === true)
            && (position.categorizationSelected === false)) ||
            (position.otherPositionsSelected === true) ||
            (position.categorized === true)) {
            currentValue = false;
          } else {
            if ((position.categorizationSelected === true) &&
              (position.categorizations.originalChangedParts.length > 0)) {
              currentValue = false;
            }
            else{
              if (position.currentSelected === false && foundOriginal === false){
                currentValue = true;
              }
              else{
                currentValue = false;
              }
            }
          }
        }
        break;
      }
      default: {
        break;
      }
    }
    return currentValue;
  }


  positionSelected(sentence: string, positions: Updateposition[], type: string): void {
    let position;
    switch (type) {
      case 'original': {
        position = positions.find(a => a.removedSentences && a.removedSentences
          .map(s => s.sentence).indexOf(sentence) === 0);
        position.oldpartsSelected = true;
        const categorizationOption: CategorizationOption = {subjectief: false, objectief: false,
          toevoeging: false, verwijdering: false, herformulering: false, update: false,
          verplaatsing: false, spelfout: false, anders: false, text: null, remarks: null};
        const categorizationPart: CategorizationPart = {originalChangedParts: [], newChangedParts: [],
          categorizations: categorizationOption};
        position.categorizations = categorizationPart;
        break;
      }
      case 'new': {
        position = positions.find(a => a.addedSentences && a.addedSentences
          .map(s => s.sentence).indexOf(sentence) === 0);
        position.newpartsSelected = true;
        const categorizationOption: CategorizationOption = {subjectief: false, objectief: false,
          toevoeging: false, verwijdering: false, herformulering: false, update: false,
          verplaatsing: false, spelfout: false, anders: false, text: null, remarks: null};
        const categorizationPart: CategorizationPart = {originalChangedParts: [], newChangedParts: [],
          categorizations: categorizationOption};
        position.categorizations = categorizationPart;
        break;
      }
      default: {
        break;
      }
    }
    position.currentSelected = true;
    for (const otherPos of this.titlepositions) {
      if ((otherPos.oldpartsSelected === false) && (otherPos.newpartsSelected === false)) {
        otherPos.otherPositionsSelected = true;
      }
    }

    for (const otherPos of this.intropositions) {
      if ((otherPos.oldpartsSelected === false) && (otherPos.newpartsSelected === false)) {
        otherPos.otherPositionsSelected = true;
      }
    }

    for (const otherPos of this.textpositions) {
      if ((otherPos.oldpartsSelected === false) && (otherPos.newpartsSelected === false)) {
        otherPos.otherPositionsSelected = true;
      }
    }
  }
  isPositionSelected(position: Updateposition) {
    if ((position.oldpartsSelected === true) || (position.newpartsSelected === true) || (position.categorizationSelected === true)) {
      return false;
    }
    else {
      return true;
    }
  }

  partsSelected(sentence: string, positions: Updateposition[], type: string){
    let position;
    switch (type) {
      case 'original': {
        position = positions.find(a => a.removedSentences && a.removedSentences
          .map(s => s.sentence).indexOf(sentence) === 0);
        position.oldpartsSelected = false;
        let foundNew = false;
        if (position.addedSentences) {
          for (const sent of position.addedSentences) {
            if (sent.changedParts.length > 0) {
              foundNew = true;
              break;
            }
          }
        }
        if (position.addedSentences && position.addedSentences.length > 0 && (foundNew === true)) {
          position.newpartsSelected = true;
          position.warningSelectAtLeastOne = false;
        }
        else {
          if (position.categorizations.originalChangedParts.length === 0){
            position.oldpartsSelected = true;
            position.warningSelectAtLeastOne = true;
          }
          else {
            position.warningSelectAtLeastOne = false;
            position.categorizationSelected = true;
          }
        }
        break;
      }
      case 'new': {
        position = positions.find(a => a.addedSentences && a.addedSentences
          .map(s => s.sentence).indexOf(sentence) === 0);
        let foundOriginal = false;
        if (position.removedSentences) {
          for (const sent of position.removedSentences) {
            if (sent.changedParts.length > 0) {
              foundOriginal = true;
              break;
            }
          }
        }
        if ((position.categorizations.originalChangedParts.length === 0) &&
          (position.categorizations.newChangedParts.length === 0)) {
          if (foundOriginal) {
            position.oldpartsSelected = true;
            position.newpartsSelected = false;
          }
          else {
            position.newpartsSelected = true;
            position.oldpartsSelected = false;
          }
          position.warningSelectAtLeastOne = true;
        }
        else {
          position.newpartsSelected = false;
          position.categorizationSelected = true;
          position.warningSelectAtLeastOne = false;
        }
        break;
      }
      default: {
        break;
      }
    }
  }
  addChangedPartToCategorizationPart(position: Updateposition, sentence: Sentence, changedPrt: changedPart, type: string) {
    const categorizationPart = position.categorizations;
    if (type === 'original') {
      const originalChangedPart: ChangedSentencePart = {sentence: sentence.sentence, parnumber: sentence.parnumber,
        sentencenumber: sentence.sentencenumber, start: changedPrt.start, end: changedPrt.end};
      categorizationPart.originalChangedParts.push(originalChangedPart);
    }
    else {
      const newChangedPart: ChangedSentencePart = {sentence: sentence.sentence, parnumber: sentence.parnumber,
        sentencenumber: sentence.sentencenumber, start: changedPrt.start, end: changedPrt.end};
      categorizationPart.newChangedParts.push(newChangedPart);
    }
  }

  clickCharacter(positions: Updateposition[], parnumber: number, sentencenumber: number, charnumber: number, type: string): void {
    switch (type) {
      case 'original': {
        let position;
        let found = false;
        for (const pos of positions) {
          if (pos.removedSentences && (found === false)) {
            for (const removedSentence of pos.removedSentences) {
              if ((removedSentence.parnumber === parnumber) && (removedSentence.sentencenumber === sentencenumber)) {
                position = pos;
                found = true;
                break;
              }
            }
          }
        }
        if (position && (position.oldpartsSelected === true)) {
          for (const sentence of position.removedSentences) {
            if ((sentence.parnumber === parnumber) && (sentence.sentencenumber === sentencenumber)) {
              for (const changedPrt of sentence.changedParts) {
                if ((changedPrt.start <= charnumber) && (changedPrt.end >= charnumber)) {
                  this.addChangedPartToCategorizationPart(position, sentence, changedPrt, type);
                  sentence.changedParts = sentence.changedParts.filter(a => a !== changedPrt);
                  const sentenceIndex = position.removedSentences.findIndex(a => a === sentence);
                  if ((sentenceIndex < position.removedSentences.length - 1) && (changedPrt.end === sentence.sentence.length - 1)
                    && (changedPrt.start === 0)
                    && (position.removedSentences[sentenceIndex + 1].changedParts.filter(a => a.start === 0).length > 0))
                  {
                    const followingChangedPart = position.removedSentences[sentenceIndex + 1].changedParts.filter(a => a.start === 0)[0];
                    if (followingChangedPart.end === position.removedSentences[sentenceIndex + 1].sentence.length - 1) {
                      this.clickCharacter(positions, position.removedSentences[sentenceIndex + 1].parnumber,
                        position.removedSentences[sentenceIndex + 1].sentencenumber, 0, 'original');
                    }
                  }
                  if ((sentenceIndex > 0) && (changedPrt.start === 0)
                    && (position.removedSentences[sentenceIndex - 1].changedParts.filter(a => a.start === 0).length > 0)) {
                    const followingChangedPart = position.removedSentences[sentenceIndex - 1].changedParts.filter(a => a.start === 0)[0];
                    if (followingChangedPart.end === position.removedSentences[sentenceIndex - 1].sentence.length - 1) {
                      this.clickCharacter(positions, position.removedSentences[sentenceIndex - 1].parnumber,
                        position.removedSentences[sentenceIndex - 1].sentencenumber, 0, 'original');
                    }
                  }
                  break;
                }
              }
            }
          }
        }
        break;
      }
      case 'new': {
        let position;
        let found = false;
        for (const pos of positions) {
          if (pos.addedSentences && (found === false)) {
            for (const addedSentence of pos.addedSentences) {
              if ((addedSentence.parnumber === parnumber) && (addedSentence.sentencenumber === sentencenumber)) {
                position = pos;
                found = true;
                break;
              }
            }
          }
        }
        if (position && (position.newpartsSelected === true)) {
          for (const sentence of position.addedSentences) {
            if ((sentence.parnumber === parnumber) && (sentence.sentencenumber === sentencenumber)) {
              for (const changedPrt of sentence.changedParts) {
                if ((changedPrt.start <= charnumber) && (changedPrt.end >= charnumber)) {
                  this.addChangedPartToCategorizationPart(position, sentence, changedPrt, type);
                  sentence.changedParts = sentence.changedParts.filter(a => a !== changedPrt);
                  const sentenceIndex = position.addedSentences.findIndex(a => a === sentence);
                  if ((sentenceIndex < position.addedSentences.length - 1) && (changedPrt.end === sentence.sentence.length - 1)
                    && (changedPrt.start === 0)
                    && (position.addedSentences[sentenceIndex + 1].changedParts.filter(a => a.start === 0).length > 0)) {
                    const followingChangedPart = position.addedSentences[sentenceIndex + 1].changedParts.filter(a => a.start === 0)[0];
                    if (followingChangedPart.end === position.addedSentences[sentenceIndex + 1].sentence.length - 1) {
                      this.clickCharacter(positions, position.addedSentences[sentenceIndex + 1].parnumber,
                        position.addedSentences[sentenceIndex + 1].sentencenumber, 0, 'new');
                    }
                  }
                  if ((sentenceIndex > 0) && (changedPrt.start === 0)
                    && (position.addedSentences[sentenceIndex - 1].changedParts.filter(a => a.start === 0).length > 0)) {
                    const followingChangedPart = position.addedSentences[sentenceIndex - 1].changedParts.filter(a => a.start === 0)[0];
                    if (followingChangedPart.end === position.addedSentences[sentenceIndex - 1].sentence.length - 1) {
                      this.clickCharacter(positions, position.addedSentences[sentenceIndex - 1].parnumber,
                        position.addedSentences[sentenceIndex - 1].sentencenumber, 0, 'new');
                    }
                  }
                  break;
                }
              }
            }
          }
        }
        break;
      }
      default: {
        break;
      }
    }
  }
  onLogoutClick(){
    this.authService.logout().subscribe(a => {
      localStorage.setItem('submittedLogin', JSON.stringify(false));
      localStorage.setItem('loadingLogin', JSON.stringify(false));
      localStorage.setItem('failedLogin', JSON.stringify(false));
      localStorage.setItem('submittedSignup', JSON.stringify(false));
      localStorage.setItem('loadingSignup', JSON.stringify(false));
      localStorage.setItem('failedSignup', JSON.stringify(false));
      localStorage.setItem('accountCreated', JSON.stringify(false));
      this.router.navigate([this.authService.loginUrl]);
    });
  }
  getNewspaper(newspaper: string): string{
    switch (newspaper) {
      case'vrt': {
        return 'VRT';
      }
      case 'demorgen': {
        return 'De Morgen';
      }
      case 'hln': {
        return 'Het Laatste Nieuws';
      }
      case 'nieuwsblad': {
        return 'Het Nieuwsblad';
      }
      case 'standaard': {
        return 'De Standaard';
      }
      case 'knack': {
        return 'Knack';
      }
      default: {
        return null;
      }
    }
  }
  getPublicationTime(publicationTime: string): string{
    const regex = /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})/;
    return publicationTime.match(regex)[3] + ' ' + this.getMonth(Number(publicationTime.match(regex)[2])) +
      ' ' + publicationTime.match(regex)[1]
      + ' om ' + publicationTime.match(regex)[4] + 'u' + publicationTime.match(regex)[5];
  }
  getMonth(month: number): string{
    const monthStrings = ['januari', 'februari', 'maart', 'april', 'mei', 'juni', 'juli', 'augustus', 'september',
      'oktober', 'november', 'december'];
    return monthStrings[month - 1];
  }
  getTooluser(): void {
    this.email = this.authService.currentUserValue.email;
    this.authService.getUser(this.email).subscribe(data => {
      this.firstname = data.firstname;
      this.lastname = data.lastname;
      this.hasLoggedIn = data.hasLoggedIn;
      this.hasFeedbackCompleted = data.hasFeedbackCompleted;
      this.feedbackStage = data.feedbackStage;
      this.allArticlesCompleted = false;
      if (this.hasLoggedIn === false) {
        this.router.navigate([this.authService.introUrl]);
      }
      else{
        if (this.hasFeedbackCompleted === true) {
          this.router.navigate([this.authService.articlesUrl]);
        }
      }
    });
  }
  ngOnInit(): void {
    this.setTitle('UGent Newstracker');
    if (this.authService.currentUserValue) {
      this.getTooluser();
      this.getArticles();
    }
  }

  public setTitle(newTitle: string) {
    this.titleService.setTitle( newTitle );
  }

  public scoresum(): number {
    let sum = 0;
    for (let i = 0; i < 5; i++) {
      sum = sum + this.scores[i];
    }
    if (sum === 0.49 || sum === 0.99 || sum === 1.49 || sum === 1.99 || sum === 2.49 || sum === 2.99 || sum === 3.49 || sum === 3.99){
      sum = sum + 0.01;
    }
    return Math.round(sum * 100) / 100;
  }

  public clickExercise(i: number, type: string): void {
  if (type === 'correctsolution'){
    this.correctsolutionChosen[i - 1] = !this.correctsolutionChosen[i - 1];
  }
  if (type === 'yoursolution'){
    this.yoursolutionChosen[i - 1] = !this.yoursolutionChosen[i - 1];
  }
  if (type === 'feedback'){
    this.feedbackChosen[i - 1] = !this.feedbackChosen[i - 1];
  }
  }

  public wordInFeedbacksol(solution: FeedbackSolution, sentencenumber: number, wordnumber: number, type: string): boolean{
    if (type === 'original'){
      if (solution.originalchangedparts[sentencenumber]) {
        for (const changedpart of solution.originalchangedparts[sentencenumber]) {
          if ((wordnumber >= changedpart.start) && (wordnumber <= changedpart.end)) {
            return true;
          }
        }
      }
    }
    if (type === 'new'){
      if (solution.newchangedparts[sentencenumber]) {
        for (const changedpart of solution.newchangedparts[sentencenumber]) {
          if ((wordnumber >= changedpart.start) && (wordnumber <= changedpart.end)) {
            return true;
          }
        }
      }
    }
    return false;
  }

  public getFeedbackSolutionsFromUrl(url: string): FeedbackSolution[]{
    return this.feedbacksolutions.filter(a => a.url === url)[0].feedbackSolutions;
  }
  public clickAcceptButton(){
    if (this.feedbackCompleted === true) {
      this.totalCount = 0;
      if (this.scoresum() >= 3 || this.feedbackStage === 3){
        this.feedbackCompleted = false;
        this.hasFeedbackCompleted = true;
        this.feedbackStage = 4;
        this.authService.setFeedbackStage(this.authService.currentUserValue.email, 4).subscribe(data => {
          this.authService.setHasFeedbackCompleted(this.authService.currentUserValue.email).subscribe(data2 => {
            this.router.navigate([this.authService.articlesUrl]);
          });
        });
      }
      else {
        if (this.feedbackStage === 1) {
          this.feedbackCompleted = false;
          this.feedbackStage = 2;
          this.authService.setFeedbackStage(this.authService.currentUserValue.email, 2).subscribe(data => {
            this.router.navigate([this.authService.articlesUrl]);
          });
        }
        else {
          if (this.feedbackStage === 2) {
            this.feedbackCompleted = false;
            this.feedbackStage = 3;
            this.authService.setFeedbackStage(this.authService.currentUserValue.email, 3).subscribe(data => {
              this.router.navigate([this.authService.articlesUrl]);
            });
          }
        }
      }
    }
  }
  public checkFeedbackStage(feedbackstage: number) {
    if (this.feedbackStage === feedbackstage) {
      return true;
    } else {
      return false;
    }
  }
  clickReloadButton(){
    this.router.navigate([this.authService.articlesUrl]);
  }

  getTicket(): string {
    if (this.totalCount >= 0) {
      if (this.totalCount <= 5 && this.feedbackCompleted === false) {
        return 'assets/progress_bar' + ((5 - this.getRemaining())).toString() + '.png';
      }
      else{
        return 'assets/progress_bar5.png';
      }
    }
    else{
      return 'assets/progress_bar0.png';
    }
  }

  getFullTicketBlack(): string {
    return 'assets/progress_bar0png';
  }
  getFullTicketWhite(): string {
    return 'assets/progress_bar5.png';
  }
  getRange(nrTickets: number): number[] {
    const ticketRange = [];
    for (let i = 0; i < nrTickets; i++){
      ticketRange[i] = i;
    }
    return ticketRange;
  }
  getRemaining(): number {
    if (this.totalCount <= 5){
      return 5 - this.totalCount;
    }
    else{
      return 5;
    }
  }
  getNrTickets(): number {
    return 0;
  }
  getImageLoaded(): boolean {
    return this.imageLoaded;
  }
  originalPartsExist(sentence: string, positions: Updateposition[]): boolean {
    const position = positions.find(a => a.addedSentences && a.addedSentences
      .map(s => s.sentence).indexOf(sentence) === 0);
    if (position.removedSentences) {
      for (const sent of position.removedSentences) {
        if (sent.changedParts.length > 0) {
          return true;
        }
      }
    }
    return false;
  }
  close(position: Updateposition): void {
    if (position.removedSentences) {
      for (const sent of position.removedSentences) {
        const changedPrts = position.categorizations.originalChangedParts.filter(changedPrt =>
          sent.parnumber === changedPrt.parnumber && sent.sentencenumber === changedPrt.sentencenumber);
        for (const changedPrt of changedPrts) {
          sent.changedParts.push(changedPrt);
        }
      }
    }
    if (position.addedSentences) {
      for (const sent of position.addedSentences) {
        const changedPrts = position.categorizations.newChangedParts.filter(changedPrt =>
          sent.parnumber === changedPrt.parnumber && sent.sentencenumber === changedPrt.sentencenumber);
        for (const changedPrt of changedPrts) {
          sent.changedParts.push(changedPrt);
        }
      }
    }
    position.categorizations.categorizations.anders = false;
    position.categorizations.categorizations.verwijdering = false;
    position.categorizations.categorizations.toevoeging = false;
    position.categorizations.categorizations.subjectief = false;
    position.categorizations.categorizations.objectief = false;
    position.categorizations.categorizations.update = false;
    position.categorizations.categorizations.herformulering = false;
    position.categorizations.categorizations.verplaatsing = false;
    position.categorizations.categorizations.text = null;
    position.categorizations.categorizations.spelfout = false;
    position.categorizations.categorizations.remarks = null;
    position.oldpartsSelected = false;
    position.newpartsSelected = false;
    position.warningSelectAtLeastOne = false;
    position.categorizationSelected = false;
    position.otherPositionsSelected = false;
    position.currentSelected = false;
    for (const otherPos of this.titlepositions) {
      otherPos.otherPositionsSelected = false;
      otherPos.currentSelected = false;
    }

    for (const otherPos of this.intropositions) {
      otherPos.otherPositionsSelected = false;
      otherPos.currentSelected = false;
    }

    for (const otherPos of this.textpositions) {
      otherPos.otherPositionsSelected = false;
      otherPos.currentSelected = false;
    }

  }
  searchFullPrevious(position: Updateposition, changedPrt: ChangedSentencePart, type: string): ChangedSentencePart {
    if (type === 'original') {
      for (const prevChangedPrt of position.categorizations.originalChangedParts) {
        if (prevChangedPrt.start === 0 && prevChangedPrt.end === prevChangedPrt.sentence.length - 1) {
          if ((prevChangedPrt.parnumber === changedPrt.parnumber) &&
            ((prevChangedPrt.sentencenumber === changedPrt.sentencenumber - 1))) {
            return prevChangedPrt;
          }
        }
      }
      let maxSentenceNumber = -1;
      for (const sent of position.removedSentences) {
        if (sent.parnumber === changedPrt.parnumber - 1) {
          maxSentenceNumber = Math.max(maxSentenceNumber, sent.sentencenumber);
        }
      }
      if (maxSentenceNumber > -1) {
        for (const prevChangedPrt of position.categorizations.originalChangedParts) {
          if (prevChangedPrt.start === 0 && prevChangedPrt.end === prevChangedPrt.sentence.length - 1) {
            if ((prevChangedPrt.parnumber === changedPrt.parnumber - 1) &&
              ((prevChangedPrt.sentencenumber === maxSentenceNumber))) {
              return prevChangedPrt;
            }
          }
        }
      }
      return null;
    }
    if (type === 'new') {
      for (const prevChangedPrt of position.categorizations.newChangedParts) {
        if (prevChangedPrt.start === 0 && prevChangedPrt.end === prevChangedPrt.sentence.length - 1) {
          if ((prevChangedPrt.parnumber === changedPrt.parnumber) &&
            ((prevChangedPrt.sentencenumber === changedPrt.sentencenumber - 1))) {
            return prevChangedPrt;
          }
        }
      }
      let maxSentenceNumber = -1;
      for (const sent of position.addedSentences) {
        if (sent.parnumber === changedPrt.parnumber - 1) {
          maxSentenceNumber = Math.max(maxSentenceNumber, sent.sentencenumber);
        }
      }
      if (maxSentenceNumber > -1) {
        for (const prevChangedPrt of position.categorizations.newChangedParts) {
          if (prevChangedPrt.start === 0 && prevChangedPrt.end === prevChangedPrt.sentence.length - 1) {
            if ((prevChangedPrt.parnumber === changedPrt.parnumber - 1) &&
              ((prevChangedPrt.sentencenumber === maxSentenceNumber))) {
              return prevChangedPrt;
            }
          }
        }
      }
      return null;
    }
    return null;
  }

  searchFullNext(position: Updateposition, changedPrt: ChangedSentencePart, type: string) {
    if (type === 'original') {
      for (const followingChangedPrt of position.categorizations.originalChangedParts) {
        if (followingChangedPrt.start === 0 && followingChangedPrt.end === followingChangedPrt.sentence.length - 1) {
          if ((followingChangedPrt.parnumber === changedPrt.parnumber) &&
            ((followingChangedPrt.sentencenumber === changedPrt.sentencenumber + 1))) {
            return followingChangedPrt;
          }
        }
      }

      let maxSentenceNumber = -1;
      for (const sent of position.removedSentences) {
        if (sent.parnumber === changedPrt.parnumber) {
          maxSentenceNumber = Math.max(maxSentenceNumber, sent.sentencenumber);
        }
      }
      if (maxSentenceNumber === changedPrt.sentencenumber) {
        for (const previousChangedPrt of position.categorizations.originalChangedParts) {
          if (previousChangedPrt.start === 0 && previousChangedPrt.end === previousChangedPrt.sentence.length - 1) {
            if ((previousChangedPrt.parnumber === changedPrt.parnumber + 1) &&
              ((previousChangedPrt.sentencenumber === 0))) {
              return previousChangedPrt;
            }
          }
        }
      }
      return null;
    }
    if (type === 'new') {
      for (const followingChangedPrt of position.categorizations.newChangedParts) {
        if (followingChangedPrt.start === 0 && followingChangedPrt.end === followingChangedPrt.sentence.length - 1) {
          if ((followingChangedPrt.parnumber === changedPrt.parnumber) &&
            ((followingChangedPrt.sentencenumber === changedPrt.sentencenumber + 1))) {
            return followingChangedPrt;
          }
        }
      }
      let maxSentenceNumber = -1;
      for (const sent of position.addedSentences) {
        if (sent.parnumber === changedPrt.parnumber) {
          maxSentenceNumber = Math.max(maxSentenceNumber, sent.sentencenumber);
        }
      }
      if (maxSentenceNumber === changedPrt.sentencenumber) {
        for (const followingChangedPrt of position.categorizations.newChangedParts) {
          if (followingChangedPrt.start === 0 && followingChangedPrt.end === followingChangedPrt.sentence.length - 1) {
            if ((followingChangedPrt.parnumber === changedPrt.parnumber + 1) &&
              ((followingChangedPrt.sentencenumber === 0))) {
              return followingChangedPrt;
            }
          }
        }
      }
      return null;
    }
    return null;
  }

  previous(sentence: string, positions: Updateposition[], type: string){
    let position;
    switch (type) {
      case 'original': {
        position = positions.find(a => a.removedSentences && a.removedSentences
          .map(s => s.sentence).indexOf(sentence) === 0);
        const changedPrt = position.categorizations.originalChangedParts.pop();
        for (let i = 0; i < position.removedSentences.length; i++) {
          const sent = position.removedSentences[i];
          if ((sent.parnumber === changedPrt.parnumber) && (sent.sentencenumber === changedPrt.sentencenumber)
            && (sent.sentence === changedPrt.sentence)) {
            sent.changedParts.push(changedPrt);
            if ((position.categorizations.originalChangedParts.length > 0)
              && changedPrt.start === 0 && changedPrt.end === sent.sentence.length - 1) {
              let prevChangedPrt = this.searchFullPrevious(position, changedPrt, type);
              let followingChangedPrt = this.searchFullNext(position, changedPrt, type);
              let q = i;
              while (prevChangedPrt !== null) {
                position.categorizations.originalChangedParts =
                  position.categorizations.originalChangedParts.filter(a => a !== prevChangedPrt);
                position.removedSentences[q - 1].changedParts.push(prevChangedPrt);
                q = q - 1;
                prevChangedPrt = this.searchFullPrevious(position, prevChangedPrt, type);
              }
              let k = i;
              while (followingChangedPrt !== null) {
                position.categorizations.originalChangedParts =
                  position.categorizations.originalChangedParts.filter(a => a !== followingChangedPrt);
                position.removedSentences[k + 1].changedParts.push(followingChangedPrt);
                k = k + 1;
                followingChangedPrt = this.searchFullNext(position, followingChangedPrt, type);
              }
            }
          }
        }
        break;
      }
      case 'new': {
        position = positions.find(a => a.addedSentences && a.addedSentences
          .map(s => s.sentence).indexOf(sentence) === 0);
        if (position.categorizations.newChangedParts.length > 0) {
          const changedPrt = position.categorizations.newChangedParts.pop();
          for (let i = 0; i < position.addedSentences.length; i++) {
            const sent = position.addedSentences[i];
            if ((sent.parnumber === changedPrt.parnumber) && (sent.sentencenumber === changedPrt.sentencenumber)
              && (sent.sentence === changedPrt.sentence)) {
              sent.changedParts.push(changedPrt);
              if ((position.categorizations.newChangedParts.length > 0)
                && changedPrt.start === 0 && changedPrt.end === sent.sentence.length - 1) {
                let prevChangedPrt = this.searchFullPrevious(position, changedPrt, type);
                let followingChangedPrt = this.searchFullNext(position, changedPrt, type);
                let q = i;
                while (prevChangedPrt !== null) {
                  position.categorizations.newChangedParts =
                    position.categorizations.newChangedParts.filter(a => a !== prevChangedPrt);
                  position.addedSentences[q - 1].changedParts.push(prevChangedPrt);
                  q = q - 1;
                  prevChangedPrt = this.searchFullPrevious(position, prevChangedPrt, type);
                }
                let k = i;
                while (followingChangedPrt !== null) {
                  position.categorizations.newChangedParts =
                    position.categorizations.newChangedParts.filter(a => a !== followingChangedPrt);
                  position.addedSentences[k + 1].changedParts.push(followingChangedPrt);
                  k = k + 1;
                  followingChangedPrt = this.searchFullNext(position, followingChangedPrt, type);
                }
              }
            }
          }

        }
        else{
          position.oldpartsSelected = true;
          position.newpartsSelected = false;
        }
        break;
      }
      default: {
        break;
      }
    }
  }
  previousCategory(sentence: string, positions: Updateposition[], type: string){
    switch (type) {
      case 'original': {
        const position = positions.find(a => a.removedSentences && a.removedSentences
          .map(s => s.sentence).indexOf(sentence) === 0);
        if (position.categorizations.newChangedParts.length > 0) {
          position.oldpartsSelected = false;
          position.newpartsSelected = true;
          position.categorizationSelected = false;
        }
        else {
          let found = false;
          for (const sent of position.addedSentences) {
            if (sent.changedParts.length > 0){
              found = true;
              break;
            }
          }
          if (found === true){
            position.oldpartsSelected = false;
            position.newpartsSelected = true;
            position.categorizationSelected = false;
          }
          else{
            position.oldpartsSelected = true;
            position.newpartsSelected = false;
            position.categorizationSelected = false;
          }
        }
        position.categorizations.categorizations.anders = false;
        position.categorizations.categorizations.verwijdering = false;
        position.categorizations.categorizations.toevoeging = false;
        position.categorizations.categorizations.subjectief = false;
        position.categorizations.categorizations.objectief = false;
        position.categorizations.categorizations.update = false;
        position.categorizations.categorizations.herformulering = false;
        position.categorizations.categorizations.verplaatsing = false;
        position.categorizations.categorizations.text = null;
        position.categorizations.categorizations.spelfout = false;
        position.categorizations.categorizations.remarks = null;
        position.warningSelectAtLeastOne = false;
        position.warning = false;

        break;
      }
      case 'new': {
        const position = positions.find(a => a.addedSentences && a.addedSentences
          .map(s => s.sentence).indexOf(sentence) === 0);
        position.oldpartsSelected = false;
        position.newpartsSelected = true;
        position.categorizationSelected = false;
        position.categorizations.categorizations.anders = false;
        position.categorizations.categorizations.verwijdering = false;
        position.categorizations.categorizations.toevoeging = false;
        position.categorizations.categorizations.subjectief = false;
        position.categorizations.categorizations.objectief = false;
        position.categorizations.categorizations.update = false;
        position.categorizations.categorizations.herformulering = false;
        position.categorizations.categorizations.verplaatsing = false;
        position.categorizations.categorizations.text = null;
        position.categorizations.categorizations.spelfout = false;
        position.categorizations.categorizations.remarks = null;
        position.warningSelectAtLeastOne = false;
        position.warning = false;
        break;
      }
      default: {
        break;
      }
    }
  }

}
