<!doctype html>
<html lang="en">
<head>
  <title>UGent Newstracker - Login</title>
</head>
<body>
<nav class="navbar navbar-expand-lg fixed-top navbar-dark bg-company-grey">
  <a class="navbar-brand" href="#"></a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <a class="navbar-brand" target="_blank" href="https://www.ugent.be/" style="padding:0px;">
    <img src="assets/ugentlogo.png" width="163" height="130">
  </a>
  <a class="navbar-brand" target="_blank" href="https://ddcmsite.ugent.be/" style="padding:0px;">
    <img src="assets/ddcmlogo.png" width="272" height="60">
  </a>
  <div class="collapse navbar-collapse text-right" id="navbarright">
    <ul class="navbar-nav ml-auto flex-nowrap">
      <li class="nav-item">
        <a class="nav-link active" href="/login" (click)="booleanService.resetLoginBooleans()">Home<span class="sr-only">(current)</span></a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="/project" (click)="booleanService.resetLoginBooleans()">Over dit project</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="/intro" (click)="booleanService.resetLoginBooleans()">Hoe werkt het?</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="/signin" (click)="booleanService.resetSigninBooleans()">Maak een account!</a>
      </li>
    </ul>
  </div>
</nav>

<div *ngIf="this.mobile===true" class="popup">
  <p>We merken dat je onze website bezoekt via je smartphone of tablet. Dit onderzoek is jammer genoeg niet geschikt voor mobiele toestellen.<br>
    We raden je dan ook aan deze website te bezoeken via je computer!</p>
  <button class="btn btn-primary" style="padding: 5px 10px;" (click)="setMobile(false)">Sluiten</button>
</div>
<div class="row">
  <div class="col-sm" style="margin-left:20px;">
    <br>
    <h2 class="h2">Welkom op het Newstracker platform!</h2>
    <h5 class="h5">Zin om de wetenschap vooruit te helpen?</h5>

    <p style="width:720px;">Met het Newstracker project willen we aan de Universiteit Gent nagaan hoe de mogelijkheid om online nieuwsartikels aan te passen in de praktijk gebruikt wordt door online redacties.
      <br><b>En daarvoor hebben we jouw hulp nodig!</b>
      <br>
      <br>
      Je hoeft ons echter niet zomaar te helpen! Indien je ons helpt 50 artikels volledig te analyseren, maak je kans om 1 of meerdere van de <b>40 cinematickets</b> te winnen die we op het einde van dit project zullen verloten.
      <b>Per 25 artikels die je daarbovenop nog extra analyseert, kan je je kans op het winnen van cinematickets extra vergroten!</b>
      Indien je een of meerdere cinematickets won, zal je na afloop van het project gecontacteerd worden via mail.<br>
      <br>
      Bekijk het <a target="_blank" href="https://youtu.be/rDPUqM32jbg">introductiefilmpje</a> hiernaast (of lees <a href="https://newstrack.ugent.be/project">hier</a> heel uitgebreid wat jij kan doen), <a href="https://newstrack.ugent.be/signin">maak een account aan</a> en draag zo je steentje bij tot de vooruitgang van de wetenschap.
    </p>
    <br>

    <div style="width:720px">
      <div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-header">Login</h5>
            <form [formGroup]="loginForm" (ngSubmit)="onLoginSubmit()">
              <div class="form-group">
                <label for="emailLogin">E-mail</label>
                <input type="text" id="emailLogin" [readonly]="currentLoadingLoginValue===true" formControlName="emailLogin" class="form-control" [ngClass]="{ 'is-invalid': currentEmptyEmailLoginValue && loginf.emailLogin.errors }" />
                <div *ngIf="currentEmptyEmailLoginValue && loginf.emailLogin.errors" class="invalid-feedback-login">
                </div>
              </div>
              <div class="form-group">
                <label for="passwordLogin">Paswoord</label>
                <input type="password" id="passwordLogin" [readonly]="currentLoadingLoginValue===true" formControlName="passwordLogin" class="form-control" [ngClass]="{ 'is-invalid': currentEmptyPasswordLoginValue && loginf.passwordLogin.errors }" />
                <div *ngIf="currentEmptyPasswordLoginValue && loginf.passwordLogin.errors" class="invalid-feedback-login">
                </div>
              </div>
              <button [disabled]="currentLoadingLoginValue" class="btn btn-primary">
                <span *ngIf="currentLoadingLoginValue" class="spinner-border spinner-border-sm mr-1"></span>
                Login
              </button>
              <div *ngIf="currentFailedLoginValue" class="failedLogin">E-mail en/of paswoord verkeerd!</div>
              <nav><a routerLink="/recover">Paswoord vergeten?</a></nav>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-sm" style="margin-top:125px;">
    <iframe width="700" height="400"
            src="https://youtube.com/embed/rDPUqM32jbg">
    </iframe>
  </div>
</div>
</body>
</html>
