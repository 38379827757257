<!doctype html>
<html lang="en">
<head>
  <title>UGent Newstracker - Registratie</title>
</head>
<body>
<nav class="navbar navbar-expand-lg fixed-top navbar-dark bg-company-grey">
  <a class="navbar-brand" href="#"></a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <a class="navbar-brand" target="_blank" href="https://www.ugent.be/" style="padding:0px;">
    <img src="assets/ugentlogo.png" width="163" height="130">
  </a>
  <a class="navbar-brand" target="_blank" href="https://ddcmsite.ugent.be/" style="padding:0px;">
    <img src="assets/ddcmlogo.png" width="272" height="60">
  </a>
  <div class="collapse navbar-collapse text-right" id="navbarright">
    <ul class="navbar-nav ml-auto flex-nowrap">
      <li class="nav-item">
        <a class="nav-link" href="/login" (click)="booleanService.resetLoginBooleans()">Home</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="/project" (click)="booleanService.resetLoginBooleans()">Over dit project</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="/intro" (click)="booleanService.resetLoginBooleans()">Hoe werkt het?</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="/signin" (click)="booleanService.resetSigninBooleans()">Maak een account!</a>
      </li>
    </ul>
  </div>
</nav>
<p *ngIf="enabled===true">Uw e-mailadres werd succesvol geverifieerd! Klik <a href="https://newstrack.ugent.be/login">hier</a> om opnieuw naar de homepagina te gaan.</p>
<p *ngIf="enabled===false">E-mail verificatie mislukt, probeer het opnieuw!  Klik <a href="https://newstrack.ugent.be/login">hier</a> om opnieuw naar de homepagina te gaan.</p>
<p *ngIf="expired===true">Verificatielink is vervallen. Maak je account opnieuw aan om te kunnen inloggen.  Klik <a href="https://newstrack.ugent.be/login">hier</a> om opnieuw naar de homepagina te gaan.</p>
<p *ngIf="verified===true">Dit e-mailadres werd al eerder geverifieerd.  Klik <a href="https://newstrack.ugent.be/login">hier</a> om opnieuw naar de homepagina te gaan.</p>
</body>
</html>
