<!doctype html>
<html lang="en">
<head>
  <title>UGent Newstracker - Welkom!</title>
</head>
<body *ngIf="this.firstname && this.lastname">
<nav class="navbar navbar-expand-lg fixed-top navbar-dark bg-company-grey">
  <a class="navbar-brand" href="#"></a>
  <button class="btn btn-primary" class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <a class="navbar-brand" target="_blank" href="https://www.ugent.be/" style="padding:0px;">
    <img src="assets/ugentlogo.png" width="163" height="130">
  </a>
  <a class="navbar-brand" target="_blank" href="https://ddcmsite.ugent.be/" style="padding:0px;">
    <img src="assets/ddcmlogo.png" width="272" height="60">
  </a>
  <div class="collapse navbar-collapse text-right" *ngIf="getImageLoaded()" id="navbarright">
    <div class="abs-center-x" *ngIf="allArticlesCompleted===false" style="display:inline-block" name="ticket">
      <div *ngIf="getNrTickets() < 10" style="display:inline; color:white">
        <ng-container *ngFor="let number of getRange(getNrTickets());">
          <img [src]="getFullTicketWhite()" width="40" height="40">
        </ng-container>
      </div>
      <div *ngIf="getNrTickets() >= 10" style="display:inline; color:white">
        ...
        <ng-container *ngFor="let number of getRange(10);">
          <img [src]="getFullTicketWhite()" width="40" height="40">
        </ng-container>
        ...
      </div>
      <br>
      <img [src]="getTicket()" />
      <p class="completed">Nog {{getRemaining()}} artikels te gaan tot lotje {{getNrTickets() + 1}}!</p>
    </div>
    <ul class="navbar-nav ml-auto flex-nowrap">
      <li class="nav-item">
        <a class="nav-link active" href="/articles" *ngIf="authService.currentUserValue">Nieuw artikelpaar<span class="sr-only">(current)</span></a>
      </li>
      <li class="nav-item">
        <a class="nav-link" target="_blank" href="/profile" *ngIf="authService.currentUserValue">{{this.firstname}} {{this.lastname}}</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" target="_blank" href="/project" *ngIf="authService.currentUserValue">Over dit project</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" target="_blank" href="/intro" *ngIf="authService.currentUserValue">Hoe werkt het?</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#" *ngIf="authService.currentUserValue" (click)="onLogoutClick()">Uitloggen</a>
      </li>
    </ul>
  </div>
</nav>
<div class="jumbotron jumbotron-fluid" *ngIf="allArticlesCompleted === false">
<div class="container">
<div class="row" *ngIf="authService.currentUserValue">
      <div class="col-sm" name="introduction">
          <br>
          <br>
          <h2 class="h2" *ngIf="authService.currentUserValue">Welkom!</h2>

          <p>
        Hieronder verschijnen verschillende opeenvolgende versies van hetzelfde krantenartikel.
        We vragen je om voor elke wijziging aan het artikel hieronder, aan te geven om welk type wijziging dit volgens jou gaat.
        Indien je vindt dat de wijziging niet past in één van de voorgestelde categorieën, kan je onder 'andere' aangeven om welk type wijziging het volgens jou
        dan wel gaat.</p>

        <br>
        <br>

        <h5 class="h5" *ngIf="oldArticle && newArticle && authService.currentUserValue && ((email!== 'yoram1291@hotmail.com') && (email !== 'antoon.bronselaer@ugent.be'))">Resterende aanpassingen in onderstaand artikel:
          {{titlepositions.length + intropositions.length + textpositions.length}}</h5>
        <h5 class="h5" *ngIf="oldArticle && newArticle && authService.currentUserValue && ((email!== 'yoram1291@hotmail.com') && (email !== 'antoon.bronselaer@ugent.be'))">Totaal aantal categorisaties: {{totalCount}}</h5>

        </div>
      <div class="col-sm" name="leftUpdates">
        <img src="assets/newslogo.jpg" width="630" height="420">
        </div>
</div>
</div>
</div>

<div class="jumbotron jumbotron-fluid" *ngIf="allArticlesCompleted === true">
  <div class="container">
    <div class="row" *ngIf="authService.currentUserValue">
      <div class="col-sm" name="introduction">
        <br>
        <br>
        <h2 class="h2" *ngIf="authService.currentUserValue">Bedankt!</h2>

        <p>
          Je categoriseerde alle artikels! Een enorme dankjewel voor je enorme bijdrage aan ons onderzoek!</p>

        <br>
        <br>

        <h5 class="h5" *ngIf="oldArticle && newArticle && authService.currentUserValue && ((email!== 'yoram1291@hotmail.com') && (email !== 'antoon.bronselaer@ugent.be'))">Totaal aantal categorisaties: {{totalCount}}</h5>

      </div>
      <div class="col-sm" name="leftUpdates">
        <img src="assets/newslogo.jpg" width="630" height="420">
      </div>
    </div>
  </div>
</div>

<p class="introduction" *ngIf="oldArticle && newArticle && authService.currentUserValue && allArticlesCompleted===false">
  Onderstaand artikel werd geschreven door {{getNewspaper(oldArticle.newspaper)}} en kan gevonden worden op
  <a href="{{oldArticle.url}}" target="_blank">
    {{oldArticle.url}}
  </a>.
</p>
<p *ngIf="(email !== 'yoram1291@hotmail.com') && (email !== 'antoon.bronselaer@ugent.be')" style="text-align:center;font-size:18pt;color:#1E64C8;"><b>Klik <a href="assets/flowchart.png" target="_blank">HIER</a> voor een handig overzicht van welk type je in welke situatie moet kiezen.</b></p>


<button *ngIf="oldArticle && newArticle && authService.currentUserValue && ((email!== 'yoram1291@hotmail.com') && (email !== 'antoon.bronselaer@ugent.be')) && allArticlesCompleted === false" type="button" (click)="clickReloadButton()" style="margin:5px;" class="btn btn-primary">Herbegin huidig artikel</button>
<a *ngIf="oldArticle && newArticle && authService.currentUserValue && ((email !== 'yoram1291@hotmail.com') && (email !== 'antoon.bronselaer@ugent.be')) && allArticlesCompleted === false" href="/articles" class="btn btn-primary" role="button">Sla huidig artikel over</a>
<p class="introduction" *ngIf="(!oldArticle || !newArticle) && authService.currentUserValue && allArticlesCompleted === false">Nieuwe artikels worden geladen...</p>

<table class="table" width="1500" *ngIf="oldArticle && newArticle && authService.currentUserValue && ((email != 'yoram1291@hotmail.com') && (email != 'antoon.bronselaer@ugent.be')) && allArticlesCompleted === false">
  <thead>
    <tr>
      <th id="oldarticle-title" >
        <span class="versionNumber">Versie {{oldArticle.versionNumber}}</span>
        <ng-container *ngFor="let title_part of oldArticle.titleParts; let i = index;">
          <ng-container *ngFor="let title_sentence of title_part; let j = index;">
            <div class="oldtitle" *ngIf="titlepositions && firstUpdateSentence(titlepositions, i, j, 'original') && startmessageSelected(title_sentence, titlepositions, 'original')">
              <div style="display:inline;">
              <div style="display:inline;" *ngIf="(titlepositions && getCorrespondingPosition(titlepositions, i, j, 'original').oldpartsSelected === true) || (titlepositions && firstUpdateSentence(titlepositions, i, j, 'original') && getCorrespondingPosition(titlepositions, i, j, 'original').categorizationSelected)">
                <button class="btn btn-primary" style="margin-left:10px;margin-top:10px;margin-right: 10px;font-size: 12px;" (click)="previous(title_sentence, titlepositions, 'original')"  *ngIf="((getCorrespondingPosition(titlepositions, i, j, 'original').categorizations.originalChangedParts.length > 0) || (getCorrespondingPosition(titlepositions, i, j, 'original').categorizations.newChangedParts.length > 0))===false && getCorrespondingPosition(titlepositions, i, j, 'original').categorizationSelected===false" disabled>Ongedaan maken</button>
                <button class="btn btn-primary" style="margin-left:10px;margin-top:10px;margin-right: 10px;font-size: 12px;" (click)="previous(title_sentence, titlepositions, 'original')"  *ngIf="((getCorrespondingPosition(titlepositions, i, j, 'original').categorizations.originalChangedParts.length > 0) || (getCorrespondingPosition(titlepositions, i, j, 'original').categorizations.newChangedParts.length > 0)) && getCorrespondingPosition(titlepositions, i, j, 'original').categorizationSelected===false">Ongedaan maken</button>
                <button class="btn btn-primary" style="margin-left:10px;margin-top:10px;margin-right: 10px;font-size: 12px;" (click)="previousCategory(title_sentence, titlepositions, 'original')"  *ngIf="titlepositions && firstUpdateSentence(titlepositions, i, j, 'original') && getCorrespondingPosition(titlepositions, i, j, 'original').categorizationSelected">Ongedaan maken</button>
              </div>
              <button style="display:inline;" *ngIf="titlepositions && (getCorrespondingPosition(titlepositions, i, j, 'original').oldpartsSelected === true || (firstUpdateSentence(titlepositions, i, j, 'original') && getCorrespondingPosition(titlepositions, i, j, 'original').categorizationSelected))" style="color:white;padding-right:2px;" (click)="close(getCorrespondingPosition(titlepositions, i, j, 'original'))" type="button" class="close" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
              </div>
              <br>
              <br>
              <div *ngIf="titlepositions && (getCorrespondingPosition(titlepositions, i, j, 'original').warningSelectAtLeastOne === true)" style="padding-left:60px;border:4px white solid;margin:10px;">
                <img src="assets/warning.png" style="float:left;" width="30" height="30">
                <p style="display:inline;margin-left:10px;">Je moet op zijn minst 1 tekstblok aanduiden om te kunnen categoriseren!<p>
              </div>
              <div class="oldtitle-startmessage" *ngIf="titlepositions && isPositionSelected(getCorrespondingPosition(titlepositions, i, j, 'original')) && (getCorrespondingPosition(titlepositions, i, j, 'original').otherPositionsSelected === false)">
                <p>Klik op 'Start' om de beoordeling van onderstaand tekstfragment te starten.<p>
                <button class="btn btn-primary" (click)="positionSelected(title_sentence, titlepositions, 'original')">Start</button>
              </div>
              <div *ngIf="titlepositions && getCorrespondingPosition(titlepositions, i, j, 'original').oldpartsSelected === true">
                <p>Welke van de donkerrode tekstblokken behoren tot eenzelfde aanpassing? Klik ze aan! <br>Klik vervolgens op 'Volgende'. <br><br>Foutje gemaakt? Klik op 'Ongedaan maken' om terug te gaan.<p>
                <button class="btn btn-primary" (click)="partsSelected(title_sentence, titlepositions, 'original')">Volgende</button>
              </div>
              <form #f="ngForm" class="row justify-content-center" *ngIf="titlepositions && firstUpdateSentence(titlepositions, i, j, 'original') && getCorrespondingPosition(titlepositions, i, j, 'original').categorizationSelected" (ngSubmit)="submit(getCorrespondingPosition(titlepositions, i, j, 'original'))">
              <p class="center-align"><b>Hoe zou je deze aanpassing categoriseren (meerdere opties mogelijk)?</b></p>
                <br/>
                <div *ngIf="getCorrespondingPosition(titlepositions, i, j, 'original').warningAnders || getCorrespondingPosition(titlepositions, i, j, 'original').warning || getCorrespondingPosition(titlepositions, i, j, 'original').failed" style="padding-left:60px;border:4px white solid;margin:10px;">
                  <img src="assets/warning.png" style="float:left;" width="30" height="30">
                  <p type="warningAnders" *ngIf="getCorrespondingPosition(titlepositions, i, j, 'original').warningAnders"> Vul tekstvak "Andere" in!</p>
                <p type="warning" *ngIf="getCorrespondingPosition(titlepositions, i, j, 'original').warning">Minstens 1 optie moet worden aangeduid/ingevuld!</p>
                <p type="failed" *ngIf="getCorrespondingPosition(titlepositions, i, j, 'original').failed"> Mislukt. Probeer opnieuw aub!</p>
                </div>
                  <div class="form-row w-100">
                  <div class="col-sm-6">
                  <input class="checkbox-inline" type="checkbox" id="update1-oldtitle" [(ngModel)]="getLastCategorizationOption(titlepositions, i, j, 'original').subjectief" name="update1-oldtitle" value="subjectief">&nbsp;
                    <label for="update1-oldtitle">  Correctie van subjectieve fout</label>
                    <div>
                      <img src="assets/questionmark.png" width="15" height="15">
                      <span class="tooltiptext">Aanpassing waarbij een subjectieve fout wordt gecorrigeerd. Hieronder vallen volgende types fouten: niet-representatieve (tussen)titels, gesensationaliseerde informatie, te ingetogen informatie, afwezigheid van essentiële informatie, misleidende cijfers, of uit de context getrokken citaten.</span>
                    </div>
                    <br>
                  </div>
                  <div class="col-sm-6">
                    <input class="checkbox-inline" type="checkbox" id="update3-oldtitle" [(ngModel)]="getLastCategorizationOption(titlepositions, i, j, 'original').objectief" name="update3-oldtitle" value="objectief">&nbsp;
                    <label for="update3-oldtitle">  Correctie van objectieve fout</label>
                    <div>
                      <img src="assets/questionmark.png" width="15" height="15">
                      <span class="tooltiptext">Aanpassing waarbij een objectieve fout wordt gecorrigeerd. Hieronder vallen volgende types fouten: foutieve namen, leeftijden, adressen, aantallen, tijdstippen, data, locaties of foutieve citaties.</span>
                    </div>
                    <br>
                  </div>
                </div>
                <div class="form-row w-100">
                  <div class="col-sm-6">
                    <input class="checkbox-inline" type="checkbox" id="update5-oldtitle" [(ngModel)]="getLastCategorizationOption(titlepositions, i, j, 'original').toevoeging" name="update5-oldtitle" value="toevoeging">&nbsp;
                    <label for="update5-oldtitle" style="font-size:9.5pt;">  Toevoeging/verduidelijking van informatie</label>
                    <div>
                      <img src="assets/questionmark.png" width="15" height="15">
                      <span class="tooltiptext">Aanpassing waarbij informatie wordt toegevoegd aan het artikel. Hieronder valt zowel de toevoeging van informatie die nog niet gekend was bij de publicatie van de originele versie, de toevoeging van reeds gekende informatie die niet essentieel was voor een correcte weergave van de inhoud van het artikel, én de toevoeging van informatie ter verduidelijking van het oorspronkelijke artikel. Ook herschrijven van titels en/of intro's, waarbij de nieuwe informatie prominent zichtbaar wordt gemaakt, valt hieronder.</span>
                    </div><br>
                  </div>
                  <div class="col-sm-6">
                    <input class="checkbox-inline" type="checkbox" id="update6-oldtitle" [(ngModel)]="getLastCategorizationOption(titlepositions, i, j, 'original').verwijdering" name="update6-oldtitle" value="verwijdering">&nbsp;
                    <label for="update6-oldtitle">  Verwijdering van informatie</label>
                    <div>
                      <img src="assets/questionmark.png" width="15" height="15">
                      <span class="tooltiptext">Aanpassing waarbij informatie, die op het moment van de originele publicatie niet fout was, verwijderd wordt omdat ze verouderd is, of om eender welke andere reden.</span>
                    </div>
                  </div>
                </div>
                <div class="form-row w-100">
                  <div class="col-sm-6">
                    <input class="checkbox-inline" type="checkbox" id="update4-oldtitle" [(ngModel)]="getLastCategorizationOption(titlepositions, i, j, 'original').herformulering" name="update4-oldtitle" value="herformulering">&nbsp;
                    <label for="update4-oldtitle">  Inhoudsneutrale aanpassing</label>
                    <div>
                      <img src="assets/questionmark.png" width="15" height="15">
                      <span class="tooltiptext">Aanpassing waarbij woorden/zinnen worden toegevoegd, verwijderd of herschreven, zonder dat hierbij nieuwe informatie wordt toegevoegd, oude informatie wordt verwijderd, fouten worden gecorrigeerd of een andere toon wordt gelegd.</span>
                    </div><br>
                  </div>
                  <div class="col-sm-6">
                    <input class="checkbox-inline" type="checkbox" id="update9-oldtitle" [(ngModel)]="getLastCategorizationOption(titlepositions, i, j, 'original').update" name="update9-oldtitle" value="update">&nbsp;
                    <label for="update9-oldtitle"> Update van oude informatie</label>
                    <div>
                      <img src="assets/questionmark.png" width="15" height="15">
                      <span class="tooltiptext">Aanpassing waarbij de oude informatie wordt vervangen door nieuwe, geüpdate informatie. Dit impliceert zowel verwijdering van oude informatie als toevoeging van nieuwe informatie.</span>
                    </div><br>
                  </div>
                </div>
                <div class="form-row w-100">
                  <div class="col-sm-6">
                    <input class="checkbox-inline" type="checkbox" id="update10-oldtitle" [(ngModel)]="getLastCategorizationOption(titlepositions, i, j, 'original').verplaatsing" name="update10-oldtitle" value="verplaatsing">&nbsp;
                    <label for="update10-oldtitle"> Verplaatsing van identieke informatie</label>
                    <div>
                      <img src="assets/questionmark.png" width="15" height="15">
                      <span class="tooltiptext">Aanpassing waarbij tekst exact gekopieerd wordt naar een andere positie in het artikel.</span>
                    </div><br>
                  </div>
                  <div class="col-sm-6">
                    <input class="checkbox-inline" type="checkbox" id="update7-oldtitle" [(ngModel)]="getLastCategorizationOption(titlepositions, i, j, 'original').spelfout" name="update7-oldtitle" value="spelfout">&nbsp;
                    <label for="update7-oldtitle">Correctie van spel- of taalfout </label>
                    <div>
                      <img src="assets/questionmark.png" style="margin-right:1em" width="15" height="15">
                      <span class="tooltiptext">Aanpassing waarbij een spelfout of grammaticale fout (bv. foutieve zinsconstructie) wordt gecorrigeerd. Onder spelfouten vallen alle fouten waarbij een woord/naam anders wordt geschreven, maar fonetisch wel nog hetzelfde klinkt. </span>
                    </div>
                    </div>
                </div>
                <div class="form-row w-100">
                  <div class="col-sm-6">
                    <input class="checkbox-inline" type="checkbox" id="update11-oldtitle" [(ngModel)]="getLastCategorizationOption(titlepositions, i, j, 'original').anders" name="update11-oldtitle" value="anders">&nbsp;
                    <label for="update11-oldtitle" style="font-size:10.5pt;">Andere </label>
                    <div>
                      <img src="assets/questionmark.png" style="margin-right:1em" width="15" height="15">
                      <span class="tooltiptext">Elk type aanpassing dat niet in één van de andere categorieën past.</span>
                    </div>
                    <input type="text" [disabled]="!getLastCategorizationOption(titlepositions, i, j, 'original').anders" id="update12-oldtitle" [(ngModel)]="getLastCategorizationOption(titlepositions, i, j, 'original').text" name="update12-oldtitle" value="text">
                  </div>
                </div>
                <br>
                <br>
                <div class="text-center">
                  <label for="explanation-oldtitle"> Opmerkingen: </label>
                  <textarea name="explanation-oldtitle" class="form-control" id="explanation-oldtitle" [(ngModel)]="getLastCategorizationOption(titlepositions, i, j, 'original').remarks"></textarea>
                </div>
                <div class="col-12 text-center">
                  <br>
                  <input class="btn btn-primary" type="submit" value="Verzend">
                </div>
                <br>
              </form>
            </div>
            <br>
            <span class="sentence">
            <ng-container *ngFor="let title_char of title_sentence.split(''); let k = index;">
            <span style="text-align:center;" [class.removedSentence]="titlepositions && sentenceInUpdate(titlepositions, i, j, 'original') && contentInSentence(title_sentence)" [class.removedChar]="titlepositions && charInUpdate(titlepositions, i, j, k, 'original') && contentInSentence(title_sentence)" (click)="clickCharacter(titlepositions, i, j, k, 'original')">{{title_char}}</span>
          </ng-container>
            <span style="text-align:center;" [class.removedSentence]="titlepositions && sentenceInUpdate(titlepositions, i, j,'original') && contentInSentence(title_sentence)" [class.removedChar]="titlepositions && charInUpdate(titlepositions, i, j, title_sentence.length - 1, 'original') && contentInSentence(title_sentence)" (click)="clickCharacter(titlepositions, i, j, title_sentence.length - 1, 'original')">&nbsp;</span>
            </span>
          </ng-container>
        </ng-container>
        <span class="onlineFrom" style="text-align:center;">Geraadpleegd op {{getPublicationTime(oldArticle.onlineFrom)}} <br> <br></span>
      </th>
      <th id="newarticle-title">
        <span class="versionNumber">Versie {{newArticle.versionNumber}}</span>
        <ng-container *ngFor="let title_part of newArticle.titleParts; let i = index;">
          <ng-container *ngFor="let title_sentence of title_part; let j = index;">
            <div class="newtitle" *ngIf="titlepositions && firstUpdateSentence(titlepositions, i, j, 'new') && startmessageSelected(title_sentence, titlepositions, 'new')">
              <div style="display:inline;">
              <div style="display:inline;" *ngIf="(titlepositions && getCorrespondingPosition(titlepositions, i, j, 'new').newpartsSelected === true)  || (titlepositions && firstUpdateSentence(titlepositions, i, j, 'new') && getCorrespondingPosition(titlepositions, i, j, 'new').categorizationSelected)">
              <button class="btn btn-primary" style="margin-left:10px;margin-top:10px;margin-right: 10px;font-size: 12px;" (click)="previous(title_sentence, titlepositions, 'new')" *ngIf="((getCorrespondingPosition(titlepositions, i, j, 'new').categorizations.originalChangedParts.length > 0) || (getCorrespondingPosition(titlepositions, i, j, 'new').categorizations.newChangedParts.length > 0) || (getCorrespondingPosition(titlepositions, i, j, 'new').newpartsSelected===true && getCorrespondingPosition(titlepositions, i, j, 'new').newpartsSelected===true  && originalPartsExist(title_sentence, titlepositions)===true))===false && getCorrespondingPosition(titlepositions, i, j, 'new').categorizationSelected===false" disabled>Ongedaan maken</button>
              <button class="btn btn-primary" style="margin-left:10px;margin-top:10px;margin-right: 10px;font-size: 12px;" (click)="previous(title_sentence, titlepositions, 'new')" *ngIf="((getCorrespondingPosition(titlepositions, i, j, 'new').categorizations.originalChangedParts.length > 0) || (getCorrespondingPosition(titlepositions, i, j, 'new').categorizations.newChangedParts.length > 0) || (getCorrespondingPosition(titlepositions, i, j, 'new').newpartsSelected===true && getCorrespondingPosition(titlepositions, i, j, 'new').newpartsSelected===true  && originalPartsExist(title_sentence, titlepositions)===true)) && getCorrespondingPosition(titlepositions, i, j, 'new').categorizationSelected===false">Ongedaan maken</button>
                <button class="btn btn-primary" style="margin-left:10px;margin-top:10px;margin-right: 10px;font-size: 12px;" (click)="previousCategory(title_sentence, titlepositions, 'new')"  *ngIf="titlepositions && firstUpdateSentence(titlepositions, i, j, 'new') && getCorrespondingPosition(titlepositions, i, j, 'new').categorizationSelected">Ongedaan maken</button>
              </div>
                <button *ngIf="titlepositions && (getCorrespondingPosition(titlepositions, i, j, 'new').newpartsSelected === true || (firstUpdateSentence(titlepositions, i, j, 'new') && getCorrespondingPosition(titlepositions, i, j, 'new').categorizationSelected))" (click)="close(getCorrespondingPosition(titlepositions, i, j, 'new'))" style="color:white;padding-right:2px;" (click)="close(getCorrespondingPosition(titlepositions, i, j, 'new'))" type="button" class="close" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
              </div>
              <br>
              <br>
              <div *ngIf="titlepositions && (getCorrespondingPosition(titlepositions, i, j, 'new').warningSelectAtLeastOne === true)" style="padding-left:60px;border:4px white solid;margin:10px;">
                <img src="assets/warning.png" style="float:left;" width="30" height="30">
                <p style="display:inline;margin-left:10px;">Je moet op zijn minst 1 tekstblok aanduiden om te kunnen categoriseren!<p>
              </div>
              <div class="newtitle-startmessage" *ngIf="titlepositions && isPositionSelected(getCorrespondingPosition(titlepositions, i, j, 'new')) && (getCorrespondingPosition(titlepositions, i, j, 'new').otherPositionsSelected === false)">
                <p>Klik op 'Start' om de beoordeling van onderstaand tekstfragment te starten.<p>
                <button class="btn btn-primary"(click)="positionSelected(title_sentence, titlepositions, 'new')">Start</button>
              </div>
              <div *ngIf="titlepositions && getCorrespondingPosition(titlepositions, i, j, 'new').newpartsSelected === true">
                <p>Welke van de donkergroene tekstblokken behoren tot eenzelfde aanpassing? Klik ze aan! <br>Klik vervolgens op 'Volgende'. <br><br>Foutje gemaakt? Klik op 'Ongedaan maken' om terug te gaan.<p>
                <button class="btn btn-primary" (click)="partsSelected(title_sentence, titlepositions, 'new')">Volgende</button>
              </div>
              <form #f="ngForm" class="row justify-content-center" *ngIf="titlepositions && firstUpdateSentence(titlepositions, i, j, 'new') && getCorrespondingPosition(titlepositions, i, j, 'new').categorizationSelected" (ngSubmit)="submit(getCorrespondingPosition(titlepositions, i, j, 'new'))">
                <p class="center-align"><b>Hoe zou je deze aanpassing categoriseren (meerdere opties mogelijk)?</b></p>
                <br>
                <div *ngIf="getCorrespondingPosition(titlepositions, i, j, 'new').warningAnders || getCorrespondingPosition(titlepositions, i, j, 'new').warning || getCorrespondingPosition(titlepositions, i, j, 'new').failed" style="padding-left:60px;border:4px white solid;margin:10px;">
                  <img src="assets/warning.png" style="float:left;" width="30" height="30">
                  <p type="warningAnders" *ngIf="getCorrespondingPosition(titlepositions, i, j, 'new').warningAnders"> Vul tekstvak "Andere" in!</p>
                <p type="warning" *ngIf="getCorrespondingPosition(titlepositions, i, j, 'new').warning">Minstens 1 optie moet worden aangeduid/ingevuld!</p>
                <p type="failed" *ngIf="getCorrespondingPosition(titlepositions, i, j, 'new').failed"> Mislukt. Probeer opnieuw aub!</p>
                </div>
                <div class="form-row w-100">
                  <div class="col-sm-6">
                    <input class="checkbox-inline" type="checkbox" id="update1-newtitle" [(ngModel)]="getLastCategorizationOption(titlepositions, i, j, 'new').subjectief" name="update1-newtitle" value="subjectief">&nbsp;
                    <label for="update1-newtitle">  Correctie van subjectieve fout</label>
                    <div>
                      <img src="assets/questionmark.png" width="15" height="15">
                      <span class="tooltiptext">Aanpassing waarbij een subjectieve fout wordt gecorrigeerd. Hieronder vallen volgende types fouten: niet-representatieve (tussen)titels, gesensationaliseerde informatie, te ingetogen informatie, afwezigheid van essentiële informatie, misleidende cijfers, of uit de context getrokken citaten.</span>
                    </div>
                    <br>
                  </div>
                  <div class="col-sm-6">
                    <input class="checkbox-inline" type="checkbox" id="update2-newtitle" [(ngModel)]="getLastCategorizationOption(titlepositions, i, j, 'new').objectief" name="update2-newtitle" value="objectief">&nbsp;
                    <label for="update2-newtitle">  Correctie van objectieve fout</label>
                    <div>
                      <img src="assets/questionmark.png" width="15" height="15">
                      <span class="tooltiptext">Aanpassing waarbij een objectieve fout wordt gecorrigeerd. Hieronder vallen volgende types fouten: foutieve namen, leeftijden, adressen, aantallen, tijdstippen, data, locaties of foutieve citaties.</span>
                    </div>
                    <br>
                  </div>
                </div>
                <div class="form-row w-100">
                  <div class="col-sm-6">
                    <input class="checkbox-inline" type="checkbox" id="update5-newtitle" [(ngModel)]="getLastCategorizationOption(titlepositions, i, j, 'new').toevoeging" name="update5-newtitle" value="toevoeging">&nbsp;
                    <label for="update5-newtitle" style="font-size:9.5pt;">  Toevoeging/verduidelijking van informatie</label>
                    <div>
                      <img src="assets/questionmark.png" width="15" height="15">
                      <span class="tooltiptext">Aanpassing waarbij informatie wordt toegevoegd aan het artikel. Hieronder valt zowel de toevoeging van informatie die nog niet gekend was bij de publicatie van de originele versie, de toevoeging van reeds gekende informatie die niet essentieel was voor een correcte weergave van de inhoud van het artikel, én de toevoeging van informatie ter verduidelijking van het oorspronkelijke artikel. Ook herschrijven van titels en/of intro's, waarbij de nieuwe informatie prominent zichtbaar wordt gemaakt, valt hieronder.</span>
                    </div><br>
                  </div>
                  <div class="col-sm-6">
                    <input class="checkbox-inline" type="checkbox" id="update6-newtitle" [(ngModel)]="getLastCategorizationOption(titlepositions, i, j, 'new').verwijdering" name="update6-newtitle" value="verwijdering">&nbsp;
                    <label for="update6-newtitle">  Verwijdering van informatie</label>
                    <div>
                      <img src="assets/questionmark.png" width="15" height="15">
                      <span class="tooltiptext">Aanpassing waarbij informatie, die op het moment van de originele publicatie niet fout was, verwijderd wordt omdat ze verouderd is, of om eender welke andere reden.</span>
                    </div>
                  </div>
                </div>
                <div class="form-row w-100">
                  <div class="col-sm-6">
                    <input class="checkbox-inline" type="checkbox" id="update4-newtitle" [(ngModel)]="getLastCategorizationOption(titlepositions, i, j, 'new').herformulering" name="update4-newtitle" value="herformulering">&nbsp;
                    <label for="update4-newtitle">  Inhoudsneutrale aanpassing</label>
                    <div>
                      <img src="assets/questionmark.png" width="15" height="15">
                      <span class="tooltiptext">Aanpassing waarbij woorden/zinnen worden toegevoegd, verwijderd of herschreven, zonder dat hierbij nieuwe informatie wordt toegevoegd, oude informatie wordt verwijderd, fouten worden gecorrigeerd of een andere toon wordt gelegd.</span>
                    </div><br>
                  </div>
                  <div class="col-sm-6">
                    <input class="checkbox-inline" type="checkbox" id="update9-newtitle" [(ngModel)]="getLastCategorizationOption(titlepositions, i, j, 'new').update" name="update9-newtitle" value="update">&nbsp;
                    <label for="update9-newtitle"> Update van oude informatie</label>
                    <div>
                      <img src="assets/questionmark.png" width="15" height="15">
                      <span class="tooltiptext">Aanpassing waarbij de oude informatie wordt vervangen door nieuwe, geüpdate informatie. Dit impliceert zowel verwijdering van oude informatie als toevoeging van nieuwe informatie.</span>
                    </div><br>
                  </div>
                </div>
                <div class="form-row w-100">
                  <div class="col-sm-6">
                    <input class="checkbox-inline" type="checkbox" id="update10-newtitle" [(ngModel)]="getLastCategorizationOption(titlepositions, i, j, 'new').verplaatsing" name="update10-newtitle" value="verplaatsing">&nbsp;
                    <label for="update10-newtitle"> Verplaatsing van identieke informatie</label>
                    <div>
                      <img src="assets/questionmark.png" width="15" height="15">
                      <span class="tooltiptext">Aanpassing waarbij tekst exact gekopieerd wordt naar een andere positie in het artikel.</span>
                    </div><br>
                  </div>
                  <div class="col-sm-6">
                    <input class="checkbox-inline" type="checkbox" id="update7-newtitle" [(ngModel)]="getLastCategorizationOption(titlepositions, i, j, 'new').spelfout" name="update7-newtitle" value="spelfout">&nbsp;
                    <label for="update7-newtitle">Correctie van spel- of taalfout </label>
                    <div>
                      <img src="assets/questionmark.png" style="margin-right:1em" width="15" height="15">
                      <span class="tooltiptext">Aanpassing waarbij een spelfout of grammaticale fout (bv. foutieve zinsconstructie) wordt gecorrigeerd. Onder spelfouten vallen alle fouten waarbij een woord/naam anders wordt geschreven, maar fonetisch wel nog hetzelfde klinkt. </span>
                    </div>
                    </div>
                </div>
                <div class="form-row w-100">
                  <div class="col-sm-6">
                    <input class="checkbox-inline" type="checkbox" id="update11-newtitle" [(ngModel)]="getLastCategorizationOption(titlepositions, i, j, 'new').anders" name="update11-newtitle" value="anders">&nbsp;
                    <label for="update11-oldtitle" style="font-size:10.5pt;">Andere </label>
                    <div>
                      <img src="assets/questionmark.png" style="margin-right:1em" width="15" height="15">
                      <span class="tooltiptext">Elk type aanpassing dat niet in één van de andere categorieën past.</span>
                    </div>
                    <input type="text" [disabled]="!getLastCategorizationOption(titlepositions, i, j, 'new').anders" id="update12-newtitle" [(ngModel)]="getLastCategorizationOption(titlepositions, i, j, 'new').text" name="update12-newtitle" value="text">
                  </div>
                </div>
                <br>
                <br>
                <div class="text-center">
                  <label for="explanation-newtitle"> Opmerkingen: </label>
                  <textarea name="explanation-newtitle" class="form-control" id="explanation-newtitle" [(ngModel)]="getLastCategorizationOption(titlepositions, i, j, 'new').remarks"></textarea>
                </div>
                <div class="col-12 text-center">
                  <br>
                   <input class="btn btn-primary" type="submit" value="Verzend">
                </div>
                <br>
              </form>
            </div>
            <br>
            <span class="sentence">
            <ng-container *ngFor="let title_char of title_sentence.split(''); let k = index;">
            <span style="text-align:center;" [class.addedSentence]="titlepositions && sentenceInUpdate(titlepositions, i, j, 'new') && contentInSentence(title_sentence)" [class.addedChar]="titlepositions && charInUpdate(titlepositions, i, j, k, 'new') && contentInSentence(title_sentence)" (click)="clickCharacter(titlepositions, i, j, k, 'new')">{{title_char}}</span>
          </ng-container>
            <span style="text-align:center;" [class.addedSentence]="titlepositions && sentenceInUpdate(titlepositions, i, j, 'new') && contentInSentence(title_sentence)" [class.addedChar]="titlepositions && charInUpdate(titlepositions, i, j, title_sentence.length - 1, 'new') && contentInSentence(title_sentence)" (click)="clickCharacter(titlepositions, i, j, title_sentence.length - 1, 'new')">&nbsp;</span>
            </span>
          </ng-container>
        </ng-container>
        <span class="onlineFrom" style="text-align:center;">Gepubliceerd op {{getPublicationTime(newArticle.onlineFrom)}} <br> <br></span>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td id="oldarticle-intro">
      <ng-container *ngFor="let intro_part of oldArticle.introParts; let i = index;">
        <ng-container *ngFor="let intro_sentence of intro_part; let j = index;">
          <div class="oldintro" *ngIf="intropositions && firstUpdateSentence(intropositions, i, j, 'original') && startmessageSelected(intro_sentence, intropositions, 'original')">
            <div style="display:inline;">
            <div style="display:inline;" *ngIf="(intropositions && getCorrespondingPosition(intropositions, i, j, 'original').oldpartsSelected === true)  || (intropositions && firstUpdateSentence(intropositions, i, j, 'original') && getCorrespondingPosition(intropositions, i, j, 'original').categorizationSelected)">
            <button class="btn btn-primary" style="margin-left:10px;margin-top:10px;margin-right: 10px;font-size: 12px;" (click)="previous(intro_sentence, intropositions, 'original')"  *ngIf="((getCorrespondingPosition(intropositions, i, j, 'original').categorizations.originalChangedParts.length > 0) || (getCorrespondingPosition(intropositions, i, j, 'original').categorizations.newChangedParts.length > 0))===false && getCorrespondingPosition(intropositions, i, j, 'original').categorizationSelected===false" disabled>Ongedaan maken</button>
            <button class="btn btn-primary" style="margin-left:10px;margin-top:10px;margin-right: 10px;font-size: 12px;" (click)="previous(intro_sentence, intropositions, 'original')"  *ngIf="((getCorrespondingPosition(intropositions, i, j, 'original').categorizations.originalChangedParts.length > 0) || (getCorrespondingPosition(intropositions, i, j, 'original').categorizations.newChangedParts.length > 0)) && getCorrespondingPosition(intropositions, i, j, 'original').categorizationSelected===false">Ongedaan maken</button>
              <button class="btn btn-primary" style="margin-left:10px;margin-top:10px;margin-right: 10px;font-size: 12px;" (click)="previousCategory(intro_sentence, intropositions, 'original')"  *ngIf="intropositions && firstUpdateSentence(intropositions, i, j, 'original') && getCorrespondingPosition(intropositions, i, j, 'original').categorizationSelected">Ongedaan maken</button>
            </div>
              <button *ngIf="intropositions && (getCorrespondingPosition(intropositions, i, j, 'original').oldpartsSelected === true || (firstUpdateSentence(intropositions, i, j, 'original') && getCorrespondingPosition(intropositions, i, j, 'original').categorizationSelected))" style="color:white;padding-right:2px;" (click)="close(getCorrespondingPosition(intropositions, i, j, 'original'))" type="button" class="close" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
            </div>
            <br>
            <br>
            <div *ngIf="intropositions && (getCorrespondingPosition(intropositions, i, j, 'original').warningSelectAtLeastOne === true)" style="padding-left:60px;border:4px white solid;margin:10px;">
              <img src="assets/warning.png" style="float:left;" width="30" height="30">
              <p style="display:inline;margin-left:10px;">Je moet op zijn minst 1 tekstblok aanduiden om te kunnen categoriseren!<p>
            </div>
            <div class="oldintro-startmessage" *ngIf="intropositions && isPositionSelected(getCorrespondingPosition(intropositions, i, j, 'original')) && (getCorrespondingPosition(intropositions, i, j, 'original').otherPositionsSelected === false)">
              <p>Klik op 'Start' om de beoordeling van onderstaand tekstfragment te starten.<p>
              <button class="btn btn-primary" (click)="positionSelected(intro_sentence, intropositions, 'original')">Start</button>
            </div>
            <div *ngIf="intropositions && getCorrespondingPosition(intropositions, i, j, 'original').oldpartsSelected === true">
              <p>Welke van de donkerrode tekstblokken behoren tot eenzelfde aanpassing? Klik ze aan! <br>Klik vervolgens op 'Volgende'. <br><br>Foutje gemaakt? Klik op 'Ongedaan maken' om terug te gaan.<p>
              <button class="btn btn-primary" (click)="partsSelected(intro_sentence, intropositions, 'original')">Volgende</button>
            </div>
            <form #f="ngForm" class="row justify-content-center" *ngIf="intropositions && firstUpdateSentence(intropositions, i, j, 'original') && getCorrespondingPosition(intropositions, i, j, 'original').categorizationSelected" (ngSubmit)="submit(getCorrespondingPosition(intropositions, i, j, 'original'))">
              <p class="center-align"><b>Hoe zou je deze aanpassing categoriseren (meerdere opties mogelijk)?</b></p>
              <br>
              <div *ngIf="getCorrespondingPosition(intropositions, i, j, 'original').warningAnders || getCorrespondingPosition(intropositions, i, j, 'original').warning || getCorrespondingPosition(intropositions, i, j, 'original').failed" style="padding-left:60px;border:4px white solid;margin:10px;">
                <img src="assets/warning.png" style="float:left;" width="30" height="30">
                <p type="warningAnders" *ngIf="getCorrespondingPosition(intropositions, i, j, 'original').warningAnders"> Vul tekstvak "Andere" in!</p>
              <p type="warning" *ngIf="getCorrespondingPosition(intropositions, i, j, 'original').warning">Minstens 1 optie moet worden aangeduid/ingevuld!</p>
              <p type="failed" *ngIf="getCorrespondingPosition(intropositions, i, j, 'original').failed"> Mislukt. Probeer opnieuw aub!</p>
              </div>
                <div class="form-row w-100">
                <div class="col-sm-6">
                  <input class="checkbox-inline" type="checkbox" id="update1-oldintro" [(ngModel)]="getLastCategorizationOption(intropositions, i, j, 'original').subjectief" name="update1-oldintro" value="subjectief">&nbsp;
                  <label for="update1-oldintro">  Correctie van subjectieve fout</label>
                  <div>
                    <img src="assets/questionmark.png" width="15" height="15">
                    <span class="tooltiptext">Aanpassing waarbij een subjectieve fout wordt gecorrigeerd. Hieronder vallen volgende types fouten: niet-representatieve (tussen)titels, gesensationaliseerde informatie, te ingetogen informatie, afwezigheid van essentiële informatie, misleidende cijfers, of uit de context getrokken citaten.</span>
                  </div>
                  <br>
                </div>
                <div class="col-sm-6">
                  <input class="checkbox-inline" type="checkbox" id="update2-oldintro" [(ngModel)]="getLastCategorizationOption(intropositions, i, j, 'original').objectief" name="update2-oldintro" value="objectief">&nbsp;
                  <label for="update2-oldintro">  Correctie van objectieve fout</label>
                  <div>
                    <img src="assets/questionmark.png" width="15" height="15">
                    <span class="tooltiptext">Aanpassing waarbij een objectieve fout wordt gecorrigeerd. Hieronder vallen volgende types fouten: foutieve namen, leeftijden, adressen, aantallen, tijdstippen, data, locaties of foutieve citaties.</span>
                  </div>
                  <br>
                </div>
              </div>
              <div class="form-row w-100">
                <div class="col-sm-6">
                  <input class="checkbox-inline" type="checkbox" id="update5-oldintro" [(ngModel)]="getLastCategorizationOption(intropositions, i, j, 'original').toevoeging" name="update5-oldintro" value="toevoeging">&nbsp;
                  <label for="update5-oldintro" style="font-size:9.5pt;">  Toevoeging/verduidelijking van informatie</label>
                  <div>
                    <img src="assets/questionmark.png" width="15" height="15">
                    <span class="tooltiptext">Aanpassing waarbij informatie wordt toegevoegd aan het artikel. Hieronder valt zowel de toevoeging van informatie die nog niet gekend was bij de publicatie van de originele versie, de toevoeging van reeds gekende informatie die niet essentieel was voor een correcte weergave van de inhoud van het artikel, én de toevoeging van informatie ter verduidelijking van het oorspronkelijke artikel. Ook herschrijven van titels en/of intro's, waarbij de nieuwe informatie prominent zichtbaar wordt gemaakt, valt hieronder.</span>
                  </div><br>
                </div>
                <div class="col-sm-6">
                  <input class="checkbox-inline" type="checkbox" id="update6-oldintro" [(ngModel)]="getLastCategorizationOption(intropositions, i, j, 'original').verwijdering" name="update6-oldintro" value="verwijdering">&nbsp;
                  <label for="update6-oldintro">  Verwijdering van informatie</label>
                  <div>
                    <img src="assets/questionmark.png" width="15" height="15">
                    <span class="tooltiptext">Aanpassing waarbij informatie, die op het moment van de originele publicatie niet fout was, verwijderd wordt omdat ze verouderd is, of om eender welke andere reden.</span>
                  </div>
                </div>
              </div>
              <div class="form-row w-100">
                <div class="col-sm-6">
                  <input class="checkbox-inline" type="checkbox" id="update4-oldintro" [(ngModel)]="getLastCategorizationOption(intropositions, i, j, 'original').herformulering" name="update4-oldintro" value="herformulering">&nbsp;
                  <label for="update4-oldintro">  Inhoudsneutrale aanpassing</label>
                  <div>
                    <img src="assets/questionmark.png" width="15" height="15">
                    <span class="tooltiptext">Aanpassing waarbij woorden/zinnen worden toegevoegd, verwijderd of herschreven, zonder dat hierbij nieuwe informatie wordt toegevoegd, oude informatie wordt verwijderd, fouten worden gecorrigeerd of een andere toon wordt gelegd.</span>
                  </div><br>
                </div>
                <div class="col-sm-6">
                  <input class="checkbox-inline" type="checkbox" id="update9-oldintro" [(ngModel)]="getLastCategorizationOption(intropositions, i, j, 'original').update" name="update9-oldintro" value="update">&nbsp;
                  <label for="update9-oldintro"> Update van oude informatie</label>
                  <div>
                    <img src="assets/questionmark.png" width="15" height="15">
                    <span class="tooltiptext">Aanpassing waarbij de oude informatie wordt vervangen door nieuwe, geüpdate informatie. Dit impliceert zowel verwijdering van oude informatie als toevoeging van nieuwe informatie.</span>
                  </div><br>
                </div>
              </div>
              <div class="form-row w-100">
                <div class="col-sm-6">
                  <input class="checkbox-inline" type="checkbox" id="update10-oldintro" [(ngModel)]="getLastCategorizationOption(intropositions, i, j, 'original').verplaatsing" name="update10-oldintro" value="verplaatsing">&nbsp;
                  <label for="update10-oldintro"> Verplaatsing van identieke informatie</label>
                  <div>
                    <img src="assets/questionmark.png" width="15" height="15">
                    <span class="tooltiptext">Aanpassing waarbij tekst exact gekopieerd wordt naar een andere positie in het artikel.</span>
                  </div><br>
                </div>
                <div class="col-sm-6">
                  <input class="checkbox-inline" type="checkbox" id="update7-oldintro" [(ngModel)]="getLastCategorizationOption(intropositions, i, j, 'original').spelfout" name="update7-oldintro" value="spelfout">&nbsp;
                  <label for="update7-oldintro">Correctie van spel- of taalfout </label>
                  <div>
                    <img src="assets/questionmark.png" style="margin-right:1em" width="15" height="15">
                    <span class="tooltiptext">Aanpassing waarbij een spelfout of grammaticale fout (bv. foutieve zinsconstructie) wordt gecorrigeerd. Onder spelfouten vallen alle fouten waarbij een woord/naam anders wordt geschreven, maar fonetisch wel nog hetzelfde klinkt. </span>
                  </div>
                  </div>
              </div>
              <div class="form-row w-100">
                <div class="col-sm-6">
                  <input class="checkbox-inline" type="checkbox" id="update11-oldintro" [(ngModel)]="getLastCategorizationOption(intropositions, i, j, 'original').anders" name="update11-oldintro" value="anders">&nbsp;
                  <label for="update11-oldintro" style="font-size:10.5pt;">Andere </label>
                  <div>
                    <img src="assets/questionmark.png" style="margin-right:1em" width="15" height="15">
                    <span class="tooltiptext">Elk type aanpassing dat niet in één van de andere categorieën past.</span>
                  </div>
                  <input type="text" [disabled]="!getLastCategorizationOption(intropositions, i, j, 'original').anders" id="update12-oldintro" [(ngModel)]="getLastCategorizationOption(intropositions, i, j, 'original').text" name="update12-oldintro" value="text">
                </div>
              </div>
              <div class="text-center">
                <label for="explanation-oldintro"> Opmerkingen: </label>
                <textarea name="explanation-oldintro" class="form-control" id="explanation-oldintro" [(ngModel)]="getLastCategorizationOption(intropositions, i, j, 'original').remarks"></textarea>
              </div>
              <div class="col-12 text-center">
                <br>
                <input class="btn btn-primary" type="submit" value="Verzend">
              </div>
              <br>
            </form>
          </div>
          <br>
          <ng-container *ngFor="let intro_char of intro_sentence.split(''); let k = index;">
          <span [class.removedSentence]="intropositions && sentenceInUpdate(intropositions, i, j, 'original') && contentInSentence(intro_sentence)" [class.removedChar]="intropositions && charInUpdate(intropositions, i, j, k, 'original') && contentInSentence(intro_sentence)" (click)="clickCharacter(intropositions, i, j, k, 'original')"><b>{{intro_char}}</b></span>
      </ng-container>
          <span [class.removedSentence]="intropositions && sentenceInUpdate(intropositions, i, j, 'original') && contentInSentence(intro_sentence)" [class.removedChar]="intropositions && charInUpdate(intropositions, i, j, intro_sentence.length - 1, 'original') && contentInSentence(intro_sentence)" (click)="clickCharacter(intropositions, i, j, intro_sentence.length - 1, 'original')">&nbsp;</span>
        </ng-container>
        <br>
        <br>
      </ng-container>
      </td>
      <td id="newarticle-intro">
      <ng-container *ngFor="let intro_part of newArticle.introParts; let i = index;">
        <ng-container *ngFor="let intro_sentence of intro_part; let j = index;">
          <div class="newintro" *ngIf="intropositions && firstUpdateSentence(intropositions, i, j, 'new') && startmessageSelected(intro_sentence, intropositions, 'new')">
            <div style="display:inline;">
            <div style="display:inline;" *ngIf="(intropositions && getCorrespondingPosition(intropositions, i, j, 'new').newpartsSelected === true)  || (intropositions && firstUpdateSentence(intropositions, i, j, 'new') && getCorrespondingPosition(intropositions, i, j, 'new').categorizationSelected)">
            <button class="btn btn-primary" style="margin-left:10px;margin-top:10px;margin-right: 10px;font-size: 12px;" (click)="previous(intro_sentence, intropositions, 'new')"  *ngIf="((getCorrespondingPosition(intropositions, i, j, 'new').categorizations.originalChangedParts.length > 0) || (getCorrespondingPosition(intropositions, i, j, 'new').categorizations.newChangedParts.length > 0) || (getCorrespondingPosition(intropositions, i, j, 'new').newpartsSelected===true && originalPartsExist(intro_sentence, intropositions)===true))===false && getCorrespondingPosition(intropositions, i, j, 'new').categorizationSelected===false" disabled>Ongedaan maken</button>
            <button class="btn btn-primary" style="margin-left:10px;margin-top:10px;margin-right: 10px;font-size: 12px;" (click)="previous(intro_sentence, intropositions, 'new')"  *ngIf="((getCorrespondingPosition(intropositions, i, j, 'new').categorizations.originalChangedParts.length > 0) || (getCorrespondingPosition(intropositions, i, j, 'new').categorizations.newChangedParts.length > 0) || (getCorrespondingPosition(intropositions, i, j, 'new').newpartsSelected===true && originalPartsExist(intro_sentence, intropositions)===true)) && getCorrespondingPosition(intropositions, i, j, 'new').categorizationSelected===false">Ongedaan maken</button>
              <button class="btn btn-primary" style="margin-left:10px;margin-top:10px;margin-right: 10px;font-size: 12px;" (click)="previousCategory(intro_sentence, intropositions, 'new')"  *ngIf="intropositions && firstUpdateSentence(intropositions, i, j, 'new') && getCorrespondingPosition(intropositions, i, j, 'new').categorizationSelected">Ongedaan maken</button>
            </div>
              <button *ngIf="intropositions && (getCorrespondingPosition(intropositions, i, j, 'new').newpartsSelected === true || (firstUpdateSentence(intropositions, i, j, 'new') && getCorrespondingPosition(intropositions, i, j, 'new').categorizationSelected))" style="color:white;padding-right:2px;" (click)="close(getCorrespondingPosition(intropositions, i, j, 'new'))" type="button" class="close" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
            </div>
            <br>
            <br>
            <div *ngIf="intropositions && (getCorrespondingPosition(intropositions, i, j, 'new').warningSelectAtLeastOne === true)" style="padding-left:60px;border:4px white solid;margin:10px;">
              <img src="assets/warning.png" style="float:left;" width="30" height="30">
              <p style="display:inline;margin-left:10px;">Je moet op zijn minst 1 tekstblok aanduiden om te kunnen categoriseren!<p>
            </div>
            <div class="newintro-startmessage" *ngIf="intropositions && isPositionSelected(getCorrespondingPosition(intropositions, i, j, 'new')) && (getCorrespondingPosition(intropositions, i, j, 'new').otherPositionsSelected === false)">
              <p>Klik op 'Start' om de beoordeling van onderstaand tekstfragment te starten.<p>
              <button class="btn btn-primary" (click)="positionSelected(intro_sentence, intropositions, 'new')">Start</button>
            </div>
            <div *ngIf="intropositions && getCorrespondingPosition(intropositions, i, j, 'new').newpartsSelected === true">
              <p>Welke van de donkergroene tekstblokken behoren tot eenzelfde aanpassing? Klik ze aan! <br>Klik vervolgens op 'Volgende'. <br><br>Foutje gemaakt? Klik op 'Ongedaan maken' om terug te gaan.<p>
              <button class="btn btn-primary" (click)="partsSelected(intro_sentence, intropositions, 'new')">Volgende</button>
            </div>
            <form #f="ngForm" class="row justify-content-center" *ngIf="intropositions && firstUpdateSentence(intropositions, i, j, 'new') && getCorrespondingPosition(intropositions, i, j, 'new').categorizationSelected" (ngSubmit)="submit(getCorrespondingPosition(intropositions, i, j, 'new'))">
              <p class="center-align"><b>Hoe zou je deze aanpassing categoriseren (meerdere opties mogelijk)?</b></p>
              <div *ngIf="getCorrespondingPosition(intropositions, i, j, 'new').warningAnders || getCorrespondingPosition(intropositions, i, j, 'new').warning || getCorrespondingPosition(intropositions, i, j, 'new').failed" style="padding-left:60px;border:4px white solid;margin:10px;">
                <img src="assets/warning.png" style="float:left;" width="30" height="30">
                <p type="warning" *ngIf="getCorrespondingPosition(intropositions, i, j, 'new').warning">Minstens 1 optie moet worden aangeduid/ingevuld!</p>
              <p type="warningAnders" *ngIf="getCorrespondingPosition(intropositions, i, j, 'new').warningAnders"> Vul tekstvak "Andere" in!</p>
              <p type="failed" *ngIf="getCorrespondingPosition(intropositions, i, j, 'new').failed"> Mislukt. Probeer opnieuw aub!</p>
              </div>
              <br>
              <div class="form-row w-100">
                <div class="col-sm-6">
                  <input class="checkbox-inline" type="checkbox" id="update1-newintro" [(ngModel)]="getLastCategorizationOption(intropositions, i, j, 'new').subjectief" name="update1-newintro" value="subjectief">&nbsp;
                  <label for="update1-newintro">  Correctie van subjectieve fout</label>
                  <div>
                    <img src="assets/questionmark.png" width="15" height="15">
                    <span class="tooltiptext">Aanpassing waarbij een subjectieve fout wordt gecorrigeerd. Hieronder vallen volgende types fouten: niet-representatieve (tussen)titels, gesensationaliseerde informatie, te ingetogen informatie, afwezigheid van essentiële informatie, misleidende cijfers, of uit de context getrokken citaten.</span>
                  </div>
                  <br>
                </div>
                <div class="col-sm-6">
                  <input class="checkbox-inline" type="checkbox" id="update2-newintro" [(ngModel)]="getLastCategorizationOption(intropositions, i, j, 'new').objectief" name="update2-newintro" value="objectief">&nbsp;
                  <label for="update2-newintro">  Correctie van objectieve fout</label>
                  <div>
                    <img src="assets/questionmark.png" width="15" height="15">
                    <span class="tooltiptext">Aanpassing waarbij een objectieve fout wordt gecorrigeerd. Hieronder vallen volgende types fouten: foutieve namen, leeftijden, adressen, aantallen, tijdstippen, data, locaties of foutieve citaties.</span>
                  </div>
                  <br>
                </div>
              </div>
              <div class="form-row w-100">
                <div class="col-sm-6">
                  <input class="checkbox-inline" type="checkbox" id="update5-newintro" [(ngModel)]="getLastCategorizationOption(intropositions, i, j, 'new').toevoeging" name="update5-newintro" value="toevoeging">&nbsp;
                  <label for="update5-newintro" style="font-size:9.5pt;">  Toevoeging/verduidelijking van informatie</label>
                  <div>
                    <img src="assets/questionmark.png" width="15" height="15">
                    <span class="tooltiptext">Aanpassing waarbij informatie wordt toegevoegd aan het artikel. Hieronder valt zowel de toevoeging van informatie die nog niet gekend was bij de publicatie van de originele versie, de toevoeging van reeds gekende informatie die niet essentieel was voor een correcte weergave van de inhoud van het artikel, én de toevoeging van informatie ter verduidelijking van het oorspronkelijke artikel. Ook herschrijven van titels en/of intro's, waarbij de nieuwe informatie prominent zichtbaar wordt gemaakt, valt hieronder.</span>
                  </div><br>
                </div>
                <div class="col-sm-6">
                  <input class="checkbox-inline" type="checkbox" id="update6-newintro" [(ngModel)]="getLastCategorizationOption(intropositions, i, j, 'new').verwijdering" name="update6-newintro" value="verwijdering">&nbsp;
                  <label for="update6-newintro">  Verwijdering van informatie</label>
                  <div>
                    <img src="assets/questionmark.png" width="15" height="15">
                    <span class="tooltiptext">Aanpassing waarbij informatie, die op het moment van de originele publicatie niet fout was, verwijderd wordt omdat ze verouderd is, of om eender welke andere reden.</span>
                  </div>
                </div>
              </div>
              <div class="form-row w-100">
                <div class="col-sm-6">
                  <input class="checkbox-inline" type="checkbox" id="update4-newintro" [(ngModel)]="getLastCategorizationOption(intropositions, i, j, 'new').herformulering" name="update4-newintro" value="herformulering">&nbsp;
                  <label for="update4-newintro">  Inhoudsneutrale aanpassing</label>
                  <div>
                    <img src="assets/questionmark.png" width="15" height="15">
                    <span class="tooltiptext">Aanpassing waarbij woorden/zinnen worden toegevoegd, verwijderd of herschreven, zonder dat hierbij nieuwe informatie wordt toegevoegd, oude informatie wordt verwijderd, fouten worden gecorrigeerd of een andere toon wordt gelegd.</span>
                  </div><br>
                </div>
                <div class="col-sm-6">
                  <input class="checkbox-inline" type="checkbox" id="update9-newintro" [(ngModel)]="getLastCategorizationOption(intropositions, i, j, 'new').update" name="update9-newintro" value="update">&nbsp;
                  <label for="update9-newintro"> Update van oude informatie</label>
                  <div>
                    <img src="assets/questionmark.png" width="15" height="15">
                    <span class="tooltiptext">Aanpassing waarbij de oude informatie wordt vervangen door nieuwe, geüpdate informatie. Dit impliceert zowel verwijdering van oude informatie als toevoeging van nieuwe informatie.</span>
                  </div><br>
                </div>
              </div>
              <div class="form-row w-100">
                <div class="col-sm-6">
                  <input class="checkbox-inline" type="checkbox" id="update10-newintro" [(ngModel)]="getLastCategorizationOption(intropositions, i, j, 'new').verplaatsing" name="update10-newintro" value="verplaatsing">&nbsp;
                  <label for="update10-newintro"> Verplaatsing van identieke informatie</label>
                  <div>
                    <img src="assets/questionmark.png" width="15" height="15">
                    <span class="tooltiptext">Aanpassing waarbij tekst exact gekopieerd wordt naar een andere positie in het artikel.</span>
                  </div><br>
                </div>
                <div class="col-sm-6">
                  <input class="checkbox-inline" type="checkbox" id="update7-newintro" [(ngModel)]="getLastCategorizationOption(intropositions, i, j, 'new').spelfout" name="update7-newintro" value="spelfout">&nbsp;
                  <label for="update7-newintro">Correctie van spel- of taalfout </label>
                  <div>
                    <img src="assets/questionmark.png" style="margin-right:1em" width="15" height="15">
                    <span class="tooltiptext">Aanpassing waarbij een spelfout of grammaticale fout (bv. foutieve zinsconstructie) wordt gecorrigeerd. Onder spelfouten vallen alle fouten waarbij een woord/naam anders wordt geschreven, maar fonetisch wel nog hetzelfde klinkt. </span>
                  </div>
                  </div>
              </div>
              <div class="form-row w-100">
                <div class="col-sm-6">
                  <input class="checkbox-inline" type="checkbox" id="update11-newintro" [(ngModel)]="getLastCategorizationOption(intropositions, i, j, 'new').anders" name="update11-newintro" value="anders">&nbsp;
                  <label for="update11-newintro" style="font-size:10.5pt;">Andere </label>
                  <div>
                    <img src="assets/questionmark.png" style="margin-right:1em" width="15" height="15">
                    <span class="tooltiptext">Elk type aanpassing dat niet in één van de andere categorieën past.</span>
                  </div>
                  <input type="text" [disabled]="!getLastCategorizationOption(intropositions, i, j, 'new').anders" id="update12-newintro" [(ngModel)]="getLastCategorizationOption(intropositions, i, j, 'new').text" name="update12-newintro" value="text">
                </div>
              </div>
              <br>
              <br>
              <div class="text-center">
                <label for="explanation-newintro"> Opmerkingen: </label>
                <textarea name="explanation-newintro" class="form-control" id="explanation-newintro" [(ngModel)]="getLastCategorizationOption(intropositions, i, j, 'new').remarks"></textarea>
              </div>
              <div class="col-12 text-center">
                <br>
                <input class="btn btn-primary" type="submit" value="Verzend">
              </div>
              <br>
            </form>
          </div>
          <br>
          <ng-container *ngFor="let intro_char of intro_sentence.split(''); let k = index;">
          <span [class.addedSentence]="intropositions && sentenceInUpdate(intropositions, i, j, 'new') && contentInSentence(intro_sentence)" [class.addedChar]="intropositions && charInUpdate(intropositions, i, j, k, 'new') && contentInSentence(intro_sentence)" (click)="clickCharacter(intropositions, i, j, k, 'new')"><b>{{intro_char}}</b></span>
      </ng-container>
          <span [class.addedSentence]="intropositions && sentenceInUpdate(intropositions, i, j, 'new') && contentInSentence(intro_sentence)" [class.addedChar]="intropositions && charInUpdate(intropositions, i, j, intro_sentence.length - 1, 'new') && contentInSentence(intro_sentence)" (click)="clickCharacter(intropositions, i, j, intro_sentence.length - 1, 'new')">&nbsp;</span>
      </ng-container>
        <br>
        <br>
      </ng-container>
      </td>
    </tr>

    <tr>
      <td id="oldarticle-text">
      <ng-container *ngFor="let text_part of oldArticle.textParts; let i = index;">
        <ng-container *ngFor="let text_sentence of text_part; let j = index;">
          <div class="oldtext" *ngIf="textpositions && firstUpdateSentence(textpositions, i, j, 'original') && startmessageSelected(text_sentence, textpositions, 'original')">
            <div style="display:inline;">
            <div style="display:inline;" *ngIf="(titlepositions && getCorrespondingPosition(textpositions, i, j, 'original').oldpartsSelected === true)  || (textpositions && firstUpdateSentence(textpositions, i, j, 'original') && getCorrespondingPosition(textpositions, i, j, 'original').categorizationSelected)">
            <button class="btn btn-primary" style="margin-left:10px;margin-top:10px;margin-right: 10px;font-size: 12px;" (click)="previous(text_sentence, textpositions, 'original')"  *ngIf="((getCorrespondingPosition(textpositions, i, j, 'original').categorizations.originalChangedParts.length > 0) || (getCorrespondingPosition(textpositions, i, j, 'original').categorizations.newChangedParts.length > 0))===false && getCorrespondingPosition(textpositions, i, j, 'original').categorizationSelected===false" disabled>Ongedaan maken</button>
            <button class="btn btn-primary" style="margin-left:10px;margin-top:10px;margin-right: 10px;font-size: 12px;" (click)="previous(text_sentence, textpositions, 'original')"  *ngIf="((getCorrespondingPosition(textpositions, i, j, 'original').categorizations.originalChangedParts.length > 0) || (getCorrespondingPosition(textpositions, i, j, 'original').categorizations.newChangedParts.length > 0)) && getCorrespondingPosition(textpositions, i, j, 'original').categorizationSelected===false">Ongedaan maken</button>
              <button class="btn btn-primary" style="margin-left:10px;margin-top:10px;margin-right: 10px;font-size: 12px;" (click)="previousCategory(text_sentence, textpositions, 'original')" *ngIf="textpositions && firstUpdateSentence(textpositions, i, j, 'original') && getCorrespondingPosition(textpositions, i, j, 'original').categorizationSelected">Ongedaan maken</button>
            </div>
              <button *ngIf="textpositions && (getCorrespondingPosition(textpositions, i, j, 'original').oldpartsSelected === true || (firstUpdateSentence(textpositions, i, j, 'original') && getCorrespondingPosition(textpositions, i, j, 'original').categorizationSelected))" style="color:white;padding-right:2px;" (click)="close(getCorrespondingPosition(textpositions, i, j, 'original'))" type="button" class="close" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
            </div>
            <br>
            <br>
            <div *ngIf="textpositions && (getCorrespondingPosition(textpositions, i, j, 'original').warningSelectAtLeastOne === true)" style="padding-left:60px;border:4px white solid;margin:10px;">
              <img src="assets/warning.png" style="float:left;" width="30" height="30">
              <p style="display:inline;margin-left:10px;">Je moet op zijn minst 1 tekstblok aanduiden om te kunnen categoriseren!<p>
            </div>
            <div class="oldtext-startmessage" *ngIf="textpositions && isPositionSelected(getCorrespondingPosition(textpositions, i, j, 'original')) && (getCorrespondingPosition(textpositions, i, j, 'original').otherPositionsSelected === false)">
              <p>Klik op 'Start' om de beoordeling van onderstaand tekstfragment te starten.<p>
              <button class="btn btn-primary" (click)="positionSelected(text_sentence, textpositions, 'original')">Start</button>
            </div>
            <div *ngIf="textpositions && getCorrespondingPosition(textpositions, i, j, 'original').oldpartsSelected === true">
              <p>Welke van de donkerrode tekstblokken behoren tot eenzelfde aanpassing? Klik ze aan! <br>Klik vervolgens op 'Volgende'. <br><br>Foutje gemaakt? Klik op 'Ongedaan maken' om terug te gaan.<p>
              <button class="btn btn-primary" (click)="partsSelected(text_sentence, textpositions, 'original')">Volgende</button>
            </div>
            <form #f="ngForm" class="row justify-content-center" *ngIf="textpositions && firstUpdateSentence(textpositions, i, j, 'original') && getCorrespondingPosition(textpositions, i, j, 'original').categorizationSelected" (ngSubmit)="submit(getCorrespondingPosition(textpositions, i, j, 'original'))">
              <p class="center-align"><b>Hoe zou je deze aanpassing categoriseren (meerdere opties mogelijk)?</b></p>
              <br>
              <div *ngIf="getCorrespondingPosition(textpositions, i, j, 'original').warningAnders || getCorrespondingPosition(textpositions, i, j, 'original').warning || getCorrespondingPosition(textpositions, i, j, 'original').failed" style="padding-left:60px;border:4px white solid;margin:10px;">
                <img src="assets/warning.png" style="float:left;" width="30" height="30">
                <p class="center-align" type="warningAnders" *ngIf="getCorrespondingPosition(textpositions, i, j, 'original').warningAnders"> Vul tekstvak "Andere" in!</p>
              <p class="center-align" type="warning" *ngIf="getCorrespondingPosition(textpositions, i, j, 'original').warning">Minstens 1 optie moet worden aangeduid/ingevuld!</p>
              <p class="center-align" type="failed" *ngIf="getCorrespondingPosition(textpositions, i, j, 'original').failed"> Mislukt. Probeer opnieuw aub!</p>
              </div>
                <div class="form-row w-100">
                <div class="col-sm-6">
                  <input class="checkbox-inline" type="checkbox" id="update1-oldtext" [(ngModel)]="getLastCategorizationOption(textpositions, i, j, 'original').subjectief" name="update1-oldtext" value="subjectief">&nbsp;
                  <label for="update1-oldtext"> Correctie van subjectieve fout</label>
                  <div>
                    <img src="assets/questionmark.png" width="15" height="15">
                    <span class="tooltiptext">Aanpassing waarbij een subjectieve fout wordt gecorrigeerd. Hieronder vallen volgende types fouten: niet-representatieve (tussen)titels, gesensationaliseerde informatie, te ingetogen informatie, afwezigheid van essentiële informatie, misleidende cijfers, of uit de context getrokken citaten.</span>
                  </div>
                  <br>
                </div>
                <div class="col-sm-6">
                  <input class="checkbox-inline" type="checkbox" id="update2-oldtext" [(ngModel)]="getLastCategorizationOption(textpositions, i, j, 'original').objectief" name="update2-oldtext" value="objectief">&nbsp;
                  <label for="update2-oldtext"> Correctie van objectieve fout</label>
                  <div>
                    <img src="assets/questionmark.png" width="15" height="15">
                    <span class="tooltiptext">Aanpassing waarbij een objectieve fout wordt gecorrigeerd. Hieronder vallen volgende types fouten: foutieve namen, leeftijden, adressen, aantallen, tijdstippen, data, locaties of foutieve citaties.</span>
                  </div>
                  <br>
                </div>
              </div>
              <div class="form-row w-100">
                <div class="col-sm-6">
                  <input class="checkbox-inline" type="checkbox" id="update5-oldtext" [(ngModel)]="getLastCategorizationOption(textpositions, i, j, 'original').toevoeging" name="update5-oldtext" value="toevoeging">&nbsp;
                  <label for="update5-oldtext" style="font-size:9.5pt;"> Toevoeging/verduidelijking van informatie</label>
                  <div>
                    <img src="assets/questionmark.png" width="15" height="15">
                    <span class="tooltiptext">Aanpassing waarbij informatie wordt toegevoegd aan het artikel. Hieronder valt zowel de toevoeging van informatie die nog niet gekend was bij de publicatie van de originele versie, de toevoeging van reeds gekende informatie die niet essentieel was voor een correcte weergave van de inhoud van het artikel, én de toevoeging van informatie ter verduidelijking van het oorspronkelijke artikel. Ook herschrijven van titels en/of intro's, waarbij de nieuwe informatie prominent zichtbaar wordt gemaakt, valt hieronder.</span>
                  </div><br>
                </div>
                <div class="col-sm-6">
                  <input class="checkbox-inline" type="checkbox" id="update6-oldtext" [(ngModel)]="getLastCategorizationOption(textpositions, i, j, 'original').verwijdering" name="update6-oldtext" value="verwijdering">&nbsp;
                  <label for="update6-oldtext"> Verwijdering van informatie</label>
                  <div>
                    <img src="assets/questionmark.png" width="15" height="15">
                    <span class="tooltiptext">Aanpassing waarbij informatie, die op het moment van de originele publicatie niet fout was, verwijderd wordt omdat ze verouderd is, of om eender welke andere reden.</span>
                  </div>
                </div>
              </div>
              <div class="form-row w-100">
                <div class="col-sm-6">
                  <input class="checkbox-inline" type="checkbox" id="update4-oldtext" [(ngModel)]="getLastCategorizationOption(textpositions, i, j, 'original').herformulering" name="update4-oldtext" value="herformulering">&nbsp;
                  <label for="update4-oldtext"> Inhoudsneutrale aanpassing</label>
                  <div>
                    <img src="assets/questionmark.png" width="15" height="15">
                    <span class="tooltiptext">Aanpassing waarbij woorden/zinnen worden toegevoegd, verwijderd of herschreven, zonder dat hierbij nieuwe informatie wordt toegevoegd, oude informatie wordt verwijderd, fouten worden gecorrigeerd of een andere toon wordt gelegd.</span>
                  </div><br>
                </div>
                <div class="col-sm-6">
                  <input class="checkbox-inline" type="checkbox" id="update9-oldtext" [(ngModel)]="getLastCategorizationOption(textpositions, i, j, 'original').update" name="update9-oldtext" value="update">&nbsp;
                  <label for="update9-oldtext"> Update van oude informatie</label>
                  <div>
                    <img src="assets/questionmark.png" width="15" height="15">
                    <span class="tooltiptext">Aanpassing waarbij de oude informatie wordt vervangen door nieuwe, geüpdate informatie. Dit impliceert zowel verwijdering van oude informatie als toevoeging van nieuwe informatie.</span>
                  </div><br>
                </div>
              </div>
              <div class="form-row w-100">
                <div class="col-sm-6">
                  <input class="checkbox-inline" type="checkbox" id="update10-oldtext" [(ngModel)]="getLastCategorizationOption(textpositions, i, j, 'original').verplaatsing" name="update10-oldtext" value="verplaatsing">&nbsp;
                  <label for="update10-oldtext"> Verplaatsing van identieke informatie</label>
                  <div>
                    <img src="assets/questionmark.png" width="15" height="15">
                    <span class="tooltiptext">Aanpassing waarbij tekst exact gekopieerd wordt naar een andere positie in het artikel.</span>
                  </div><br>
                </div>
                <div class="col-sm-6">
                  <input class="checkbox-inline" type="checkbox" id="update7-oldtext" [(ngModel)]="getLastCategorizationOption(textpositions, i, j, 'original').spelfout" name="update7-oldtext" value="spelfout">&nbsp;
                  <label for="update7-oldtext">Correctie van spel- of taalfout </label>
                  <div>
                    <img src="assets/questionmark.png" style="margin-right:1em" width="15" height="15">
                    <span class="tooltiptext">Aanpassing waarbij een spelfout of grammaticale fout (bv. foutieve zinsconstructie) wordt gecorrigeerd. Onder spelfouten vallen alle fouten waarbij een woord/naam anders wordt geschreven, maar fonetisch wel nog hetzelfde klinkt. </span>
                  </div>
                </div>
              </div>
              <div class="form-row w-100">
                <div class="col-sm-6">
                  <input class="checkbox-inline" type="checkbox" id="update11-oldtext" [(ngModel)]="getLastCategorizationOption(textpositions, i, j, 'original').anders" name="update11-oldtext" value="anders">&nbsp;
                  <label for="update11-oldtext" style="font-size:10.5pt;">Andere </label>
                  <div>
                    <img src="assets/questionmark.png" style="margin-right:1em" width="15" height="15">
                    <span class="tooltiptext">Elk type aanpassing dat niet in één van de andere categorieën past.</span>
                  </div>
                  <input type="text" [disabled]="!getLastCategorizationOption(textpositions, i, j, 'original').anders" id="update12-oldtext" [(ngModel)]="getLastCategorizationOption(textpositions, i, j, 'original').text" name="update12-oldtext" value="text">
                </div>
              </div>
              <br>
              <br>
              <div class="text-center">
              <label for="explanation-oldtext"> Opmerkingen: </label>
              <textarea name="explanation-oldtext" class="form-control" id="explanation-oldtext" [(ngModel)]="getLastCategorizationOption(textpositions, i, j, 'original').remarks"></textarea>
              </div>
              <div class="col-12 text-center">
                <br>
                  <input class="btn btn-primary" type="submit" value="Verzend">
              </div>
              <br>
            </form>
          </div>
          <br>
          <ng-container *ngFor="let text_char of text_sentence.split(''); let k = index;">
          <span [class.removedSentence]="textpositions && sentenceInUpdate(textpositions, i, j, 'original') && contentInSentence(text_sentence)" [class.removedChar]="textpositions && charInUpdate(textpositions, i, j, k, 'original') && contentInSentence(text_sentence)" (click)="clickCharacter(textpositions, i, j, k, 'original')">{{text_char}}</span>
      </ng-container>
          <span [class.removedSentence]="textpositions && sentenceInUpdate(textpositions, i, j, 'original') && contentInSentence(text_sentence)" [class.removedChar]="textpositions && charInUpdate(textpositions, i, j, text_sentence.length - 1, 'original') && contentInSentence(text_sentence)" (click)="clickCharacter(textpositions, i, j, text_sentence.length - 1, 'original')">&nbsp;</span>
      </ng-container>
        <br>
        <br>
      </ng-container>
      </td>
      <td id="newarticle-text">
      <ng-container *ngFor="let text_part of newArticle.textParts; let i = index;">
        <ng-container *ngFor="let text_sentence of text_part; let j = index;">
          <div class="newtext" *ngIf="textpositions && firstUpdateSentence(textpositions, i, j, 'new') && startmessageSelected(text_sentence, textpositions, 'new')">
            <div style="display:inline;">
            <div style="display:inline;" *ngIf="(textpositions && getCorrespondingPosition(textpositions, i, j, 'new').newpartsSelected === true)  || (textpositions && firstUpdateSentence(textpositions, i, j, 'new') && getCorrespondingPosition(textpositions, i, j, 'new').categorizationSelected)">
            <button class="btn btn-primary" style="margin-left:10px;margin-top:10px;margin-right: 10px;font-size: 12px;" (click)="previous(text_sentence, textpositions, 'new')"  *ngIf="((getCorrespondingPosition(textpositions, i, j, 'new').categorizations.originalChangedParts.length > 0) || (getCorrespondingPosition(textpositions, i, j, 'new').categorizations.newChangedParts.length > 0) || (getCorrespondingPosition(textpositions, i, j, 'new').newpartsSelected===true  && originalPartsExist(text_sentence, textpositions))===true)===false && getCorrespondingPosition(textpositions, i, j, 'new').categorizationSelected===false" disabled>Ongedaan maken</button>
            <button class="btn btn-primary" style="margin-left:10px;margin-top:10px;margin-right: 10px;font-size: 12px;" (click)="previous(text_sentence, textpositions, 'new')"  *ngIf="(((getCorrespondingPosition(textpositions, i, j, 'new').categorizations.originalChangedParts.length > 0) || (getCorrespondingPosition(textpositions, i, j, 'new').categorizations.newChangedParts.length > 0) || (getCorrespondingPosition(textpositions, i, j, 'new').newpartsSelected===true)  && originalPartsExist(text_sentence, textpositions))===true) && getCorrespondingPosition(textpositions, i, j, 'new').categorizationSelected===false">Ongedaan maken</button>
              <button class="btn btn-primary" style="margin-left:10px;margin-top:10px;margin-right: 10px;font-size: 12px;" (click)="previousCategory(text_sentence, textpositions, 'new')"  *ngIf="textpositions && firstUpdateSentence(textpositions, i, j, 'new') && getCorrespondingPosition(textpositions, i, j, 'new').categorizationSelected">Ongedaan maken</button>
            </div>
              <button type="button" *ngIf="textpositions && (getCorrespondingPosition(textpositions, i, j, 'new').newpartsSelected === true || (firstUpdateSentence(textpositions, i, j, 'new') && getCorrespondingPosition(textpositions, i, j, 'new').categorizationSelected))" (click)="close(getCorrespondingPosition(textpositions, i, j, 'new'))" style="color:white;padding-right:2px;"  class="close" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
            </div>
            <br>
            <br>
            <div *ngIf="textpositions && (getCorrespondingPosition(textpositions, i, j, 'new').warningSelectAtLeastOne === true)" style="padding-left:60px;border:4px white solid;margin:10px;">
              <img src="assets/warning.png" style="float:left;" width="30" height="30">
              <p style="display:inline;margin-left:10px;">Je moet op zijn minst 1 tekstblok aanduiden om te kunnen categoriseren!<p>
            </div>
            <div *ngIf="textpositions && isPositionSelected(getCorrespondingPosition(textpositions, i, j, 'new')) && (getCorrespondingPosition(textpositions, i, j, 'new').otherPositionsSelected === false)">
              <p>Klik op 'Start' om de beoordeling van onderstaand tekstfragment te starten.<p>
              <button class="btn btn-primary" (click)="positionSelected(text_sentence, textpositions, 'new')">Start</button>
            </div>
            <div *ngIf="textpositions && getCorrespondingPosition(textpositions, i, j, 'new').newpartsSelected === true">
              <p>Welke van de donkergroene tekstblokken behoren tot eenzelfde aanpassing? Klik ze aan! <br>Klik vervolgens op 'Volgende'. <br><br>Foutje gemaakt? Klik op 'Ongedaan maken' om terug te gaan.<p>
              <button class="btn btn-primary" (click)="partsSelected(text_sentence, textpositions, 'new')">Volgende</button>
            </div>
            <form #f="ngForm" class="row justify-content-center" *ngIf="textpositions && firstUpdateSentence(textpositions, i, j, 'new') && getCorrespondingPosition(textpositions, i, j, 'new').categorizationSelected" (ngSubmit)="submit(getCorrespondingPosition(textpositions, i, j, 'new'))">
              <p class="center-align"><b>Hoe zou je deze aanpassing categoriseren (meerdere opties mogelijk)?</b></p>
              <br>
              <div *ngIf="getCorrespondingPosition(textpositions, i, j, 'new').warningAnders || getCorrespondingPosition(textpositions, i, j, 'new').warning || getCorrespondingPosition(textpositions, i, j, 'new').failed" style="padding-left:60px;border:4px white solid;margin:10px;">
                <img src="assets/warning.png" style="float:left;" width="30" height="30">
                <p  type="warningAnders" *ngIf="getCorrespondingPosition(textpositions, i, j, 'new').warningAnders"> Vul tekstvak "Andere" in!</p>
              <p  type="warning" *ngIf="getCorrespondingPosition(textpositions, i, j, 'new').warning">Minstens 1 optie moet worden aangeduid/ingevuld!</p>
              <p  type="failed" *ngIf="getCorrespondingPosition(textpositions, i, j, 'new').failed"> Mislukt. Probeer opnieuw aub!</p>
              </div>
                <div class="form-row w-100">
                <div class="col-sm-6">
                  <input class="checkbox-inline" type="checkbox" id="update1-newtext" [(ngModel)]="getLastCategorizationOption(textpositions, i, j, 'new').subjectief" name="update1-newtext" value="subjectief">&nbsp;
                  <label for="update1-newtext">  Correctie van subjectieve fout</label>
                  <div>
                    <img src="assets/questionmark.png" width="15" height="15">
                    <span class="tooltiptext">Aanpassing waarbij een subjectieve fout wordt gecorrigeerd. Hieronder vallen volgende types fouten: niet-representatieve (tussen)titels, gesensationaliseerde informatie, te ingetogen informatie, afwezigheid van essentiële informatie, misleidende cijfers, of uit de context getrokken citaten.</span>
                  </div>
                  <br>
                </div>
                <div class="col-sm-6">
                  <input class="checkbox-inline" type="checkbox" id="update2-newtext" [(ngModel)]="getLastCategorizationOption(textpositions, i, j, 'new').objectief" name="update2-newtext" value="objectief">&nbsp;
                  <label for="update2-newtext">  Correctie van objectieve fout</label>
                  <div>
                    <img src="assets/questionmark.png" width="15" height="15">
                    <span class="tooltiptext">Aanpassing waarbij een objectieve fout wordt gecorrigeerd. Hieronder vallen volgende types fouten: foutieve namen, leeftijden, adressen, aantallen, tijdstippen, data, locaties of foutieve citaties.</span>
                  </div>
                  <br>
                </div>
              </div>
              <div class="form-row w-100">
                <div class="col-sm-6">
                  <input class="checkbox-inline" type="checkbox" id="update5-newtext" [(ngModel)]="getLastCategorizationOption(textpositions, i, j, 'new').toevoeging" name="update5-newtext" value="toevoeging">&nbsp;
                  <label for="update5-newtext" style="font-size:9.5pt;"> Toevoeging/verduidelijking van informatie</label>
                  <div>
                    <img src="assets/questionmark.png" width="15" height="15">
                    <span class="tooltiptext">Aanpassing waarbij informatie wordt toegevoegd aan het artikel. Hieronder valt zowel de toevoeging van informatie die nog niet gekend was bij de publicatie van de originele versie, de toevoeging van reeds gekende informatie die niet essentieel was voor een correcte weergave van de inhoud van het artikel, én de toevoeging van informatie ter verduidelijking van het oorspronkelijke artikel. Ook herschrijven van titels en/of intro's, waarbij de nieuwe informatie prominent zichtbaar wordt gemaakt, valt hieronder.</span>
                  </div>
                  <br>
                </div>
                <div class="col-sm-6">
                  <input class="checkbox-inline" type="checkbox" id="update6-newtext" [(ngModel)]="getLastCategorizationOption(textpositions, i, j, 'new').verwijdering" name="update6-newtext" value="verwijdering">&nbsp;
                  <label for="update6-newtext"> Verwijdering van informatie</label>
                  <div>
                    <img src="assets/questionmark.png" width="15" height="15">
                    <span class="tooltiptext">Aanpassing waarbij informatie, die op het moment van de originele publicatie niet fout was, verwijderd wordt omdat ze verouderd is, of om eender welke andere reden.</span>
                  </div>
                </div>
              </div>
              <div class="form-row w-100">
                <div class="col-sm-6">
                  <input class="checkbox-inline" type="checkbox" id="update4-newtext" [(ngModel)]="getLastCategorizationOption(textpositions, i, j, 'new').herformulering" name="update4-newtext" value="herformulering">&nbsp;
                  <label for="update4-newtext"> Inhoudsneutrale aanpassing</label>
                  <div>
                    <img src="assets/questionmark.png" width="15" height="15">
                    <span class="tooltiptext">Aanpassing waarbij woorden/zinnen worden toegevoegd, verwijderd of herschreven, zonder dat hierbij nieuwe informatie wordt toegevoegd, oude informatie wordt verwijderd, fouten worden gecorrigeerd of een andere toon wordt gelegd.</span>
                  </div>
                  <br>
                </div>
                <div class="col-sm-6">
                  <input class="checkbox-inline" type="checkbox" id="update9-newtext" [(ngModel)]="getLastCategorizationOption(textpositions, i, j, 'new').update" name="update9-newtext" value="update">&nbsp;
                  <label for="update9-newtext"> Update van oude informatie</label>
                  <div>
                    <img src="assets/questionmark.png" width="15" height="15">
                    <span class="tooltiptext">Aanpassing waarbij de oude informatie wordt vervangen door nieuwe, geüpdate informatie. Dit impliceert zowel verwijdering van oude informatie als toevoeging van nieuwe informatie.</span>
                  </div>
                  <br>
                </div>
              </div>
              <div class="form-row w-100">
                <div class="col-sm-6">
                  <input class="checkbox-inline" type="checkbox" id="update10-newtext" [(ngModel)]="getLastCategorizationOption(textpositions, i, j, 'new').verplaatsing" name="update10-newtext" value="verplaatsing">&nbsp;
                  <label for="update10-newtext"> Verplaatsing van identieke informatie</label>
                  <div>
                    <img src="assets/questionmark.png" width="15" height="15">
                    <span class="tooltiptext">Aanpassing waarbij tekst exact gekopieerd wordt naar een andere positie in het artikel.</span>
                  </div><br>
                </div>
                <div class="col-sm-6">
                  <input class="checkbox-inline" type="checkbox" id="update7-newtext" [(ngModel)]="getLastCategorizationOption(textpositions, i, j, 'new').spelfout" name="update7-newtext" value="spelfout">&nbsp;
                  <label for="update7-newtext">Correctie van spel- of taalfout </label>
                  <div>
                    <img src="assets/questionmark.png" style="margin-right:1em" width="15" height="15">
                    <span class="tooltiptext">Aanpassing waarbij een spelfout of grammaticale fout (bv. foutieve zinsconstructie) wordt gecorrigeerd. Onder spelfouten vallen alle fouten waarbij een woord/naam anders wordt geschreven, maar fonetisch wel nog hetzelfde klinkt. </span>
                  </div>
                  </div>
              </div>
              <div class="form-row w-100">
                <div class="col-sm-6">
                  <input class="checkbox-inline" type="checkbox" id="update11-newtext" [(ngModel)]="getLastCategorizationOption(textpositions, i, j, 'new').anders" name="update11-newtext" value="anders">&nbsp;
                  <label for="update11-newtext" style="font-size:10.5pt;">Andere </label>
                  <div>
                    <img src="assets/questionmark.png" style="margin-right:1em" width="15" height="15">
                    <span class="tooltiptext">Elk type aanpassing dat niet in één van de andere categorieën past.</span>
                  </div>
                  <input type="text" [disabled]="!getLastCategorizationOption(textpositions, i, j, 'new').anders" id="update12-newtext" [(ngModel)]="getLastCategorizationOption(textpositions, i, j, 'new').text" name="update12-newtext" value="text">
                </div>
              </div>
              <br>
              <br>
              <div class="text-center">
                <label for="explanation-newtext"> Opmerkingen: </label>
                <textarea name="explanation-newtext" class="form-control" id="explanation-newtext" [(ngModel)]="getLastCategorizationOption(textpositions, i, j, 'new').remarks"></textarea>
              </div>
              <div class="col-12 text-center">
                <br>
                <input class="btn btn-primary" type="submit" value="Verzend">
              </div>
              <br>
            </form>
            </div>
          <br>
          <ng-container *ngFor="let text_char of text_sentence.split(''); let k = index;">
          <span [class.addedSentence]="textpositions && sentenceInUpdate(textpositions, i, j, 'new') && contentInSentence(text_sentence)" [class.addedChar]="textpositions && charInUpdate(textpositions, i, j, k, 'new') && contentInSentence(text_sentence)" (click)="clickCharacter(textpositions, i, j, k, 'new')">{{text_char}}</span>
      </ng-container>
          <span [class.addedSentence]="textpositions && sentenceInUpdate(textpositions, i, j, 'new') && contentInSentence(text_sentence)" [class.addedChar]="textpositions && charInUpdate(textpositions, i, j, text_sentence.length - 1, 'new') && contentInSentence(text_sentence)" (click)="clickCharacter(textpositions, i, j, text_sentence.length - 1, 'new')">&nbsp;</span>
      </ng-container>
        <br>
        <br>
      </ng-container>
      </td>
    </tr>
  </tbody>
</table>
<div *ngIf="(email === 'yoram1291@hotmail.com' || email === 'antoon.bronselaer@ugent.be')">
<p class="center-align"><b>Welke van onderstaande aanpassingen werd correct geannoteerd?</b></p>
<form #f="ngForm" class="row justify-content-center" (ngSubmit)="submitVerifications()">
  <br/>
  <div *ngFor="let mistake of mistakes; let i = index;" class="form-row w-100">
    <div class="col-sm-6">
      <input class="checkbox-inline" type="checkbox" [id]="i" [(ngModel)]="getCorrespondingVerification(mistake.id).trueMistake" name="mistake">&nbsp;
      <label [class.selectedMistake]="mistake.clicked === true" [for]="i"> Aanpassing {{i}}: {{mistake.type}}</label>
      <div>
        <p [class.selectedMistake]="mistake.clicked === true" style="cursor:pointer;" (click)="clickMistake(mistake)">CHECK FOUT</p>
      </div>
      <div *ngIf="getCorrespondingVerification(mistake.id).trueMistake">
      <div *ngIf="mistake.type==='Correctie van subjectieve fout'">
        <label [for]="'mistake' + i" [class.selectedMistake]="mistake.clicked === true"> Subtype van fout? </label>&nbsp;
        <select [name]="'mistake' + i" [id]="'mistake' + i" [(ngModel)]="getCorrespondingVerification(mistake.id).subtype">
          <option value="Niet-representatieve (tussen)titel">Niet-representatieve (tussen)titel</option>
          <option value="Informatie gesensationaliseerd">Informatie gesensationaliseerd</option>
          <option value="Informatie te ingetogen geformuleerd">Informatie te ingetogen geformuleerd</option>
          <option value="Essentiële informatie afwezig">Essentiële informatie afwezig</option>
          <option value="Misleidende cijfers">Misleidende cijfers</option>
          <option value="Uit de context getrokken citaat">Uit de context getrokken citaat</option>
          <option value="Andere">Andere</option>
        </select>
      </div>
        <div *ngIf="mistake.type==='Correctie van objectieve fout'">
          <label [for]="'mistake-obj' + i" [class.selectedMistake]="mistake.clicked === true"> Subtype van fout? </label>&nbsp;
          <select [name]="'mistake-obj' + i" [id]="'mistake-obj' + i" [(ngModel)]="getCorrespondingVerification(mistake.id).subtype">
            <option value="Foutieve naam">Foutieve naam</option>
            <option value="Foutieve functietitel">Foutieve functietitel</option>
            <option value="Foutieve leeftijd">Foutieve leeftijd</option>
            <option value="Foutief adres">Foutief adres</option>
            <option value="Foutief geciteerd">Foutief geciteerd</option>
            <option value="Foutief aantal">Foutief aantal</option>
            <option value="Foutief tijdstip">Foutief tijdstip</option>
            <option value="Foutieve datum">Foutieve datum</option>
            <option value="Foutieve locatie">Foutieve locatie</option>
            <option value="Andere">Andere</option>
          </select>
        </div>
      </div>
      <div *ngIf="getCorrespondingVerification(mistake.id).trueMistake===false">
        <label [for]="'mistake-type' + i" [class.selectedMistake]="mistake.clicked === true"> Welke aanpassing is het wel? </label>&nbsp;
        <select [name]="'mistake-type' + i" [id]="'mistake-type' + i" [(ngModel)]="getCorrespondingVerification(mistake.id).otherType">
          <option value="Correctie van objectieve fout">Correctie van objectieve fout</option>
          <option value="Correctie van subjectieve fout">Correctie van subjectieve fout</option>
          <option value="Toevoeging/verduidelijking van informatie">Toevoeging/verduidelijking van informatie</option>
          <option value="Verwijdering van informatie">Verwijdering van informatie</option>
          <option value="Inhoudsneutrale herformulering">Inhoudsneutrale herformulering</option>
          <option value="Update van oude informatie">Update van oude informatie</option>
          <option value="Verplaatsing van identieke informatie">Verplaatsing van identieke informatie</option>
          <option value="Correctie van spel- of taalfout">Correctie van spel- of taalfout</option>
          <option value="Andere">Andere</option>
        </select>
      </div>
      <br>
      <div class="text-center">
        <label [id]="'mistake-type' + i" [class.selectedMistake]="mistake.clicked === true"> Opmerkingen: </label>
        <textarea [name]="'mistake-type' + i" class="form-control" [id]="'mistake-opm' + i" [(ngModel)]="getCorrespondingVerification(mistake.id).opmerkingen"></textarea>
      </div>
    </div>
  </div>
  <br>
  <div>
    <br>
    <input class="btn btn-primary" type="submit" value="Verzend">
  </div>
</form>
</div>
<table class="table" width="1500" *ngIf="oldArticle && newArticle && authService.currentUserValue && ((email === 'yoram1291@hotmail.com') || (email === 'antoon.bronselaer@ugent.be')) && allArticlesCompleted === false">
  <thead>
  <tr>
    <th id="oldarticle-title2" >
      <span class="versionNumber">Versie {{oldArticle.versionNumber}}</span>
      <ng-container *ngFor="let title_part of oldArticle.titleParts; let i = index;">
        <ng-container *ngFor="let title_sentence of title_part; let j = index;">
          <br>
          <span class="sentence">
            <ng-container *ngFor="let title_char of title_sentence.split(''); let k = index;">
            <span style="text-align:center;" [class.removedSentence]="sentenceInVerification('title', i, j, 'original') && contentInSentence(title_sentence)" [class.removedChar]="charInVerification('title', i, j, k, 'original') && contentInSentence(title_sentence)">{{title_char}}</span>
          </ng-container>
            <span style="text-align:center;" [class.removedSentence]="sentenceInVerification('title', i, j,'original') && contentInSentence(title_sentence)" [class.removedChar]="charInVerification('title', i, j, title_sentence.length - 1, 'original') && contentInSentence(title_sentence)">&nbsp;</span>
            </span>
        </ng-container>
      </ng-container>
      <span class="onlineFrom" style="text-align:center;">Geraadpleegd op {{getPublicationTime(oldArticle.onlineFrom)}} <br> <br></span>
    </th>
    <th id="newarticle-title2">
      <span class="versionNumber">Versie {{newArticle.versionNumber}}</span>
      <ng-container *ngFor="let title_part of newArticle.titleParts; let i = index;">
        <ng-container *ngFor="let title_sentence of title_part; let j = index;">
          <br>
          <span class="sentence">
            <ng-container *ngFor="let title_char of title_sentence.split(''); let k = index;">
            <span style="text-align:center;" [class.addedSentence]="sentenceInVerification('title', i, j, 'new') && contentInSentence(title_sentence)" [class.addedChar]="charInVerification('title', i, j, k, 'new') && contentInSentence(title_sentence)">{{title_char}}</span>
          </ng-container>
            <span style="text-align:center;" [class.addedSentence]="sentenceInVerification('title', i, j, 'new') && contentInSentence(title_sentence)" [class.addedChar]="charInVerification('title', i, j, title_sentence.length - 1, 'new') && contentInSentence(title_sentence)">&nbsp;</span>
            </span>
        </ng-container>
      </ng-container>
      <span class="onlineFrom" style="text-align:center;">Gepubliceerd op {{getPublicationTime(newArticle.onlineFrom)}} <br> <br></span>
    </th>
  </tr>
  </thead>
  <tbody>
  <tr>
    <td id="oldarticle-intro2">
      <ng-container *ngFor="let intro_part of oldArticle.introParts; let i = index;">
        <ng-container *ngFor="let intro_sentence of intro_part; let j = index;">
          <br>
          <ng-container *ngFor="let intro_char of intro_sentence.split(''); let k = index;">
            <span [class.removedSentence]="sentenceInVerification('intro', i, j, 'original') && contentInSentence(intro_sentence)" [class.removedChar]="charInVerification('intro', i, j, k, 'original') && contentInSentence(intro_sentence)"><b>{{intro_char}}</b></span>
          </ng-container>
          <span [class.removedSentence]="sentenceInVerification('intro', i, j, 'original') && contentInSentence(intro_sentence)" [class.removedChar]="charInVerification('intro', i, j, intro_sentence.length - 1, 'original') && contentInSentence(intro_sentence)">&nbsp;</span>
        </ng-container>
        <br>
        <br>
      </ng-container>
    </td>
    <td id="newarticle-intro2">
      <ng-container *ngFor="let intro_part of newArticle.introParts; let i = index;">
        <ng-container *ngFor="let intro_sentence of intro_part; let j = index;">
          <br>
          <ng-container *ngFor="let intro_char of intro_sentence.split(''); let k = index;">
            <span [class.addedSentence]="sentenceInVerification('intro', i, j, 'new') && contentInSentence(intro_sentence)" [class.addedChar]="charInVerification('intro', i, j, k, 'new') && contentInSentence(intro_sentence)" (click)="clickCharacter(intropositions, i, j, k, 'new')"><b>{{intro_char}}</b></span>
          </ng-container>
          <span [class.addedSentence]="sentenceInVerification('intro', i, j, 'new') && contentInSentence(intro_sentence)" [class.addedChar]="charInVerification('intro', i, j, intro_sentence.length - 1, 'new') && contentInSentence(intro_sentence)">&nbsp;</span>
        </ng-container>
        <br>
        <br>
      </ng-container>
    </td>
  </tr>

  <tr>
    <td id="oldarticle-text2">
      <ng-container *ngFor="let text_part of oldArticle.textParts; let i = index;">
        <ng-container *ngFor="let text_sentence of text_part; let j = index;">
          <br>
          <ng-container *ngFor="let text_char of text_sentence.split(''); let k = index;">
            <span [class.removedSentence]="sentenceInVerification('text', i, j, 'original') && contentInSentence(text_sentence)" [class.removedChar]="charInVerification('text', i, j, k, 'original') && contentInSentence(text_sentence)">{{text_char}}</span>
          </ng-container>
          <span [class.removedSentence]="sentenceInVerification('text', i, j, 'original') && contentInSentence(text_sentence)" [class.removedChar]="charInVerification('text', i, j, text_sentence.length - 1, 'original') && contentInSentence(text_sentence)">&nbsp;</span>
        </ng-container>
        <br>
        <br>
      </ng-container>
    </td>
    <td id="newarticle-text2">
      <ng-container *ngFor="let text_part of newArticle.textParts; let i = index;">
        <ng-container *ngFor="let text_sentence of text_part; let j = index;">
          <br>
          <ng-container *ngFor="let text_char of text_sentence.split(''); let k = index;">
            <span [class.addedSentence]="sentenceInVerification('text', i, j, 'new') && contentInSentence(text_sentence)" [class.addedChar]="charInVerification('text', i, j, k, 'new') && contentInSentence(text_sentence)">{{text_char}}</span>
          </ng-container>
          <span [class.addedSentence]="sentenceInVerification('text', i, j, 'new') && contentInSentence(text_sentence)" [class.addedChar]="charInVerification('text', i, j, text_sentence.length - 1, 'new') && contentInSentence(text_sentence)">&nbsp;</span>
        </ng-container>
        <br>
        <br>
      </ng-container>
    </td>
  </tr>
  </tbody>
</table>


</body>
</html>
