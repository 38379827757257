import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BehaviorSubject} from 'rxjs';
import {Router} from '@angular/router';
import {AuthService} from '../auth.service';
import {BooleanService} from '../boolean.service';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-recover',
  templateUrl: './recover.component.html',
  styleUrls: ['./recover.component.css']
})
export class RecoverComponent implements OnInit {

  recoverForm: FormGroup;
  public loadingRecover: BehaviorSubject<boolean>;
  public emptyEmailRecover: BehaviorSubject<boolean>;
  public failedExistingRecover: BehaviorSubject<boolean>;
  public failedInternalRecover: BehaviorSubject<boolean>;
  public failedMalformedRecover: BehaviorSubject<boolean>;
  public recovertokenCreated: BehaviorSubject<boolean>;

  constructor(private formBuilder: FormBuilder,
              private router: Router,
              private authenticationService: AuthService,
              public booleanService: BooleanService,
              private titleService: Title) {
    booleanService.resetSigninBooleans();
    booleanService.resetRecoverConfirmBooleans();
    booleanService.resetLoginBooleans();
    localStorage.setItem('loadingRecover', JSON.stringify(false));
    localStorage.setItem('emptyEmailRecover', JSON.stringify(false));
    if (!localStorage.getItem('failedExistingRecover')) {
      localStorage.setItem('failedExistingRecover', JSON.stringify(false));
    }
    if (!localStorage.getItem('failedMalformedRecover')) {
      localStorage.setItem('failedMalformedRecover', JSON.stringify(false));
    }
    if (!localStorage.getItem('failedInternalRecover')) {
      localStorage.setItem('failedInternalRecover', JSON.stringify(false));
    }
    if (!localStorage.getItem('recovertokenCreated')) {
      localStorage.setItem('recovertokenCreated', JSON.stringify(false));
    }
    this.loadingRecover = new BehaviorSubject<boolean>(JSON.parse(localStorage.getItem('loadingRecover')));
    this.emptyEmailRecover = new BehaviorSubject<boolean>(JSON.parse(localStorage.getItem('emptyEmailRecover')));
    this.failedExistingRecover = new BehaviorSubject<boolean>(JSON.parse(localStorage.getItem('failedExistingRecover')));
    this.failedInternalRecover = new BehaviorSubject<boolean>(JSON.parse(localStorage.getItem('failedInternalRecover')));
    this.failedMalformedRecover = new BehaviorSubject<boolean>(JSON.parse(localStorage.getItem('failedMalformedRecover')));
    this.recovertokenCreated = new BehaviorSubject<boolean>(JSON.parse(localStorage.getItem('recovertokenCreated')));
  }

  ngOnInit(): void {
    this.setTitle('UGent Newstracker - Paswoordherstel');
    this.recoverForm = this.formBuilder.group({
      emailRecover: ['', Validators.required]
    });
  }

  get recoverf() {
    return this.recoverForm.controls;
  }

  onRecoverSubmit() {
    if (this.recoverForm.invalid) {
      localStorage.setItem('emptyEmailRecover', JSON.stringify(true));
      this.emptyEmailRecover.next(true);
      localStorage.setItem('failedExistingRecover', JSON.stringify(false));
      this.failedExistingRecover.next(false);
      localStorage.setItem('failedInternalRecover', JSON.stringify(false));
      this.failedInternalRecover.next(false);
      localStorage.setItem('failedMalformedRecover', JSON.stringify(false));
      this.failedMalformedRecover.next(false);
      return;
    }
    localStorage.setItem('loadingRecover', JSON.stringify(true));
    this.loadingRecover.next(true);
    if (this.recoverf.emailRecover.value) {
      this.authenticationService.recover(this.recoverf.emailRecover.value)
        .subscribe(
          data => {
            localStorage.setItem('failedExistingRecover', JSON.stringify(false));
            this.failedExistingRecover.next(false);
            localStorage.setItem('failedInternalRecover', JSON.stringify(false));
            this.failedInternalRecover.next(false);
            localStorage.setItem('loadingRecover', JSON.stringify(false));
            localStorage.setItem('failedMalformedRecover', JSON.stringify(false));
            this.failedMalformedRecover.next(false);
            this.loadingRecover.next(false);
            localStorage.setItem('recovertokenCreated', JSON.stringify(true));
            this.recovertokenCreated.next(true);
            this.recoverForm.reset();
          },
          error => {
            localStorage.setItem('loadingRecover', JSON.stringify(false));
            this.loadingRecover.next(false);
            localStorage.setItem('emptyEmailRecover', JSON.stringify(false));
            this.emptyEmailRecover.next(false);
            if (error.error.message === 'No existing account for this emailadress.') {
              localStorage.setItem('failedExistingRecover', JSON.stringify(true));
              this.failedExistingRecover.next(true);
              localStorage.setItem('failedInternalRecover', JSON.stringify(false));
              this.failedInternalRecover.next(false);
              localStorage.setItem('failedMalformedRecover', JSON.stringify(false));
              this.failedMalformedRecover.next(false);
            }
            if (error.error.message === 'Recover could not be completed due to an internal server error.') {
              localStorage.setItem('failedExistingRecover', JSON.stringify(false));
              this.failedExistingRecover.next(false);
              localStorage.setItem('failedInternalRecover', JSON.stringify(true));
              this.failedInternalRecover.next(true);
              localStorage.setItem('failedMalformedRecover', JSON.stringify(false));
              this.failedMalformedRecover.next(false);
            }
            if (error.error.message === 'Malformed emailadress.') {
              localStorage.setItem('failedExistingRecover', JSON.stringify(false));
              this.failedExistingRecover.next(false);
              localStorage.setItem('failedInternalRecover', JSON.stringify(false));
              this.failedInternalRecover.next(false);
              localStorage.setItem('failedMalformedRecover', JSON.stringify(true));
              this.failedMalformedRecover.next(true);
            }
            localStorage.setItem('recovertokenCreated', JSON.stringify(false));
            this.recovertokenCreated.next(false);
            this.recoverForm.reset();
          });
    }
  }
  public get currentLoadingRecoverValue(): boolean {
    return this.loadingRecover.value;
  }
  public get currentEmptyEmailRecoverValue(): boolean {
    return this.emptyEmailRecover.value;
  }
  public get currentFailedExistingRecoverValue(): boolean {
    return this.failedExistingRecover.value;
  }
  public get currentFailedInternalRecoverValue(): boolean {
    return this.failedInternalRecover.value;
  }
  public get currentFailedMalformedRecoverValue(): boolean {
    return this.failedMalformedRecover.value;
  }
  public get currentRecovertokenCreatedValue(): boolean {
    return this.recovertokenCreated.value;
  }

  public setTitle( newTitle: string) {
    this.titleService.setTitle( newTitle );
  }
}
