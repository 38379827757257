<!doctype html>
<html lang="en">
<head>
  <title>UGent Newstracker - Hoe werkt het?</title>
</head>
<body>
<nav class="navbar navbar-expand-lg fixed-top navbar-dark bg-company-grey">
  <a class="navbar-brand" href="#"></a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <a class="navbar-brand" target="_blank" href="https://www.ugent.be/" style="padding:0px;">
    <img src="assets/ugentlogo.png" width="163" height="130">
  </a>
  <a class="navbar-brand" target="_blank" href="https://ddcmsite.ugent.be/" style="padding:0px;">
    <img src="assets/ddcmlogo.png" width="272" height="60">
  </a>
  <div class="collapse navbar-collapse text-right" id="navbarright">
    <div class="abs-center-x" *ngIf="allArticlesCompleted===false && authService.currentUserValue && this.hasLoggedIn===true && this.hasFeedbackCompleted===true" style="display:inline-block" name="ticket">
      <div *ngIf="getNrTickets() < 10" style="display:inline; color:white">
        <ng-container *ngFor="let number of getRange(getNrTickets());">
          <img [src]="getFullTicketWhite()" width="40" height="40">
        </ng-container>
      </div>
      <div *ngIf="getNrTickets() >= 10" style="display:inline; color:white">
        ...
        <ng-container *ngFor="let number of getRange(10);">
          <img [src]="getFullTicketWhite()" width="40" height="40">
        </ng-container>
        ...
      </div>
      <br>
      <img [src]="getTicket()" />
      <p class="completed">Nog {{getRemaining()}} artikels te gaan tot lotje {{getNrTickets() + 1}}!</p>
    </div>
    <ul class="navbar-nav ml-auto flex-nowrap">
      <li class="nav-item" *ngIf="!authService.currentUserValue">
        <a class="nav-link" href="/login" (click)="booleanService.resetLoginBooleans()">Home</a>
      </li>
      <li class="nav-item" *ngIf="authService.currentUserValue && this.hasLoggedIn===true">
        <a class="nav-link" href="/articles">Nieuw artikelpaar</a>
      </li>
      <li class="nav-item" *ngIf="authService.currentUserValue">
        <a class="nav-link" href="/profile" target="_blank">{{this.firstname}} {{this.lastname}}</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="/project" target="_blank">Over dit project</a>
      </li>
      <li class="nav-item">
        <a class="nav-link active" href="/intro">Hoe werkt het?<span class="sr-only">(current)</span></a>
      </li>
      <li class="nav-item" *ngIf="!authService.currentUserValue">
        <a class="nav-link" href="/signin" (click)="booleanService.resetSigninBooleans()">Maak een account!</a>
      </li>
      <li class="nav-item" *ngIf="authService.currentUserValue">
        <a class="nav-link" href="#" (click)="onLogoutClick()">Uitloggen</a>
      </li>
    </ul>
  </div>
</nav>
<br>
<div class="jumbotron jumbotron-fluid" *ngIf="hasLoggedIn===false">
<h2 align="center">Welkom!</h2>
<p class="abs-center-x-5">Via dit online platform willen we je hulp inroepen om aanpassingen die online nieuwsredacties in het verleden aan hun artikels maakten, te categoriseren.
  Op die manier hopen we een beter zicht te krijgen op de wijze waarop de mogelijkheid tot het aanpassen van online artikels in de praktijk gebruikt wordt. <b>Meer info over ons onderzoek kan je <a target="_blank" href="https://newstrack.ugent.be/project">hier</a> vinden.</b>
  <br>
  <br>
  Aangezien dit de eerste keer is dat je inlogt op dit platform, krijg je eerst wat meer informatie over hoe alles hier precies in zijn werk gaat.
  Aan de hand van een filmpje krijg je in slechts enkele minuten tijd uitgelegd hoe je aanpassingen kan categoriseren.
  Vervolgens vind je op deze pagina ook meer informatie over welke types aanpassingen van nieuwsartikels er allemaal bestaan, en welke je zou moeten kiezen in welke concrete situatie.
<br>
  <br>
  Aangezien het voor de kwaliteit van ons onderzoek heel belangrijk is dat je het platform op een correcte manier gebruikt, <b>zouden we je willen vragen om de beschikbare documentatie rustig door te nemen vooraleer je begint met het categoriseren van nieuwsaanpassingen.</b>
  Nadat je het filmpje en de uitleg eronder hebt doorlopen, kan je onderaan deze pagina klikken op <b>"Ik heb alles begrepen!"</b>.
  Veel informatie in 1 keer? Geen probleem: deze pagina blijft ook na het doorlopen van deze inleidingssessie beschikbaar op de website!</p>
</div>
  <br>
<div name="centered-vid2" *ngIf="hasLoggedIn===false||hasLoggedIn===true||!authService.currentUserValue">
  <br>
<h2 align="center">Hoe werkt het?</h2>
<p class="abs-center-x-2">Hoe kan je aanpassingen via de UGent Newstracker categoriseren? Dit filmpje helpt je in geen tijd op weg!</p>
<div class="embed-responsive embed-responsive-16by9 col-md-8" name="centered-vid">
  <iframe width="640px" height="420px"
          src="https://youtube.com/embed/fGKyWlmr1xs">
  </iframe></div>
  <br>
</div>
<div class="jumbotron jumbotron-fluid" name="under-centered-vid" *ngIf="hasLoggedIn===false||hasLoggedIn===true||!authService.currentUserValue">
  <h2 align="center">Welke categorie kies ik?</h2>
  <p class="abs-center-x-5">Er zijn heel wat verschillende categorieën die je kan kiezen om een aanpassing aan een artikel te typeren.
  Om je een beter idee te geven van welke categorie je dient te gebruiken in welke situatie, vind je hieronder een uitgebreide uitleg voor iedere categorie.</p>
  <p class="abs-center-x-5"><b>Door te klikken op het plus-icoontje bekom je meer uitleg over een specifieke categorie!</b></p>
  <br>
  <br>
  <div class="abs-center-x">
  <h5 style="display: inline-block;">Correctie van subjectieve fout </h5>
  <img class="plus" src="assets/plus.png" *ngIf="subjectiefChosen===false" style="margin-left:4px;" width="15" height="15" (click)="clickCategory('subjectief')">
    <img class="min" src="assets/minus.png" *ngIf="subjectiefChosen===true" style="margin-left:4px;" width="15" height="15" (click)="clickCategory('subjectief')">
  </div>
  <br>
  <p class="abs-center-x-2"><i>Aanpassing waarbij een subjectieve fout wordt gecorrigeerd. De verschillende types aanpassingen die hieronder vallen vind je door te klikken op het +-icoontje.</i></p>
    <div *ngIf="subjectiefChosen===true">
      <p class="abs-center-x-2"><b>Onderstaande lijst omvat alle types aanpassingen die onder de categorie "Aanpassing van een subjectieve fout" vallen. Door te klikken op een type aanpassing bekom je voorbeelden en tegenvoorbeelden voor dit type.</b></p>
      <ul class="list-group">
      <li class="list-group-item text-center" (click)="clickSubcategory('niet-representatieve (tussen)titel')"><b style="font-size:14pt;">Niet-representatieve (tussen)titel</b> <br> <i>De (tussen)titel in het oorspronkelijke artikel wordt aangepast omdat het geen correcte weergave vormt van wat er in de eigenlijke tekst staat.</i></li>
      <div class="examples-sub" *ngIf="this.subjectivesChosen.indexOf(10) >= 0">
        <p class="abs-center-x-2"><b>Voorbeelden:</b></p>
        <p class="abs-center-x-3">&#9642; Aanpassing waarbij originele (tussen)titel wordt aangepast omdat die over klein detail gaat die helemaal niet de essentie van de volgende paragraaf/het artikel weergeeft.</p>
        <p class="abs-center-x-2"><b>Tegenvoorbeelden (géén niet-representatieve (tussen)titels!):</b></p>
        <p class="abs-center-x-3">&#9642; Aanpassing waarbij spelfouten in (tussen)titels wordt gecorrigeerd.</p>
        <p class="abs-center-x-3">&#9642; Aanpassing waarbij informatie in originele (tussen)titel verouderd is.</p>
        <p class="abs-center-x-3">&#9642; Aanpassing waarbij objectief foutieve informatie uit (tussen)titel wordt verwijderd.</p>
      </div>
      <li class="list-group-item text-center" (click)="clickSubcategory('informatie gesensationaliseerd')"><b style="font-size:14pt;">Informatie gesensationaliseerd</b> <br> <i>Een deel van de inhoud van het artikel wordt herschreven omdat in het oorspronkelijke artikel de inhoud te gesensationaliseerd werd voorgesteld.</i></li>
      <div class="examples-sub" *ngIf="this.subjectivesChosen.indexOf(11) >= 0">
        <p class="abs-center-x-2"><b>Voorbeelden:</b></p>
        <p class="abs-center-x-3">&#9642; Aanpassing van "in Brussel zijn vanavond <span style="color:red">grootschalige rellen</span> uitgebroken" naar "in Brussel <span style="color:green">werd</span> vanavond <span style="color:green">op meerdere plaatsen gevochten</span>".</p>
        <p class="abs-center-x-3">&#9642; Aanpassing van "er zouden miljoenen doden kunnen vallen" naar "<span style="color:green">volgens één model </span>zouden er miljoenen doden kunnen vallen<span style="color:green">, al lijkt dat met de huidige informatie nog steeds onwaarschijnlijk</span>".</p>
        <p class="abs-center-x-3">&#9642; Aanpassing waarbij overdreven taalgebruik van woorden zoals "exclusief", "chaos", "schokerend" wordt aangepast omdat ze in de originele tekst zonder bijhorende fundering en argumentatie werden gebruikt.</p>
        <p class="abs-center-x-2"><b>Tegenvoorbeelden (géén gesensationaliseerde informatie!):</b></p>
        <p class="abs-center-x-3">&#9642; Aanpassing waarbij objectief foutieve informatie wordt verwijderd.</p>
        </div>
      <li class="list-group-item text-center" (click)="clickSubcategory('informatie te ingetogen geformuleerd')"><b style="font-size:14pt;">Informatie te ingetogen geformuleerd</b> <br> <i>In het oorspronkelijke artikel wordt de inhoud geminimaliseerd: het belang of de ernst van wat verteld wordt, wordt onder de mat geschoven.</i></li>
      <div class="examples-sub" *ngIf="this.subjectivesChosen.indexOf(12) >= 0">
        <p class="abs-center-x-2"><b>Voorbeelden:</b></p>
        <p class="abs-center-x-3">&#9642; Aanpassing van "bij het vliegtuigongeval vielen <span style="color:red">slechts</span> 125 doden, 7 passagiers overleefden de crash" naar "bij het vliegtuigongeval vielen 125 doden, <span style="color:green">slechts</span> 7 passagiers overleefden de crash".</p>
        <p class="abs-center-x-3">&#9642; Aanpassing van "<span style="color:red">ondanks de zware storm die boven hun huis uitbrak, werd enkel het dak van hun huis helemaal vernieuwd maar bleef de rest overeind</span>" naar "<span style="color:green">de zware storm die uitbrak boven hun huis vernielde het volledige dak</span>".</p>
        <p class="abs-center-x-2"><b>Tegenvoorbeelden (géén te ingetogen geformuleerde informatie!):</b></p>
        <p class="abs-center-x-3">&#9642; Aanpassing waarbij objectief foutieve informatie wordt verwijderd.</p>
        </div>
      <li class="list-group-item text-center" (click)="clickSubcategory('essentiële informatie afwezig')"><b style="font-size:14pt;">Essentiële informatie afwezig</b> <br> <i>Het artikel wordt herschreven omdat essentiële informatie in het oorspronkelijke artikel afwezig was, terwijl deze op dat moment reeds beschikbaar was.</i></li>
      <div class="examples-sub" *ngIf="this.subjectivesChosen.indexOf(13) >= 0">
        <p class="abs-center-x-2"><b>Voorbeelden:</b></p>
        <p class="abs-center-x-3">&#9642; Aanpassing van "President Trump zegt dat de uitslag van de verkiezingen is gefraudeerd" naar "President Trump zegt dat de uitslag van de verkiezingen is gefraudeerd<span style="color:green">, alhoewel hiervoor uit voorlopig onderzoek geen enkel bewijs werd gevonden</span>".</p>
        <p class="abs-center-x-3">&#9642; Aanpassing van "bij de aanval vielen 4 Israëlische slachtoffers" naar "bij de aanval vielen 4 Israëlische<span style="color:green"> en 3 Palestijnse slachtoffers</span>".</p>
        <p class="abs-center-x-2"><b>Tegenvoorbeelden (géén afwezigheid van essentiële informatie!):</b></p>
        <p class="abs-center-x-3">&#9642; Aanpassing waarbij informatie wordt toegevoegd die op het moment van de publicatie van het originele artikel nog niet gekend was.</p>
        </div>
      <li class="list-group-item text-center" (click)="clickSubcategory('misleidende cijfers')"><b style="font-size:14pt;">Misleidende cijfers</b> <br> <i>Numerieke informatie in het oorspronkelijke artikel wordt op een misleidende manier voorgesteld, waardoor de lezer mogelijks foutieve conclusies uit het artikel zou kunnen trekken.</i></li>
      <div class="examples-sub" *ngIf="this.subjectivesChosen.indexOf(14) >= 0">
        <p class="abs-center-x-2"><b>Voorbeelden:</b></p>
        <p class="abs-center-x-3">&#9642; Aanpassing van "het aantal dagelijkse overlijdens ten gevolge van corona steeg <span style="color:red">met 100%</span> ten opzichte van de week voordien." naar "het aantal dagelijkse overlijdens ten gevolge van corona steeg <span style="color:green">naar twee</span> ten opzichte van <span style="color:green">één</span> de week voordien.".</p>
        <p class="abs-center-x-2"><b>Tegenvoorbeelden (géén misleidende cijfers!):</b></p>
        <p class="abs-center-x-3">&#9642; Aanpassing waarbij foutieve cijfers in het oorspronkelijke artikel worden gecorrigeerd.</p>
        <p class="abs-center-x-3">&#9642; Aanpassing waarbij cijfers worden toegevoegd die op het moment van de publicatie van het originele artikel nog niet gekend waren.</p>
        </div>
      <li class="list-group-item text-center" (click)="clickSubcategory('uit de context getrokken citaat')"><b style="font-size:14pt;">Uit de context getrokken citaat</b> <br> <i>Het citaat in het oorspronkelijke artikel werd onvoldoende gekaderd, waardoor de boodschap anders overkomt dan ze oorspronkelijk door de persoon in kwestie bedoeld was. In tegenstelling tot de subcategorie "Correctie van objectieve fout - Foutief citaat" worden de woorden die een bepaald persoon sprak wel nog correct geformuleerd in het artikel, alleen wordt de context waarin deze woorden werden uitgesproken onvoldoende geduid.</i></li>
      <div class="examples-sub" *ngIf="this.subjectivesChosen.indexOf(15) >= 0">
        <p class="abs-center-x-2"><b>Voorbeelden:</b></p>
        <p class="abs-center-x-3">&#9642; Aanpassing van "Annelies Verlinden (CD&V) verklaarde<span style="color:red">: 'de politie kan binnenvallen op kerstfeestjes tijdens coronacrisis'</span>" naar "Annelies Verlinden (CD&V) verklaarde<span style="color:green"> dat het niet de bedoeling is een heksenjacht te organiseren op kerstavond, maar dat de politie wanneer er een klacht binnenkomt wel degelijk kan binnenvallen op kerstfeestjes</span>".</p>
        <p class="abs-center-x-2"><b>Tegenvoorbeelden (géén uit de context getrokken citaat!):</b></p>
        <p class="abs-center-x-3">&#9642; Aanpassing waarbij informatie wordt toegevoegd die op het moment van de publicatie van het originele artikel nog niet gekend was.</p>
        <p class="abs-center-x-3">&#9642; Aanpassing waarbij objectief foutieve informatie (bv. een 'citaat' dat nooit werd uitgesproken) wordt verwijderd.</p>
      </div>
    </ul>
  </div>
  <hr>
  <div class="abs-center-x">
  <h5 style="display: inline-block;">Correctie van objectieve fout </h5>
  <img class="plus" src="assets/plus.png" *ngIf="objectiefChosen===false" style="margin-left:4px;" width="15" height="15" (click)="clickCategory('objectief')">
    <img class="min" src="assets/minus.png" *ngIf="objectiefChosen===true" style="margin-left:4px;" width="15" height="15" (click)="clickCategory('objectief')">
  </div>
  <br>
  <p class="abs-center-x-2"><i>Aanpassing waarbij een objectieve fout wordt gecorrigeerd. De verschillende types aanpassingen die hieronder vallen vind je door te klikken op het +-icoontje.</i></p>
  <div class="card align-items-center" *ngIf="objectiefChosen===true">
    <p class="abs-center-x-2"><b>Onderstaande lijst omvat alle types aanpassingen die onder de categorie "Aanpassing van een objectieve fout" vallen. Door te klikken op een type aanpassing bekom je voorbeelden en tegenvoorbeelden voor dit type.</b></p>
    <ul class="list-group">
      <li class="list-group-item text-center" (click)="clickSubcategory('foutieve naam')"><b style="font-size:14pt;">Foutieve naam</b> <br> <i>In het oorspronkelijk artikel wordt een wordt een foutieve eigennaam (van een bepaald persoon, bedrijf, vereniging, ...) gebruikt, die fonetisch voldoende verschilt van de eigenlijke correcte naam. Indien het op het moment van de oorspronkelijke publicatie aangenomen werd dat dit de correcte naam was, dan valt deze fout onder de categorie "Update van oude informatie". Indien niet, dan gebruik je de subcategorie "Correctie van objectieve fout - Foutieve naam"</i></li>
      <div class="examples-sub" *ngIf="this.objectivesChosen.indexOf(1) >= 0">
        <p class="abs-center-x-2"><b>Voorbeelden:</b></p>
        <p class="abs-center-x-3">&#9642; Aanpassing van "acteur <span style="color:red">Bart</span> Janssens" naar "acteur <span style="color:green">Kevin</span> Janssens".</p>
        <p class="abs-center-x-3">&#9642; Aanpassing van "het recreatiedomein <span style="color:red">De Brielmeersen</span>" naar "het recreatiedomein <span style="color:green">De Blaarmeersen</span>".</p>
        <p class="abs-center-x-2"><b>Tegenvoorbeelden (géén foutieve namen!):</b></p>
        <p class="abs-center-x-3">&#9642; Aanpassing van "acteur Kevin <span style="color:red">Jansens</span>" naar "acteur Kevin <span style="color:green">Janssens</span>" is een "Correctie van spel- of taalfout".</p>
        <p class="abs-center-x-3">&#9642; Aanpassingen waarbij de naam effectief veranderde en waar dus geen fout gemaakt werd door de journalist (bv. naam van een terreurverdachte werd foutief doorgegeven door de politie). Dit is een "Update van oude informatie".</p>
      </div>
      <li class="list-group-item text-center" (click)="clickSubcategory('foutieve functietitel')"><b style="font-size:14pt;">Foutieve functietitel</b> <br> <i>In het oorspronkelijk artikel wordt een foutieve functieomschrijving/jobtitel gebruikt voor een bepaald persoon.</i></li>
      <div class="examples-sub" *ngIf="this.objectivesChosen.indexOf(2) >= 0">
        <p class="abs-center-x-2"><b>Voorbeelden:</b></p>
        <p class="abs-center-x-3">&#9642; Aanpassing van "<span style="color:red">regisseur</span> Kevin Janssens" naar "<span style="color:green">acteur</span> Kevin Janssens".</p>
        <p class="abs-center-x-2"><b>Tegenvoorbeelden (géén foutieve functietitels!):</b></p>
        <p class="abs-center-x-3">&#9642; Aanpassing van "<span style="color:red">akteur</span> Kevin Janssens" naar "<span style="color:green">acteur</span> Kevin Janssens". Dit is een "Correctie van spel- of taalfout".</p>
      </div>
      <li class="list-group-item text-center" (click)="clickSubcategory('foutieve leeftijd')"><b style="font-size:14pt;">Foutieve leeftijd</b> <br> <i>In het oorspronkelijk artikel wordt een foute leeftijd aangehaald voor een bepaald persoon.</i></li>
      <div class="examples-sub" *ngIf="this.objectivesChosen.indexOf(3) >= 0">
        <p class="abs-center-x-2"><b>Voorbeelden:</b></p>
        <p class="abs-center-x-3">&#9642; Aanpassing van "de <span style="color:red">40-jarige</span> Kevin Janssens" naar "de <span style="color:green">41-jarige</span> Kevin Janssens".</p>
        <p class="abs-center-x-3">&#9642; Aanpassing van "Royal Antwerp FC is de oudste voetbalclub van het land (al <span style="color:red">130</span> jaar oud!)" naar "Royal Antwerp FC is de oudste voetbalclub van het land (al <span style="color:green">140</span> jaar oud!)".</p>
        </div>
      <li class="list-group-item text-center" (click)="clickSubcategory('foutief adres')"><b style="font-size:14pt;">Foutief adres</b> <br> <i>In het oorspronkelijk artikel wordt een foutief adres (straat, huisnummer of gemeente) vermeld van een bepaald persoon.</i></li>
      <div class="examples-sub" *ngIf="this.objectivesChosen.indexOf(4) >= 0">
        <p class="abs-center-x-2"><b>Voorbeelden:</b></p>
        <p class="abs-center-x-3">&#9642; Aanpassing van "De hoofdkantoren van Het Laatste Nieuws bevinden zich op Mediaplein <span style="color:red">15</span>, 2018 Antwerpen" naar "De hoofdkantoren van Het Laatste Nieuws bevinden zich op Mediaplein <span style="color:green">1</span>, 2018 Antwerpen".</p>
        </div>
      <li class="list-group-item text-center" (click)="clickSubcategory('foutief geciteerd')"><b style="font-size:14pt;">Foutief geciteerd</b> <br> <i>In het oorspronkelijk artikel worden de woorden die een bepaald persoon sprak foutief geformuleerd. In tegenstelling tot de categorie "Correctie van subjectieve fout - Uit de context getrokken citaat", is de boodschap die is neergeschreven nooit zo gezegd geweest door de persoon, los van de context.</i></li>
      <div class="examples-sub" *ngIf="this.objectivesChosen.indexOf(5) >= 0">
        <p class="abs-center-x-2"><b>Voorbeelden:</b></p>
        <p class="abs-center-x-3">&#9642; Aanpassing van "Premier De Croo zei: "het coronavirus is <span style="color:red">niet</span> gevaarlijk."" naar "Premier De Croo zei: "het coronavirus is gevaarlijk."".</p>
        <p class="abs-center-x-3">&#9642; Aanpassing van ""<span style="color:red">Overmorgen</span> zullen we communiceren wie onze nieuwe trainer wordt", besloot voorzitter Ivan De Witte." naar "<span style="color:green">Morgen</span> zullen we communiceren wie onze nieuwe trainer wordt", besloot voorzitter Ivan De Witte.".</p>
        <p class="abs-center-x-2"><b>Tegenvoorbeelden (géén foutieve citaties!):</b></p>
        <p class="abs-center-x-3">&#9642; Aanpassing van "de premier zei dat er <span style="color:red">veel</span> doden waren gevallen" naar "de premier zei dat er <span style="color:green">300</span> doden waren gevallen". Dit is een "Toevoeging/verduidelijking van informatie".</p>
        <p class="abs-center-x-3">&#9642; Aanpassing van "Jan Jambon zei dat er moslims hadden gedanst na de aanslagen in Brussel" naar "Jan Jambon zei dat er moslims hadden gedanst na de aanslagen in Brussel<span style="color:green">, maar voegde er aan toe dat het zeker niet om alle moslims ging</span>". Dit is een "Correctie van subjectieve fout".</p>
      </div>
      <li class="list-group-item text-center" (click)="clickSubcategory('foutief aantal')"><b style="font-size:14pt;">Foutief aantal</b> <br> <i>In het oorsponkelijk artikel wordt een foutief aantal opgegeven. Indien het op het moment van de oorspronkelijke publicatie aangenomen werd dat dit het correcte aantal was, gebruik je de categorie "Update van oude informatie". Indien niet, gebruik je de subcategorie "Correctie van objectieve fout - Foutief aantal".</i></li>
      <div class="examples-sub" *ngIf="this.objectivesChosen.indexOf(6) >= 0">
        <p class="abs-center-x-2"><b>Voorbeelden:</b></p>
        <p class="abs-center-x-3">&#9642; Aanpassing van "de premier zei dat er <span style="color:red">330</span> doden waren gevallen" naar "de premier zei dat er <span style="color:green">300</span> doden waren gevallen".</p>
        <p class="abs-center-x-3">&#9642; Aanpassing van "Er waren 30 aanwezigen, onder wie <span style="color:red">14</span> volwassenen en 15 kinderen" naar "Er waren 30 aanwezigen, onder wie <span style="color:green">15</span> volwassenen en 15 kinderen".</p>
        <p class="abs-center-x-2"><b>Tegenvoorbeelden (géén foutieve aantallen!):</b></p>
        <p class="abs-center-x-3">&#9642; Aanpassing van "de premier zei dat er <span style="color:red">veel</span> doden waren gevallen" naar "de premier zei dat er <span style="color:green">300</span> doden waren gevallen". Dit is een "Toevoeging/verduidelijking van informatie".</p>
        <p class="abs-center-x-3">&#9642; Aanpassing waarbij een aantal wordt aangepast, maar waarbij het aantal in het originele artikel op het moment van publicatie nog correct was (bv. aantal doden dat oploopt in een vliegtuigcrash). Dit is een "Update van oude informatie".</p>
       </div>
      <li class="list-group-item text-center" (click)="clickSubcategory('foutief tijdstip')"><b style="font-size:14pt;">Foutief tijdstip</b> <br> <i>In het oorspronkelijk artikel wordt een foutief moment van de dag, uur, ... vermeld voor wanneer iets gebeurd zou zijn. Indien het op het moment van de oorspronkelijke publicatie aangenomen werd dat dit het correcte tijdstip was, dan gebruik je de categorie "Update van oude informatie". Indien niet, gebruik je de subcategorie "Correctie van objectieve fout - Foutief tijdstip".</i></li>
      <div class="examples-sub" *ngIf="this.objectivesChosen.indexOf(7) >= 0">
        <p class="abs-center-x-2"><b>Voorbeelden:</b></p>
        <p class="abs-center-x-3">&#9642; Aanpassing van "het ongeval gebeurde <span style="color:red">zaterdagochtend</span>" naar "het ongeval gebeurde <span style="color:green">zaterdagnamiddag</span>".</p>
        <p class="abs-center-x-3">&#9642; Aanpassing van "de wedstrijd start om <span style="color:red">20u15</span>" naar "de wedstrijd start om <span style="color:green">20u</span>".</p>
        <p class="abs-center-x-3">&#9642; Aanpassing van "Kevin Janssens werd geboren in <span style="color:red">1975</span>" naar "Kevin Janssens werd geboren in <span style="color:green">1979</span>".</p>
        <p class="abs-center-x-2"><b>Tegenvoorbeelden (géén foutieve tijdstippen!):</b></p>
        <p class="abs-center-x-3">&#9642; Aanpassing van "de wedstrijd start om <span style="color:red">200u15</span>" naar "de wedstrijd start om <span style="color:green">20u15</span>". Dit is een "Correctie van spel- of taalfout".</p>
        <p class="abs-center-x-3">&#9642; Aanpassing van "het ongeval gebeurde zaterdagochtend" naar "het ongeval gebeurde zaterdagochtend <span style="color:green">vroeg</span>". Dit is een "Toevoeging/verduidelijking van informatie".</p>
      </div>
      <li class="list-group-item text-center" (click)="clickSubcategory('foutieve datum')"><b style="font-size:14pt;">Foutieve datum</b> <br> <i>In het oorspronkelijk artikel wordt een foutieve datum vermeld voor wanneer iets gebeurd zou zijn. Indien het op het moment van de oorspronkelijke publicatie aangenomen werd dat dit de correcte datum was, dan gebruik je de categorie "Update van oude informatie". Indien niet, gebruik je de subcategorie "Correctie van objectieve fout - Foutieve datum".</i></li>
      <div class="examples-sub" *ngIf="this.objectivesChosen.indexOf(8) >= 0">
        <p class="abs-center-x-2"><b>Voorbeelden:</b></p>
        <p class="abs-center-x-3">&#9642; Aanpassing van "de eerste maanlanding vond plaats op 20 <span style="color:red">juni </span> 1969" naar "de eerste maanlanding vond plaats op 20 <span style="color:green">juli </span> 1969".</p>
        <p class="abs-center-x-3">&#9642; Aanpassing van "de wedstrijd zal plaatsvinden op <span style="color:red">13</span> oktober" naar "de wedstrijd zal plaatsvinden op <span style="color:green">14</span> oktober".</p>
      </div>
      <li class="list-group-item text-center" (click)="clickSubcategory('foutieve locatie')"><b style="font-size:14pt;">Foutieve locatie</b> <br> <i>In het oorspronkelijk artikel wordt een foutieve locatie vermeld voor waar iets gebeurd zou zijn. Indien het op het moment van de oorspronkelijke publicatie aangenomen werd dat dit de juiste locatie was, dan gebruik je de categorie "Update van oude informatie". Indien niet, gebruik je de subcategorie "Correctie van objectieve fout - Foutieve locatie".</i></li>
      <div class="examples-sub" *ngIf="this.objectivesChosen.indexOf(9) >= 0">
        <p class="abs-center-x-2"><b>Voorbeelden:</b></p>
        <p class="abs-center-x-3">&#9642; Aanpassing van "Het ongeval gebeurde in <span style="color:red">Sint-Martens-Latem</span>" naar "Het ongeval gebeurde in <span style="color:green">Sint-Denijs-Westrem</span>".</p>
        <p class="abs-center-x-3">&#9642; Aanpassing van "Het ongeval gebeurde op de <span style="color:red">Kortrijksesteenweg</span>" naar "Het ongeval gebeurde op de <span style="color:green">Brusselsesteenweg</span>".</p>
        <p class="abs-center-x-3">&#9642; Aanpassing van "De man werd dood teruggevonden in zijn <span style="color:red">living</span>" naar "De man werd dood teruggevonden in zijn <span style="color:green">keuken</span>".</p>
      </div>
    </ul>
  </div>
  <hr>
  <div class="abs-center-x">
  <h5 style="display: inline-block;">Toevoeging/verduidelijking van informatie </h5>
  <img class="plus" src="assets/plus.png" *ngIf="toevoegingChosen===false" style="margin-left:4px;" width="15" height="15" (click)="clickCategory('toevoeging')">
    <img class="min" src="assets/minus.png" *ngIf="toevoegingChosen===true" style="margin-left:4px;" width="15" height="15" (click)="clickCategory('toevoeging')">
  </div>
  <br>
  <p class="abs-center-x-2" *ngIf="toevoegingChosen===false"><i>Aanpassing waarbij informatie wordt toegevoegd ten opzichte van de vorige versie van het artikel.</i></p>
  <div class="examples" *ngIf="toevoegingChosen===true">
    <p><i>Aanpassing waarbij informatie wordt toegevoegd ten opzichte van de vorige versie van het artikel.
      Hieronder verstaan we zowel informatie die op het moment van de publicatie van de originele versie van het artikel nog niet gekend was, informatie die wel reeds gekend was maar pas later is toegevoegd aan het artikel (en niet essentieel was, anders is het een "Correctie van subjectieve fout"!), als informatie die toegevoegd wordt ter verduidelijking van het originele artikel. Bij dit type aanpassingen wordt geen inhoud verwijderd uit het artikel, maar mogelijks worden titel en/of intro wel herschreven zodat de nieuwe informatie meer prominent aanwezig is. </i></p>
  <div class="examples-sub2">
    <p><b>Voorbeelden:</b></p>
    <p class="examples-sub2">&#9642; Aanpassing van "De speurders zijn op zoek naar de daders van de terroristische aanval." naar "De speurders zijn op zoek naar de daders van de terroristische aanval.<span style="color:green"> Volgens een politiebron zou het gaan om twee twintigers met de Franse nationaliteit.</span>".</p>
    <p class="examples-sub2">&#9642; Aanpassing van "twee <span style="color:red">personen</span> werden aangehouden" naar "twee <span style="color:green">mannen</span> werden aangehouden".</p>
    <p class="examples-sub2">&#9642; Aanpassing waarbij titels en/of intro's helemaal worden herschreven zodat de nieuw toegevoegde informatie verderop in het artikel duidelijk naar voor komt.</p>
    <p><b>Tegenvoorbeelden (géén toevoegingen van nieuwe informatie!):</b></p>
    <p class="examples-sub2">&#9642; Aanpassing van "De speurders zijn op zoek naar de daders van de terroristische aanval.<span style="color:red"> Volgens een politiebron zou het gaan om zeker één twintiger met de Franse nationaliteit.</span>" naar "De speurders zijn op zoek naar de daders van de terroristische aanval.<span style="color:green"> Volgens een politiebron zou het gaan om twee twintigers met de Franse nationaliteit.</span>".</p>
    <p class="examples-sub2">&#9642; Aanpassing waarbij essentiële informatie wordt toegevoegd die ook al gekend was bij de publicatie van de originele versie van het artikel.</p>
    <p class="examples-sub2">&#9642; Aanpassing waarbij oude informatie wordt geüpdatet (zie tegenvoorbeeld 1).</p>
  </div>
    <br>
  </div>
  <hr>
  <div class="abs-center-x">
  <h5 style="display: inline-block;" align="center">Verwijdering van informatie </h5>
  <img class="plus" src="assets/plus.png" *ngIf="verwijderingChosen===false" style="margin-left:4px;" width="15" height="15" (click)="clickCategory('verwijdering')">
    <img class="min" src="assets/minus.png" *ngIf="verwijderingChosen===true" style="margin-left:4px;" width="15" height="15" (click)="clickCategory('verwijdering')">
  </div>
  <br>
  <p class="abs-center-x-2" *ngIf="verwijderingChosen===false"><i>Aanpassing waarbij informatie wordt verwijderd ten opzichte van de vorige versie van het artikel.</i></p>
   <div class="examples" *ngIf="verwijderingChosen===true">
     <p><i>Aanpassing waarbij informatie wordt verwijderd ten opzichte van de vorige versie van het artikel.
       De informatie die verwijderd wordt was op het tijdstip van originele publicatie correct. De informatie kan zowel worden verwijderd omdat de informatie verouderd is (en dus niet meer correct is), als om eender welke andere reden. Aanpassingen waarbij de informatie reeds foutief/verouderd was op het moment van publicatie van de originele versie van het artikel vallen hier echter niet onder, net zoals aanpassingen waar ook informatie wordt toegevoegd.</i></p>
  <div class="examples-sub2">
    <p><b>Voorbeelden:</b></p>
    <p class="examples-sub2">&#9642; Aanpassing van "De speurders zijn op zoek naar de daders van de terroristische aanval.<span style="color:red"> Volgens een politiebron zou het gaan om twee twintigers met de Franse nationaliteit.</span>" naar "De speurders zijn op zoek naar de daders van de terroristische aanval.<span style="color:green"> Eerst werd gedacht dat het om twee twintigers ging met de Franse nationaliteit, maar ondertussen blijkt dit niet te kloppen.</span>".</p>
    <p class="examples-sub2">&#9642; Aanpassing van "De <span style="color:red">22-jarige</span> dader sloeg nadat hij het verkeersongeval veroorzaakte op de vlucht" naar "De dader sloeg nadat hij het verkeersongeval veroorzaakte op de vlucht". Pas op: dit is enkel een "Verwijdering van informatie" als de dader effectief 22 jaar was (anders is het een "Correctie van een objectieve fout"!).</p>
    <p><b>Tegenvoorbeelden (géén toevoegingen van nieuwe informatie!):</b></p>
    <p class="examples-sub2">&#9642; Aanpassing waarbij informatie wordt verwijderd uit het oorspronkelijk artikel die op het moment van publicatie van dat artikel ook al fout was.</p>
    <p class="examples-sub2">&#9642; Aanpassing waarbij oude informatie wordt geüpdatet.</p>
  </div>
     <br>
   </div>
  <hr>
  <div class="abs-center-x">
  <h5 style="display: inline-block;">Inhoudsneutrale aanpassing </h5>
  <img class="plus" src="assets/plus.png" *ngIf="neutraalChosen===false" style="margin-left:4px;" width="15" height="15" (click)="clickCategory('herformulering')">
    <img class="min" src="assets/minus.png" *ngIf="neutraalChosen===true" style="margin-left:4px;" width="15" height="15" (click)="clickCategory('herformulering')">
  </div>
  <br>
  <p class="abs-center-x-2" *ngIf="neutraalChosen===false"><i>Aanpassing waarbij de boodschap uit het oorspronkelijke artikel wordt geherformuleerd, zonder dat hierbij informatie wordt toegevoegd, verwijderd of een andere toon wordt gelegd.</i></p>
  <div class="examples" *ngIf="neutraalChosen===true">
    <p><i>Aanpassing waarbij woorden /zinnen uit het oorspronkelijke artikel worden verwijderd, herschreven of nieuwe woorden worden toegevoegd, zonder dat hierbij nieuwe informatie wordt toegevoegd, oude informatie wordt verwijderd, fouten worden verwijderd of een andere toon wordt gehanteerd.
      Dezelfde inhoud kan op een andere manier worden beschreven, de oorspronkelijke tekst kan verduidelijkt worden, dubbele informatie kan verwijderd worden, maar aan de inhoud van het artikel an sich verandert niks.
      De aanpassing zorgt ook niet voor een verandering van toon (bv. minder suggestief), waardoor de aanpassing niet onder een "Correctie van subjectieve fout" valt.</i></p>
  <div class="examples-sub2">
    <p><b>Voorbeelden:</b></p>
    <p class="examples-sub2">&#9642; Aanpassing van "De speurders <span style="color:red">zijn op zoek</span> naar de daders van de terroristische aanval." naar "De speurders <span style="color:green">zoeken ondertussen verder</span> naar de daders van de terroristische aanval.".</p>
    <p class="examples-sub2">&#9642; Aanpassing van "Grote kerstfeestjes zullen er tijdens de coronacrisis niet inzitten<span style="color:red">, zegt Marc Van Ranst</span>." naar "<span style="color:green">Marc Van Ranst benadrukt dat grote</span> kerstfeestjes er niet zullen inzitten tijdens de coronacrisis.".</p>
    <p class="examples-sub2">&#9642; Aanpassing van "<span style="color:red">Morgen</span> komt het Overlegcomité samen." naar "<span style="color:green">Vandaag</span> komt het Overlegcomité samen", indien de nieuwe versie van het artikel geschreven is de dag na de oorspronkelijke versie van het artikel.</p>
    <p class="examples-sub2">&#9642; Aanpassing van "Er zullen de komende jaren geen afleveringen van FC De Kampioenen worden gemaakt." naar "Er zullen de komende jaren geen <span style="color:green">nieuwe</span> afleveringen van FC De Kampioenen worden gemaakt."</p>
    <p><b>Tegenvoorbeelden (géén inhoudsneutrale aanpassing!):</b></p>
    <p class="examples-sub2">&#9642; Aanpassing waarbij informatie wordt verwijderd of toegevoegd.</p>
    <p class="examples-sub2">&#9642; Aanpassing waarbij exact dezelfde formulering (dus geen toevoeging van nieuwe woorden, verwijdering van oude woorden, gebruik van synoniemen ...) verschoven wordt naar een andere plek in het artikel.</p>
    <p class="examples-sub2">&#9642; Aanpassing waarbij spelfouten, objectieve fouten en/of subjectieve fouten worden verwijderd.</p>
  </div>
    <br>
  </div>
  <hr>
  <div class="abs-center-x">
  <h5 style="display: inline-block;">Update van oude informatie </h5>
  <img class="plus" src="assets/plus.png" *ngIf="updateChosen===false" style="margin-left:4px;" width="15" height="15" (click)="clickCategory('update')">
    <img class="min" src="assets/minus.png" *ngIf="updateChosen===true" style="margin-left:4px;" width="15" height="15" (click)="clickCategory('update')">
  </div>
  <br>
  <p class="abs-center-x-2" *ngIf="updateChosen===false"><i>Aanpassing waarbij de oude informatie wordt geüpdatet. Dit impliceert zowel verwijdering van oude informatie als toevoeging van informatie.</i></p>
    <div class="examples" *ngIf="updateChosen===true">
      <p><i>Aanpassing waarbij de oude informatie wordt geüpdatet. Hierbij wordt dus zowel informatie verwijderd als toegevoegd. De verwijderde informatie was correct op het moment dat het oorspronkelijke artikel werd gepubliceerd, maar is ondertussen achterhaald.</i></p>
  <div class="examples-sub2">
    <p><b>Voorbeelden:</b></p>
    <p class="examples-sub2">&#9642; Aanpassing van "De speurders zijn op zoek naar de daders van de terroristische aanval.<span style="color:red"> Volgens een politiebron zou het gaan om zeker één twintiger met de Franse nationaliteit.</span>" naar "De speurders zijn op zoek naar de daders van de terroristische aanval.<span style="color:green"> Volgens een politiebron zou het gaan om twee twintigers met de Franse nationaliteit.</span>".</p>
    <p><b>Tegenvoorbeelden (géén vervangingen van oude door nieuwe informatie!):</b></p>
    <p class="examples-sub2">&#9642; Aanpassing van "De speurders zijn op zoek naar de daders van de terroristische aanval." naar "De speurders zijn op zoek naar de daders van de terroristische aanval.<span style="color:green"> Volgens een politiebron zou het gaan om twee twintigers met de Franse nationaliteit.</span>".</p>
    <p class="examples-sub2">&#9642; Aanpassing waarbij enkel maar informatie wordt toegevoegd (zie tegenvoorbeeld 1) of verwijderd, maar geen oude informatie wordt geüpdatet.</p>
    <p class="examples-sub2">&#9642; Aanpassing waarbij oude informatie wordt vervangen die op het moment van de publicatie van het oude artikel reeds foutief was.</p>
    <p class="examples-sub2">&#9642; Aanpassing waarbij tekst wordt vervangen, maar waarbij de inhoud van het artikel helemaal niet veranderd.</p>
  </div>
      <br>
    </div>
  <hr>
  <div class="abs-center-x">
  <h5 style="display: inline-block;">Verplaatsing van identieke informatie</h5>
  <img class="plus" src="assets/plus.png" *ngIf="verplaatsingChosen===false" style="margin-left:4px;" width="15" height="15" (click)="clickCategory('verplaatsing')">
    <img class="min" src="assets/minus.png" *ngIf="verplaatsingChosen===true" style="margin-left:4px;" width="15" height="15" (click)="clickCategory('verplaatsing')">
  </div>
  <br>
  <p class="abs-center-x-2" *ngIf="verplaatsingChosen===false"><i>Aanpassing waarbij tekst exact gekopieerd wordt naar een andere positie in het artikel.</i></p>
  <div class="examples" *ngIf="verplaatsingChosen===true">
    <p><i>Aanpassing waarbij informatie wordt verplaatst naar een andere plaats in het artikel.
      Bij dit soort aanpassing wordt dus geen informatie verwijderd, toegevoegd, noch op een andere manier geformuleerd.
      De informatie wordt gewoon verplaatst naar een andere plaats in het nieuwe artikel.
      Hierbij is het mogelijk dat de originele plaats en nieuwe plaats van de tekst elk tot een ander rood of groen gebied behoren.
      In dit geval kies je voor zowel de aanpassing waarin de oorspronkelijke tekst voorkwam als de aanpassing waar de nieuwe tekst in voorkomt
      deze categorie.</i></p>
  <div class="examples-sub2">
    <p><b>Voorbeelden:</b></p>
    <p class="examples-sub2">&#9642; Aanpassing waarbij exact dezelfde zin/alinea wordt gekopieerd naar een andere positie in het artikel, zonder enige wijzigingen door te voeren.</p>
    <p><b>Tegenvoorbeelden (géén verplaatsingen van identieke informatie!):</b></p>
    <p class="examples-sub2">&#9642; Aanpassing waarbij informatie wordt toegevoegd of verwijderd.</p>
    <p class="examples-sub2">&#9642; Aanpassing waarbij oude informatie wordt geüpdatet.</p>
    <p class="examples-sub2">&#9642; Aanpassing waarbij tekst wordt vervangen zonder de inhoud van het artikel te wijzigen, maar waarbij weldegelijk andere formuleringen worden gebruikt.</p>
  </div>
    <br>
  </div>
  <hr>
  <div class="abs-center-x">
    <h5 style="display: inline-block;">Correctie van spel- of taalfout</h5>
    <img class="plus" src="assets/plus.png" *ngIf="spellingChosen===false" style="margin-left:4px;" width="15" height="15" (click)="clickCategory('spelling')">
    <img class="min" src="assets/minus.png" *ngIf="spellingChosen===true" style="margin-left:4px;" width="15" height="15" (click)="clickCategory('spelling')">
  </div>
  <br>
  <p class="abs-center-x-2" *ngIf="spellingChosen===false"><i>Aanpassing waarbij een spelfout of grammaticale fout (bv. foutieve zinsconstructie) wordt gecorrigeerd.</i></p>
  <div class="examples" *ngIf="spellingChosen===true">
    <p><i>Aanpassing waarbij een spelfout of grammaticale fout (bv. foutieve zinsconstructie) wordt gecorrigeerd. Onder spelfouten vallen alle fouten waarbij een woord/naam anders wordt geschreven, maar fonetisch wel nog hetzelfde klinkt.</i></p>
    <div class="examples-sub2">
      <p><b>Voorbeelden:</b></p>
      <p class="examples-sub2">&#9642; Aanpassing van "hij <span style="color:red">verkleede</span> zich" naar "hij <span style="color:green">verkleedde</span> zich".</p>
      <p class="examples-sub2">&#9642; Aanpassing van "de presentatoren <span style="color:red">sprak</span>" naar "de presentatoren <span style="color:green">spraken"</span>.</p>
      <p class="examples-sub2">&#9642; Aanpassing van "acteur Kevin <span style="color:red">Jansens</span>" naar "acteur Kevin <span style="color:green">Janssens</span>".</p>
      <p class="examples-sub2">&#9642; Aanpassing van "<span style="color:red">December</span>" naar "<span style="color:green">december</span>".</p>
      <p class="examples-sub2">&#9642; Vergeten van leestekens, dubbele leestekens...</p>
      <p class="examples-sub2">&#9642; ...</p>
      <p><b>Tegenvoorbeelden (géén correctie van spel- of taalfouten!):</b></p>
      <p class="examples-sub2">&#9642; Aanpassing van "er vielen <span style="color:red">17</span> doden" naar "er vielen <span style="color:green">14</span> doden". Dit is een "Correctie van objectieve fout".</p>
      <p class="examples-sub2">&#9642; Aanpassing van "acteur <span style="color:red">Bart</span> Janssens" naar "acteur <span style="color:green">Kevin</span> Janssens". Dit is een "Correctie van objectieve fout".</p>
      <p class="examples-sub2">&#9642; Aanpassing van "17 <span style="color:red">juni</span>" naar "17 <span style="color:green">juli</span>". Dit is een "Correctie van objectieve fout".</p>
      <p class="examples-sub2">&#9642; ...</p>
    </div>
    <br>
  </div>
  <hr>
  <div class="abs-center-x">
  <h5 style="display: inline-block;">Andere </h5>
  </div>
  <br>
  <p class="abs-center-x-2"><i>Elk type aanpassing dat niet in één van de andere categorieën past.</i></p>
</div>
<br>
<div style="margin-top:700px; text-align:center">
<h2 align="center">Samengevat</h2>
<img class="flowchart" src="assets/flowchart.png" width="1100" height="1097">
</div>
<br>
<div name="acceptbutton" *ngIf="hasLoggedIn===false">
  <div class="container">
    <button class="btn btn-primary btn-lg btn-block" (click)="clickAcceptButton()">Ik heb alles begrepen!</button>
  </div>
  <br>
</div>

</body>
</html>
