import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class ConfirmationService {

  // public confirmationServerUrl = 'https://localhost:443/registrationConfirm';
  public confirmationServerUrl = 'https://newstrack.ugent.be/backend/registrationConfirm';
  constructor(private http: HttpClient) { }

  confirm(email: string, token: string): Observable<any> {
    /*tslint:disable:object-literal-key-quotes */
    return this.http.get<any>(this.confirmationServerUrl, {params: new HttpParams().set('email', email).set('token', token), responseType: 'json',
      headers: { 'Accept': 'application/json', 'rejectUnauthorized': 'false', 'insecure': 'true'}});
  }
}
