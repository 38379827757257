import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { AuthService } from '../auth.service';
import {BehaviorSubject} from 'rxjs';
import {BooleanService} from '../boolean.service';
import {Title} from '@angular/platform-browser';

@Component({ templateUrl: 'login.component.html',
  styleUrls: ['./login.component.css']})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  public loadingLogin: BehaviorSubject<boolean>;
  public emptyEmailLogin: BehaviorSubject<boolean>;
  public emptyPasswordLogin: BehaviorSubject<boolean>;
  public failedLogin: BehaviorSubject<boolean>;
  public mobile: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authenticationService: AuthService,
    public booleanService: BooleanService,
    private titleService: Title
  ) {
    booleanService.resetSigninBooleans();
    booleanService.resetRecoverBooleans();
    booleanService.resetRecoverConfirmBooleans();
    const current = new Date();
    if (this.authenticationService.currentUserValue && current <= new Date(this.authenticationService.currentUserValue.expiration)) {
      this.router.navigate([this.authenticationService.articlesUrl]);
    }
    localStorage.setItem('loadingLogin', JSON.stringify(false));
    localStorage.setItem('emptyEmailLogin', JSON.stringify(false));
    localStorage.setItem('emptyPasswordLogin', JSON.stringify(false));
    if (!localStorage.getItem('failedLogin')){
      localStorage.setItem('failedLogin', JSON.stringify(false));
    }
    this.loadingLogin = new BehaviorSubject<boolean>(JSON.parse(localStorage.getItem('loadingLogin')));
    this.emptyEmailLogin = new BehaviorSubject<boolean>(JSON.parse(localStorage.getItem('emptyEmailLogin')));
    this.emptyPasswordLogin = new BehaviorSubject<boolean>(JSON.parse(localStorage.getItem('emptyPasswordLogin')));
    this.failedLogin = new BehaviorSubject<boolean>(JSON.parse(localStorage.getItem('failedLogin')));
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      emailLogin: ['', Validators.required],
      passwordLogin: ['', Validators.required]
    });
    this.setTitle('UGent Newstracker - Login');
    const nav = navigator.userAgent;
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(nav)) {
      this.mobile = true;
    }

    else {
      this.mobile = false;
    }
  }
  get loginf() { return this.loginForm.controls; }

  onLoginSubmit() {
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      if (this.loginf.emailLogin.invalid) {
        localStorage.setItem('emptyEmailLogin', JSON.stringify(true));
        this.emptyEmailLogin.next(true);
      }
      if (this.loginf.passwordLogin.invalid) {
        localStorage.setItem('emptyPasswordLogin', JSON.stringify(true));
        this.emptyPasswordLogin.next(true);
      }
      localStorage.setItem('failedLogin', JSON.stringify(false));
      this.failedLogin.next(false);
      return;
    }
    localStorage.setItem('loadingLogin', JSON.stringify(true));
    this.loadingLogin.next(true);
    if (this.loginf.emailLogin.value && this.loginf.passwordLogin.value) {
      this.authenticationService.login(this.loginf.emailLogin.value, this.loginf.passwordLogin.value)
        .subscribe(
          data => {
            localStorage.setItem('failedLogin', JSON.stringify(false));
            this.failedLogin.next(false);
            this.router.navigate([this.authenticationService.articlesUrl]);
            localStorage.setItem('loadingLogin', JSON.stringify(false));
            this.loadingLogin.next(false);
            this.loginForm.reset();
          },
          error => {
            localStorage.setItem('loadingLogin', JSON.stringify(false));
            this.loadingLogin.next(false);
            localStorage.setItem('emptyEmailLogin', JSON.stringify(false));
            this.emptyEmailLogin.next(false);
            localStorage.setItem('emptyPasswordLogin', JSON.stringify(false));
            this.emptyPasswordLogin.next(false);
            localStorage.setItem('failedLogin', JSON.stringify(true));
            this.failedLogin.next(true);
            this.loginForm.reset();
          });
    }
  }
  public get currentLoadingLoginValue(): boolean {
    return this.loadingLogin.value;
  }
  public get currentEmptyEmailLoginValue(): boolean {
    return this.emptyEmailLogin.value;
  }
  public get currentEmptyPasswordLoginValue(): boolean {
    return this.emptyPasswordLogin.value;
  }
  public get currentFailedLoginValue(): boolean {
    return this.failedLogin.value;
  }

  public setTitle( newTitle: string) {
    this.titleService.setTitle( newTitle );
  }
  public setMobile(newMobile: boolean) {
    this.mobile = newMobile;
  }
}
