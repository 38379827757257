<!doctype html>
<html lang="en">
<head>
  <title>UGent Newstracker - Welkom!</title>
</head>
<body *ngIf="this.firstname && this.lastname">
<nav class="navbar navbar-expand-lg fixed-top navbar-dark bg-company-grey">
  <a class="navbar-brand" href="#"></a>
  <button class="btn btn-primary" class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <a class="navbar-brand" target="_blank" href="https://www.ugent.be/" style="padding:0px;">
    <img src="assets/ugentlogo.png" width="163" height="130">
  </a>
  <a class="navbar-brand" target="_blank" href="https://ddcmsite.ugent.be/" style="padding:0px;">
    <img src="assets/ddcmlogo.png" width="272" height="60">
  </a>
  <div class="collapse navbar-collapse text-right" *ngIf="getImageLoaded() && this.hasFeedbackCompleted===false && (this.scores === null || getRemaining() === 0)" id="navbarright">
    <div class="abs-center-x" *ngIf="allArticlesCompleted===false" style="display:inline-block;" name="ticket">
      <div *ngIf="getNrTickets() < 10" style="display:inline; color:white">
        <ng-container *ngFor="let number of getRange(getNrTickets());">
          <img [src]="getFullTicketWhite()" width="40" height="40">
        </ng-container>
      </div>
      <div *ngIf="getNrTickets() >= 10" style="display:inline; color:white">
        ...
        <ng-container *ngFor="let number of getRange(10);">
          <img [src]="getFullTicketWhite()" width="40" height="40">
        </ng-container>
        ...
      </div>
      <br>
      <br>
      <img [src]="getTicket()" width="281" height="41"/>
      <br>
      <p class="completed" *ngIf="getRemaining()>1 && this.feedbackCompleted === false">Nog {{getRemaining()}} oefenartikels te gaan!</p>
      <p class="completed" *ngIf="getRemaining()===1">Nog {{getRemaining()}} oefenartikel te gaan!</p>
      <p class="completed" *ngIf="getRemaining()===0 && this.feedbackCompleted === true">Oefenreeks {{seriesnumber}} voltooid!</p>
      </div>
    <ul class="navbar-nav ml-auto flex-nowrap">
      <li class="nav-item">
        <a class="nav-link active" href="/articles" *ngIf="authService.currentUserValue">Home<span class="sr-only">(current)</span></a>
      </li>
      <li class="nav-item">
        <a class="nav-link" target="_blank" href="/profile" *ngIf="authService.currentUserValue">{{this.firstname}} {{this.lastname}}</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" target="_blank" href="/project" *ngIf="authService.currentUserValue">Over dit project</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" target="_blank" href="/intro" *ngIf="authService.currentUserValue">Hoe werkt het?</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#" *ngIf="authService.currentUserValue" (click)="onLogoutClick()">Uitloggen</a>
      </li>
    </ul>
  </div>
</nav>
<div *ngIf="feedbackCompleted === false" class="jumbotron jumbotron-fluid">
  <div class="container">
    <div class="row" *ngIf="authService.currentUserValue">
      <div class="col-sm" name="introduction">
        <br>
        <br>
        <h2 class="h2" *ngIf="authService.currentUserValue && feedbackStage <= 3 && hasFeedbackCompleted === false">Welkom!</h2>
        <h5 class="h6" *ngIf="authService.currentUserValue && feedbackStage === 1 && hasFeedbackCompleted === false">Laten we eens proberen...</h5>
        <h5 class="h6" *ngIf="authService.currentUserValue && feedbackStage >= 2 && hasFeedbackCompleted === false">Laten we nog eens proberen...</h5>

        <p *ngIf="feedbackStage === 1">Aangezien je nieuw bent op dit platform, geven we je eerst wat tijd om te oefenen.
          Hierna volgen <b>vijf oefenartikels</b> die werden aangepast.
          De bedoeling is dat jij voor iedere wijziging tussen twee opeenvolgende versies van eenzelfde artikel aangeeft om welk type wijziging(en) het gaat, zoals ook gedemonstreerd werd in het introductiefilmpje.
        </p>
        <p *ngIf="feedbackStage === 2">Net zoals in de vorige reeks, volgen hierna opnieuw <b>vijf oefenartikels</b> die werden aangepast.
          De bedoeling is dat jij voor iedere wijziging tussen twee opeenvolgende versies van eenzelfde artikel aangeeft om welk type wijziging(en) het gaat.
        </p>
        <p *ngIf="feedbackStage === 3">Net zoals in de vorige reeksen, volgen hierna opnieuw <b>vijf oefenartikels</b> die werden aangepast.
          De bedoeling is dat jij voor iedere wijziging tussen twee opeenvolgende versies van eenzelfde artikel aangeeft om welk type wijziging(en) het gaat.
        </p>
        <p *ngIf="feedbackStage <= 3 && hasFeedbackCompleted === false">Op het einde van de oefenreeks krijg je feedback over de fouten die je gemaakt hebt. Indien nodig, kan je steeds de documentatie en extra uitleg die je eerder kreeg <a target="_blank" href="https://newstrack.ugent.be/intro">hier</a> nog eens bekijken.

        <p *ngIf="feedbackStage <= 3 && hasFeedbackCompleted === false">Veel succes!</p>

        <h5 class="h5" *ngIf="oldArticle && newArticle && authService.currentUserValue">Resterende aanpassingen in onderstaand artikel:
          {{titlepositions.length + intropositions.length + textpositions.length}}</h5>

      </div>
      <div class="col-sm" name="leftUpdates">
        <img src="assets/newslogo.jpg" width="720" height="520">
      </div>
    </div>
  </div>
</div>

<div *ngIf="feedbackCompleted === true" class="jumbotron jumbotron-fluid">
  <div class="container">
    <div class="row" *ngIf="authService.currentUserValue && this.scores">
      <div class="col-sm-8" name="introduction">
        <br>
        <br>
        <h2 class="h2" *ngIf="authService.currentUserValue && feedbackStage === 1">De eerste oefenreeks zit erop!</h2>
        <h2 class="h2" *ngIf="authService.currentUserValue && feedbackStage === 2">De tweede oefenreeks zit erop!</h2>
        <h2 class="h2" *ngIf="authService.currentUserValue && feedbackStage === 3">De derde oefenreeks zit erop!</h2>
        <h2 class="h5" *ngIf="authService.currentUserValue">Je behaalde in totaal {{scoresum()}}/5</h2>
        <br>
        <p *ngIf="authService.currentUserValue && (scoresum() >= 3)">Dat is een zeer goede score, goed gedaan! <b>Jij bent helemaal klaar om te beginnen aan de echte artikels! Bekijk zeker nog eens de feedback hieronder, zodat je weet wat je eventueel nog fout deed.</b> Nadien klik je op "Ik heb alles begrepen", en word je geleid naar de echte artikels.</p>
        <p *ngIf="authService.currentUserValue && scoresum() < 3 && feedbackStage === 3">Ziezo, je hebt nu genoeg geoefend! <b>Bekijk zeker nog eens de feedback hieronder, zodat je weet wat je eventueel nog fout deed.</b> Nadien klik je op "Ik heb alles begrepen". <b>Vervolgens kan je beginnen aan de echte artikels!</b></p>
        <p *ngIf="authService.currentUserValue && (scoresum() >= 2 && scoresum() < 3 && feedbackStage !== 3)">Jammer, je haalde net geen 3/5! Maar je bent er bijna, dus goed bezig! We gaan dat nog even extra inoefenen. <b>Bekijk hieronder wat je precies fout deed</b>, en klik vervolgens onderaan op deze pagina op "Ik heb alles begrepen". <b>
          <br>Je wordt dan naar een nieuwe reeks van 5 oefeningen geleid.</b></p>
        <p *ngIf="authService.currentUserValue && (scoresum() < 2 && feedbackStage !== 3)">Oei, het liep precies nog niet zo goed! Maar dat is helemaal niet erg. We zouden graag hebben dat je 3/5 haalt op een oefeningenreeks.  <b>Kijk daarom hieronder zeker goed naar wat je precies fout deed</b>, en klik vervolgens onderaan op deze pagina op "Ik heb alles begrepen". <b>
          <br>Je wordt dan naar een nieuwe reeks van 5 oefeningen geleid.</b> Het zal vast al veel beter gaan!</p>

        <h5 class="h5" *ngIf="oldArticle && newArticle && authService.currentUserValue">Resterende aanpassingen in onderstaand artikel:
          {{titlepositions.length + intropositions.length + textpositions.length}}</h5>

      </div>
      <div class="col-sm-4" name="leftUpdates">
        <img src="assets/success.png" *ngIf="authService.currentUserValue && (scoresum() >= 3 || feedbackStage === 3)" width="200" height="433">
        <img src="assets/almost.png" *ngIf="authService.currentUserValue && scoresum() >= 2 && scoresum () < 3 && feedbackStage != 3" width="200" height="433">
        <img src="assets/failure.png" *ngIf="authService.currentUserValue && scoresum () < 2 && feedbackStage !== 3" width="200" height="433">
      </div>
    </div>
  </div>
</div>

<p class="introduction" *ngIf="oldArticle && newArticle && authService.currentUserValue && feedbackCompleted===false">
  Onderstaand artikel werd geschreven door {{getNewspaper(oldArticle.newspaper)}} en kan gevonden worden op
  <a href="{{oldArticle.url}}" target="_blank">
    {{oldArticle.url}}
  </a>.
</p>
<button *ngIf="oldArticle && newArticle && authService.currentUserValue" type="button" (click)="clickReloadButton()" style="margin:5px;" class="btn btn-primary">Herbegin huidig artikel</button>
<p style="text-align:center;font-size:18pt;color:#1E64C8;"><b>Klik <a href="assets/flowchart.png" target="_blank">HIER</a> voor een handig overzicht van welk type je in welke situatie moet kiezen.</b></p>
<p class="introduction" *ngIf="(!oldArticle || !newArticle) && authService.currentUserValue && (feedbackCompleted === false) && feedbackStage <= 3 && hasFeedbackCompleted === false">Nieuwe artikels worden geladen...</p>

<table class="table" width="1500" *ngIf="oldArticle && newArticle && authService.currentUserValue && (feedbackCompleted === false)">
  <thead>
  <tr>
    <th id="oldarticle-title" >
      <span class="versionNumber">Versie {{oldArticle.versionNumber}}</span>
      <ng-container *ngFor="let title_part of oldArticle.titleParts; let i = index;">
        <ng-container *ngFor="let title_sentence of title_part; let j = index;">
          <div class="oldtitle" *ngIf="titlepositions && firstUpdateSentence(titlepositions, i, j, 'original') && startmessageSelected(title_sentence, titlepositions, 'original')">
            <div style="display:inline;">
              <div style="display:inline;" *ngIf="(titlepositions && getCorrespondingPosition(titlepositions, i, j, 'original').oldpartsSelected === true) || (titlepositions && firstUpdateSentence(titlepositions, i, j, 'original') && getCorrespondingPosition(titlepositions, i, j, 'original').categorizationSelected)">
                <button class="btn btn-primary" style="margin-left:10px;margin-top:10px;margin-right: 10px;font-size: 12px;" (click)="previous(title_sentence, titlepositions, 'original')"  *ngIf="((getCorrespondingPosition(titlepositions, i, j, 'original').categorizations.originalChangedParts.length > 0) || (getCorrespondingPosition(titlepositions, i, j, 'original').categorizations.newChangedParts.length > 0))===false && getCorrespondingPosition(titlepositions, i, j, 'original').categorizationSelected===false" disabled>Ongedaan maken</button>
                <button class="btn btn-primary" style="margin-left:10px;margin-top:10px;margin-right: 10px;font-size: 12px;" (click)="previous(title_sentence, titlepositions, 'original')"  *ngIf="((getCorrespondingPosition(titlepositions, i, j, 'original').categorizations.originalChangedParts.length > 0) || (getCorrespondingPosition(titlepositions, i, j, 'original').categorizations.newChangedParts.length > 0)) && getCorrespondingPosition(titlepositions, i, j, 'original').categorizationSelected===false">Ongedaan maken</button>
                <button class="btn btn-primary" style="margin-left:10px;margin-top:10px;margin-right: 10px;font-size: 12px;" (click)="previousCategory(title_sentence, titlepositions, 'original')"  *ngIf="titlepositions && firstUpdateSentence(titlepositions, i, j, 'original') && getCorrespondingPosition(titlepositions, i, j, 'original').categorizationSelected">Ongedaan maken</button>
              </div>
              <button style="display:inline;" *ngIf="titlepositions && (getCorrespondingPosition(titlepositions, i, j, 'original').oldpartsSelected === true || (firstUpdateSentence(titlepositions, i, j, 'original') && getCorrespondingPosition(titlepositions, i, j, 'original').categorizationSelected))" style="color:white;padding-right:2px;" (click)="close(getCorrespondingPosition(titlepositions, i, j, 'original'))" type="button" class="close" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <br>
            <br>
            <div *ngIf="titlepositions && (getCorrespondingPosition(titlepositions, i, j, 'original').warningSelectAtLeastOne === true)" style="padding-left:60px;border:4px white solid;margin:10px;">
              <img src="assets/warning.png" style="float:left;" width="30" height="30">
              <p>Je moet op zijn minst 1 tekstblok aanduiden om te kunnen categoriseren!<p>
            </div>
            <div class="oldtitle-startmessage" *ngIf="titlepositions && isPositionSelected(getCorrespondingPosition(titlepositions, i, j, 'original')) && (getCorrespondingPosition(titlepositions, i, j, 'original').otherPositionsSelected === false)">
              <p>Klik op 'Start' om de beoordeling van onderstaand tekstfragment te starten.<p>
              <button class="btn btn-primary" (click)="positionSelected(title_sentence, titlepositions, 'original')">Start</button>
            </div>
            <div *ngIf="titlepositions && getCorrespondingPosition(titlepositions, i, j, 'original').oldpartsSelected === true">
              <p>Welke van de donkerrode tekstblokken behoren tot eenzelfde aanpassing? Klik ze aan! <br>Klik vervolgens op 'Volgende'. <br><br>Foutje gemaakt? Klik op 'Ongedaan maken' om terug te gaan.<p>
              <button class="btn btn-primary" (click)="partsSelected(title_sentence, titlepositions, 'original')">Volgende</button>
            </div>
            <form #f="ngForm" class="row justify-content-center" *ngIf="titlepositions && firstUpdateSentence(titlepositions, i, j, 'original') && getCorrespondingPosition(titlepositions, i, j, 'original').categorizationSelected" (ngSubmit)="submit(getCorrespondingPosition(titlepositions, i, j, 'original'))">
              <p class="center-align"><b>Hoe zou je deze aanpassing categoriseren (meerdere opties mogelijk)?</b></p>
              <br/>
              <div *ngIf="getCorrespondingPosition(titlepositions, i, j, 'original').warningAnders || getCorrespondingPosition(titlepositions, i, j, 'original').warning || getCorrespondingPosition(titlepositions, i, j, 'original').failed" style="padding-left:60px;border:4px white solid;margin:10px;">
                <img src="assets/warning.png" style="float:left;" width="30" height="30">
                <p type="warningAnders" *ngIf="getCorrespondingPosition(titlepositions, i, j, 'original').warningAnders"> Vul tekstvak "Andere" in!</p>
              <p type="warning" *ngIf="getCorrespondingPosition(titlepositions, i, j, 'original').warning">Minstens 1 optie moet worden aangeduid/ingevuld!</p>
              <p type="failed" *ngIf="getCorrespondingPosition(titlepositions, i, j, 'original').failed"> Mislukt. Probeer opnieuw aub!</p>
              </div>
                <div class="form-row w-100">
                <div class="col-sm-6">
                  <input class="checkbox-inline" type="checkbox" id="update1-oldtitle" [(ngModel)]="getLastCategorizationOption(titlepositions, i, j, 'original').subjectief" name="update1-oldtitle" value="subjectief">&nbsp;
                  <label for="update1-oldtitle">  Correctie van subjectieve fout</label>
                  <div>
                    <img src="assets/questionmark.png" width="15" height="15">
                    <span class="tooltiptext">Aanpassing waarbij een subjectieve fout wordt gecorrigeerd. Hieronder vallen volgende types fouten: niet-representatieve (tussen)titels, gesensationaliseerde informatie, te ingetogen informatie, afwezigheid van essentiële informatie, misleidende cijfers, of uit de context getrokken citaten.</span>
                  </div>
                  <br>
                </div>
                <div class="col-sm-6">
                  <input class="checkbox-inline" type="checkbox" id="update3-oldtitle" [(ngModel)]="getLastCategorizationOption(titlepositions, i, j, 'original').objectief" name="update3-oldtitle" value="objectief">&nbsp;
                  <label for="update3-oldtitle">  Correctie van objectieve fout</label>
                  <div>
                    <img src="assets/questionmark.png" width="15" height="15">
                    <span class="tooltiptext">Aanpassing waarbij een objectieve fout wordt gecorrigeerd. Hieronder vallen volgende types fouten: foutieve namen, leeftijden, adressen, aantallen, tijdstippen, data, locaties of foutieve citaties.</span>
                  </div>
                  <br>
                </div>
              </div>
              <div class="form-row w-100">
                <div class="col-sm-6">
                  <input class="checkbox-inline" type="checkbox" id="update5-oldtitle" [(ngModel)]="getLastCategorizationOption(titlepositions, i, j, 'original').toevoeging" name="update5-oldtitle" value="toevoeging">&nbsp;
                  <label for="update5-oldtitle" style="font-size:9.5pt;">  Toevoeging/verduidelijking van informatie</label>
                  <div>
                    <img src="assets/questionmark.png" width="15" height="15">
                    <span class="tooltiptext">Aanpassing waarbij informatie wordt toegevoegd aan het artikel. Hieronder valt zowel de toevoeging van informatie die nog niet gekend was bij de publicatie van de originele versie, de toevoeging van reeds gekende informatie die niet essentieel was voor een correcte weergave van de inhoud van het artikel, én de toevoeging van informatie ter verduidelijking van het oorspronkelijke artikel. Ook herschrijven van titels en/of intro's, waarbij de nieuwe informatie prominent zichtbaar wordt gemaakt, valt hieronder.</span>
                  </div><br>
                </div>
                <div class="col-sm-6">
                  <input class="checkbox-inline" type="checkbox" id="update6-oldtitle" [(ngModel)]="getLastCategorizationOption(titlepositions, i, j, 'original').verwijdering" name="update6-oldtitle" value="verwijdering">&nbsp;
                  <label for="update6-oldtitle">  Verwijdering van informatie</label>
                  <div>
                    <img src="assets/questionmark.png" width="15" height="15">
                    <span class="tooltiptext">Aanpassing waarbij informatie, die op het moment van de originele publicatie niet fout was, verwijderd wordt omdat ze verouderd is, of om eender welke andere reden.</span>
                  </div>
                </div>
              </div>
              <div class="form-row w-100">
                <div class="col-sm-6">
                  <input class="checkbox-inline" type="checkbox" id="update4-oldtitle" [(ngModel)]="getLastCategorizationOption(titlepositions, i, j, 'original').herformulering" name="update4-oldtitle" value="herformulering">&nbsp;
                  <label for="update4-oldtitle">  Inhoudsneutrale aanpassing</label>
                  <div>
                    <img src="assets/questionmark.png" width="15" height="15">
                    <span class="tooltiptext">Aanpassing waarbij woorden/zinnen worden toegevoegd, verwijderd of herschreven, zonder dat hierbij nieuwe informatie wordt toegevoegd, oude informatie wordt verwijderd, fouten worden gecorrigeerd of een andere toon wordt gelegd.</span>
                  </div><br>
                </div>
                <div class="col-sm-6">
                  <input class="checkbox-inline" type="checkbox" id="update9-oldtitle" [(ngModel)]="getLastCategorizationOption(titlepositions, i, j, 'original').update" name="update9-oldtitle" value="update">&nbsp;
                  <label for="update9-oldtitle"> Update van oude informatie</label>
                  <div>
                    <img src="assets/questionmark.png" width="15" height="15">
                    <span class="tooltiptext">Aanpassing waarbij de oude informatie wordt vervangen door nieuwe, geüpdate informatie. Dit impliceert zowel verwijdering van oude informatie als toevoeging van nieuwe informatie.</span>
                  </div><br>
                </div>
              </div>
              <div class="form-row w-100">
                <div class="col-sm-6">
                  <input class="checkbox-inline" type="checkbox" id="update10-oldtitle" [(ngModel)]="getLastCategorizationOption(titlepositions, i, j, 'original').verplaatsing" name="update10-oldtitle" value="verplaatsing">&nbsp;
                  <label for="update10-oldtitle"> Verplaatsing van identieke informatie</label>
                  <div>
                    <img src="assets/questionmark.png" width="15" height="15">
                    <span class="tooltiptext">Aanpassing waarbij tekst exact gekopieerd wordt naar een andere positie in het artikel.</span>
                  </div><br>
                </div>
                <div class="col-sm-6">
                  <input class="checkbox-inline" type="checkbox" id="update7-oldtitle" [(ngModel)]="getLastCategorizationOption(titlepositions, i, j, 'original').spelfout" name="update7-oldtitle" value="spelfout">&nbsp;
                  <label for="update7-oldtitle">Correctie van spel- of taalfout </label>
                  <div>
                    <img src="assets/questionmark.png" style="margin-right:1em" width="15" height="15">
                    <span class="tooltiptext">Aanpassing waarbij een spelfout of grammaticale fout (bv. foutieve zinsconstructie) wordt gecorrigeerd. Onder spelfouten vallen alle fouten waarbij een woord/naam anders wordt geschreven, maar fonetisch wel nog hetzelfde klinkt. </span>
                  </div>
                  </div>
              </div>
              <div class="form-row w-100">
                <div class="col-sm-6">
                  <input class="checkbox-inline" type="checkbox" id="update11-oldtitle" [(ngModel)]="getLastCategorizationOption(titlepositions, i, j, 'original').anders" name="update11-oldtitle" value="anders">&nbsp;
                  <label for="update11-oldtitle" style="font-size:10.5pt;">Andere </label>
                  <div>
                    <img src="assets/questionmark.png" style="margin-right:1em" width="15" height="15">
                    <span class="tooltiptext">Elk type aanpassing dat niet in één van de andere categorieën past.</span>
                  </div>
                  <input type="text" [disabled]="!getLastCategorizationOption(titlepositions, i, j, 'original').anders" id="update12-oldtitle" [(ngModel)]="getLastCategorizationOption(titlepositions, i, j, 'original').text" name="update12-oldtitle" value="text">
                </div>
              </div>
              <br>
              <br>
              <div class="text-center">
                <label for="explanation-oldtitle"> Opmerkingen: </label>
                <textarea name="explanation-oldtitle" class="form-control" id="explanation-oldtitle" [(ngModel)]="getLastCategorizationOption(titlepositions, i, j, 'original').remarks"></textarea>
              </div>
              <div class="col-12 text-center">
                <br>
                <input class="btn btn-primary" type="submit" value="Verzend">
              </div>
              <br>
            </form>
          </div>
          <br>
          <span class="sentence">
            <ng-container *ngFor="let title_char of title_sentence.split(''); let k = index;">
            <span style="text-align:center;" [class.removedSentence]="titlepositions && sentenceInUpdate(titlepositions, i, j, 'original') && contentInSentence(title_sentence)" [class.removedChar]="titlepositions && charInUpdate(titlepositions, i, j, k, 'original') && contentInSentence(title_sentence)" (click)="clickCharacter(titlepositions, i, j, k, 'original')">{{title_char}}</span>
          </ng-container>
            <span style="text-align:center;" [class.removedSentence]="titlepositions && sentenceInUpdate(titlepositions, i, j,'original') && contentInSentence(title_sentence)" [class.removedChar]="titlepositions && charInUpdate(titlepositions, i, j, title_sentence.length - 1, 'original') && contentInSentence(title_sentence)" (click)="clickCharacter(titlepositions, i, j, title_sentence.length - 1, 'original')">&nbsp;</span>
            </span>
        </ng-container>
      </ng-container>
      <span class="onlineFrom" style="text-align:center;">Geraadpleegd op {{getPublicationTime(oldArticle.onlineFrom)}} <br> <br></span>
    </th>
    <th id="newarticle-title">
      <span class="versionNumber">Versie {{newArticle.versionNumber}}</span>
      <ng-container *ngFor="let title_part of newArticle.titleParts; let i = index;">
        <ng-container *ngFor="let title_sentence of title_part; let j = index;">
          <div class="newtitle" *ngIf="titlepositions && firstUpdateSentence(titlepositions, i, j, 'new') && startmessageSelected(title_sentence, titlepositions, 'new')">
            <div style="display:inline;">
              <div style="display:inline;" *ngIf="(titlepositions && getCorrespondingPosition(titlepositions, i, j, 'new').newpartsSelected === true)  || (titlepositions && firstUpdateSentence(titlepositions, i, j, 'new') && getCorrespondingPosition(titlepositions, i, j, 'new').categorizationSelected)">
                <button class="btn btn-primary" style="margin-left:10px;margin-top:10px;margin-right: 10px;font-size: 12px;" (click)="previous(title_sentence, titlepositions, 'new')" *ngIf="((getCorrespondingPosition(titlepositions, i, j, 'new').categorizations.originalChangedParts.length > 0) || (getCorrespondingPosition(titlepositions, i, j, 'new').categorizations.newChangedParts.length > 0) || (getCorrespondingPosition(titlepositions, i, j, 'new').newpartsSelected===true && getCorrespondingPosition(titlepositions, i, j, 'new').newpartsSelected===true  && originalPartsExist(title_sentence, titlepositions)===true))===false && getCorrespondingPosition(titlepositions, i, j, 'new').categorizationSelected===false" disabled>Ongedaan maken</button>
                <button class="btn btn-primary" style="margin-left:10px;margin-top:10px;margin-right: 10px;font-size: 12px;" (click)="previous(title_sentence, titlepositions, 'new')" *ngIf="((getCorrespondingPosition(titlepositions, i, j, 'new').categorizations.originalChangedParts.length > 0) || (getCorrespondingPosition(titlepositions, i, j, 'new').categorizations.newChangedParts.length > 0) || (getCorrespondingPosition(titlepositions, i, j, 'new').newpartsSelected===true && getCorrespondingPosition(titlepositions, i, j, 'new').newpartsSelected===true  && originalPartsExist(title_sentence, titlepositions)===true)) && getCorrespondingPosition(titlepositions, i, j, 'new').categorizationSelected===false">Ongedaan maken</button>
                <button class="btn btn-primary" style="margin-left:10px;margin-top:10px;margin-right: 10px;font-size: 12px;" (click)="previousCategory(title_sentence, titlepositions, 'new')"  *ngIf="titlepositions && firstUpdateSentence(titlepositions, i, j, 'new') && getCorrespondingPosition(titlepositions, i, j, 'new').categorizationSelected">Ongedaan maken</button>
              </div>
              <button *ngIf="titlepositions && (getCorrespondingPosition(titlepositions, i, j, 'new').newpartsSelected === true || (firstUpdateSentence(titlepositions, i, j, 'new') && getCorrespondingPosition(titlepositions, i, j, 'new').categorizationSelected))" (click)="close(getCorrespondingPosition(titlepositions, i, j, 'new'))" style="color:white;padding-right:2px;" (click)="close(getCorrespondingPosition(titlepositions, i, j, 'new'))" type="button" class="close" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <br>
            <br>
            <div *ngIf="titlepositions && (getCorrespondingPosition(titlepositions, i, j, 'new').warningSelectAtLeastOne === true)" style="padding-left:60px;border:4px white solid;margin:10px;">
              <img src="assets/warning.png" style="float:left;" width="30" height="30">
              <p>Je moet op zijn minst 1 tekstblok aanduiden om te kunnen categoriseren!<p>
            </div>
            <div class="newtitle-startmessage" *ngIf="titlepositions && isPositionSelected(getCorrespondingPosition(titlepositions, i, j, 'new')) && (getCorrespondingPosition(titlepositions, i, j, 'new').otherPositionsSelected === false)">
              <p>Klik op 'Start' om de beoordeling van onderstaand tekstfragment te starten.<p>
              <button class="btn btn-primary"(click)="positionSelected(title_sentence, titlepositions, 'new')">Start</button>
            </div>
            <div *ngIf="titlepositions && getCorrespondingPosition(titlepositions, i, j, 'new').newpartsSelected === true">
              <p>Welke van de donkergroene tekstblokken behoren tot eenzelfde aanpassing? Klik ze aan! <br>Klik vervolgens op 'Volgende'. <br><br>Foutje gemaakt? Klik op 'Ongedaan maken' om terug te gaan.<p>
              <button class="btn btn-primary" (click)="partsSelected(title_sentence, titlepositions, 'new')">Volgende</button>
            </div>
            <form #f="ngForm" class="row justify-content-center" *ngIf="titlepositions && firstUpdateSentence(titlepositions, i, j, 'new') && getCorrespondingPosition(titlepositions, i, j, 'new').categorizationSelected" (ngSubmit)="submit(getCorrespondingPosition(titlepositions, i, j, 'new'))">
              <p class="center-align"><b>Hoe zou je deze aanpassing categoriseren (meerdere opties mogelijk)?</b></p>
              <br>
              <div *ngIf="getCorrespondingPosition(titlepositions, i, j, 'new').warningAnders || getCorrespondingPosition(titlepositions, i, j, 'new').warning || getCorrespondingPosition(titlepositions, i, j, 'new').failed" style="padding-left:60px;border:4px white solid;margin:10px;">
                <img src="assets/warning.png" style="float:left;" width="30" height="30">
                <p type="warningAnders" *ngIf="getCorrespondingPosition(titlepositions, i, j, 'new').warningAnders"> Vul tekstvak "Andere" in!</p>
              <p type="warning" *ngIf="getCorrespondingPosition(titlepositions, i, j, 'new').warning">Minstens 1 optie moet worden aangeduid/ingevuld!</p>
              <p type="failed" *ngIf="getCorrespondingPosition(titlepositions, i, j, 'new').failed"> Mislukt. Probeer opnieuw aub!</p>
              </div>
                <div class="form-row w-100">
                <div class="col-sm-6">
                  <input class="checkbox-inline" type="checkbox" id="update1-newtitle" [(ngModel)]="getLastCategorizationOption(titlepositions, i, j, 'new').subjectief" name="update1-newtitle" value="subjectief">&nbsp;
                  <label for="update1-newtitle"> Correctie van subjectieve fout</label>
                  <div>
                    <img src="assets/questionmark.png" width="15" height="15">
                    <span class="tooltiptext">Aanpassing waarbij een subjectieve fout wordt gecorrigeerd. Hieronder vallen volgende types fouten: niet-representatieve (tussen)titels, gesensationaliseerde informatie, te ingetogen informatie, afwezigheid van essentiële informatie, misleidende cijfers, of uit de context getrokken citaten.</span>
                  </div>
                  <br>
                </div>
                <div class="col-sm-6">
                  <input class="checkbox-inline" type="checkbox" id="update2-newtitle" [(ngModel)]="getLastCategorizationOption(titlepositions, i, j, 'new').objectief" name="update2-newtitle" value="objectief">&nbsp;
                  <label for="update2-newtitle">  Correctie van objectieve fout</label>
                  <div>
                    <img src="assets/questionmark.png" width="15" height="15">
                    <span class="tooltiptext">Aanpassing waarbij een objectieve fout wordt gecorrigeerd. Hieronder vallen volgende types fouten: foutieve namen, leeftijden, adressen, aantallen, tijdstippen, data, locaties of foutieve citaties.</span>
                  </div>
                  <br>
                </div>
              </div>
              <div class="form-row w-100">
                <div class="col-sm-6">
                  <input class="checkbox-inline" type="checkbox" id="update5-newtitle" [(ngModel)]="getLastCategorizationOption(titlepositions, i, j, 'new').toevoeging" name="update5-newtitle" value="toevoeging">&nbsp;
                  <label for="update5-newtitle" style="font-size:9.5pt;">  Toevoeging/verduidelijking van informatie</label>
                  <div>
                    <img src="assets/questionmark.png" width="15" height="15">
                    <span class="tooltiptext">Aanpassing waarbij informatie wordt toegevoegd aan het artikel. Hieronder valt zowel de toevoeging van informatie die nog niet gekend was bij de publicatie van de originele versie, de toevoeging van reeds gekende informatie die niet essentieel was voor een correcte weergave van de inhoud van het artikel, én de toevoeging van informatie ter verduidelijking van het oorspronkelijke artikel. Ook herschrijven van titels en/of intro's, waarbij de nieuwe informatie prominent zichtbaar wordt gemaakt, valt hieronder.</span>
                  </div><br>
                </div>
                <div class="col-sm-6">
                  <input class="checkbox-inline" type="checkbox" id="update6-newtitle" [(ngModel)]="getLastCategorizationOption(titlepositions, i, j, 'new').verwijdering" name="update6-newtitle" value="verwijdering">&nbsp;
                  <label for="update6-newtitle">  Verwijdering van informatie</label>
                  <div>
                    <img src="assets/questionmark.png" width="15" height="15">
                    <span class="tooltiptext">Aanpassing waarbij informatie, die op het moment van de originele publicatie niet fout was, verwijderd wordt omdat ze verouderd is, of om eender welke andere reden.</span>
                  </div>
                </div>
              </div>
              <div class="form-row w-100">
                <div class="col-sm-6">
                  <input class="checkbox-inline" type="checkbox" id="update4-newtitle" [(ngModel)]="getLastCategorizationOption(titlepositions, i, j, 'new').herformulering" name="update4-newtitle" value="herformulering">&nbsp;
                  <label for="update4-newtitle">  Inhoudsneutrale aanpassing</label>
                  <div>
                    <img src="assets/questionmark.png" width="15" height="15">
                    <span class="tooltiptext">Aanpassing waarbij woorden/zinnen worden toegevoegd, verwijderd of herschreven, zonder dat hierbij nieuwe informatie wordt toegevoegd, oude informatie wordt verwijderd, fouten worden gecorrigeerd of een andere toon wordt gelegd.</span>
                  </div><br>
                </div>
                <div class="col-sm-6">
                  <input class="checkbox-inline" type="checkbox" id="update9-newtitle" [(ngModel)]="getLastCategorizationOption(titlepositions, i, j, 'new').update" name="update9-newtitle" value="update">&nbsp;
                  <label for="update9-newtitle"> Update van oude informatie</label>
                  <div>
                    <img src="assets/questionmark.png" width="15" height="15">
                    <span class="tooltiptext">Aanpassing waarbij de oude informatie wordt vervangen door nieuwe, geüpdate informatie. Dit impliceert zowel verwijdering van oude informatie als toevoeging van nieuwe informatie.</span>
                  </div><br>
                </div>
              </div>
              <div class="form-row w-100">
                <div class="col-sm-6">
                  <input class="checkbox-inline" type="checkbox" id="update10-newtitle" [(ngModel)]="getLastCategorizationOption(titlepositions, i, j, 'new').verplaatsing" name="update10-newtitle" value="verplaatsing">&nbsp;
                  <label for="update10-newtitle"> Verplaatsing van identieke informatie</label>
                  <div>
                    <img src="assets/questionmark.png" width="15" height="15">
                    <span class="tooltiptext">Aanpassing waarbij tekst exact gekopieerd wordt naar een andere positie in het artikel.</span>
                  </div><br>
                </div>
                <div class="col-sm-6">
                  <input class="checkbox-inline" type="checkbox" id="update7-newtitle" [(ngModel)]="getLastCategorizationOption(titlepositions, i, j, 'new').spelfout" name="update7-newtitle" value="spelfout">&nbsp;
                  <label for="update7-newtitle">Correctie van spel- of taalfout </label>
                  <div>
                    <img src="assets/questionmark.png" style="margin-right:1em" width="15" height="15">
                    <span class="tooltiptext">Aanpassing waarbij een spelfout of grammaticale fout (bv. foutieve zinsconstructie) wordt gecorrigeerd. Onder spelfouten vallen alle fouten waarbij een woord/naam anders wordt geschreven, maar fonetisch wel nog hetzelfde klinkt. </span>
                  </div>
                  </div>
              </div>
              <div class="form-row w-100">
                <div class="col-sm-6">
                  <input class="checkbox-inline" type="checkbox" id="update11-newtitle" [(ngModel)]="getLastCategorizationOption(titlepositions, i, j, 'new').anders" name="update11-newtitle" value="anders">&nbsp;
                  <label for="update11-oldtitle" style="font-size:10.5pt;">Andere </label>
                  <div>
                    <img src="assets/questionmark.png" style="margin-right:1em" width="15" height="15">
                    <span class="tooltiptext">Elk type aanpassing dat niet in één van de andere categorieën past.</span>
                  </div>
                  <input type="text" [disabled]="!getLastCategorizationOption(titlepositions, i, j, 'new').anders" id="update12-newtitle" [(ngModel)]="getLastCategorizationOption(titlepositions, i, j, 'new').text" name="update12-newtitle" value="text">
                </div>
              </div>
              <br>
              <br>
              <div class="text-center">
                <label for="explanation-newtitle"> Opmerkingen: </label>
                <textarea name="explanation-newtitle" class="form-control" id="explanation-newtitle" [(ngModel)]="getLastCategorizationOption(titlepositions, i, j, 'new').remarks"></textarea>
              </div>
              <div class="col-12 text-center">
                <br>
                <input class="btn btn-primary" type="submit" value="Verzend">
              </div>
              <br>
            </form>
          </div>
          <br>
          <span class="sentence">
            <ng-container *ngFor="let title_char of title_sentence.split(''); let k = index;">
            <span style="text-align:center;" [class.addedSentence]="titlepositions && sentenceInUpdate(titlepositions, i, j, 'new') && contentInSentence(title_sentence)" [class.addedChar]="titlepositions && charInUpdate(titlepositions, i, j, k, 'new') && contentInSentence(title_sentence)" (click)="clickCharacter(titlepositions, i, j, k, 'new')">{{title_char}}</span>
          </ng-container>
            <span style="text-align:center;" [class.addedSentence]="titlepositions && sentenceInUpdate(titlepositions, i, j, 'new') && contentInSentence(title_sentence)" [class.addedChar]="titlepositions && charInUpdate(titlepositions, i, j, title_sentence.length - 1, 'new') && contentInSentence(title_sentence)" (click)="clickCharacter(titlepositions, i, j, title_sentence.length - 1, 'new')">&nbsp;</span>
            </span>
        </ng-container>
      </ng-container>
      <span class="onlineFrom" style="text-align:center;">Gepubliceerd op {{getPublicationTime(newArticle.onlineFrom)}} <br> <br></span>
    </th>
  </tr>
  </thead>
  <tbody>
  <tr>
    <td id="oldarticle-intro">
      <ng-container *ngFor="let intro_part of oldArticle.introParts; let i = index;">
        <ng-container *ngFor="let intro_sentence of intro_part; let j = index;">
          <div class="oldintro" *ngIf="intropositions && firstUpdateSentence(intropositions, i, j, 'original') && startmessageSelected(intro_sentence, intropositions, 'original')">
          <div style="display:inline;">
            <div style="display:inline;" *ngIf="(intropositions && getCorrespondingPosition(intropositions, i, j, 'original').oldpartsSelected === true)  || (intropositions && firstUpdateSentence(intropositions, i, j, 'original') && getCorrespondingPosition(intropositions, i, j, 'original').categorizationSelected)">
              <button class="btn btn-primary" style="margin-left:10px;margin-top:10px;margin-right: 10px;font-size: 12px;" (click)="previous(intro_sentence, intropositions, 'original')"  *ngIf="((getCorrespondingPosition(intropositions, i, j, 'original').categorizations.originalChangedParts.length > 0) || (getCorrespondingPosition(intropositions, i, j, 'original').categorizations.newChangedParts.length > 0))===false && getCorrespondingPosition(intropositions, i, j, 'original').categorizationSelected===false" disabled>Ongedaan maken</button>
              <button class="btn btn-primary" style="margin-left:10px;margin-top:10px;margin-right: 10px;font-size: 12px;" (click)="previous(intro_sentence, intropositions, 'original')"  *ngIf="((getCorrespondingPosition(intropositions, i, j, 'original').categorizations.originalChangedParts.length > 0) || (getCorrespondingPosition(intropositions, i, j, 'original').categorizations.newChangedParts.length > 0)) && getCorrespondingPosition(intropositions, i, j, 'original').categorizationSelected===false">Ongedaan maken</button>
              <button class="btn btn-primary" style="margin-left:10px;margin-top:10px;margin-right: 10px;font-size: 12px;" (click)="previousCategory(intro_sentence, intropositions, 'original')"  *ngIf="intropositions && firstUpdateSentence(intropositions, i, j, 'original') && getCorrespondingPosition(intropositions, i, j, 'original').categorizationSelected">Ongedaan maken</button>
            </div>
            <button *ngIf="intropositions && (getCorrespondingPosition(intropositions, i, j, 'original').oldpartsSelected === true || (firstUpdateSentence(intropositions, i, j, 'original') && getCorrespondingPosition(intropositions, i, j, 'original').categorizationSelected))" style="color:white;padding-right:2px;" (click)="close(getCorrespondingPosition(intropositions, i, j, 'original'))" type="button" class="close" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <br>
          <br>
            <div *ngIf="intropositions && (getCorrespondingPosition(intropositions, i, j, 'original').warningSelectAtLeastOne === true)" style="padding-left:60px;border:4px white solid;margin:10px;">
              <img src="assets/warning.png" style="float:left;" width="30" height="30">
              <p>Je moet op zijn minst 1 tekstblok aanduiden om te kunnen categoriseren!<p>
            </div>
            <div class="oldintro-startmessage" *ngIf="intropositions && isPositionSelected(getCorrespondingPosition(intropositions, i, j, 'original')) && (getCorrespondingPosition(intropositions, i, j, 'original').otherPositionsSelected === false)">
              <p>Klik op 'Start' om de beoordeling van onderstaand tekstfragment te starten.<p>
              <button class="btn btn-primary" (click)="positionSelected(intro_sentence, intropositions, 'original')">Start</button>
            </div>
            <div *ngIf="intropositions && getCorrespondingPosition(intropositions, i, j, 'original').oldpartsSelected === true">
              <p>Welke van de donkerrode tekstblokken behoren tot eenzelfde aanpassing? Klik ze aan! <br>Klik vervolgens op 'Volgende'. <br><br>Foutje gemaakt? Klik op 'Ongedaan maken' om terug te gaan.<p>
              <button class="btn btn-primary" (click)="partsSelected(intro_sentence, intropositions, 'original')">Volgende</button>
            </div>
            <form #f="ngForm" class="row justify-content-center" *ngIf="intropositions && firstUpdateSentence(intropositions, i, j, 'original') && getCorrespondingPosition(intropositions, i, j, 'original').categorizationSelected" (ngSubmit)="submit(getCorrespondingPosition(intropositions, i, j, 'original'))">
              <p class="center-align"><b>Hoe zou je deze aanpassing categoriseren (meerdere opties mogelijk)?</b></p>
              <br>
              <div *ngIf="getCorrespondingPosition(intropositions, i, j, 'original').warningAnders || getCorrespondingPosition(intropositions, i, j, 'original').warning || getCorrespondingPosition(intropositions, i, j, 'original').failed" style="padding-left:60px;border:4px white solid;margin:10px;">
                <img src="assets/warning.png" style="float:left;" width="30" height="30">
                <p type="warningAnders" *ngIf="getCorrespondingPosition(intropositions, i, j, 'original').warningAnders"> Vul tekstvak "Andere" in!</p>
              <p type="warning" *ngIf="getCorrespondingPosition(intropositions, i, j, 'original').warning">Minstens 1 optie moet worden aangeduid/ingevuld!</p>
              <p type="failed" *ngIf="getCorrespondingPosition(intropositions, i, j, 'original').failed"> Mislukt. Probeer opnieuw aub!</p>
              </div>
                <div class="form-row w-100">
                <div class="col-sm-6">
                  <input class="checkbox-inline" type="checkbox" id="update1-oldintro" [(ngModel)]="getLastCategorizationOption(intropositions, i, j, 'original').subjectief" name="update1-oldintro" value="subjectief">&nbsp;
                  <label for="update1-oldintro">  Correctie van subjectieve fout</label>
                  <div>
                    <img src="assets/questionmark.png" width="15" height="15">
                    <span class="tooltiptext">Aanpassing waarbij een subjectieve fout wordt gecorrigeerd. Hieronder vallen volgende types fouten: niet-representatieve (tussen)titels, gesensationaliseerde informatie, te ingetogen informatie, afwezigheid van essentiële informatie, misleidende cijfers, of uit de context getrokken citaten.</span>
                  </div>
                  <br>
                </div>
                <div class="col-sm-6">
                  <input class="checkbox-inline" type="checkbox" id="update2-oldintro" [(ngModel)]="getLastCategorizationOption(intropositions, i, j, 'original').objectief" name="update2-oldintro" value="objectief">&nbsp;
                  <label for="update2-oldintro">  Correctie van objectieve fout</label>
                  <div>
                    <img src="assets/questionmark.png" width="15" height="15">
                    <span class="tooltiptext">Aanpassing waarbij een objectieve fout wordt gecorrigeerd. Hieronder vallen volgende types fouten: foutieve namen, leeftijden, adressen, aantallen, tijdstippen, data, locaties of foutieve citaties.</span>
                  </div>
                  <br>
                </div>
              </div>
              <div class="form-row w-100">
                <div class="col-sm-6">
                  <input class="checkbox-inline" type="checkbox" id="update5-oldintro" [(ngModel)]="getLastCategorizationOption(intropositions, i, j, 'original').toevoeging" name="update5-oldintro" value="toevoeging">&nbsp;
                  <label for="update5-oldintro" style="font-size:9.5pt;">  Toevoeging/verduidelijking van informatie</label>
                  <div>
                    <img src="assets/questionmark.png" width="15" height="15">
                    <span class="tooltiptext">Aanpassing waarbij informatie wordt toegevoegd aan het artikel. Hieronder valt zowel de toevoeging van informatie die nog niet gekend was bij de publicatie van de originele versie, de toevoeging van reeds gekende informatie die niet essentieel was voor een correcte weergave van de inhoud van het artikel, én de toevoeging van informatie ter verduidelijking van het oorspronkelijke artikel. Ook herschrijven van titels en/of intro's, waarbij de nieuwe informatie prominent zichtbaar wordt gemaakt, valt hieronder.</span>
                  </div><br>
                </div>
                <div class="col-sm-6">
                  <input class="checkbox-inline" type="checkbox" id="update6-oldintro" [(ngModel)]="getLastCategorizationOption(intropositions, i, j, 'original').verwijdering" name="update6-oldintro" value="verwijdering">&nbsp;
                  <label for="update6-oldintro">  Verwijdering van informatie</label>
                  <div>
                    <img src="assets/questionmark.png" width="15" height="15">
                    <span class="tooltiptext">Aanpassing waarbij informatie, die op het moment van de originele publicatie niet fout was, verwijderd wordt omdat ze verouderd is, of om eender welke andere reden.</span>
                  </div>
                </div>
              </div>
              <div class="form-row w-100">
                <div class="col-sm-6">
                  <input class="checkbox-inline" type="checkbox" id="update4-oldintro" [(ngModel)]="getLastCategorizationOption(intropositions, i, j, 'original').herformulering" name="update4-oldintro" value="herformulering">&nbsp;
                  <label for="update4-oldintro">  Inhoudsneutrale aanpassing</label>
                  <div>
                    <img src="assets/questionmark.png" width="15" height="15">
                    <span class="tooltiptext">Aanpassing waarbij woorden/zinnen worden toegevoegd, verwijderd of herschreven, zonder dat hierbij nieuwe informatie wordt toegevoegd, oude informatie wordt verwijderd, fouten worden gecorrigeerd of een andere toon wordt gelegd.</span>
                  </div><br>
                </div>
                <div class="col-sm-6">
                  <input class="checkbox-inline" type="checkbox" id="update9-oldintro" [(ngModel)]="getLastCategorizationOption(intropositions, i, j, 'original').update" name="update9-oldintro" value="update">&nbsp;
                  <label for="update9-oldintro"> Update van oude informatie</label>
                  <div>
                    <img src="assets/questionmark.png" width="15" height="15">
                    <span class="tooltiptext">Aanpassing waarbij de oude informatie wordt vervangen door nieuwe, geüpdate informatie. Dit impliceert zowel verwijdering van oude informatie als toevoeging van nieuwe informatie.</span>
                  </div><br>
                </div>
              </div>
              <div class="form-row w-100">
                <div class="col-sm-6">
                  <input class="checkbox-inline" type="checkbox" id="update10-oldintro" [(ngModel)]="getLastCategorizationOption(intropositions, i, j, 'original').verplaatsing" name="update10-oldintro" value="verplaatsing">&nbsp;
                  <label for="update10-oldintro"> Verplaatsing van identieke informatie</label>
                  <div>
                    <img src="assets/questionmark.png" width="15" height="15">
                    <span class="tooltiptext">Aanpassing waarbij tekst exact gekopieerd wordt naar een andere positie in het artikel.</span>
                  </div><br>
                </div>
                <div class="col-sm-6">
                  <input class="checkbox-inline" type="checkbox" id="update7-oldintro" [(ngModel)]="getLastCategorizationOption(intropositions, i, j, 'original').spelfout" name="update7-oldintro" value="spelfout">&nbsp;
                  <label for="update7-oldintro">Correctie van spel- of taalfout </label>
                  <div>
                    <img src="assets/questionmark.png" style="margin-right:1em" width="15" height="15">
                    <span class="tooltiptext">Aanpassing waarbij een spelfout of grammaticale fout (bv. foutieve zinsconstructie) wordt gecorrigeerd. Onder spelfouten vallen alle fouten waarbij een woord/naam anders wordt geschreven, maar fonetisch wel nog hetzelfde klinkt. </span>
                  </div>
                  </div>
              </div>
              <div class="form-row w-100">
                <div class="col-sm-6">
                  <input class="checkbox-inline" type="checkbox" id="update11-oldintro" [(ngModel)]="getLastCategorizationOption(intropositions, i, j, 'original').anders" name="update11-oldintro" value="anders">&nbsp;
                  <label for="update11-oldintro" style="font-size:10.5pt;">Andere </label>
                  <div>
                    <img src="assets/questionmark.png" style="margin-right:1em" width="15" height="15">
                    <span class="tooltiptext">Elk type aanpassing dat niet in één van de andere categorieën past.</span>
                  </div>
                  <input type="text" [disabled]="!getLastCategorizationOption(intropositions, i, j, 'original').anders" id="update12-oldintro" [(ngModel)]="getLastCategorizationOption(intropositions, i, j, 'original').text" name="update12-oldintro" value="text">
                </div>
              </div>
              <div class="text-center">
                <label for="explanation-oldintro"> Opmerkingen: </label>
                <textarea name="explanation-oldintro" class="form-control" id="explanation-oldintro" [(ngModel)]="getLastCategorizationOption(intropositions, i, j, 'original').remarks"></textarea>
              </div>
              <div class="col-12 text-center">
                <br>
                <input class="btn btn-primary" type="submit" value="Verzend">
              </div>
              <br>
            </form>
          </div>
          <br>
          <ng-container *ngFor="let intro_char of intro_sentence.split(''); let k = index;">
            <span [class.removedSentence]="intropositions && sentenceInUpdate(intropositions, i, j, 'original') && contentInSentence(intro_sentence)" [class.removedChar]="intropositions && charInUpdate(intropositions, i, j, k, 'original') && contentInSentence(intro_sentence)" (click)="clickCharacter(intropositions, i, j, k, 'original')"><b>{{intro_char}}</b></span>
          </ng-container>
          <span [class.removedSentence]="intropositions && sentenceInUpdate(intropositions, i, j, 'original') && contentInSentence(intro_sentence)" [class.removedChar]="intropositions && charInUpdate(intropositions, i, j, intro_sentence.length - 1, 'original') && contentInSentence(intro_sentence)" (click)="clickCharacter(intropositions, i, j, intro_sentence.length - 1, 'original')">&nbsp;</span>
        </ng-container>
        <br>
        <br>
      </ng-container>
    </td>
    <td id="newarticle-intro">
      <ng-container *ngFor="let intro_part of newArticle.introParts; let i = index;">
        <ng-container *ngFor="let intro_sentence of intro_part; let j = index;">
          <div class="newintro" *ngIf="intropositions && firstUpdateSentence(intropositions, i, j, 'new') && startmessageSelected(intro_sentence, intropositions, 'new')">
            <div style="display:inline;">
              <div style="display:inline;" *ngIf="(intropositions && getCorrespondingPosition(intropositions, i, j, 'new').newpartsSelected === true)  || (intropositions && firstUpdateSentence(intropositions, i, j, 'new') && getCorrespondingPosition(intropositions, i, j, 'new').categorizationSelected)">
                <button class="btn btn-primary" style="margin-left:10px;margin-top:10px;margin-right: 10px;font-size: 12px;" (click)="previous(intro_sentence, intropositions, 'new')"  *ngIf="((getCorrespondingPosition(intropositions, i, j, 'new').categorizations.originalChangedParts.length > 0) || (getCorrespondingPosition(intropositions, i, j, 'new').categorizations.newChangedParts.length > 0) || (getCorrespondingPosition(intropositions, i, j, 'new').newpartsSelected===true && originalPartsExist(intro_sentence, intropositions)===true))===false && getCorrespondingPosition(intropositions, i, j, 'new').categorizationSelected===false" disabled>Ongedaan maken</button>
                <button class="btn btn-primary" style="margin-left:10px;margin-top:10px;margin-right: 10px;font-size: 12px;" (click)="previous(intro_sentence, intropositions, 'new')"  *ngIf="((getCorrespondingPosition(intropositions, i, j, 'new').categorizations.originalChangedParts.length > 0) || (getCorrespondingPosition(intropositions, i, j, 'new').categorizations.newChangedParts.length > 0) || (getCorrespondingPosition(intropositions, i, j, 'new').newpartsSelected===true && originalPartsExist(intro_sentence, intropositions)===true)) && getCorrespondingPosition(intropositions, i, j, 'new').categorizationSelected===false">Ongedaan maken</button>
                <button class="btn btn-primary" style="margin-left:10px;margin-top:10px;margin-right: 10px;font-size: 12px;" (click)="previousCategory(intro_sentence, intropositions, 'new')"  *ngIf="intropositions && firstUpdateSentence(intropositions, i, j, 'new') && getCorrespondingPosition(intropositions, i, j, 'new').categorizationSelected">Ongedaan maken</button>
              </div>
              <button *ngIf="intropositions && (getCorrespondingPosition(intropositions, i, j, 'new').newpartsSelected === true || (firstUpdateSentence(intropositions, i, j, 'new') && getCorrespondingPosition(intropositions, i, j, 'new').categorizationSelected))" style="color:white;padding-right:2px;" (click)="close(getCorrespondingPosition(intropositions, i, j, 'new'))" type="button" class="close" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <br>
            <br>
            <div *ngIf="intropositions && (getCorrespondingPosition(intropositions, i, j, 'new').warningSelectAtLeastOne === true)" style="padding-left:60px;border:4px white solid;margin:10px;">
              <img src="assets/warning.png" style="float:left;" width="30" height="30">
              <p>Je moet op zijn minst 1 tekstblok aanduiden om te kunnen categoriseren!<p>
            </div>
            <div class="newintro-startmessage" *ngIf="intropositions && isPositionSelected(getCorrespondingPosition(intropositions, i, j, 'new')) && (getCorrespondingPosition(intropositions, i, j, 'new').otherPositionsSelected === false)">
              <p>Klik op 'Start' om de beoordeling van onderstaand tekstfragment te starten.<p>
              <button class="btn btn-primary" (click)="positionSelected(intro_sentence, intropositions, 'new')">Start</button>
            </div>
            <div *ngIf="intropositions && getCorrespondingPosition(intropositions, i, j, 'new').newpartsSelected === true">
              <p>Welke van de donkergroene tekstblokken behoren tot eenzelfde aanpassing? Klik ze aan! <br>Klik vervolgens op 'Volgende'. <br><br>Foutje gemaakt? Klik op 'Ongedaan maken' om terug te gaan.<p>
              <button class="btn btn-primary" (click)="partsSelected(intro_sentence, intropositions, 'new')">Volgende</button>
            </div>
            <form #f="ngForm" class="row justify-content-center" *ngIf="intropositions && firstUpdateSentence(intropositions, i, j, 'new') && getCorrespondingPosition(intropositions, i, j, 'new').categorizationSelected" (ngSubmit)="submit(getCorrespondingPosition(intropositions, i, j, 'new'))">
              <p class="center-align"><b>Hoe zou je deze aanpassing categoriseren (meerdere opties mogelijk)?</b></p>
              <div *ngIf="getCorrespondingPosition(intropositions, i, j, 'new').warningAnders || getCorrespondingPosition(intropositions, i, j, 'new').warning || getCorrespondingPosition(intropositions, i, j, 'new').failed" style="padding-left:60px;border:4px white solid;margin:10px;">
                <img src="assets/warning.png" style="float:left;" width="30" height="30">
                <p type="warning" *ngIf="getCorrespondingPosition(intropositions, i, j, 'new').warning">Minstens 1 optie moet worden aangeduid/ingevuld!</p>
              <p type="warningAnders" *ngIf="getCorrespondingPosition(intropositions, i, j, 'new').warningAnders"> Vul tekstvak "Andere" in!</p>
              <p type="failed" *ngIf="getCorrespondingPosition(intropositions, i, j, 'new').failed"> Mislukt. Probeer opnieuw aub!</p>
              </div>
                <br>
              <div class="form-row w-100">
                <div class="col-sm-6">
                  <input class="checkbox-inline" type="checkbox" id="update1-newintro" [(ngModel)]="getLastCategorizationOption(intropositions, i, j, 'new').subjectief" name="update1-newintro" value="subjectief">&nbsp;
                  <label for="update1-newintro">  Correctie van subjectieve fout</label>
                  <div>
                    <img src="assets/questionmark.png" width="15" height="15">
                    <span class="tooltiptext">Aanpassing waarbij een subjectieve fout wordt gecorrigeerd. Hieronder vallen volgende types fouten: niet-representatieve (tussen)titels, gesensationaliseerde informatie, te ingetogen informatie, afwezigheid van essentiële informatie, misleidende cijfers, of uit de context getrokken citaten.</span>
                  </div>
                  <br>
                </div>
                <div class="col-sm-6">
                  <input class="checkbox-inline" type="checkbox" id="update2-newintro" [(ngModel)]="getLastCategorizationOption(intropositions, i, j, 'new').objectief" name="update2-newintro" value="objectief">&nbsp;
                  <label for="update2-newintro">  Correctie van objectieve fout</label>
                  <div>
                    <img src="assets/questionmark.png" width="15" height="15">
                    <span class="tooltiptext">Aanpassing waarbij een objectieve fout wordt gecorrigeerd. Hieronder vallen volgende types fouten: foutieve namen, leeftijden, adressen, aantallen, tijdstippen, data, locaties of foutieve citaties.</span>
                  </div>
                  <br>
                </div>
              </div>
              <div class="form-row w-100">
                <div class="col-sm-6">
                  <input class="checkbox-inline" type="checkbox" id="update5-newintro" [(ngModel)]="getLastCategorizationOption(intropositions, i, j, 'new').toevoeging" name="update5-newintro" value="toevoeging">&nbsp;
                  <label for="update5-newintro" style="font-size:9.5pt;">  Toevoeging/verduidelijking van informatie</label>
                  <div>
                    <img src="assets/questionmark.png" width="15" height="15">
                    <span class="tooltiptext">Aanpassing waarbij informatie wordt toegevoegd aan het artikel. Hieronder valt zowel de toevoeging van informatie die nog niet gekend was bij de publicatie van de originele versie, de toevoeging van reeds gekende informatie die niet essentieel was voor een correcte weergave van de inhoud van het artikel, én de toevoeging van informatie ter verduidelijking van het oorspronkelijke artikel. Ook herschrijven van titels en/of intro's, waarbij de nieuwe informatie prominent zichtbaar wordt gemaakt, valt hieronder.</span>
                  </div><br>
                </div>
                <div class="col-sm-6">
                  <input class="checkbox-inline" type="checkbox" id="update6-newintro" [(ngModel)]="getLastCategorizationOption(intropositions, i, j, 'new').verwijdering" name="update6-newintro" value="verwijdering">&nbsp;
                  <label for="update6-newintro">  Verwijdering van informatie</label>
                  <div>
                    <img src="assets/questionmark.png" width="15" height="15">
                    <span class="tooltiptext">Aanpassing waarbij informatie, die op het moment van de originele publicatie niet fout was, verwijderd wordt omdat ze verouderd is, of om eender welke andere reden.</span>
                  </div>
                </div>
              </div>
              <div class="form-row w-100">
                <div class="col-sm-6">
                  <input class="checkbox-inline" type="checkbox" id="update4-newintro" [(ngModel)]="getLastCategorizationOption(intropositions, i, j, 'new').herformulering" name="update4-newintro" value="herformulering">&nbsp;
                  <label for="update4-newintro">  Inhoudsneutrale aanpassing</label>
                  <div>
                    <img src="assets/questionmark.png" width="15" height="15">
                    <span class="tooltiptext">Aanpassing waarbij woorden/zinnen worden toegevoegd, verwijderd of herschreven, zonder dat hierbij nieuwe informatie wordt toegevoegd, oude informatie wordt verwijderd, fouten worden gecorrigeerd of een andere toon wordt gelegd.</span>
                  </div><br>
                </div>
                <div class="col-sm-6">
                  <input class="checkbox-inline" type="checkbox" id="update9-newintro" [(ngModel)]="getLastCategorizationOption(intropositions, i, j, 'new').update" name="update9-newintro" value="update">&nbsp;
                  <label for="update9-newintro"> Update van oude informatie</label>
                  <div>
                    <img src="assets/questionmark.png" width="15" height="15">
                    <span class="tooltiptext">Aanpassing waarbij de oude informatie wordt vervangen door nieuwe, geüpdate informatie. Dit impliceert zowel verwijdering van oude informatie als toevoeging van nieuwe informatie.</span>
                  </div><br>
                </div>
              </div>
              <div class="form-row w-100">
                <div class="col-sm-6">
                  <input class="checkbox-inline" type="checkbox" id="update10-newintro" [(ngModel)]="getLastCategorizationOption(intropositions, i, j, 'new').verplaatsing" name="update10-newintro" value="verplaatsing">&nbsp;
                  <label for="update10-newintro"> Verplaatsing van identieke informatie</label>
                  <div>
                    <img src="assets/questionmark.png" width="15" height="15">
                    <span class="tooltiptext">Aanpassing waarbij tekst exact gekopieerd wordt naar een andere positie in het artikel.</span>
                  </div><br>
                </div>
                <div class="col-sm-6">
                  <input class="checkbox-inline" type="checkbox" id="update7-newintro" [(ngModel)]="getLastCategorizationOption(intropositions, i, j, 'new').spelfout" name="update7-newintro" value="spelfout">&nbsp;
                  <label for="update7-newintro">Correctie van spel- of taalfout </label>
                  <div>
                    <img src="assets/questionmark.png" style="margin-right:1em" width="15" height="15">
                    <span class="tooltiptext">Aanpassing waarbij een spelfout of grammaticale fout (bv. foutieve zinsconstructie) wordt gecorrigeerd. Onder spelfouten vallen alle fouten waarbij een woord/naam anders wordt geschreven, maar fonetisch wel nog hetzelfde klinkt. </span>
                  </div>
                  </div>
              </div>
              <div class="form-row w-100">
                <div class="col-sm-6">
                  <input class="checkbox-inline" type="checkbox" id="update11-newintro" [(ngModel)]="getLastCategorizationOption(intropositions, i, j, 'new').anders" name="update11-newintro" value="anders">&nbsp;
                  <label for="update11-newintro" style="font-size:10.5pt;">Andere </label>
                  <div>
                    <img src="assets/questionmark.png" style="margin-right:1em" width="15" height="15">
                    <span class="tooltiptext">Elk type aanpassing dat niet in één van de andere categorieën past.</span>
                  </div>
                  <input type="text" [disabled]="!getLastCategorizationOption(intropositions, i, j, 'new').anders" id="update12-newintro" [(ngModel)]="getLastCategorizationOption(intropositions, i, j, 'new').text" name="update12-newintro" value="text">
                </div>
              </div>
              <br>
              <br>
              <div class="text-center">
                <label for="explanation-newintro"> Opmerkingen: </label>
                <textarea name="explanation-newintro" class="form-control" id="explanation-newintro" [(ngModel)]="getLastCategorizationOption(intropositions, i, j, 'new').remarks"></textarea>
              </div>
              <div class="col-12 text-center">
                <br>
                 <input class="btn btn-primary" type="submit" value="Verzend">
              </div>
              <br>
            </form>
          </div>
          <br>
          <ng-container *ngFor="let intro_char of intro_sentence.split(''); let k = index;">
            <span [class.addedSentence]="intropositions && sentenceInUpdate(intropositions, i, j, 'new') && contentInSentence(intro_sentence)" [class.addedChar]="intropositions && charInUpdate(intropositions, i, j, k, 'new') && contentInSentence(intro_sentence)" (click)="clickCharacter(intropositions, i, j, k, 'new')"><b>{{intro_char}}</b></span>
          </ng-container>
          <span [class.addedSentence]="intropositions && sentenceInUpdate(intropositions, i, j, 'new') && contentInSentence(intro_sentence)" [class.addedChar]="intropositions && charInUpdate(intropositions, i, j, intro_sentence.length - 1, 'new') && contentInSentence(intro_sentence)" (click)="clickCharacter(intropositions, i, j, intro_sentence.length - 1, 'new')">&nbsp;</span>
        </ng-container>
        <br>
        <br>
      </ng-container>
    </td>
  </tr>

  <tr>
    <td id="oldarticle-text">
      <ng-container *ngFor="let text_part of oldArticle.textParts; let i = index;">
        <ng-container *ngFor="let text_sentence of text_part; let j = index;">
          <div class="oldtext" *ngIf="textpositions && firstUpdateSentence(textpositions, i, j, 'original') && startmessageSelected(text_sentence, textpositions, 'original')">
            <div style="display:inline;">
              <div style="display:inline;" *ngIf="(titlepositions && getCorrespondingPosition(textpositions, i, j, 'original').oldpartsSelected === true)  || (textpositions && firstUpdateSentence(textpositions, i, j, 'original') && getCorrespondingPosition(textpositions, i, j, 'original').categorizationSelected)">
                <button class="btn btn-primary" style="margin-left:10px;margin-top:10px;margin-right: 10px;font-size: 12px;" (click)="previous(text_sentence, textpositions, 'original')"  *ngIf="((getCorrespondingPosition(textpositions, i, j, 'original').categorizations.originalChangedParts.length > 0) || (getCorrespondingPosition(textpositions, i, j, 'original').categorizations.newChangedParts.length > 0))===false && getCorrespondingPosition(textpositions, i, j, 'original').categorizationSelected===false" disabled>Ongedaan maken</button>
                <button class="btn btn-primary" style="margin-left:10px;margin-top:10px;margin-right: 10px;font-size: 12px;" (click)="previous(text_sentence, textpositions, 'original')"  *ngIf="((getCorrespondingPosition(textpositions, i, j, 'original').categorizations.originalChangedParts.length > 0) || (getCorrespondingPosition(textpositions, i, j, 'original').categorizations.newChangedParts.length > 0)) && getCorrespondingPosition(textpositions, i, j, 'original').categorizationSelected===false">Ongedaan maken</button>
                <button class="btn btn-primary" style="margin-left:10px;margin-top:10px;margin-right: 10px;font-size: 12px;" (click)="previousCategory(text_sentence, textpositions, 'original')" *ngIf="textpositions && firstUpdateSentence(textpositions, i, j, 'original') && getCorrespondingPosition(textpositions, i, j, 'original').categorizationSelected">Ongedaan maken</button>
              </div>
              <button *ngIf="textpositions && (getCorrespondingPosition(textpositions, i, j, 'original').oldpartsSelected === true || (firstUpdateSentence(textpositions, i, j, 'original') && getCorrespondingPosition(textpositions, i, j, 'original').categorizationSelected))" style="color:white;padding-right:2px;" (click)="close(getCorrespondingPosition(textpositions, i, j, 'original'))" type="button" class="close" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <br>
            <br>
            <div *ngIf="textpositions && (getCorrespondingPosition(textpositions, i, j, 'original').warningSelectAtLeastOne === true)" style="padding-left:60px;border:4px white solid;margin:10px;">
              <img src="assets/warning.png" style="float:left;" width="30" height="30">
              <p>Je moet op zijn minst 1 tekstblok aanduiden om te kunnen categoriseren!<p>
            </div>
            <div class="oldtext-startmessage" *ngIf="textpositions && isPositionSelected(getCorrespondingPosition(textpositions, i, j, 'original')) && (getCorrespondingPosition(textpositions, i, j, 'original').otherPositionsSelected === false)">
              <p>Klik op 'Start' om de beoordeling van onderstaand tekstfragment te starten.<p>
              <button class="btn btn-primary" (click)="positionSelected(text_sentence, textpositions, 'original')">Start</button>
            </div>
            <div *ngIf="textpositions && getCorrespondingPosition(textpositions, i, j, 'original').oldpartsSelected === true">
              <p>Welke van de donkerrode tekstblokken behoren tot eenzelfde aanpassing? Klik ze aan! <br>Klik vervolgens op 'Volgende'. <br><br>Foutje gemaakt? Klik op 'Ongedaan maken' om terug te gaan.<p>
               <button class="btn btn-primary" (click)="partsSelected(text_sentence, textpositions, 'original')">Volgende</button>
            </div>
            <form #f="ngForm" class="row justify-content-center" *ngIf="textpositions && firstUpdateSentence(textpositions, i, j, 'original') && getCorrespondingPosition(textpositions, i, j, 'original').categorizationSelected" (ngSubmit)="submit(getCorrespondingPosition(textpositions, i, j, 'original'))">
              <p class="center-align"><b>Hoe zou je deze aanpassing categoriseren (meerdere opties mogelijk)?</b></p>
              <br>
              <div *ngIf="getCorrespondingPosition(textpositions, i, j, 'original').warningAnders || getCorrespondingPosition(textpositions, i, j, 'original').warning || getCorrespondingPosition(textpositions, i, j, 'original').failed" style="padding-left:60px;border:4px white solid;margin:10px;">
                <img src="assets/warning.png" style="float:left;" width="30" height="30">
                <p class="center-align" type="warningAnders" *ngIf="getCorrespondingPosition(textpositions, i, j, 'original').warningAnders"> Vul tekstvak "Andere" in!</p>
              <p class="center-align" type="warning" *ngIf="getCorrespondingPosition(textpositions, i, j, 'original').warning">Minstens 1 optie moet worden aangeduid/ingevuld!</p>
              <p class="center-align" type="failed" *ngIf="getCorrespondingPosition(textpositions, i, j, 'original').failed"> Mislukt. Probeer opnieuw aub!</p>
              </div>
                <div class="form-row w-100">
                <div class="col-sm-6">
                  <input class="checkbox-inline" type="checkbox" id="update1-oldtext" [(ngModel)]="getLastCategorizationOption(textpositions, i, j, 'original').subjectief" name="update1-oldtext" value="subjectief">&nbsp;
                  <label for="update1-oldtext"> Correctie van subjectieve fout</label>
                  <div>
                    <img src="assets/questionmark.png" width="15" height="15">
                    <span class="tooltiptext">Aanpassing waarbij een subjectieve fout wordt gecorrigeerd. Hieronder vallen volgende types fouten: niet-representatieve (tussen)titels, gesensationaliseerde informatie, te ingetogen informatie, afwezigheid van essentiële informatie, misleidende cijfers, of uit de context getrokken citaten.</span>
                  </div>
                  <br>
                </div>
                <div class="col-sm-6">
                  <input class="checkbox-inline" type="checkbox" id="update2-oldtext" [(ngModel)]="getLastCategorizationOption(textpositions, i, j, 'original').objectief" name="update2-oldtext" value="objectief">&nbsp;
                  <label for="update2-oldtext"> Correctie van objectieve fout</label>
                  <div>
                    <img src="assets/questionmark.png" width="15" height="15">
                    <span class="tooltiptext">Aanpassing waarbij een objectieve fout wordt gecorrigeerd. Hieronder vallen volgende types fouten: foutieve namen, leeftijden, adressen, aantallen, tijdstippen, data, locaties of foutieve citaties.</span>
                  </div>
                  <br>
                </div>
              </div>
              <div class="form-row w-100">
                <div class="col-sm-6">
                  <input class="checkbox-inline" type="checkbox" id="update5-oldtext" [(ngModel)]="getLastCategorizationOption(textpositions, i, j, 'original').toevoeging" name="update5-oldtext" value="toevoeging">&nbsp;
                  <label for="update5-oldtext" style="font-size:9.5pt;"> Toevoeging/verduidelijking van informatie</label>
                  <div>
                    <img src="assets/questionmark.png" width="15" height="15">
                    <span class="tooltiptext">Aanpassing waarbij informatie wordt toegevoegd aan het artikel. Hieronder valt zowel de toevoeging van informatie die nog niet gekend was bij de publicatie van de originele versie, de toevoeging van reeds gekende informatie die niet essentieel was voor een correcte weergave van de inhoud van het artikel, én de toevoeging van informatie ter verduidelijking van het oorspronkelijke artikel. Ook herschrijven van titels en/of intro's, waarbij de nieuwe informatie prominent zichtbaar wordt gemaakt, valt hieronder.</span>
                  </div><br>
                </div>
                <div class="col-sm-6">
                  <input class="checkbox-inline" type="checkbox" id="update6-oldtext" [(ngModel)]="getLastCategorizationOption(textpositions, i, j, 'original').verwijdering" name="update6-oldtext" value="verwijdering">&nbsp;
                  <label for="update6-oldtext"> Verwijdering van informatie</label>
                  <div>
                    <img src="assets/questionmark.png" width="15" height="15">
                    <span class="tooltiptext">Aanpassing waarbij informatie, die op het moment van de originele publicatie niet fout was, verwijderd wordt omdat ze verouderd is, of om eender welke andere reden.</span>
                  </div>
                </div>
              </div>
              <div class="form-row w-100">
                <div class="col-sm-6">
                  <input class="checkbox-inline" type="checkbox" id="update4-oldtext" [(ngModel)]="getLastCategorizationOption(textpositions, i, j, 'original').herformulering" name="update4-oldtext" value="herformulering">&nbsp;
                  <label for="update4-oldtext"> Inhoudsneutrale aanpassing</label>
                  <div>
                    <img src="assets/questionmark.png" width="15" height="15">
                    <span class="tooltiptext">Aanpassing waarbij woorden/zinnen worden toegevoegd, verwijderd of herschreven, zonder dat hierbij nieuwe informatie wordt toegevoegd, oude informatie wordt verwijderd, fouten worden gecorrigeerd of een andere toon wordt gelegd.</span>
                  </div><br>
                </div>
                <div class="col-sm-6">
                  <input class="checkbox-inline" type="checkbox" id="update9-oldtext" [(ngModel)]="getLastCategorizationOption(textpositions, i, j, 'original').update" name="update9-oldtext" value="update">&nbsp;
                  <label for="update9-oldtext"> Update van oude informatie</label>
                  <div>
                    <img src="assets/questionmark.png" width="15" height="15">
                    <span class="tooltiptext">Aanpassing waarbij de oude informatie wordt vervangen door nieuwe, geüpdate informatie. Dit impliceert zowel verwijdering van oude informatie als toevoeging van nieuwe informatie.</span>
                  </div><br>
                </div>
              </div>
              <div class="form-row w-100">
                <div class="col-sm-6">
                  <input class="checkbox-inline" type="checkbox" id="update10-oldtext" [(ngModel)]="getLastCategorizationOption(textpositions, i, j, 'original').verplaatsing" name="update10-oldtext" value="verplaatsing">&nbsp;
                  <label for="update10-oldtext"> Verplaatsing van identieke informatie</label>
                  <div>
                    <img src="assets/questionmark.png" width="15" height="15">
                    <span class="tooltiptext">Aanpassing waarbij tekst exact gekopieerd wordt naar een andere positie in het artikel.</span>
                  </div><br>
                </div>
                <div class="col-sm-6">
                  <input class="checkbox-inline" type="checkbox" id="update7-oldtext" [(ngModel)]="getLastCategorizationOption(textpositions, i, j, 'original').spelfout" name="update7-oldtext" value="spelfout">&nbsp;
                  <label for="update7-oldtext">Correctie van spel- of taalfout </label>
                  <div>
                    <img src="assets/questionmark.png" style="margin-right:1em" width="15" height="15">
                    <span class="tooltiptext">Aanpassing waarbij een spelfout of grammaticale fout (bv. foutieve zinsconstructie) wordt gecorrigeerd. Onder spelfouten vallen alle fouten waarbij een woord/naam anders wordt geschreven, maar fonetisch wel nog hetzelfde klinkt. </span>
                  </div>
                  </div>
              </div>
              <div class="form-row w-100">
                <div class="col-sm-6">
                  <input class="checkbox-inline" type="checkbox" id="update11-oldtext" [(ngModel)]="getLastCategorizationOption(textpositions, i, j, 'original').anders" name="update11-oldtext" value="anders">&nbsp;
                  <label for="update11-oldtext" style="font-size:10.5pt;">Andere </label>
                  <div>
                    <img src="assets/questionmark.png" style="margin-right:1em" width="15" height="15">
                    <span class="tooltiptext">Elk type aanpassing dat niet in één van de andere categorieën past.</span>
                  </div>
                  <input type="text" [disabled]="!getLastCategorizationOption(textpositions, i, j, 'original').anders" id="update12-oldtext" [(ngModel)]="getLastCategorizationOption(textpositions, i, j, 'original').text" name="update12-oldtext" value="text">
                </div>
              </div>
              <br>
              <br>
              <div class="text-center">
                <label for="explanation-oldtext"> Opmerkingen: </label>
                <textarea name="explanation-oldtext" class="form-control" id="explanation-oldtext" [(ngModel)]="getLastCategorizationOption(textpositions, i, j, 'original').remarks"></textarea>
              </div>
              <div class="col-12 text-center">
                <br>
                <input class="btn btn-primary" type="submit" value="Verzend">
              </div>
              <br>
            </form>
          </div>
          <br>
          <ng-container *ngFor="let text_char of text_sentence.split(''); let k = index;">
            <span [class.removedSentence]="textpositions && sentenceInUpdate(textpositions, i, j, 'original') && contentInSentence(text_sentence)" [class.removedChar]="textpositions && charInUpdate(textpositions, i, j, k, 'original') && contentInSentence(text_sentence)" (click)="clickCharacter(textpositions, i, j, k, 'original')">{{text_char}}</span>
          </ng-container>
          <span [class.removedSentence]="textpositions && sentenceInUpdate(textpositions, i, j, 'original') && contentInSentence(text_sentence)" [class.removedChar]="textpositions && charInUpdate(textpositions, i, j, text_sentence.length - 1, 'original') && contentInSentence(text_sentence)" (click)="clickCharacter(textpositions, i, j, text_sentence.length - 1, 'original')">&nbsp;</span>
        </ng-container>
        <br>
        <br>
      </ng-container>
    </td>
    <td id="newarticle-text">
      <ng-container *ngFor="let text_part of newArticle.textParts; let i = index;">
        <ng-container *ngFor="let text_sentence of text_part; let j = index;">
          <div class="newtext" *ngIf="textpositions && firstUpdateSentence(textpositions, i, j, 'new') && startmessageSelected(text_sentence, textpositions, 'new')">
            <div style="display:inline;">
              <div style="display:inline;" *ngIf="(textpositions && getCorrespondingPosition(textpositions, i, j, 'new').newpartsSelected === true)  || (textpositions && firstUpdateSentence(textpositions, i, j, 'new') && getCorrespondingPosition(textpositions, i, j, 'new').categorizationSelected)">
                <button class="btn btn-primary" style="margin-left:10px;margin-top:10px;margin-right: 10px;font-size: 12px;" (click)="previous(text_sentence, textpositions, 'new')"  *ngIf="((getCorrespondingPosition(textpositions, i, j, 'new').categorizations.originalChangedParts.length > 0) || (getCorrespondingPosition(textpositions, i, j, 'new').categorizations.newChangedParts.length > 0) || (getCorrespondingPosition(textpositions, i, j, 'new').newpartsSelected===true  && originalPartsExist(text_sentence, textpositions))===true)===false && getCorrespondingPosition(textpositions, i, j, 'new').categorizationSelected===false" disabled>Ongedaan maken</button>
                <button class="btn btn-primary" style="margin-left:10px;margin-top:10px;margin-right: 10px;font-size: 12px;" (click)="previous(text_sentence, textpositions, 'new')"  *ngIf="(((getCorrespondingPosition(textpositions, i, j, 'new').categorizations.originalChangedParts.length > 0) || (getCorrespondingPosition(textpositions, i, j, 'new').categorizations.newChangedParts.length > 0) || (getCorrespondingPosition(textpositions, i, j, 'new').newpartsSelected===true)  && originalPartsExist(text_sentence, textpositions))===true) && getCorrespondingPosition(textpositions, i, j, 'new').categorizationSelected===false">Ongedaan maken</button>
                <button class="btn btn-primary" style="margin-left:10px;margin-top:10px;margin-right: 10px;font-size: 12px;" (click)="previousCategory(text_sentence, textpositions, 'new')"  *ngIf="textpositions && firstUpdateSentence(textpositions, i, j, 'new') && getCorrespondingPosition(textpositions, i, j, 'new').categorizationSelected">Ongedaan maken</button>
              </div>
              <button type="button" *ngIf="textpositions && (getCorrespondingPosition(textpositions, i, j, 'new').newpartsSelected === true || (firstUpdateSentence(textpositions, i, j, 'new') && getCorrespondingPosition(textpositions, i, j, 'new').categorizationSelected))" (click)="close(getCorrespondingPosition(textpositions, i, j, 'new'))" style="color:white;padding-right:2px;"  class="close" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <br>
            <br>
            <div *ngIf="textpositions && (getCorrespondingPosition(textpositions, i, j, 'new').warningSelectAtLeastOne === true)" style="padding-left:60px;border:4px white solid;margin:10px;">
              <img src="assets/warning.png" style="float:left;" width="30" height="30">
              <p>Je moet op zijn minst 1 tekstblok aanduiden om te kunnen categoriseren!<p>
            </div>
            <div *ngIf="textpositions && isPositionSelected(getCorrespondingPosition(textpositions, i, j, 'new')) && (getCorrespondingPosition(textpositions, i, j, 'new').otherPositionsSelected === false)">
              <p>Klik op 'Start' om de beoordeling van onderstaand tekstfragment te starten.<p>
              <button class="btn btn-primary" (click)="positionSelected(text_sentence, textpositions, 'new')">Start</button>
            </div>
            <div *ngIf="textpositions && getCorrespondingPosition(textpositions, i, j, 'new').newpartsSelected === true">
              <p>Welke van de donkergroene tekstblokken behoren tot eenzelfde aanpassing? Klik ze aan! <br>Klik vervolgens op 'Volgende'. <br><br>Foutje gemaakt? Klik op 'Ongedaan maken' om terug te gaan.<p>
              <button class="btn btn-primary" (click)="partsSelected(text_sentence, textpositions, 'new')">Volgende</button>
            </div>
            <form #f="ngForm" class="row justify-content-center" *ngIf="textpositions && firstUpdateSentence(textpositions, i, j, 'new') && getCorrespondingPosition(textpositions, i, j, 'new').categorizationSelected" (ngSubmit)="submit(getCorrespondingPosition(textpositions, i, j, 'new'))">
              <p class="center-align"><b>Hoe zou je deze aanpassing categoriseren (meerdere opties mogelijk)?</b></p>
              <br>
              <div *ngIf="getCorrespondingPosition(textpositions, i, j, 'new').warningAnders || getCorrespondingPosition(textpositions, i, j, 'new').warning || getCorrespondingPosition(textpositions, i, j, 'new').failed" style="padding-left:60px;border:4px white solid;margin:10px;">
                <img src="assets/warning.png" style="float:left;" width="30" height="30">
                <p  type="warningAnders" *ngIf="getCorrespondingPosition(textpositions, i, j, 'new').warningAnders"> Vul tekstvak "Andere" in!</p>
              <p  type="warning" *ngIf="getCorrespondingPosition(textpositions, i, j, 'new').warning">Minstens 1 optie moet worden aangeduid/ingevuld!</p>
              <p  type="failed" *ngIf="getCorrespondingPosition(textpositions, i, j, 'new').failed"> Mislukt. Probeer opnieuw aub!</p>
              </div>
                <div class="form-row w-100">
                <div class="col-sm-6">
                  <input class="checkbox-inline" type="checkbox" id="update1-newtext" [(ngModel)]="getLastCategorizationOption(textpositions, i, j, 'new').subjectief" name="update1-newtext" value="subjectief">&nbsp;
                  <label for="update1-newtext">  Correctie van subjectieve fout</label>
                  <div>
                    <img src="assets/questionmark.png" width="15" height="15">
                    <span class="tooltiptext">Aanpassing waarbij een subjectieve fout wordt gecorrigeerd. Hieronder vallen volgende types fouten: niet-representatieve (tussen)titels, gesensationaliseerde informatie, te ingetogen informatie, afwezigheid van essentiële informatie, misleidende cijfers, of uit de context getrokken citaten.</span>
                  </div>
                  <br>
                </div>
                <div class="col-sm-6">
                  <input class="checkbox-inline" type="checkbox" id="update2-newtext" [(ngModel)]="getLastCategorizationOption(textpositions, i, j, 'new').objectief" name="update2-newtext" value="objectief">&nbsp;
                  <label for="update2-newtext">  Correctie van objectieve fout</label>
                  <div>
                    <img src="assets/questionmark.png" width="15" height="15">
                    <span class="tooltiptext">Aanpassing waarbij een objectieve fout wordt gecorrigeerd. Hieronder vallen volgende types fouten: foutieve namen, leeftijden, adressen, aantallen, tijdstippen, data, locaties of foutieve citaties.</span>
                  </div>
                  <br>
                </div>
              </div>
              <div class="form-row w-100">
                <div class="col-sm-6">
                  <input class="checkbox-inline" type="checkbox" id="update5-newtext" [(ngModel)]="getLastCategorizationOption(textpositions, i, j, 'new').toevoeging" name="update5-newtext" value="toevoeging">&nbsp;
                  <label for="update5-newtext" style="font-size:9.5pt;"> Toevoeging/verduidelijking van informatie</label>
                  <div>
                    <img src="assets/questionmark.png" width="15" height="15">
                    <span class="tooltiptext">Aanpassing waarbij informatie wordt toegevoegd aan het artikel. Hieronder valt zowel de toevoeging van informatie die nog niet gekend was bij de publicatie van de originele versie, de toevoeging van reeds gekende informatie die niet essentieel was voor een correcte weergave van de inhoud van het artikel, én de toevoeging van informatie ter verduidelijking van het oorspronkelijke artikel. Ook herschrijven van titels en/of intro's, waarbij de nieuwe informatie prominent zichtbaar wordt gemaakt, valt hieronder.</span>
                  </div>
                  <br>
                </div>
                <div class="col-sm-6">
                  <input class="checkbox-inline" type="checkbox" id="update6-newtext" [(ngModel)]="getLastCategorizationOption(textpositions, i, j, 'new').verwijdering" name="update6-newtext" value="verwijdering">&nbsp;
                  <label for="update6-newtext"> Verwijdering van informatie</label>
                  <div>
                    <img src="assets/questionmark.png" width="15" height="15">
                    <span class="tooltiptext">Aanpassing waarbij informatie, die op het moment van de originele publicatie niet fout was, verwijderd wordt omdat ze verouderd is, of om eender welke andere reden.</span>
                  </div>
                </div>
              </div>
              <div class="form-row w-100">
                <div class="col-sm-6">
                  <input class="checkbox-inline" type="checkbox" id="update4-newtext" [(ngModel)]="getLastCategorizationOption(textpositions, i, j, 'new').herformulering" name="update4-newtext" value="herformulering">&nbsp;
                  <label for="update4-newtext"> Inhoudsneutrale aanpassing</label>
                  <div>
                    <img src="assets/questionmark.png" width="15" height="15">
                    <span class="tooltiptext">Aanpassing waarbij woorden/zinnen worden toegevoegd, verwijderd of herschreven, zonder dat hierbij nieuwe informatie wordt toegevoegd, oude informatie wordt verwijderd, fouten worden gecorrigeerd of een andere toon wordt gelegd.</span>
                  </div>
                  <br>
                </div>
                <div class="col-sm-6">
                  <input class="checkbox-inline" type="checkbox" id="update9-newtext" [(ngModel)]="getLastCategorizationOption(textpositions, i, j, 'new').update" name="update9-newtext" value="update">&nbsp;
                  <label for="update9-newtext"> Update van oude informatie</label>
                  <div>
                    <img src="assets/questionmark.png" width="15" height="15">
                    <span class="tooltiptext">Aanpassing waarbij de oude informatie wordt vervangen door nieuwe, geüpdate informatie. Dit impliceert zowel verwijdering van oude informatie als toevoeging van nieuwe informatie.</span>
                  </div>
                  <br>
                </div>
              </div>
              <div class="form-row w-100">
                <div class="col-sm-6">
                  <input class="checkbox-inline" type="checkbox" id="update10-newtext" [(ngModel)]="getLastCategorizationOption(textpositions, i, j, 'new').verplaatsing" name="update10-newtext" value="verplaatsing">&nbsp;
                  <label for="update10-newtext"> Verplaatsing van identieke informatie</label>
                  <div>
                    <img src="assets/questionmark.png" width="15" height="15">
                    <span class="tooltiptext">Aanpassing waarbij tekst exact gekopieerd wordt naar een andere positie in het artikel.</span>
                  </div><br>
                </div>
                <div class="col-sm-6">
                  <input class="checkbox-inline" type="checkbox" id="update7-newtext" [(ngModel)]="getLastCategorizationOption(textpositions, i, j, 'new').spelfout" name="update7-newtext" value="spelfout">&nbsp;
                  <label for="update7-newtext">Correctie van spel- of taalfout </label>
                  <div>
                    <img src="assets/questionmark.png" style="margin-right:1em" width="15" height="15">
                    <span class="tooltiptext">Aanpassing waarbij een spelfout of grammaticale fout (bv. foutieve zinsconstructie) wordt gecorrigeerd. Onder spelfouten vallen alle fouten waarbij een woord/naam anders wordt geschreven, maar fonetisch wel nog hetzelfde klinkt. </span>
                  </div>
                  </div>
              </div>
              <div class="form-row w-100">
                <div class="col-sm-6">
                  <input class="checkbox-inline" type="checkbox" id="update11-newtext" [(ngModel)]="getLastCategorizationOption(textpositions, i, j, 'new').anders" name="update11-newtext" value="anders">&nbsp;
                  <label for="update11-newtext" style="font-size:10.5pt;">Andere </label>
                  <div>
                    <img src="assets/questionmark.png" style="margin-right:1em" width="15" height="15">
                    <span class="tooltiptext">Elk type aanpassing dat niet in één van de andere categorieën past.</span>
                  </div>
                  <input type="text" [disabled]="!getLastCategorizationOption(textpositions, i, j, 'new').anders" id="update12-newtext" [(ngModel)]="getLastCategorizationOption(textpositions, i, j, 'new').text" name="update12-newtext" value="text">
                </div>
              </div>
              <br>
              <br>
              <div class="text-center">
                <label for="explanation-newtext"> Opmerkingen: </label>
                <textarea name="explanation-newtext" class="form-control" id="explanation-newtext" [(ngModel)]="getLastCategorizationOption(textpositions, i, j, 'new').remarks"></textarea>
              </div>
              <div class="col-12 text-center">
                <br>
                <input class="btn btn-primary" type="submit" value="Verzend">
              </div>
              <br>
            </form>
          </div>
          <br>
          <ng-container *ngFor="let text_char of text_sentence.split(''); let k = index;">
            <span [class.addedSentence]="textpositions && sentenceInUpdate(textpositions, i, j, 'new') && contentInSentence(text_sentence)" [class.addedChar]="textpositions && charInUpdate(textpositions, i, j, k, 'new') && contentInSentence(text_sentence)" (click)="clickCharacter(textpositions, i, j, k, 'new')">{{text_char}}</span>
          </ng-container>
          <span [class.addedSentence]="textpositions && sentenceInUpdate(textpositions, i, j, 'new') && contentInSentence(text_sentence)" [class.addedChar]="textpositions && charInUpdate(textpositions, i, j, text_sentence.length - 1, 'new') && contentInSentence(text_sentence)" (click)="clickCharacter(textpositions, i, j, text_sentence.length - 1, 'new')">&nbsp;</span>
        </ng-container>
        <br>
        <br>
      </ng-container>
    </td>
  </tr>
  </tbody>
</table>

<div *ngIf="(feedbackCompleted===true) && checkFeedbackStage(1) && this.scores">
  <h2 style="margin-left:43%;display:inline-block;" align="center">Artikelpaar 1</h2>
  <div style="margin-left:15%;margin-right:15%;display:inline-block;">
    <p><b>Jouw score: {{scores[0]}}/1</b></p>
    <p><i>Artikel werd geschreven door Knack en kan geraadpleegd worden op <a href="https://www.knack.be/nieuws/wereld/donald-trump-waarschuwt-belgie-betalen-zullen-ze/article-news-1539459.html">https://www.knack.be/nieuws/wereld/donald-trump-waarschuwt-belgie-betalen-zullen-ze/article-news-1539459.html</a>.</i></p>
    <br>
    <h4 style="margin-left:40%;display:inline-block;" align="center">Jouw oplossing</h4>
    <img class="plus" src="assets/plus.png" *ngIf="yoursolutionChosen[0]===false" style="margin-left:4px;" width="15" height="15" (click)="clickExercise(1, 'yoursolution')">
    <img class="min" src="assets/minus.png" *ngIf="yoursolutionChosen[0]===true" style="margin-left:4px;" width="15" height="15" (click)="clickExercise(1, 'yoursolution')">
    <div *ngIf="yoursolutionChosen[0]===true" style="margin-left:15%;margin-right:15%;display:inline-block;">
      <ng-container *ngFor="let feedbacksol of getFeedbackSolutionsFromUrl('https://www.knack.be/nieuws/wereld/donald-trump-waarschuwt-belgie-betalen-zullen-ze/article-news-1539459.html');let i  = index">
        <h6>Aanpassing {{i + 1}}:</h6>
        <b>Originele tekst: </b>
        <ng-container *ngFor="let sentence of feedbacksol.originaltext; let j = index;">
          <ng-container *ngFor="let word of sentence.split(''); let k = index;">
            <span [class.removedWord]="wordInFeedbacksol(feedbacksol, j, k, 'original')">{{word}}</span>
          </ng-container>
          <span>&nbsp;</span>
        </ng-container>
        <br>
        <br>
        <b>Nieuwe tekst: </b>
        <ng-container *ngFor="let sentence of feedbacksol.newtext; let j = index;">
          <ng-container *ngFor="let word of sentence.split(''); let k = index;">
            <span [class.addedWord]="wordInFeedbacksol(feedbacksol, j, k, 'new')">{{word}}</span>
          </ng-container>
          <span>&nbsp;</span>
        </ng-container>
        <br>
        <br>
        <b>Type: </b>
        <ng-container *ngFor="let type of feedbacksol.categorizationtypes; let j = index;">
          <span>{{type}}</span>
          <span *ngIf="j !== feedbacksol.categorizationtypes.length - 1">, </span>
        </ng-container>
        <hr *ngIf="i !== getFeedbackSolutionsFromUrl('https://www.knack.be/nieuws/wereld/donald-trump-waarschuwt-belgie-betalen-zullen-ze/article-news-1539459.html').length - 1" style="border-top:3px dashed;">
      </ng-container>
    </div>
    <br>
    <h4 style="margin-left:38%;display:inline-block;" align="center">Correcte oplossing</h4>
    <img class="plus" src="assets/plus.png" *ngIf="correctsolutionChosen[0]===false" style="margin-left:4px;" width="15" height="15" (click)="clickExercise(1, 'correctsolution')">
    <img class="min" src="assets/minus.png" *ngIf="correctsolutionChosen[0]===true" style="margin-left:4px;" width="15" height="15" (click)="clickExercise(1, 'correctsolution')">
    <div *ngIf="correctsolutionChosen[0]===true" style="margin-left:15%;margin-right:15%;display:inline-block;">
      <h6>Aanpassing 1:</h6>
      <p><b>Originele tekst: </b>Trump toonde zich verheugd dat <span style="color:red"><b>de defensie-uitgaven</b></span> van veel lidstaten de laatste drie jaar aanzienlijk zijn gestegen, al is die stijging in werkelijkheid al langer bezig.</p>
      <p><b>Nieuwe tekst: </b>Trump toonde zich verheugd dat <span style="color:green"><b>die sommen</b></span> van veel lidstaten de laatste drie jaar aanzienlijk zijn gestegen, al is die stijging in werkelijkheid al langer bezig.</p>
      <br>
      <p><b>Type: </b>Inhoudsneutrale aanpassing</p>
    </div>
  </div>
  <br>
  <h4 style="margin-left:45.5%;display:inline-block;" align="center">Feedback</h4>
  <img class="plus" src="assets/plus.png" *ngIf="feedbackChosen[0]===false" style="margin-left:4px;" width="15" height="15" (click)="clickExercise(1, 'feedback')">
  <img class="min" src="assets/minus.png" *ngIf="feedbackChosen[0]===true" style="margin-left:4px;" width="15" height="15" (click)="clickExercise(1, 'feedback')">
  <div *ngIf="feedbackChosen[0]===true" style="margin-left:15%;margin-right:15%;display:inline-block;">
    <p>De aanpassing van "de defensie-uitgaven" naar "die sommen" is een aanpassing zonder dat er aan de inhoud van het artikel zelf iets veranderd. Er wordt hier geen fout gemaakt (beide zinnen zijn op zich correct), en er wordt geen informatie toegevoegd of verwijderd.
    Het gaat hier dan ook om een "Inhoudsneutrale aanpassing".</p>
  </div>
  <hr>
  <h2 style="margin-left:43%;display:inline-block;" align="center">Artikelpaar 2</h2>
  <div style="margin-left:15%;margin-right:15%;display:inline-block;">
    <p><b>Jouw score: {{scores[1]}}/1</b></p>
    <p><i>Artikel werd geschreven door De Morgen en kan geraadpleegd worden op <a href="https://www.demorgen.be/nieuws/emmanuel-andre-en-maggie-de-block-botsen-over-wereldwijd-slechtste-belgische-aanpak-coronacrisis~bc4c1c7d/">https://www.demorgen.be/nieuws/emmanuel-andre-en-maggie-de-block-botsen-over-wereldwijd-slechtste-belgische-aanpak-coronacrisis~bc4c1c7d/</a>.</i></p>
    <br>
    <h4 style="margin-left:40%;display:inline-block;" align="center">Jouw oplossing</h4>
    <img class="plus" src="assets/plus.png" *ngIf="yoursolutionChosen[1]===false" style="margin-left:4px;" width="15" height="15" (click)="clickExercise(2, 'yoursolution')">
    <img class="min" src="assets/minus.png" *ngIf="yoursolutionChosen[1]===true" style="margin-left:4px;" width="15" height="15" (click)="clickExercise(2, 'yoursolution')">
    <div *ngIf="yoursolutionChosen[1]===true" style="margin-left:15%;margin-right:15%;display:inline-block;">
      <ng-container *ngFor="let feedbacksol of getFeedbackSolutionsFromUrl('https://www.demorgen.be/nieuws/emmanuel-andre-en-maggie-de-block-botsen-over-wereldwijd-slechtste-belgische-aanpak-coronacrisis~bc4c1c7d/');let i  = index">
        <h6>Aanpassing {{i + 1}}:</h6>
        <b>Originele tekst: </b>
        <ng-container *ngFor="let sentence of feedbacksol.originaltext; let j = index;">
          <ng-container *ngFor="let word of sentence.split(''); let k = index;">
            <span [class.removedWord]="wordInFeedbacksol(feedbacksol, j, k, 'original')">{{word}}</span>
          </ng-container>
          <span>&nbsp;</span>
        </ng-container>
        <br>
        <br>
        <b>Nieuwe tekst: </b>
        <ng-container *ngFor="let sentence of feedbacksol.newtext; let j = index;">
          <ng-container *ngFor="let word of sentence.split(''); let k = index;">
            <span [class.addedWord]="wordInFeedbacksol(feedbacksol, j, k, 'new')">{{word}}</span>
          </ng-container>
          <span>&nbsp;</span>
        </ng-container>
        <br>
        <br>
        <b>Type: </b>
        <ng-container *ngFor="let type of feedbacksol.categorizationtypes; let j = index;">
          <span>{{type}}</span>
          <span *ngIf="j !== feedbacksol.categorizationtypes.length - 1">, </span>
        </ng-container>
        <hr *ngIf="i !== getFeedbackSolutionsFromUrl('https://www.demorgen.be/nieuws/emmanuel-andre-en-maggie-de-block-botsen-over-wereldwijd-slechtste-belgische-aanpak-coronacrisis~bc4c1c7d/').length - 1" style="border-top:3px dashed;">
      </ng-container>
    </div>
    <br>
    <h4 style="margin-left:38%;display:inline-block;" align="center">Correcte oplossing</h4>
    <img class="plus" src="assets/plus.png" *ngIf="correctsolutionChosen[1]===false" style="margin-left:4px;" width="15" height="15" (click)="clickExercise(2, 'correctsolution')">
    <img class="min" src="assets/minus.png" *ngIf="correctsolutionChosen[1]===true" style="margin-left:4px;" width="15" height="15" (click)="clickExercise(2, 'correctsolution')">
    <div *ngIf="correctsolutionChosen[1]===true" style="margin-left:15%;margin-right:15%;display:inline-block;">
      <h6>Aanpassing 1:</h6>
      <p><b>Originele tekst: </b>Emmanuel André en Maggie De Block botsen over <span style="color:red;"><b>‘wereldwijd slechtste’</b></span> Belgische aanpak coronacrisis</p>
      <p><b>Nieuwe tekst: </b>Emmanuel André en Maggie De Block botsen over Belgische aanpak coronacrisis<br>
      <p><b>Type: </b>Correctie van subjectieve fout</p>
    </div>
  </div>
  <br>
  <h4 style="margin-left:45.5%;display:inline-block;" align="center">Feedback</h4>
  <img class="plus" src="assets/plus.png" *ngIf="feedbackChosen[1]===false" style="margin-left:4px;" width="15" height="15" (click)="clickExercise(2, 'feedback')">
  <img class="min" src="assets/minus.png" *ngIf="feedbackChosen[1]===true" style="margin-left:4px;" width="15" height="15" (click)="clickExercise(2, 'feedback')">
  <div *ngIf="feedbackChosen[1]===true" style="margin-left:15%;margin-right:15%;display:inline-block;">
    In het rapport van The Economist worden 21 verschillende landen vergeleken, en daarin scoort België duidelijk het slechts van allemaal. Dat wil echter niet direct zeggen dat België daarom de slechtste aanpak ter wereld zou hebben, zoals in de originele titel wordt gezegd.
    De oorspronkelijke titel stelt met andere woorden de inhoud van het artikel veel sterker voor dan de conclusies van het onderzoek eigenlijk zijn. Er kan hier dus gesproken worden over een sensationele titel. Dit valt onder de categorie "Correctie van subjectieve fout".
  </div>
  <hr>
  <h2 style="margin-left:43%;display:inline-block;" align="center">Artikelpaar 3</h2>
  <div style="margin-left:15%;margin-right:15%;display:inline-block;">
    <p><b>Jouw score: {{scores[2]}}/1</b></p>
    <p><i>Artikel werd geschreven door VRT NWS en kan geraadpleegd worden op <a href="https://www.vrt.be/vrtnws/nl/2019/12/13/wat-komt-er-straks-uit-de-bus-op-de-klimaatconferentie/">https://www.vrt.be/vrtnws/nl/2019/12/13/wat-komt-er-straks-uit-de-bus-op-de-klimaatconferentie/</a>.</i></p>
    <br>
    <h4 style="margin-left:40%;display:inline-block;" align="center">Jouw oplossing</h4>
    <img class="plus" src="assets/plus.png" *ngIf="yoursolutionChosen[2]===false" style="margin-left:4px;" width="15" height="15" (click)="clickExercise(3, 'yoursolution')">
    <img class="min" src="assets/minus.png" *ngIf="yoursolutionChosen[2]===true" style="margin-left:4px;" width="15" height="15" (click)="clickExercise(3, 'yoursolution')">
    <div *ngIf="yoursolutionChosen[2]===true" style="margin-left:15%;margin-right:15%;display:inline-block;">
      <ng-container *ngFor="let feedbacksol of getFeedbackSolutionsFromUrl('https://www.vrt.be/vrtnws/nl/2019/12/13/wat-komt-er-straks-uit-de-bus-op-de-klimaatconferentie/');let i  = index">
        <h6>Aanpassing {{i + 1}}:</h6>
        <b>Originele tekst: </b>
        <ng-container *ngFor="let sentence of feedbacksol.originaltext; let j = index;">
          <ng-container *ngFor="let word of sentence.split(''); let k = index;">
            <span [class.removedWord]="wordInFeedbacksol(feedbacksol, j, k, 'original')">{{word}}</span>
          </ng-container>
          <span>&nbsp;</span>
        </ng-container>
        <br>
        <br>
        <b>Nieuwe tekst: </b>
        <ng-container *ngFor="let sentence of feedbacksol.newtext; let j = index;">
          <ng-container *ngFor="let word of sentence.split(''); let k = index;">
            <span [class.addedWord]="wordInFeedbacksol(feedbacksol, j, k, 'new')">{{word}}</span>
          </ng-container>
          <span>&nbsp;</span>
        </ng-container>
        <br>
        <br>
        <b>Type: </b>
        <ng-container *ngFor="let type of feedbacksol.categorizationtypes; let j = index;">
          <span>{{type}}</span>
          <span *ngIf="j !== feedbacksol.categorizationtypes.length - 1">, </span>
        </ng-container>
        <hr *ngIf="i !== getFeedbackSolutionsFromUrl('https://www.vrt.be/vrtnws/nl/2019/12/13/wat-komt-er-straks-uit-de-bus-op-de-klimaatconferentie/').length - 1" style="border-top:3px dashed;">
      </ng-container>
    </div>
    <br>
    <h4 style="margin-left:38%;display:inline-block;" align="center">Correcte oplossing</h4>
    <img class="plus" src="assets/plus.png" *ngIf="correctsolutionChosen[2]===false" style="margin-left:4px;" width="15" height="15" (click)="clickExercise(3, 'correctsolution')">
    <img class="min" src="assets/minus.png" *ngIf="correctsolutionChosen[2]===true" style="margin-left:4px;" width="15" height="15" (click)="clickExercise(3, 'correctsolution')">
    <div *ngIf="correctsolutionChosen[2]===true" style="margin-left:15%;margin-right:15%;display:inline-block;">
      <h6>Aanpassing 1:</h6>
      <p><b>Originele tekst: </b>Het <span style="color:red;"><b>gebeurt wel vaker</b></span> dat de onderhandelaars op een klimaatconferentie op zaterdag nog verderdoen.</p>
      <p><b>Nieuwe tekst: </b>Het <span style="color:green;"><b>is niet de eerste keer</b></span> dat er langer onderhandeld moet worden, vertelt VRT-journaliste Leen De Witte die ter plaatse is: "Het is bijna altijd zo, dat er langer voortgepraat wordt dan voorzien. <br>
      <p><b>Type: </b>Inhoudsneutrale aanpassing</p>
      <hr style="border-top:3px dashed;">
      <h6>Aanpassing 2:</h6>
      <p><b>Originele tekst: </b>Het gebeurt wel vaker dat <span style="color:red;"><b>de onderhandelaars op een klimaatconferentie op zaterdag nog verderdoen.</b></span></p>
      <p><b>Nieuwe tekst: </b>Het is niet de eerste keer dat <span style="color:green;"><b>er langer onderhandeld moet worden, vertelt VRT-journaliste Leen De Witte die ter plaatse is: "Het is bijna altijd zo, dat er langer voortgepraat wordt dan voorzien. Er zijn grote verschillen tussen de 200 delegaties die het klimaatakkoord van Parijs ondertekenden."</b></span> Het is wel de bedoeling om tot de finish door te gaan.</p>
      <p><b>Type: </b>Toevoeging/verduidelijking van informatie</p>
    </div>
  </div>
  <br>
  <h4 style="margin-left:45.5%;display:inline-block;" align="center">Feedback</h4>
  <img class="plus" src="assets/plus.png" *ngIf="feedbackChosen[2]===false" style="margin-left:4px;" width="15" height="15" (click)="clickExercise(3, 'feedback')">
  <img class="min" src="assets/minus.png" *ngIf="feedbackChosen[2]===true" style="margin-left:4px;" width="15" height="15" (click)="clickExercise(3, 'feedback')">
  <div *ngIf="feedbackChosen[2]===true" style="margin-left:15%;margin-right:15%;display:inline-block;">
    Hier worden twee verschillende aanpassingen doorgevoerd. Een eerste aanpassing betreft het wijzigen van "het gebeurt wel vaker" naar "het is niet de eerste keer". Beide constructies drukken exact hetzelfde uit, zonder dat er informatie wordt verwijderd, toegevoegd of fouten worden gecorrigeerd. Het gaat hier dan ook om een inhoudsneutrale aanpassing.
    Daarnaast is er ook een tweede aanpassing, waarbij de informatie uit het oorspronkelijke artikel nog steeds correct is, maar er nog bijkomende informatie wordt aan toegevoegd. Daarom spreken we hier van het "toevoegen van informatie".
    Het gaat hier niet om een update van verouderde informatie, aangezien de oorspronkelijke informatie nog steeds correct is en ook nog vermeld wordt in de nieuwe versie van het artikel. Het gaat hier ook niet om zowel een "inhoudsneutrale aanpassing" én een "toevoeging/verduidelijking van informatie", ook al wordt een deel van de eerste zin herschreven. De categorie "inhoudsneutrale aanpassing"  is enkel van toepassing als er geen enkele wijziging is in/aan de inhoud/correctheid van het fragment dat je analyseert.
  </div>
  <hr>
  <h2 style="margin-left:43%;display:inline-block;" align="center">Artikelpaar 4</h2>
  <div style="margin-left:15%;margin-right:15%;display:inline-block;">
    <p><b>Jouw score: {{scores[3]}}/1</b></p>
    <p><i>Artikel werd geschreven door VRT NWS en kan geraadpleegd worden op <a href="https://www.vrt.be/vrtnws/nl/2019/10/09/nobelprijs-voor-scheikunde/">https://www.vrt.be/vrtnws/nl/2019/10/09/nobelprijs-voor-scheikunde/</a>.</i></p>
    <br>
    <h4 style="margin-left:40%;display:inline-block;" align="center">Jouw oplossing</h4>
    <img class="plus" src="assets/plus.png" *ngIf="yoursolutionChosen[3]===false" style="margin-left:4px;" width="15" height="15" (click)="clickExercise(4, 'yoursolution')">
    <img class="min" src="assets/minus.png" *ngIf="yoursolutionChosen[3]===true" style="margin-left:4px;" width="15" height="15" (click)="clickExercise(4, 'yoursolution')">
    <div *ngIf="yoursolutionChosen[3]===true" style="margin-left:15%;margin-right:15%;display:inline-block;">
      <ng-container *ngFor="let feedbacksol of getFeedbackSolutionsFromUrl('https://www.vrt.be/vrtnws/nl/2019/10/09/nobelprijs-voor-scheikunde/');let i  = index">
        <h6>Aanpassing {{i + 1}}:</h6>
        <b>Originele tekst: </b>
        <ng-container *ngFor="let sentence of feedbacksol.originaltext; let j = index;">
          <ng-container *ngFor="let word of sentence.split(''); let k = index;">
            <span [class.removedWord]="wordInFeedbacksol(feedbacksol, j, k, 'original')">{{word}}</span>
          </ng-container>
          <span>&nbsp;</span>
        </ng-container>
        <br>
        <br>
        <b>Nieuwe tekst: </b>
        <ng-container *ngFor="let sentence of feedbacksol.newtext; let j = index;">
          <ng-container *ngFor="let word of sentence.split(''); let k = index;">
            <span [class.addedWord]="wordInFeedbacksol(feedbacksol, j, k, 'new')">{{word}}</span>
          </ng-container>
          <span>&nbsp;</span>
        </ng-container>
        <br>
        <br>
        <b>Type: </b>
        <ng-container *ngFor="let type of feedbacksol.categorizationtypes; let j = index;">
          <span>{{type}}</span>
          <span *ngIf="j !== feedbacksol.categorizationtypes.length - 1">, </span>
        </ng-container>
        <hr *ngIf="i !== getFeedbackSolutionsFromUrl('https://www.vrt.be/vrtnws/nl/2019/10/09/nobelprijs-voor-scheikunde/').length - 1" style="border-top:3px dashed;">
      </ng-container>
    </div>
    <br>
    <h4 style="margin-left:38%;display:inline-block;" align="center">Correcte oplossing</h4>
    <img class="plus" src="assets/plus.png" *ngIf="correctsolutionChosen[3]===false" style="margin-left:4px;" width="15" height="15" (click)="clickExercise(4, 'correctsolution')">
    <img class="min" src="assets/minus.png" *ngIf="correctsolutionChosen[3]===true" style="margin-left:4px;" width="15" height="15" (click)="clickExercise(4, 'correctsolution')">
    <div *ngIf="correctsolutionChosen[3]===true" style="margin-left:15%;margin-right:15%;display:inline-block;">
      <h6>Aanpassing 1:</h6>
      <p><b>Originele tekst: </b><span style="color:red;"><b>Mettalisch</b></span> lithium is evenwel een zeer reactief materiaal - het reageert onder meer met water - en de batterij was te explosief om praktisch te kunnen gebruiken.</p>
      <p><b>Nieuwe tekst: </b><span style="color:green;"><b>Metalisch</b></span> lithium is evenwel een zeer reactief materiaal - het reageert onder meer met water - en de batterij was te explosief om praktisch te kunnen gebruiken.<br>
      <p><b>Type: </b>Correctie van spel- of taalfout</p>
      <hr style="border-top:3px dashed;">
      <h6>Aanpassing 2:</h6>
      <p><b>Originele tekst: </b>John Goodenough, overigens de oudste Nobelprijslaureaat ooit, voorspelde dat de kathode zelfs nog een groter vermogen zou kunnen hebben als ze gemaakt zou zijn uit een metaaloxide in plaats van een metaalsulfide.</p>
      <p><b>Nieuwe tekst: </b>John Goodenough, overigens de oudste Nobelprijslaureaat ooit<span style="color:green"><b> (hij werd in 1922 geboren in Jena in Duitsland)</b></span>, voorspelde dat de kathode zelfs nog een groter vermogen zou kunnen hebben als ze gemaakt zou zijn uit een metaaloxide in plaats van een metaalsulfide. </p>
      <p><b>Type: </b>Toevoeging/verduidelijking van informatie</p>
      <hr style="border-top:3px dashed;">
      <h6>Aanpassing 3:</h6>
      <p><b>Originele tekst: </b>Het voordeel van litium-ion accu's is dat ze niet gebaseerd zijn op chemische reacties die de elektronen afbreken, <span style="color:red"><b>maaar</b></span> op litiumionen die over en weer stromen tussen de anode en de kathode.</p>
      <p><b>Nieuwe tekst: </b>Het voordeel van litium-ion accu's is dat ze niet gebaseerd zijn op chemische reacties die de elektronen afbreken, <span style="color:green"><b>maar</b></span> op litiumionen die over en weer stromen tussen de anode en de kathode.</p>
      <p><b>Type: </b>Correctie van spel- of taalfout</p>
    </div>
  </div>
  <br>
  <h4 style="margin-left:45.5%;display:inline-block;" align="center">Feedback</h4>
  <img class="plus" src="assets/plus.png" *ngIf="feedbackChosen[3]===false" style="margin-left:4px;" width="15" height="15" (click)="clickExercise(4, 'feedback')">
  <img class="min" src="assets/minus.png" *ngIf="feedbackChosen[3]===true" style="margin-left:4px;" width="15" height="15" (click)="clickExercise(4, 'feedback')">
  <div *ngIf="feedbackChosen[3]===true" style="margin-left:15%;margin-right:15%;display:inline-block;">
    Bij de publicatie van de nieuwe versie van het artikel zijn drie verschillende aanpassingen in 1 keer gedaan. "Mettalisch" naar "metalisch" is heel duidelijk de correctie van een spelfout, net zoals van "maaar" naar "maar".
    Bij de derde aanpassing wordt extra informatie gegeven over de persoon in kwestie. Deze informatie was niet essentieel om de inhoud van het oorspronkelijke artikel op een correcte manier te kunnen brengen.
    Daarom is er geen sprake van een "Correctie van een subjectieve fout" (er is geen essentiële informatie afwezig). Het gaat om een toevoeging zonder dat er oorspronkelijk een fout werd gemaakt, met andere woorden het "toevoegen van informatie".
  </div>
  <hr>
  <h2 style="margin-left:43%;display:inline-block;" align="center">Artikelpaar 5</h2>
  <div style="margin-left:15%;margin-right:15%;display:inline-block;">
    <p><b>Jouw score: {{scores[4]}}/1</b></p>
    <p><i>Artikel werd geschreven door VRT NWS en kan geraadpleegd worden op <a href="https://www.vrt.be/vrtnws/nl/2019/10/14/nepfilmpje-met-moordende-president-donald-trump-laait-gemoederen/">https://www.vrt.be/vrtnws/nl/2019/10/14/nepfilmpje-met-moordende-president-donald-trump-laait-gemoederen/</a>.</i></p>
    <br>
    <h4 style="margin-left:40%;display:inline-block;" align="center">Jouw oplossing</h4>
    <img class="plus" src="assets/plus.png" *ngIf="yoursolutionChosen[4]===false" style="margin-left:4px;" width="15" height="15" (click)="clickExercise(5, 'yoursolution')">
    <img class="min" src="assets/minus.png" *ngIf="yoursolutionChosen[4]===true" style="margin-left:4px;" width="15" height="15" (click)="clickExercise(5, 'yoursolution')">
    <div *ngIf="yoursolutionChosen[4]===true" style="margin-left:15%;margin-right:15%;display:inline-block;">
      <ng-container *ngFor="let feedbacksol of getFeedbackSolutionsFromUrl('https://www.vrt.be/vrtnws/nl/2019/10/14/nepfilmpje-met-moordende-president-donald-trump-laait-gemoederen/');let i  = index">
        <h6>Aanpassing {{i + 1}}:</h6>
        <b>Originele tekst: </b>
        <ng-container *ngFor="let sentence of feedbacksol.originaltext; let j = index;">
          <ng-container *ngFor="let word of sentence.split(''); let k = index;">
            <span [class.removedWord]="wordInFeedbacksol(feedbacksol, j, k, 'original')">{{word}}</span>
          </ng-container>
          <span>&nbsp;</span>
        </ng-container>
        <br>
        <br>
        <b>Nieuwe tekst: </b>
        <ng-container *ngFor="let sentence of feedbacksol.newtext; let j = index;">
          <ng-container *ngFor="let word of sentence.split(''); let k = index;">
            <span [class.addedWord]="wordInFeedbacksol(feedbacksol, j, k, 'new')">{{word}}</span>
          </ng-container>
          <span>&nbsp;</span>
        </ng-container>
        <br>
        <br>
        <b>Type: </b>
        <ng-container *ngFor="let type of feedbacksol.categorizationtypes; let j = index;">
          <span>{{type}}</span>
          <span *ngIf="j !== feedbacksol.categorizationtypes.length - 1">, </span>
        </ng-container>
        <hr *ngIf="i !== getFeedbackSolutionsFromUrl('https://www.vrt.be/vrtnws/nl/2019/10/14/nepfilmpje-met-moordende-president-donald-trump-laait-gemoederen/').length - 1" style="border-top:3px dashed;">
      </ng-container>
    </div>
    <br>
    <h4 style="margin-left:38%;display:inline-block;" align="center">Correcte oplossing</h4>
    <img class="plus" src="assets/plus.png" *ngIf="correctsolutionChosen[4]===false" style="margin-left:4px;" width="15" height="15" (click)="clickExercise(5, 'correctsolution')">
    <img class="min" src="assets/minus.png" *ngIf="correctsolutionChosen[4]===true" style="margin-left:4px;" width="15" height="15" (click)="clickExercise(5, 'correctsolution')">
    <div *ngIf="correctsolutionChosen[4]===true" style="margin-left:15%;margin-right:15%;display:inline-block;">
      <h6>Aanpassing 1:</h6>
      <p><b>Originele tekst: </b><span style="color:red;"><b>De Democratische presidentskandidaat Bernie Sanders schreeuwt het uit wanneer zijn haar in brand wordt gestoken.</b></span></p>
      <p><b>Nieuwe tekst: </b><br>
      <p><b>Type: </b>Verplaatsing van identieke informatie</p>
      <hr style="border-top:3px dashed;">
      <h6>Aanpassing 2:</h6>
      <p><b>Originele tekst: </b><br>
      <p><b>Nieuwe tekst: </b><span style="color:green;"><b>De Democratische presidentskandidaat Bernie Sanders schreeuwt het uit wanneer zijn haar in brand wordt gestoken.</b></span></p>
      <p><b>Type: </b>Verplaatsing van identieke informatie</p>
    </div>
  </div>
  <br>
  <h4 style="margin-left:45.5%;display:inline-block;" align="center">Feedback</h4>
  <img class="plus" src="assets/plus.png" *ngIf="feedbackChosen[4]===false" style="margin-left:4px;" width="15" height="15" (click)="clickExercise(5, 'feedback')">
  <img class="min" src="assets/minus.png" *ngIf="feedbackChosen[4]===true" style="margin-left:4px;" width="15" height="15" (click)="clickExercise(5, 'feedback')">
  <div *ngIf="feedbackChosen[4]===true" style="margin-left:15%;margin-right:15%;display:inline-block;">
    In deze overgang van het oude naar het nieuwe artikel is eigenlijk maar 1 aanpassing gebeurd, maar deze zit gespreid over twee verschillende aanpassingen omdat het gaat over het verplaatsen van identieke informatie naar een nieuwe plaats in het artikel.
    Typisch voor dit soort aanpassingen is dat je hierbij twee typeringen van aanpassingen nodig hebt, beiden van het type "verplaatsing van identieke informatie", waarbij éénmaal het originele tekstveld leeg is en éénmaal het nieuwe tekstveld leeg blijft.

  </div>
</div>

<div *ngIf="(feedbackCompleted===true) && checkFeedbackStage(2) && this.scores">
  <h2 style="margin-left:43%;display:inline-block;" align="center">Artikelpaar 1</h2>
  <div style="margin-left:15%;margin-right:15%;display:inline-block;">
    <p><b>Jouw score: {{scores[0]}}/1</b></p>
    <p><i>Artikel werd geschreven door De Standaard en kan geraadpleegd worden op <a href="https://www.standaard.be/cnt/dmf20200130_04827598">https://www.standaard.be/cnt/dmf20200130_04827598</a>.</i></p>
    <br>
    <h4 style="margin-left:40%;display:inline-block;" align="center">Jouw oplossing</h4>
    <img class="plus" src="assets/plus.png" *ngIf="yoursolutionChosen[5]===false" style="margin-left:4px;" width="15" height="15" (click)="clickExercise(6, 'yoursolution')">
    <img class="min" src="assets/minus.png" *ngIf="yoursolutionChosen[5]===true" style="margin-left:4px;" width="15" height="15" (click)="clickExercise(6, 'yoursolution')">
    <div *ngIf="yoursolutionChosen[5]===true" style="margin-left:15%;margin-right:15%;display:inline-block;">
      <ng-container *ngFor="let feedbacksol of getFeedbackSolutionsFromUrl('https://www.standaard.be/cnt/dmf20200130_04827598');let i  = index">
        <h6>Aanpassing {{i + 1}}:</h6>
        <b>Originele tekst: </b>
        <ng-container *ngFor="let sentence of feedbacksol.originaltext; let j = index;">
          <ng-container *ngFor="let word of sentence.split(''); let k = index;">
            <span [class.removedWord]="wordInFeedbacksol(feedbacksol, j, k, 'original')">{{word}}</span>
          </ng-container>
          <span>&nbsp;</span>
        </ng-container>
        <br>
        <br>
        <b>Nieuwe tekst: </b>
        <ng-container *ngFor="let sentence of feedbacksol.newtext; let j = index;">
          <ng-container *ngFor="let word of sentence.split(''); let k = index;">
            <span [class.addedWord]="wordInFeedbacksol(feedbacksol, j, k, 'new')">{{word}}</span>
          </ng-container>
          <span>&nbsp;</span>
        </ng-container>
        <br>
        <br>
        <b>Type: </b>
        <ng-container *ngFor="let type of feedbacksol.categorizationtypes; let j = index;">
          <span>{{type}}</span>
          <span *ngIf="j !== feedbacksol.categorizationtypes.length - 1">, </span>
        </ng-container>
        <hr *ngIf="i !== getFeedbackSolutionsFromUrl('https://www.standaard.be/cnt/dmf20200130_04827598').length - 1" style="border-top:3px dashed;">
      </ng-container>
    </div>
    <br>
    <h4 style="margin-left:38%;display:inline-block;" align="center">Correcte oplossing</h4>
    <img class="plus" src="assets/plus.png" *ngIf="correctsolutionChosen[5]===false" style="margin-left:4px;" width="15" height="15" (click)="clickExercise(6, 'correctsolution')">
    <img class="min" src="assets/minus.png" *ngIf="correctsolutionChosen[5]===true" style="margin-left:4px;" width="15" height="15" (click)="clickExercise(6, 'correctsolution')">
    <div *ngIf="correctsolutionChosen[5]===true" style="margin-left:15%;margin-right:15%;display:inline-block;">
      <h6>Aanpassing 1:</h6>
      <p><b>Originele tekst: </b><span style="color:red;"><b>Volgens AP staat nergens vermeld wanneer de dam klaar zou moeten zijn of waar hij geïnstalleerd zou worden.</b></span></p>
      <p><b>Nieuwe tekst: </b><span style="color:green;"><b>De dam zou voor de kust van Lesbos geïnstalleerd worden. Indien het succesvol blijkt te zijn, zullen extra delen worden toegevoegd om in totaal aan 13 à 15 kilometer te komen. Het is niet duidelijk wanneer de dam klaar zou moeten zijn.</b></span></p>
      <p><b>Type: </b>Update van oude informatie</p>
    </div>
  </div>
  <br>
  <h4 style="margin-left:45.5%;display:inline-block;" align="center">Feedback</h4>
  <img class="plus" src="assets/plus.png" *ngIf="feedbackChosen[5]===false" style="margin-left:4px;" width="15" height="15" (click)="clickExercise(6, 'feedback')">
  <img class="min" src="assets/minus.png" *ngIf="feedbackChosen[5]===true" style="margin-left:4px;" width="15" height="15" (click)="clickExercise(6, 'feedback')">
  <div *ngIf="feedbackChosen[5]===true" style="margin-left:15%;margin-right:15%;display:inline-block;">
    Hier gaat het op een "update van oude informatie" en dus niet om een "toevoeging/verduidelijking van informatie". De reden hiervoor is dat de informatie die in het oorspronkelijke artikel wordt vermeld niet meer helemaal correct is.
    Zo klopt het bijvoorbeeld niet meer dat er helemaal niet geweten is waar de dam zou moeten gebouwd worden. Het is echter ook niet correct om te zeggen dat deze aanpassing  zou behoren tot de categorie "Correctie van objectieve fout".
    Op het moment dat het artikel was gepubliceerd was de informatie immers écht niet voorhanden (dat bewijst het feit dat bronnen worden aangehaald die de informatie in het oorspronkelijke artikel bevestigen).
  </div>
  <hr>
  <h2 style="margin-left:43%;display:inline-block;" align="center">Artikelpaar 2</h2>
  <div style="margin-left:15%;margin-right:15%;display:inline-block;">
    <p><b>Jouw score: {{scores[1]}}/1</b></p>
    <p><i>Artikel werd geschreven door Knack en kan geraadpleegd worden op <a href="https://www.knack.be/nieuws/belgie/vivaldi-ploeg-wil-vanaf-2026-alleen-nog-elektrische-bedrijfswagens/article-news-1642505.html">https://www.knack.be/nieuws/belgie/vivaldi-ploeg-wil-vanaf-2026-alleen-nog-elektrische-bedrijfswagens/article-news-1642505.html</a>.</i></p>
    <br>
    <h4 style="margin-left:40%;display:inline-block;" align="center">Jouw oplossing</h4>
    <img class="plus" src="assets/plus.png" *ngIf="yoursolutionChosen[6]===false" style="margin-left:4px;" width="15" height="15" (click)="clickExercise(7, 'yoursolution')">
    <img class="min" src="assets/minus.png" *ngIf="yoursolutionChosen[6]===true" style="margin-left:4px;" width="15" height="15" (click)="clickExercise(7, 'yoursolution')">
    <div *ngIf="yoursolutionChosen[6]===true" style="margin-left:15%;margin-right:15%;display:inline-block;">
      <ng-container *ngFor="let feedbacksol of getFeedbackSolutionsFromUrl('https://www.knack.be/nieuws/belgie/vivaldi-ploeg-wil-vanaf-2026-alleen-nog-elektrische-bedrijfswagens/article-news-1642505.html');let i  = index">
        <h6>Aanpassing {{i + 1}}:</h6>
        <b>Originele tekst: </b>
        <ng-container *ngFor="let sentence of feedbacksol.originaltext; let j = index;">
          <ng-container *ngFor="let word of sentence.split(''); let k = index;">
            <span [class.removedWord]="wordInFeedbacksol(feedbacksol, j, k, 'original')">{{word}}</span>
          </ng-container>
          <span>&nbsp;</span>
        </ng-container>
        <br>
        <br>
        <b>Nieuwe tekst: </b>
        <ng-container *ngFor="let sentence of feedbacksol.newtext; let j = index;">
          <ng-container *ngFor="let word of sentence.split(''); let k = index;">
            <span [class.addedWord]="wordInFeedbacksol(feedbacksol, j, k, 'new')">{{word}}</span>
          </ng-container>
          <span>&nbsp;</span>
        </ng-container>
        <br>
        <br>
        <b>Type: </b>
        <ng-container *ngFor="let type of feedbacksol.categorizationtypes; let j = index;">
          <span>{{type}}</span>
          <span *ngIf="j !== feedbacksol.categorizationtypes.length - 1">, </span>
        </ng-container>
        <hr *ngIf="i !== getFeedbackSolutionsFromUrl('https://www.knack.be/nieuws/belgie/vivaldi-ploeg-wil-vanaf-2026-alleen-nog-elektrische-bedrijfswagens/article-news-1642505.html').length - 1" style="border-top:3px dashed;">
      </ng-container>
    </div>
    <br>
    <h4 style="margin-left:38%;display:inline-block;" align="center">Correcte oplossing</h4>
    <img class="plus" src="assets/plus.png" *ngIf="correctsolutionChosen[6]===false" style="margin-left:4px;" width="15" height="15" (click)="clickExercise(7, 'correctsolution')">
    <img class="min" src="assets/minus.png" *ngIf="correctsolutionChosen[6]===true" style="margin-left:4px;" width="15" height="15" (click)="clickExercise(7, 'correctsolution')">
    <div *ngIf="correctsolutionChosen[6]===true" style="margin-left:15%;margin-right:15%;display:inline-block;">
      <h6>Aanpassing 1:</h6>
      <p><b>Originele tekst: </b></p>
      <p><b>Nieuwe tekst: </b><span style="color:green;"><b>De Bond Beter Leefmilieu reageert enthousiast.</b></span></p><br>
      <p><b>Type: </b>Toevoeging/verduidelijking van informatie</p>
      <hr style="border-top:3px dashed;">
      <h6>Aanpassing 2:</h6>
      <p><b>Originele tekst: </b></p>
      <p><b>Nieuwe tekst: </b><span style="color:green;"><b>De Vlaamse milieukoepel Bond Beter Leefmilieu (BBL) is enthousiast over de maatregel van de Vivaldi-onderhandelaars om tegen 2026 alle nieuwe bedrijfswagens 'broeikasvrij' te maken. 'Het is een zeer goede maatregel', reageert Mathias Bienstman, beleids- en projectcoördinator van BBL, maar hij wil hem al in 2023 doen ingaan en niet pas in 2016.
        'De maatregel dringt de luchtvervuiling terug', zegt Bienstman. 'Hij reduceert broeikasgasemissies met miljoenen tonnen in 2030 en is goed voor de Belgische economie. Maar we willen dat alle nieuwe bedrijfswagens al emissievrij zijn in 2023. Over twee jaar zal er al een ruim aanbod zijn van betaalbare elektrische voertuigen met een bereik van meer dan 350 kilometer op een volle batterij. De maatregel moet samengaan met de onmiddellijke stopzetting van terugbetaling van brandstofkosten voor privéverplaatsingen.'</b></span></p>
      <p><b>Type: </b>Toevoeging/verduidelijking van informatie</p>
    </div>
  </div>
  <br>
  <h4 style="margin-left:45.5%;display:inline-block;" align="center">Feedback</h4>
  <img class="plus" src="assets/plus.png" *ngIf="feedbackChosen[6]===false" style="margin-left:4px;" width="15" height="15" (click)="clickExercise(7, 'feedback')">
  <img class="min" src="assets/minus.png" *ngIf="feedbackChosen[6]===true" style="margin-left:4px;" width="15" height="15" (click)="clickExercise(7, 'feedback')">
  <div *ngIf="feedbackChosen[6]===true" style="margin-left:15%;margin-right:15%;display:inline-block;">
    Aan de nieuwe versie van het artikel wordt enkel maar informatie toegevoegd, geen informatie gewijzigd. Bovendien wordt in de nieuw toegevoegde informatie niks uit het oorspronkelijk artikel tegengesproken.
    Het gaat hier dus duidelijk om "Toevoeging/verduidelijking van informatie". Meer specifiek gebeuren er twee aanpassingen, beiden van hetzelfde type, aangezien er op verschillende plaatsen in het artikel wordt ingegrepen.
  </div>
  <hr>
  <h2 style="margin-left:43%;display:inline-block;" align="center">Artikelpaar 3</h2>
  <div style="margin-left:15%;margin-right:15%;display:inline-block;">
    <p><b>Jouw score: {{scores[2]}}/1</b></p>
    <p><i>Artikel werd geschreven door Het Laatste Nieuws en kan geraadpleegd worden op <a href="https://www.hln.be/binnenland/versoepelingen-steeds-verder-weg-door-slechte-cijfers-als-we-blijven-hangen-zijn-er-eventueel-andere-maatregelen-nodig~af3565cd/">https://www.hln.be/binnenland/versoepelingen-steeds-verder-weg-door-slechte-cijfers-als-we-blijven-hangen-zijn-er-eventueel-andere-maatregelen-nodig~af3565cd/</a>.</i></p>
    <br>
    <h4 style="margin-left:40%;display:inline-block;" align="center">Jouw oplossing</h4>
    <img class="plus" src="assets/plus.png" *ngIf="yoursolutionChosen[7]===false" style="margin-left:4px;" width="15" height="15" (click)="clickExercise(8, 'yoursolution')">
    <img class="min" src="assets/minus.png" *ngIf="yoursolutionChosen[7]===true" style="margin-left:4px;" width="15" height="15" (click)="clickExercise(8, 'yoursolution')">
    <div *ngIf="yoursolutionChosen[7]===true" style="margin-left:15%;margin-right:15%;display:inline-block;">
      <ng-container *ngFor="let feedbacksol of getFeedbackSolutionsFromUrl('https://www.hln.be/binnenland/versoepelingen-steeds-verder-weg-door-slechte-cijfers-als-we-blijven-hangen-zijn-er-eventueel-andere-maatregelen-nodig~af3565cd/');let i  = index">
        <h6>Aanpassing {{i + 1}}:</h6>
        <b>Originele tekst: </b>
        <ng-container *ngFor="let sentence of feedbacksol.originaltext; let j = index;">
          <ng-container *ngFor="let word of sentence.split(''); let k = index;">
            <span [class.removedWord]="wordInFeedbacksol(feedbacksol, j, k, 'original')">{{word}}</span>
          </ng-container>
          <span>&nbsp;</span>
        </ng-container>
        <br>
        <br>
        <b>Nieuwe tekst: </b>
        <ng-container *ngFor="let sentence of feedbacksol.newtext; let j = index;">
          <ng-container *ngFor="let word of sentence.split(''); let k = index;">
            <span [class.addedWord]="wordInFeedbacksol(feedbacksol, j, k, 'new')">{{word}}</span>
          </ng-container>
          <span>&nbsp;</span>
        </ng-container>
        <br>
        <br>
        <b>Type: </b>
        <ng-container *ngFor="let type of feedbacksol.categorizationtypes; let j = index;">
          <span>{{type}}</span>
          <span *ngIf="j !== feedbacksol.categorizationtypes.length - 1">, </span>
        </ng-container>
        <hr *ngIf="i !== getFeedbackSolutionsFromUrl('https://www.hln.be/binnenland/versoepelingen-steeds-verder-weg-door-slechte-cijfers-als-we-blijven-hangen-zijn-er-eventueel-andere-maatregelen-nodig~af3565cd/').length - 1" style="border-top:3px dashed;">
      </ng-container>
    </div>
    <br>
    <h4 style="margin-left:38%;display:inline-block;" align="center">Correcte oplossing</h4>
    <img class="plus" src="assets/plus.png" *ngIf="correctsolutionChosen[7]===false" style="margin-left:4px;" width="15" height="15" (click)="clickExercise(8, 'correctsolution')">
    <img class="min" src="assets/minus.png" *ngIf="correctsolutionChosen[7]===true" style="margin-left:4px;" width="15" height="15" (click)="clickExercise(8, 'correctsolution')">
    <div *ngIf="correctsolutionChosen[7]===true" style="margin-left:15%;margin-right:15%;display:inline-block;">
      <h6>Aanpassing 1:</h6>
      <p><b>Originele tekst: </b>Tel daar nog 3 weken bij, en je zit al aan <span style="color:red;"><b>29 februari</b></span>.</p>
      <p><b>Nieuwe tekst: </b>Tel daar nog 3 weken bij, en je zit al aan <span style="color:green;"><b>1 maart</b></span>.</p>
      <p><b>Type: </b>Correctie van objectieve fout</p>
    </div>
  </div>
  <br>
  <h4 style="margin-left:45.5%;display:inline-block;" align="center">Feedback</h4>
  <img class="plus" src="assets/plus.png" *ngIf="feedbackChosen[7]===false" style="margin-left:4px;" width="15" height="15" (click)="clickExercise(8, 'feedback')">
  <img class="min" src="assets/minus.png" *ngIf="feedbackChosen[7]===true" style="margin-left:4px;" width="15" height="15" (click)="clickExercise(8, 'feedback')">
  <div *ngIf="feedbackChosen[7]===true" style="margin-left:15%;margin-right:15%;display:inline-block;">
    Aangezien 2021 geen schrikkeljaar is, bestaat de datum 29 februari 2021 niet. Het gaat hier dan ook om de "Correctie van een objectieve fout".
  </div>
  <hr>
  <h2 style="margin-left:43%;display:inline-block;" align="center">Artikelpaar 4</h2>
  <div style="margin-left:15%;margin-right:15%;display:inline-block;">
    <p><b>Jouw score: {{scores[3]}}/1</b></p>
    <p><i>Artikel werd geschreven door VRT NWS en kan geraadpleegd worden op <a href="https://sporza.be/nl/matches/voetbal/buitenland/fra/ligue-1-uber-eats/2020-2021/regulier/11/stade-rennes-girondins-de-bordeaux-invaller-jeremy-doku-treft-de-paal-en-verliest-opnieuw-met-rennes~1594309682473/?linkId=sporza%7Cvrtnws%7C%2Fnl%2Fnet-binnen%7Cteaser">https://sporza.be/nl/matches/voetbal/buitenland/fra/ligue-1-uber-eats/2020-2021/regulier/11/stade-rennes-girondins-de-bordeaux-invaller-jeremy-doku-treft-de-paal-en-verliest-opnieuw-met-rennes~1594309682473/?linkId=sporza%7Cvrtnws%7C%2Fnl%2Fnet-binnen%7Cteaser</a>.</i></p>
    <br>
    <h4 style="margin-left:40%;display:inline-block;" align="center">Jouw oplossing</h4>
    <img class="plus" src="assets/plus.png" *ngIf="yoursolutionChosen[8]===false" style="margin-left:4px;" width="15" height="15" (click)="clickExercise(9, 'yoursolution')">
    <img class="min" src="assets/minus.png" *ngIf="yoursolutionChosen[8]===true" style="margin-left:4px;" width="15" height="15" (click)="clickExercise(9, 'yoursolution')">
    <div *ngIf="yoursolutionChosen[8]===true" style="margin-left:15%;margin-right:15%;display:inline-block;">
      <ng-container *ngFor="let feedbacksol of getFeedbackSolutionsFromUrl('https://sporza.be/nl/matches/voetbal/buitenland/fra/ligue-1-uber-eats/2020-2021/regulier/11/stade-rennes-girondins-de-bordeaux-invaller-jeremy-doku-treft-de-paal-en-verliest-opnieuw-met-rennes~1594309682473/?linkId=sporza%7Cvrtnws%7C%2Fnl%2Fnet-binnen%7Cteaser');let i  = index">
        <h6>Aanpassing {{i + 1}}:</h6>
        <b>Originele tekst: </b>
        <ng-container *ngFor="let sentence of feedbacksol.originaltext; let j = index;">
          <ng-container *ngFor="let word of sentence.split(''); let k = index;">
            <span [class.removedWord]="wordInFeedbacksol(feedbacksol, j, k, 'original')">{{word}}</span>
          </ng-container>
          <span>&nbsp;</span>
        </ng-container>
        <br>
        <br>
        <b>Nieuwe tekst: </b>
        <ng-container *ngFor="let sentence of feedbacksol.newtext; let j = index;">
          <ng-container *ngFor="let word of sentence.split(''); let k = index;">
            <span [class.addedWord]="wordInFeedbacksol(feedbacksol, j, k, 'new')">{{word}}</span>
          </ng-container>
          <span>&nbsp;</span>
        </ng-container>
        <br>
        <br>
        <b>Type: </b>
        <ng-container *ngFor="let type of feedbacksol.categorizationtypes; let j = index;">
          <span>{{type}}</span>
          <span *ngIf="j !== feedbacksol.categorizationtypes.length - 1">, </span>
        </ng-container>
        <hr *ngIf="i !== getFeedbackSolutionsFromUrl('https://sporza.be/nl/matches/voetbal/buitenland/fra/ligue-1-uber-eats/2020-2021/regulier/11/stade-rennes-girondins-de-bordeaux-invaller-jeremy-doku-treft-de-paal-en-verliest-opnieuw-met-rennes~1594309682473/?linkId=sporza%7Cvrtnws%7C%2Fnl%2Fnet-binnen%7Cteaser').length - 1" style="border-top:3px dashed;">
      </ng-container>
    </div>
    <br>
    <h4 style="margin-left:38%;display:inline-block;" align="center">Correcte oplossing</h4>
    <img class="plus" src="assets/plus.png" *ngIf="correctsolutionChosen[8]===false" style="margin-left:4px;" width="15" height="15" (click)="clickExercise(9, 'correctsolution')">
    <img class="min" src="assets/minus.png" *ngIf="correctsolutionChosen[8]===true" style="margin-left:4px;" width="15" height="15" (click)="clickExercise(9, 'correctsolution')">
    <div *ngIf="correctsolutionChosen[8]===true" style="margin-left:15%;margin-right:15%;display:inline-block;">
      <h6>Aanpassing 1:</h6>
      <p><b>Originele tekst: </b>Het wou dus tegen Bordeaux vertrouwen tanken met het CL-duel tegen Chelsea dinsdag.</p>
      <p><b>Nieuwe tekst: </b>Het wou dus tegen Bordeaux vertrouwen tanken met het <span style="color:green;"><b>oog op het </b></span>CL-duel tegen Chelsea dinsdag.</p>
      <p><b>Type: </b>Correctie van spel- of taalfout</p>
    </div>
    <br>
    <h4 style="margin-left:43.5%;display:inline-block;" align="center">Feedback</h4>
    <img class="plus" src="assets/plus.png" *ngIf="feedbackChosen[8]===false" style="margin-left:4px;" width="15" height="15" (click)="clickExercise(9, 'feedback')">
    <img class="min" src="assets/minus.png" *ngIf="feedbackChosen[8]===true" style="margin-left:4px;" width="15" height="15" (click)="clickExercise(9, 'feedback')">
    <div *ngIf="feedbackChosen[8]===true" style="margin-left:15%;margin-right:15%;display:inline-block;">
      In het originele artikel is een deel van een spreekwoordelijke uitdrukking (met het oog op) afwezig, waardoor de zin grammaticaal niet meer ok is.
      Het gaat hier dus duidelijk om een "Correctie van spel- of taalfout".
    </div>
  </div>
  <hr>
  <h2 style="margin-left:43%;display:inline-block;" align="center">Artikelpaar 5</h2>
  <div style="margin-left:15%;margin-right:15%;display:inline-block;">
    <p><b>Jouw score: {{scores[4]}}/1</b></p>
    <p><i>Artikel werd geschreven door Nieuwsblad en kan geraadpleegd worden op <a href="https://www.nieuwsblad.be/cnt/dmf20200115_04806821">https://www.nieuwsblad.be/cnt/dmf20200115_04806821</a>.</i></p>
    <br>
    <h4 style="margin-left:40%;display:inline-block;" align="center">Jouw oplossing</h4>
    <img class="plus" src="assets/plus.png" *ngIf="yoursolutionChosen[14]===false" style="margin-left:4px;" width="15" height="15" (click)="clickExercise(15, 'yoursolution')">
    <img class="min" src="assets/minus.png" *ngIf="yoursolutionChosen[14]===true" style="margin-left:4px;" width="15" height="15" (click)="clickExercise(15, 'yoursolution')">
    <div *ngIf="yoursolutionChosen[14]===true" style="margin-left:15%;margin-right:15%;display:inline-block;">
      <ng-container *ngFor="let feedbacksol of getFeedbackSolutionsFromUrl('https://www.nieuwsblad.be/cnt/dmf20200115_04806821');let i  = index">
        <h6>Aanpassing {{i + 1}}:</h6>
        <b>Originele tekst: </b>
        <ng-container *ngFor="let sentence of feedbacksol.originaltext; let j = index;">
          <ng-container *ngFor="let word of sentence.split(''); let k = index;">
            <span [class.removedWord]="wordInFeedbacksol(feedbacksol, j, k, 'original')">{{word}}</span>
          </ng-container>
          <span>&nbsp;</span>
        </ng-container>
        <br>
        <br>
        <b>Nieuwe tekst: </b>
        <ng-container *ngFor="let sentence of feedbacksol.newtext; let j = index;">
          <ng-container *ngFor="let word of sentence.split(''); let k = index;">
            <span [class.addedWord]="wordInFeedbacksol(feedbacksol, j, k, 'new')">{{word}}</span>
          </ng-container>
          <span>&nbsp;</span>
        </ng-container>
        <br>
        <br>
        <b>Type: </b>
        <ng-container *ngFor="let type of feedbacksol.categorizationtypes; let j = index;">
          <span>{{type}}</span>
          <span *ngIf="j !== feedbacksol.categorizationtypes.length - 1">, </span>
        </ng-container>
        <hr *ngIf="i !== getFeedbackSolutionsFromUrl('https://www.nieuwsblad.be/cnt/dmf20200115_04806821').length - 1" style="border-top:3px dashed;">
      </ng-container>
    </div>
    <br>
    <h4 style="margin-left:38%;display:inline-block;" align="center">Correcte oplossing</h4>
    <img class="plus" src="assets/plus.png" *ngIf="correctsolutionChosen[14]===false" style="margin-left:4px;" width="15" height="15" (click)="clickExercise(15, 'correctsolution')">
    <img class="min" src="assets/minus.png" *ngIf="correctsolutionChosen[14]===true" style="margin-left:4px;" width="15" height="15" (click)="clickExercise(15, 'correctsolution')">
    <div *ngIf="correctsolutionChosen[14]===true" style="margin-left:15%;margin-right:15%;display:inline-block;">
      <h6>Aanpassing 1:</h6>
      <p><b>Originele tekst: </b>Levensgevaarlijke act <span style="color:red;"><b>gaat</b></span> helemaal <span style="color:red;"><b>fout</b></span> in ‘America’s got talent’ </p>
      <p><b>Nieuwe tekst: </b>Levensgevaarlijke act <span style="color:green;"><b>loopt</b></span> helemaal <span style="color:green;"><b>uit de hand</b></span> in ‘America’s got talent’ </p>
      <p><b>Type: </b>Inhoudsneutrale aanpassing</p>
      <hr style="border-top:3px dashed;">
      <h6>Aanpassing 2:</h6>
      <p><b>Originele tekst: </b>Die bestond uit een spelletje <span style="color:red;"><b>Russsische</b></span> roulette, maar dan met een kruisboog. </p>
      <p><b>Nieuwe tekst: </b>Die bestond uit een spelletje <span style="color:green;"><b>Russische</b></span> roulette, maar dan met een kruisboog. </p>
      <p><b>Type: </b>Correctie van spel- of taalfout</p>
      <hr style="border-top:3px dashed;">
      <h6>Aanpassing 3:</h6>
      <p><b>Originele tekst: </b>Dixon moest die <span style="color:red;"><b>één</b></span> voor een afvuren op hem. </p>
      <p><b>Nieuwe tekst: </b>Dixon moest die <span style="color:green;"><b>een</b></span> voor een afvuren op hem. </p>
      <p><b>Type: </b>Correctie van spel- of taalfout</p>
    </div>
    <br>
    <h4 style="margin-left:43.5%;display:inline-block;" align="center">Feedback</h4>
    <img class="plus" src="assets/plus.png" *ngIf="feedbackChosen[14]===false" style="margin-left:4px;" width="15" height="15" (click)="clickExercise(15, 'feedback')">
    <img class="min" src="assets/minus.png" *ngIf="feedbackChosen[14]===true" style="margin-left:4px;" width="15" height="15" (click)="clickExercise(15, 'feedback')">
    <div *ngIf="feedbackChosen[14]===true" style="margin-left:15%;margin-right:15%;display:inline-block;">
      In de overgang tussen deze twee artikelversies worden verschillende aanpassingen gedaan, die elk ook van een verschillend type zijn.
      Zo wordt de titel aangepast, waarbij gekozen wordt voor een andere uitdrukking die in se geen extra informatie of context uit het originele artikel verwijderd of aan het nieuwe artikel toevoegt. Hier gaat het dus om een "Inhoudsneutrale aanpassing".
      Daarnaast worden er ook twee spel- of taalfouten gecorrigeerd.
    </div>
  </div>
  <br>
</div>

<div *ngIf="(feedbackCompleted===true) && checkFeedbackStage(3) && this.scores">
  <h2 style="margin-left:43%;display:inline-block;" align="center">Artikelpaar 1</h2>
  <div style="margin-left:15%;margin-right:15%;display:inline-block;">
    <p><b>Jouw score: {{scores[0]}}/1</b></p>
    <p><i>Artikel werd geschreven door De Morgen en kan geraadpleegd worden op <a href="https://www.demorgen.be/nieuws/430-000-sms-en-voor-zieke-baby-pia-proximus-en-telenet-laten-kosten-vallen~bb5c3b48/">https://www.demorgen.be/nieuws/430-000-sms-en-voor-zieke-baby-pia-proximus-en-telenet-laten-kosten-vallen~bb5c3b48/</a>.</i></p>
    <br>
    <h4 style="margin-left:40%;display:inline-block;" align="center">Jouw oplossing</h4>
    <img class="plus" src="assets/plus.png" *ngIf="yoursolutionChosen[10]===false" style="margin-left:4px;" width="15" height="15" (click)="clickExercise(11, 'yoursolution')">
    <img class="min" src="assets/minus.png" *ngIf="yoursolutionChosen[10]===true" style="margin-left:4px;" width="15" height="15" (click)="clickExercise(11, 'yoursolution')">
    <div *ngIf="yoursolutionChosen[10]===true" style="margin-left:15%;margin-right:15%;display:inline-block;">
      <ng-container *ngFor="let feedbacksol of getFeedbackSolutionsFromUrl('https://www.demorgen.be/nieuws/430-000-sms-en-voor-zieke-baby-pia-proximus-en-telenet-laten-kosten-vallen~bb5c3b48/');let i  = index">
        <h6>Aanpassing {{i + 1}}:</h6>
        <b>Originele tekst: </b>
        <ng-container *ngFor="let sentence of feedbacksol.originaltext; let j = index;">
          <ng-container *ngFor="let word of sentence.split(''); let k = index;">
            <span [class.removedWord]="wordInFeedbacksol(feedbacksol, j, k, 'original')">{{word}}</span>
          </ng-container>
          <span>&nbsp;</span>
        </ng-container>
        <br>
        <br>
        <b>Nieuwe tekst: </b>
        <ng-container *ngFor="let sentence of feedbacksol.newtext; let j = index;">
          <ng-container *ngFor="let word of sentence.split(''); let k = index;">
            <span [class.addedWord]="wordInFeedbacksol(feedbacksol, j, k, 'new')">{{word}}</span>
          </ng-container>
          <span>&nbsp;</span>
        </ng-container>
        <br>
        <br>
        <b>Type: </b>
        <ng-container *ngFor="let type of feedbacksol.categorizationtypes; let j = index;">
          <span>{{type}}</span>
          <span *ngIf="j !== feedbacksol.categorizationtypes.length - 1">, </span>
        </ng-container>
        <hr *ngIf="i !== getFeedbackSolutionsFromUrl('https://www.demorgen.be/nieuws/430-000-sms-en-voor-zieke-baby-pia-proximus-en-telenet-laten-kosten-vallen~bb5c3b48/').length - 1" style="border-top:3px dashed;">
      </ng-container>
    </div>
    <br>
    <h4 style="margin-left:38%;display:inline-block;" align="center">Correcte oplossing</h4>
    <img class="plus" src="assets/plus.png" *ngIf="correctsolutionChosen[10]===false" style="margin-left:4px;" width="15" height="15" (click)="clickExercise(11, 'correctsolution')">
    <img class="min" src="assets/minus.png" *ngIf="correctsolutionChosen[10]===true" style="margin-left:4px;" width="15" height="15" (click)="clickExercise(11, 'correctsolution')">
    <div *ngIf="correctsolutionChosen[10]===true" style="margin-left:15%;margin-right:15%;display:inline-block;">
      <h6>Aanpassing 1:</h6>
      <p><b>Originele tekst: </b>Reeds <span style="color:red;"><b>430.000</b></span> sms’en voor zieke baby Pia, Proximus en Telenet laten kosten vallen</p>
      <p><b>Nieuwe tekst: </b>Reeds <span style="color:green;"><b>450.000</b></span> sms’en voor zieke baby Pia, Proximus en Telenet laten kosten vallen</p>
      <p><b>Type: </b>Update van oude informatie</p>
      <hr style="border-top:3px dashed;">
      <h6>Aanpassing 2:</h6>
      <p><b>Originele tekst: </b>Al meer dan <span style="color:red;"><b>430.000</b></span> mensen stuurden al een sms ter waarde van 2 euro.</p>
      <p><b>Nieuwe tekst: </b> Al meer dan <span style="color:green;"><b>450.000</b></span> mensen stuurden al een sms ter waarde van 2 euro.</p>
      <p><b>Type: </b>Update van oude informatie</p>
      <hr style="border-top:3px dashed;">
      <h6>Aanpassing 3:</h6>
      <p><b>Originele tekst: </b>(Intussen staat de teller al op meer dan <span style="color:red;"><b>430.000</b></span>, redactie.) “Na al die tegenslagen, de ene ‘nee’ na de andere, het farmabedrijf en de overheid die ons in de steek laten, stráált ons gezin vandaag.</p>
      <p><b>Nieuwe tekst: </b>(Intussen staat de teller al op meer dan <span style="color:green;"><b>450.000</b></span>, redactie.) “Na al die tegenslagen, de ene ‘nee’ na de andere, het farmabedrijf en de overheid die ons in de steek laten, stráált ons gezin vandaag.</p>
      <p><b>Type: </b>Update van oude informatie</p>
      <hr style="border-top:3px dashed;">
      <h6>Aanpassing 4:</h6>
      <p><b>Originele tekst: </b>Al meer dan <span style="color:red;"><b>430.000</b></span> mensen stuurden een sms. </p>
      <p><b>Nieuwe tekst: </b>Al meer dan <span style="color:green;"><b>450.000</b></span> mensen stuurden een sms. </p>
      <p><b>Type: </b>Update van oude informatie</p>
      <hr style="border-top:3px dashed;">
    </div>
  </div>
  <br>
  <h4 style="margin-left:45.5%;display:inline-block;" align="center">Feedback</h4>
  <img class="plus" src="assets/plus.png" *ngIf="feedbackChosen[10]===false" style="margin-left:4px;" width="15" height="15" (click)="clickExercise(11, 'feedback')">
  <img class="min" src="assets/minus.png" *ngIf="feedbackChosen[10]===true" style="margin-left:4px;" width="15" height="15" (click)="clickExercise(11, 'feedback')">
  <div *ngIf="feedbackChosen[10]===true" style="margin-left:15%;margin-right:15%;display:inline-block;">
    In de overgang tussen deze beide artikelversies worden vier aanpassingen gemaakt die in essentie altijd hetzelfde zijn, namelijk het aanpassen van het aantal 430000 naar 450000.
    Ook al zou je op het eerste zicht kunnen zeggen dat het hier om een "Correctie van objectieve fout" gaat (namelijk een foutief aantal), is dit hier niet zo. De informatie in het oorspronkelijke artikel was immers effectief correct.
    Dit artikel werd doorheen de tijd meerdere keren aangepast om het aantal sms'en up to date te houden. Dit blijkt ook uit het feit dat het artikel het aantal sms'en steevast als een minimum beschouwd. Het gaat hier dus telkens om "Updates van oude informatie".
  </div>
  <hr>
  <h2 style="margin-left:43%;display:inline-block;" align="center">Artikelpaar 2</h2>
  <div style="margin-left:15%;margin-right:15%;display:inline-block;">
    <p><b>Jouw score: {{scores[1]}}/1</b></p>
    <p><i>Artikel werd geschreven door Het Laatste Nieuws en kan geraadpleegd worden op <a href="https://www.hln.be/wetenschap-planeet/medisch/amerikaans-biotechbedrijf-start-eind-deze-maand-laatste-fase-klinische-studie-voor-coronavaccin~a47c4846/">https://www.hln.be/wetenschap-planeet/medisch/amerikaans-biotechbedrijf-start-eind-deze-maand-laatste-fase-klinische-studie-voor-coronavaccin~a47c4846/</a>.</i></p>
    <br>
    <h4 style="margin-left:40%;display:inline-block;" align="center">Jouw oplossing</h4>
    <img class="plus" src="assets/plus.png" *ngIf="yoursolutionChosen[11]===false" style="margin-left:4px;" width="15" height="15" (click)="clickExercise(12, 'yoursolution')">
    <img class="min" src="assets/minus.png" *ngIf="yoursolutionChosen[11]===true" style="margin-left:4px;" width="15" height="15" (click)="clickExercise(12, 'yoursolution')">
    <div *ngIf="yoursolutionChosen[11]===true" style="margin-left:15%;margin-right:15%;display:inline-block;">
      <ng-container *ngFor="let feedbacksol of getFeedbackSolutionsFromUrl('https://www.hln.be/wetenschap-planeet/medisch/amerikaans-biotechbedrijf-start-eind-deze-maand-laatste-fase-klinische-studie-voor-coronavaccin~a47c4846/');let i  = index">
        <h6>Aanpassing {{i + 1}}:</h6>
        <b>Originele tekst: </b>
        <ng-container *ngFor="let sentence of feedbacksol.originaltext; let j = index;">
          <ng-container *ngFor="let word of sentence.split(''); let k = index;">
            <span [class.removedWord]="wordInFeedbacksol(feedbacksol, j, k, 'original')">{{word}}</span>
          </ng-container>
          <span>&nbsp;</span>
        </ng-container>
        <br>
        <br>
        <b>Nieuwe tekst: </b>
        <ng-container *ngFor="let sentence of feedbacksol.newtext; let j = index;">
          <ng-container *ngFor="let word of sentence.split(''); let k = index;">
            <span [class.addedWord]="wordInFeedbacksol(feedbacksol, j, k, 'new')">{{word}}</span>
          </ng-container>
          <span>&nbsp;</span>
        </ng-container>
        <br>
        <br>
        <b>Type: </b>
        <ng-container *ngFor="let type of feedbacksol.categorizationtypes; let j = index;">
          <span>{{type}}</span>
          <span *ngIf="j !== feedbacksol.categorizationtypes.length - 1">, </span>
        </ng-container>
        <hr *ngIf="i !== getFeedbackSolutionsFromUrl('https://www.hln.be/wetenschap-planeet/medisch/amerikaans-biotechbedrijf-start-eind-deze-maand-laatste-fase-klinische-studie-voor-coronavaccin~a47c4846/').length - 1" style="border-top:3px dashed;">
      </ng-container>
    </div>
    <br>
    <h4 style="margin-left:38%;display:inline-block;" align="center">Correcte oplossing</h4>
    <img class="plus" src="assets/plus.png" *ngIf="correctsolutionChosen[11]===false" style="margin-left:4px;" width="15" height="15" (click)="clickExercise(12, 'correctsolution')">
    <img class="min" src="assets/minus.png" *ngIf="correctsolutionChosen[11]===true" style="margin-left:4px;" width="15" height="15" (click)="clickExercise(12, 'correctsolution')">
    <div *ngIf="correctsolutionChosen[11]===true" style="margin-left:15%;margin-right:15%;display:inline-block;">
      <h6>Aanpassing 1:</h6>
      <p><b>Originele tekst: </b>MEDISCH Het Amerikaanse biotechbedrijf Moderna start op <span style="color:red;"><b>de zevenentwintigste</b></span> de finale fase van de klinische tests voor de ontwikkeling van een vaccin tegen het coronavirus. </p>
      <p><b>Nieuwe tekst: </b>MEDISCH Het Amerikaanse biotechbedrijf Moderna start op <span style="color:green;"><b>27 juli</b></span> de finale fase van de klinische tests voor de ontwikkeling van een vaccin tegen het coronavirus.</p><br>
      <p><b>Type: </b>Correctie van subjectieve fout</p>
    </div>
  </div>
  <br>
  <h4 style="margin-left:45.5%;display:inline-block;" align="center">Feedback</h4>
  <img class="plus" src="assets/plus.png" *ngIf="feedbackChosen[11]===false" style="margin-left:4px;" width="15" height="15" (click)="clickExercise(12, 'feedback')">
  <img class="min" src="assets/minus.png" *ngIf="feedbackChosen[11]===true" style="margin-left:4px;" width="15" height="15" (click)="clickExercise(12, 'feedback')">
  <div *ngIf="feedbackChosen[11]===true" style="margin-left:15%;margin-right:15%;display:inline-block;">
    In het originele artikel wordt nergens de maand "juli" vermeld. Daardoor is het niet duidelijk over de 27e van welke maand er precies gesproken wordt in het artikel. Dit kan beschouwd worden als het ontbreken van essentiële informatie, waardoor het hier gaat om een "Correctie van een subjectieve fout".
  </div>
  <hr>
  <h2 style="margin-left:43%;display:inline-block;" align="center">Artikelpaar 3</h2>
  <div style="margin-left:15%;margin-right:15%;display:inline-block;">
    <p><b>Jouw score: {{scores[2]}}/1</b></p>
    <p><i>Artikel werd geschreven door Het Nieuwsblad en kan geraadpleegd worden op <a href="https://www.nieuwsblad.be/cnt/dmf20191126_04738694">https://www.nieuwsblad.be/cnt/dmf20191126_04738694</a>.</i></p>
    <br>
    <h4 style="margin-left:40%;display:inline-block;" align="center">Jouw oplossing</h4>
    <img class="plus" src="assets/plus.png" *ngIf="yoursolutionChosen[12]===false" style="margin-left:4px;" width="15" height="15" (click)="clickExercise(13, 'yoursolution')">
    <img class="min" src="assets/minus.png" *ngIf="yoursolutionChosen[12]===true" style="margin-left:4px;" width="15" height="15" (click)="clickExercise(13, 'yoursolution')">
    <div *ngIf="yoursolutionChosen[12]===true" style="margin-left:15%;margin-right:15%;display:inline-block;">
      <ng-container *ngFor="let feedbacksol of getFeedbackSolutionsFromUrl('https://www.nieuwsblad.be/cnt/dmf20191126_04738694');let i  = index">
        <h6>Aanpassing {{i + 1}}:</h6>
        <b>Originele tekst: </b>
        <ng-container *ngFor="let sentence of feedbacksol.originaltext; let j = index;">
          <ng-container *ngFor="let word of sentence.split(''); let k = index;">
            <span [class.removedWord]="wordInFeedbacksol(feedbacksol, j, k, 'original')">{{word}}</span>
          </ng-container>
          <span>&nbsp;</span>
        </ng-container>
        <br>
        <br>
        <b>Nieuwe tekst: </b>
        <ng-container *ngFor="let sentence of feedbacksol.newtext; let j = index;">
          <ng-container *ngFor="let word of sentence.split(''); let k = index;">
            <span [class.addedWord]="wordInFeedbacksol(feedbacksol, j, k, 'new')">{{word}}</span>
          </ng-container>
          <span>&nbsp;</span>
        </ng-container>
        <br>
        <br>
        <b>Type: </b>
        <ng-container *ngFor="let type of feedbacksol.categorizationtypes; let j = index;">
          <span>{{type}}</span>
          <span *ngIf="j !== feedbacksol.categorizationtypes.length - 1">, </span>
        </ng-container>
        <hr *ngIf="i !== getFeedbackSolutionsFromUrl('https://www.nieuwsblad.be/cnt/dmf20191126_04738694').length - 1" style="border-top:3px dashed;">
      </ng-container>
    </div>
    <br>
    <h4 style="margin-left:38%;display:inline-block;" align="center">Correcte oplossing</h4>
    <img class="plus" src="assets/plus.png" *ngIf="correctsolutionChosen[12]===false" style="margin-left:4px;" width="15" height="15" (click)="clickExercise(13, 'correctsolution')">
    <img class="min" src="assets/minus.png" *ngIf="correctsolutionChosen[12]===true" style="margin-left:4px;" width="15" height="15" (click)="clickExercise(13, 'correctsolution')">
    <div *ngIf="correctsolutionChosen[12]===true" style="margin-left:15%;margin-right:15%;display:inline-block;">
      <h6>Aanpassing 1:</h6>
      <p><b>Originele tekst: </b>Op het kruispunt van de Kloosterstraat en de <span style="color:red;"><b>Werchtersesteenweg</b></span> in Haacht is dinsdagnamiddag een 74-jarige fietser om het leven gekomen.</p>
      <p><b>Nieuwe tekst: </b>Op het kruispunt van de Kloosterstraat en de <span style="color:green;"><b>Wespelaarsesteenweg</b></span> in Haacht is dinsdagnamiddag een 74-jarige fietser om het leven gekomen. </p>
      <p><b>Type: </b>Correctie van objectieve fout</p>
    </div>
  </div>
  <br>
  <h4 style="margin-left:43.5%;display:inline-block;" align="center">Feedback</h4>
  <img class="plus" src="assets/plus.png" *ngIf="feedbackChosen[12]===false" style="margin-left:4px;" width="15" height="15" (click)="clickExercise(13, 'feedback')">
  <img class="min" src="assets/minus.png" *ngIf="feedbackChosen[12]===true" style="margin-left:4px;" width="15" height="15" (click)="clickExercise(13, 'feedback')">
  <div *ngIf="feedbackChosen[12]===true" style="margin-left:15%;margin-right:15%;display:inline-block;">
     In de overgang tussen deze twee artikelversies wordt de naam van de steenweg veranderd waar het ongeval zou zijn gebeurd.
    In de oorspronkelijke versie van het artikel wordt nergens een bron aangehaald die bevestigd dat het ongeval zich zou hebben afgespeeld op het kruispunt met de Werchtersesteenweg.
    De ochtend nadien wordt deze fout dan aangepast. We kunnen er hier dus vanuit gaan dat het effectief om een "Correctie van objectieve fout: foutieve locatie" gaat.
  </div>
  <hr>
  <h2 style="margin-left:43%;display:inline-block;" align="center">Artikelpaar 4</h2>
  <div style="margin-left:15%;margin-right:15%;display:inline-block;">
    <p><b>Jouw score: {{scores[3]}}/1</b></p>
    <p><i>Artikel werd geschreven door De Standaard en kan geraadpleegd worden op <a href="https://www.standaard.be/cnt/dmf20191129_04743838">https://www.standaard.be/cnt/dmf20191129_04743838</a>.</i></p>
    <br>
    <h4 style="margin-left:40%;display:inline-block;" align="center">Jouw oplossing</h4>
    <img class="plus" src="assets/plus.png" *ngIf="yoursolutionChosen[13]===false" style="margin-left:4px;" width="15" height="15" (click)="clickExercise(14, 'yoursolution')">
    <img class="min" src="assets/minus.png" *ngIf="yoursolutionChosen[13]===true" style="margin-left:4px;" width="15" height="15" (click)="clickExercise(14, 'yoursolution')">
    <div *ngIf="yoursolutionChosen[13]===true" style="margin-left:15%;margin-right:15%;display:inline-block;">
      <ng-container *ngFor="let feedbacksol of getFeedbackSolutionsFromUrl('https://www.standaard.be/cnt/dmf20191129_04743838');let i  = index">
        <h6>Aanpassing {{i + 1}}:</h6>
        <b>Originele tekst: </b>
        <ng-container *ngFor="let sentence of feedbacksol.originaltext; let j = index;">
          <ng-container *ngFor="let word of sentence.split(''); let k = index;">
            <span [class.removedWord]="wordInFeedbacksol(feedbacksol, j, k, 'original')">{{word}}</span>
          </ng-container>
          <span>&nbsp;</span>
        </ng-container>
        <br>
        <br>
        <b>Nieuwe tekst: </b>
        <ng-container *ngFor="let sentence of feedbacksol.newtext; let j = index;">
          <ng-container *ngFor="let word of sentence.split(''); let k = index;">
            <span [class.addedWord]="wordInFeedbacksol(feedbacksol, j, k, 'new')">{{word}}</span>
          </ng-container>
          <span>&nbsp;</span>
        </ng-container>
        <br>
        <br>
        <b>Type: </b>
        <ng-container *ngFor="let type of feedbacksol.categorizationtypes; let j = index;">
          <span>{{type}}</span>
          <span *ngIf="j !== feedbacksol.categorizationtypes.length - 1">, </span>
        </ng-container>
        <hr *ngIf="i !== getFeedbackSolutionsFromUrl('https://www.standaard.be/cnt/dmf20191129_04743838').length - 1" style="border-top:3px dashed;">
      </ng-container>
    </div>
    <br>
    <h4 style="margin-left:38%;display:inline-block;" align="center">Correcte oplossing</h4>
    <img class="plus" src="assets/plus.png" *ngIf="correctsolutionChosen[13]===false" style="margin-left:4px;" width="15" height="15" (click)="clickExercise(14, 'correctsolution')">
    <img class="min" src="assets/minus.png" *ngIf="correctsolutionChosen[13]===true" style="margin-left:4px;" width="15" height="15" (click)="clickExercise(14, 'correctsolution')">
    <div *ngIf="correctsolutionChosen[13]===true" style="margin-left:15%;margin-right:15%;display:inline-block;">
      <h6>Aanpassing 1:</h6>
      <p><b>Originele tekst: </b>Met <span style="color:red;"><b>zowat 64</b></span> procent tegen <span style="color:red;"><b>36</b></span> procent voor zijn uitdager Denis Ducarme, heeft Georges-Louis Bouchez de voorzittersverkiezingen bij de MR gewonnen. </p>
      <p><b>Nieuwe tekst: </b>Met <span style="color:green;"><b>62</b></span> procent tegen <span style="color:green;"><b>38</b></span> procent voor zijn uitdager Denis Ducarme, heeft Georges-Louis Bouchez de voorzittersverkiezingen bij de MR gewonnen. </p>
      <p><b>Type: </b>Update van oude informatie</p>
      <hr style="border-top:3px dashed;">
      <h6>Aanpassing 2:</h6>
      <p><b>Originele tekst: </b><span style="color:red;"><b>Een definitieve officiële uitslag is er nog niet, maar Bouchez kreeg zeker meer dan 60 procent en werd al uitgeroepen tot winnaar door de partij. </b></span></p>
      <p><b>Nieuwe tekst: </b></p>
      <p><b>Type: </b>Verwijdering van informatie</p>
    </div>
    <br>
    <h4 style="margin-left:43.5%;display:inline-block;" align="center">Feedback</h4>
    <img class="plus" src="assets/plus.png" *ngIf="feedbackChosen[13]===false" style="margin-left:4px;" width="15" height="15" (click)="clickExercise(14, 'feedback')">
    <img class="min" src="assets/minus.png" *ngIf="feedbackChosen[13]===true" style="margin-left:4px;" width="15" height="15" (click)="clickExercise(14, 'feedback')">
    <div *ngIf="feedbackChosen[13]===true" style="margin-left:15%;margin-right:15%;display:inline-block;">
      In de overgang tussen deze twee artikelversies gebeuren twee aanpassingen die beiden gerelateerd zijn: er komt namelijk nieuwe informatie binnen over de verkiezingen, waardoor de percentages accurater (definitief) worden, en de zin die verwijderd wordt in de tweede aanpassing overbodig wordt.
      Het gaat dus twee keer om oude informatie die verwijderd wordt/aangepast wordt, omdat er nieuwe informatie beschikbaar is. Het gaat dus twee keer om een "Update van oude informatie".
    </div>
  </div>
  <hr>
  <h2 style="margin-left:43%;display:inline-block;" align="center">Artikelpaar 5</h2>
  <div style="margin-left:15%;margin-right:15%;display:inline-block;">
    <p><b>Jouw score: {{scores[4]}}/1</b></p>
    <p><i>Artikel werd geschreven door Het Laatste Nieuws en kan geraadpleegd worden op <a href="https://www.hln.be/in-de-buurt/antwerpen/auto-rijdt-in-op-vrachtwagen-e313-in-ranst-volledig-versperd~a553742c/">https://www.hln.be/in-de-buurt/antwerpen/auto-rijdt-in-op-vrachtwagen-e313-in-ranst-volledig-versperd~a553742c/</a>.</i></p>
    <br>
    <h4 style="margin-left:40%;display:inline-block;" align="center">Jouw oplossing</h4>
    <img class="plus" src="assets/plus.png" *ngIf="yoursolutionChosen[9]===false" style="margin-left:4px;" width="15" height="15" (click)="clickExercise(10, 'yoursolution')">
    <img class="min" src="assets/minus.png" *ngIf="yoursolutionChosen[9]===true" style="margin-left:4px;" width="15" height="15" (click)="clickExercise(10, 'yoursolution')">
    <div *ngIf="yoursolutionChosen[9]===true" style="margin-left:15%;margin-right:15%;display:inline-block;">
      <ng-container *ngFor="let feedbacksol of getFeedbackSolutionsFromUrl('https://www.hln.be/in-de-buurt/antwerpen/auto-rijdt-in-op-vrachtwagen-e313-in-ranst-volledig-versperd~a553742c/');let i  = index">
        <h6>Aanpassing {{i + 1}}:</h6>
        <b>Originele tekst: </b>
        <ng-container *ngFor="let sentence of feedbacksol.originaltext; let j = index;">
          <ng-container *ngFor="let word of sentence.split(''); let k = index;">
            <span [class.removedWord]="wordInFeedbacksol(feedbacksol, j, k, 'original')">{{word}}</span>
          </ng-container>
          <span>&nbsp;</span>
        </ng-container>
        <br>
        <br>
        <b>Nieuwe tekst: </b>
        <ng-container *ngFor="let sentence of feedbacksol.newtext; let j = index;">
          <ng-container *ngFor="let word of sentence.split(''); let k = index;">
            <span [class.addedWord]="wordInFeedbacksol(feedbacksol, j, k, 'new')">{{word}}</span>
          </ng-container>
          <span>&nbsp;</span>
        </ng-container>
        <br>
        <br>
        <b>Type: </b>
        <ng-container *ngFor="let type of feedbacksol.categorizationtypes; let j = index;">
          <span>{{type}}</span>
          <span *ngIf="j !== feedbacksol.categorizationtypes.length - 1">, </span>
        </ng-container>
        <hr *ngIf="i !== getFeedbackSolutionsFromUrl('https://www.hln.be/in-de-buurt/antwerpen/auto-rijdt-in-op-vrachtwagen-e313-in-ranst-volledig-versperd~a553742c/').length - 1" style="border-top:3px dashed;">
      </ng-container>
    </div>
    <br>
    <h4 style="margin-left:38%;display:inline-block;" align="center">Correcte oplossing</h4>
    <img class="plus" src="assets/plus.png" *ngIf="correctsolutionChosen[9]===false" style="margin-left:4px;" width="15" height="15" (click)="clickExercise(10, 'correctsolution')">
    <img class="min" src="assets/minus.png" *ngIf="correctsolutionChosen[9]===true" style="margin-left:4px;" width="15" height="15" (click)="clickExercise(10, 'correctsolution')">
    <div *ngIf="correctsolutionChosen[9]===true" style="margin-left:15%;margin-right:15%;display:inline-block;">
      <h6>Aanpassing 1:</h6>
      <p><b>Originele tekst: </b></p>
      <p><b>Nieuwe tekst: </b> <span style="color:green;"><b>De snelweg gaat op dit moment terug open.</b></span> </p>
      <p><b>Type: </b>Toevoeging/verduidelijking van informatie</p>
      <hr style="border-top:3px dashed;">
      <h6>Aanpassing 2:</h6>
      <p><b>Originele tekst: </b></p>
      <p><b>Nieuwe tekst: </b> <span style="color:green;"><b>De snelweg gaat op dit moment terug open.</b></span></p>
      <p><b>Type: </b>Toevoeging/verduidelijking van informatie</p>
    </div>
    <br>
    <h4 style="margin-left:43.5%;display:inline-block;" align="center">Feedback</h4>
    <img class="plus" src="assets/plus.png" *ngIf="feedbackChosen[9]===false" style="margin-left:4px;" width="15" height="15" (click)="clickExercise(10, 'feedback')">
    <img class="min" src="assets/minus.png" *ngIf="feedbackChosen[9]===true" style="margin-left:4px;" width="15" height="15" (click)="clickExercise(10, 'feedback')">
    <div *ngIf="feedbackChosen[9]===true" style="margin-left:15%;margin-right:15%;display:inline-block;">
      In de overgang tussen deze twee opeenvolgende artikelversies worden twee identieke aanpassingen gedaan, waarbij twee keer exact dezelfde zin wordt toegevoegd aan een ander deel van het artikel.
      In beide gevallen wordt nieuwe informatie toegevoegd aan het artikel, zonder dat er informatie uit het oorspronkelijke artikel moest verwijderd of aangepast worden (dus geen "Update van oude informatie").
      Het gaat hier dus om de "Toevoeging/verduidelijking van informatie".
      <br>
    </div>
    <br>
  </div>
</div>

<div name="acceptbutton" *ngIf="feedbackCompleted === true && this.scores">
  <div class="container">
    <button class="btn btn-primary btn-lg btn-block" (click)="clickAcceptButton()">Ik heb alles begrepen!</button>
  </div>
  <br>
</div>
</body>
</html>
