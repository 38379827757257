import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {AuthService} from '../auth.service';
import {BehaviorSubject} from 'rxjs';
import {BooleanService} from '../boolean.service';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css']
})
export class SigninComponent implements OnInit {
  signupForm: FormGroup;
  public loadingSignup: BehaviorSubject<boolean>;
  public emptyEmailSignup: BehaviorSubject<boolean>;
  public emptyPassword1Signup: BehaviorSubject<boolean>;
  public emptyPassword2Signup: BehaviorSubject<boolean>;
  public emptyFirstnameSignup: BehaviorSubject<boolean>;
  public emptyLastnameSignup: BehaviorSubject<boolean>;
  public emptyAkkoordSignup: BehaviorSubject<boolean>;
  public emptyInteresseSignup: BehaviorSubject<boolean>;
  public failedExistingSignup: BehaviorSubject<boolean>;
  public failedPasswordSignup: BehaviorSubject<boolean>;
  public failedInternalSignup: BehaviorSubject<boolean>;
  public failedIncorrectEmailSignup: BehaviorSubject<boolean>;
  public failedEqualPasswordSignup: BehaviorSubject<boolean>;
  public accountCreated: BehaviorSubject<boolean>;
  constructor( private formBuilder: FormBuilder,
               private router: Router,
               private authenticationService: AuthService,
               public booleanService: BooleanService, private titleService: Title) {
    booleanService.resetLoginBooleans();
    booleanService.resetRecoverBooleans();
    booleanService.resetRecoverConfirmBooleans();
    localStorage.setItem('loadingSignup', JSON.stringify(false));
    localStorage.setItem('emptyEmailSignup', JSON.stringify(false));
    localStorage.setItem('emptyPassword1Signup', JSON.stringify(false));
    localStorage.setItem('emptyPassword2Signup', JSON.stringify(false));
    localStorage.setItem('emptyFirstnameSignup', JSON.stringify(false));
    localStorage.setItem('emptyLastnameSignup', JSON.stringify(false));
    localStorage.setItem('emptyAkkoordSignup', JSON.stringify(false));
    localStorage.setItem('emptyInteresseSignup', JSON.stringify(false));
    if (!localStorage.getItem('failedExistingSignup')) {
      localStorage.setItem('failedExistingSignup', JSON.stringify(false));
    }
    if (!localStorage.getItem('failedInternalSignup')) {
      localStorage.setItem('failedInternalSignup', JSON.stringify(false));
    }
    if (!localStorage.getItem('failedPasswordSignup')) {
      localStorage.setItem('failedPasswordSignup', JSON.stringify(false));
    }
    if (!localStorage.getItem('failedIncorrectEmailSignup')) {
      localStorage.setItem('failedIncorrectEmailSignup', JSON.stringify(false));
    }
    if (!localStorage.getItem('failedEqualPasswordSignup')) {
      localStorage.setItem('failedEqualPasswordSignup', JSON.stringify(false));
    }
    if (!localStorage.getItem('accountCreated')) {
      localStorage.setItem('accountCreated', JSON.stringify(false));
    }
    this.loadingSignup = new BehaviorSubject<boolean>(JSON.parse(localStorage.getItem('loadingSignup')));
    this.emptyEmailSignup = new BehaviorSubject<boolean>(JSON.parse(localStorage.getItem('emptyEmailSignup')));
    this.emptyPassword1Signup = new BehaviorSubject<boolean>(JSON.parse(localStorage.getItem('emptyPassword1Signup')));
    this.emptyPassword2Signup = new BehaviorSubject<boolean>(JSON.parse(localStorage.getItem('emptyPassword2Signup')));
    this.emptyFirstnameSignup = new BehaviorSubject<boolean>(JSON.parse(localStorage.getItem('emptyFirstnameSignup')));
    this.emptyLastnameSignup = new BehaviorSubject<boolean>(JSON.parse(localStorage.getItem('emptyLastnameSignup')));
    this.emptyAkkoordSignup = new BehaviorSubject<boolean>(JSON.parse(localStorage.getItem('emptyAkkoordSignup')));
    this.emptyInteresseSignup = new BehaviorSubject<boolean>(JSON.parse(localStorage.getItem('emptyInteresseSignup')));
    this.failedExistingSignup = new BehaviorSubject<boolean>(JSON.parse(localStorage.getItem('failedExistingSignup')));
    this.failedPasswordSignup = new BehaviorSubject<boolean>(JSON.parse(localStorage.getItem('failedPasswordSignup')));
    this.failedIncorrectEmailSignup = new BehaviorSubject<boolean>(JSON.parse(localStorage.getItem('failedIncorrectEmailSignup')));
    this.failedInternalSignup = new BehaviorSubject<boolean>(JSON.parse(localStorage.getItem('failedInternalSignup')));
    this.failedEqualPasswordSignup = new BehaviorSubject<boolean>(JSON.parse(localStorage.getItem('failedEqualPasswordSignup')));
    this.accountCreated = new BehaviorSubject<boolean>(JSON.parse(localStorage.getItem('accountCreated')));
  }

  ngOnInit(): void {
    this.setTitle('UGent Newstracker - Registratie');
    this.signupForm = this.formBuilder.group({
      emailSignup: ['', Validators.required],
      password1Signup: ['', Validators.required],
      password2Signup: ['', Validators.required],
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      akkoord: ['', Validators.requiredTrue],
      interesse: ['']
    });
  }

  get signupf() { return this.signupForm.controls; }
  onSignupSubmit() {
    // stop here if form is invalid
    if (this.signupForm.invalid) {
      if (this.signupf.emailSignup.invalid) {
        localStorage.setItem('emptyEmailSignup', JSON.stringify(true));
        this.emptyEmailSignup.next(true);
      }
      if (this.signupf.password1Signup.invalid) {
        localStorage.setItem('emptyPassword1Signup', JSON.stringify(true));
        this.emptyPassword1Signup.next(true);
      }
      if (this.signupf.password2Signup.invalid) {
        localStorage.setItem('emptyPassword2Signup', JSON.stringify(true));
        this.emptyPassword2Signup.next(true);
      }
      if (this.signupf.firstname.invalid) {
        localStorage.setItem('emptyFirstnameSignup', JSON.stringify(true));
        this.emptyFirstnameSignup.next(true);
      }
      if (this.signupf.lastname.invalid) {
        localStorage.setItem('emptyLastnameSignup', JSON.stringify(true));
        this.emptyLastnameSignup.next(true);
      }
      if (this.signupf.akkoord.invalid) {
        localStorage.setItem('emptyAkkoordSignup', JSON.stringify(true));
        this.emptyAkkoordSignup.next(true);
      }
      localStorage.setItem('failedExistingSignup', JSON.stringify(false));
      this.failedExistingSignup.next(false);
      localStorage.setItem('failedPasswordSignup', JSON.stringify(false));
      this.failedPasswordSignup.next(false);
      localStorage.setItem('failedIncorrectEmailSignup', JSON.stringify(false));
      this.failedIncorrectEmailSignup.next(false);
      localStorage.setItem('failedInternalSignup', JSON.stringify(false));
      this.failedInternalSignup.next(false);
      return;
    }
    if (this.signupf.password1Signup.value !== this.signupf.password2Signup.value) {
      localStorage.setItem('emptyEmailSignup', JSON.stringify(false));
      this.emptyEmailSignup.next(false);
      localStorage.setItem('emptyPassword1Signup', JSON.stringify(false));
      this.emptyPassword1Signup.next(false);
      localStorage.setItem('emptyPassword2Signup', JSON.stringify(false));
      this.emptyPassword2Signup.next(false);
      localStorage.setItem('emptyFirstnameSignup', JSON.stringify(false));
      this.emptyFirstnameSignup.next(false);
      localStorage.setItem('emptyLastnameSignup', JSON.stringify(false));
      this.emptyLastnameSignup.next(false);
      localStorage.setItem('emptyAkkoordSignup', JSON.stringify(false));
      this.emptyAkkoordSignup.next(false);
      localStorage.setItem('failedExistingSignup', JSON.stringify(false));
      this.failedExistingSignup.next(false);
      localStorage.setItem('failedPasswordSignup', JSON.stringify(false));
      this.failedPasswordSignup.next(false);
      localStorage.setItem('failedIncorrectEmailSignup', JSON.stringify(false));
      this.failedIncorrectEmailSignup.next(false);
      localStorage.setItem('failedInternalSignup', JSON.stringify(false));
      this.failedInternalSignup.next(false);
      localStorage.setItem('failedEqualPasswordSignup', JSON.stringify(true));
      this.failedEqualPasswordSignup.next(true);
      this.signupf.password1Signup.reset();
      this.signupf.password2Signup.reset();
      return;
    }
    localStorage.setItem('loadingSignup', JSON.stringify(true));
    this.loadingSignup.next(true);
    if (this.signupf.emailSignup.value && this.signupf.password1Signup.value) {
      this.authenticationService.signup(this.signupf.emailSignup.value, this.signupf.password1Signup.value,
        this.signupf.firstname.value, this.signupf.lastname.value, this.signupf.interesse.value)
        .subscribe(
          data => {
              localStorage.setItem('failedExistingSignup', JSON.stringify(false));
              this.failedExistingSignup.next(false);
              localStorage.setItem('failedPasswordSignup', JSON.stringify(false));
              this.failedPasswordSignup.next(false);
              localStorage.setItem('failedIncorrectEmailSignup', JSON.stringify(false));
              this.failedIncorrectEmailSignup.next(false);
              localStorage.setItem('failedInternalSignup', JSON.stringify(false));
              this.failedInternalSignup.next(false);
              localStorage.setItem('loadingSignup', JSON.stringify(false));
              this.loadingSignup.next(false);
              localStorage.setItem('failedEqualPasswordSignup', JSON.stringify(false));
              this.failedEqualPasswordSignup.next(false);
              localStorage.setItem('accountCreated', JSON.stringify(true));
              this.accountCreated.next(true);
              this.signupForm.reset();
          },
          error => {
            localStorage.setItem('loadingSignup', JSON.stringify(false));
            this.loadingSignup.next(false);
            localStorage.setItem('emptyEmailSignup', JSON.stringify(false));
            this.emptyEmailSignup.next(false);
            localStorage.setItem('emptyPassword1Signup', JSON.stringify(false));
            this.emptyPassword1Signup.next(false);
            localStorage.setItem('emptyPassword2Signup', JSON.stringify(false));
            this.emptyPassword2Signup.next(false);
            localStorage.setItem('emptyFirstnameSignup', JSON.stringify(false));
            this.emptyFirstnameSignup.next(false);
            localStorage.setItem('emptyAkkoordSignup', JSON.stringify(false));
            this.emptyAkkoordSignup.next(false);
            localStorage.setItem('emptyInteresseSignup', JSON.stringify(false));
            this.emptyInteresseSignup.next(false);
            localStorage.setItem('emptyLastnameSignup', JSON.stringify(false));
            this.emptyLastnameSignup.next(false);
            if (error.error.message === 'Email already has an associated account.') {
              localStorage.setItem('failedExistingSignup', JSON.stringify(true));
              this.failedExistingSignup.next(true);
              localStorage.setItem('failedPasswordSignup', JSON.stringify(false));
              this.failedPasswordSignup.next(false);
              localStorage.setItem('failedIncorrectEmailSignup', JSON.stringify(false));
              this.failedIncorrectEmailSignup.next(false);
              localStorage.setItem('failedInternalSignup', JSON.stringify(false));
              this.failedInternalSignup.next(false);
              localStorage.setItem('failedEqualPasswordSignup', JSON.stringify(false));
              this.failedEqualPasswordSignup.next(false);
              this.signupForm.reset();
            }
            if (error.error.message === 'Sign in could not be completed due to an internal server error.'){
              localStorage.setItem('failedExistingSignup', JSON.stringify(false));
              this.failedExistingSignup.next(false);
              localStorage.setItem('failedPasswordSignup', JSON.stringify(false));
              this.failedPasswordSignup.next(false);
              localStorage.setItem('failedIncorrectEmailSignup', JSON.stringify(false));
              this.failedIncorrectEmailSignup.next(false);
              localStorage.setItem('failedInternalSignup', JSON.stringify(true));
              this.failedInternalSignup.next(true);
              localStorage.setItem('failedEqualPasswordSignup', JSON.stringify(false));
              this.failedEqualPasswordSignup.next(false);
              this.signupForm.reset();
            }
            if (error.error.message === 'Invalid password!'){
              localStorage.setItem('failedExistingSignup', JSON.stringify(false));
              this.failedExistingSignup.next(false);
              localStorage.setItem('failedPasswordSignup', JSON.stringify(true));
              this.failedPasswordSignup.next(true);
              localStorage.setItem('failedIncorrectEmailSignup', JSON.stringify(false));
              this.failedIncorrectEmailSignup.next(false);
              localStorage.setItem('failedInternalSignup', JSON.stringify(false));
              this.failedInternalSignup.next(false);
              localStorage.setItem('failedEqualPasswordSignup', JSON.stringify(false));
              this.failedEqualPasswordSignup.next(false);
              this.signupf.password1Signup.reset();
              this.signupf.password2Signup.reset();
            }
            if (error.error.message === 'Malformed emailadress.'){
              localStorage.setItem('failedExistingSignup', JSON.stringify(false));
              this.failedExistingSignup.next(false);
              localStorage.setItem('failedIncorrectEmailSignup', JSON.stringify(true));
              this.failedIncorrectEmailSignup.next(true);
              localStorage.setItem('failedPasswordSignup', JSON.stringify(false));
              this.failedPasswordSignup.next(false);
              localStorage.setItem('failedInternalSignup', JSON.stringify(false));
              this.failedInternalSignup.next(false);
              localStorage.setItem('failedEqualPasswordSignup', JSON.stringify(false));
              this.failedEqualPasswordSignup.next(false);
              this.signupf.password1Signup.reset();
              this.signupf.password2Signup.reset();
            }
            localStorage.setItem('accountCreated', JSON.stringify(false));
            this.accountCreated.next(false);
          });
    }
  }
  public get currentLoadingSignupValue(): boolean {
    return this.loadingSignup.value;
  }
  public get currentEmptyEmailSignupValue(): boolean {
    return this.emptyEmailSignup.value;
  }
  public get currentEmptyPassword1SignupValue(): boolean {
    return this.emptyPassword1Signup.value;
  }
  public get currentEmptyPassword2SignupValue(): boolean {
    return this.emptyPassword2Signup.value;
  }
  public get currentEmptyFirstnameSignupValue(): boolean {
    return this.emptyFirstnameSignup.value;
  }
  public get currentEmptyLastnameSignupValue(): boolean {
    return this.emptyLastnameSignup.value;
  }
  public get currentEmptyAkkoordSignupValue(): boolean {
    return this.emptyAkkoordSignup.value;
  }
  public get currentEmptyInteresseSignupValue(): boolean {
    return this.emptyInteresseSignup.value;
  }
  public get currentFailedExistingSignupValue(): boolean {
    return this.failedExistingSignup.value;
  }
  public get currentFailedPasswordSignupValue(): boolean {
    return this.failedPasswordSignup.value;
  }
  public get currentIncorrectEmailSignupValue(): boolean {
    return this.failedIncorrectEmailSignup.value;
  }
  public get currentFailedInternalSignupValue(): boolean {
    return this.failedInternalSignup.value;
  }
  public get currentFailedEqualPasswordSignupValue(): boolean {
    return this.failedEqualPasswordSignup.value;
  }
  public get currentAccountCreatedValue(): boolean {
    return this.accountCreated.value;
  }

  public setTitle( newTitle: string) {
    this.titleService.setTitle( newTitle );
  }
}
