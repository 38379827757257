import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {ArticlesComponent} from './articles/articles.component';
import {LoginComponent} from './login/login.component';
import {SigninComponent} from './signin/signin.component';
import {ConfirmationComponent} from './confirmation/confirmation.component';
import {ProfileComponent} from './profile/profile.component';
import {RecoverComponent} from './recover/recover.component';
import {RecoverConfirmationComponent} from './recover-confirmation/recover-confirmation.component';
import {IntroComponent} from './intro/intro.component';
import {ProjectComponent} from './project/project.component';
import {FeedbackComponent} from './feedback/feedback.component';


const routes: Routes = [
  { path: 'articles', component: ArticlesComponent},
  { path: 'intro', component: IntroComponent},
  { path: 'feedback', component: FeedbackComponent},
  { path: 'project', component: ProjectComponent},
  { path: 'login', component: LoginComponent},
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  {path: 'signin', component: SigninComponent},
  {path: 'registrationConfirm/:email/:token', component: ConfirmationComponent},
  {path: 'profile', component: ProfileComponent},
  {path: 'recover', component: RecoverComponent},
  {path: 'recoverConfirm/:email/:token', component: RecoverConfirmationComponent},
  {path: '**', redirectTo: '/login'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
