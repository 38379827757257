import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BehaviorSubject} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../auth.service';
import {BooleanService} from '../boolean.service';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-recover-confirmation',
  templateUrl: './recover-confirmation.component.html',
  styleUrls: ['./recover-confirmation.component.css']
})
export class RecoverConfirmationComponent implements OnInit {

  recoverConfirmForm: FormGroup;
  public loadingRecoverConfirm: BehaviorSubject<boolean>;
  public emptyPassword1RecoverConfirm: BehaviorSubject<boolean>;
  public emptyPassword2RecoverConfirm: BehaviorSubject<boolean>;
  public failedInternalRecoverConfirm: BehaviorSubject<boolean>;
  public failedPasswordRecoverConfirm: BehaviorSubject<boolean>;
  public failedExpiredRecoverConfirm: BehaviorSubject<boolean>;
  public failedEqualPasswordRecoverConfirm: BehaviorSubject<boolean>;
  public enabled: boolean;
  public expired: boolean;
  public recoverConfirm: BehaviorSubject<boolean>;
  public email: string;
  public token: string;
  constructor(    private formBuilder: FormBuilder,
                  private router: Router,
                  private authenticationService: AuthService,
                  private route: ActivatedRoute,
                  public booleanService: BooleanService,
                  private titleService: Title) {
    booleanService.resetLoginBooleans();
    booleanService.resetRecoverBooleans();
    booleanService.resetSigninBooleans();
    localStorage.setItem('loadingRecoverConfirm', JSON.stringify(false));
    localStorage.setItem('emptyPassword1RecoverConfirm', JSON.stringify(false));
    localStorage.setItem('emptyPassword2RecoverConfirm', JSON.stringify(false));
    if (!localStorage.getItem('failedPasswordRecoverConfirm')) {
      localStorage.setItem('failedPasswordRecoverConfirm', JSON.stringify(false));
    }
    if (!localStorage.getItem('failedEqualPasswordRecoverConfirm')) {
      localStorage.setItem('failedEqualPasswordRecoverConfirm', JSON.stringify(false));
    }
    if (!localStorage.getItem('failedExpiredRecoverConfirm')) {
      localStorage.setItem('failedEqualPasswordRecoverConfirm', JSON.stringify(false));
    }
    if (!localStorage.getItem('failedInternalRecoverConfirm')) {
      localStorage.setItem('failedInternalRecoverConfirm', JSON.stringify(false));
    }
    if (!localStorage.getItem('recoverConfirm')) {
      localStorage.setItem('recoverConfirm', JSON.stringify(false));
    }
    this.loadingRecoverConfirm = new BehaviorSubject<boolean>(JSON.parse(localStorage.getItem('loadingRecoverConfirm')));
    this.emptyPassword1RecoverConfirm = new BehaviorSubject<boolean>(JSON.parse(localStorage.getItem('emptyPassword1RecoverConfirm')));
    this.emptyPassword2RecoverConfirm = new BehaviorSubject<boolean>(JSON.parse(localStorage.getItem('emptyPassword2RecoverConfirm')));
    this.failedPasswordRecoverConfirm = new BehaviorSubject<boolean>(JSON.parse(localStorage.getItem('failedPasswordRecoverConfirm')));
    this.failedExpiredRecoverConfirm = new BehaviorSubject<boolean>(JSON.parse(localStorage.getItem('failedExpiredRecoverConfirm')));
    this.failedInternalRecoverConfirm = new BehaviorSubject<boolean>(JSON.parse(localStorage.getItem('failedInternalRecoverConfirm')));
    this.failedEqualPasswordRecoverConfirm = new BehaviorSubject<boolean>(JSON.parse(localStorage.getItem('failedEqualPasswordRecoverConfirm ')));
    this.recoverConfirm = new BehaviorSubject<boolean>(JSON.parse(localStorage.getItem('recoverConfirm')));
  }

  ngOnInit(): void {
    this.setTitle('UGent Newstracker - Paswoordherstel');
    this.recoverConfirmForm = this.formBuilder.group({
      password1RecoverConfirm: ['', Validators.required],
      password2RecoverConfirm: ['', Validators.required]
    });
    this.email = this.route.snapshot.paramMap.get('email');
    this.token = this.route.snapshot.paramMap.get('token');

    this.authenticationService.recoverConfirmValid(this.email, this.token).subscribe(data => {
        this.enabled = true;
      },
      error => {
        if (error.error.message === 'Recover confirmation link is expired.'){
          this.expired = true;
        }
        if (error.error.message === 'Recover confirmation was called with the wrong credentials.') {
          this.enabled = false;
        }
      });
  }

  get recoverconfirmf() {
    return this.recoverConfirmForm.controls;
  }

  public get currentLoadingRecoverConfirmValue(): boolean {
    return this.loadingRecoverConfirm.value;
  }
  public get currentEmptyPassword1RecoverConfirmValue(): boolean {
    return this.emptyPassword1RecoverConfirm.value;
  }
  public get currentEmptyPassword2RecoverConfirmValue(): boolean {
    return this.emptyPassword2RecoverConfirm.value;
  }
  public get currentFailedPasswordRecoverConfirmValue(): boolean {
    return this.failedPasswordRecoverConfirm.value;
  }
  public get currentFailedInternalRecoverConfirmValue(): boolean {
    return this.failedInternalRecoverConfirm.value;
  }
  public get currentFailedExpiredRecoverConfirmValue(): boolean {
    return this.failedInternalRecoverConfirm.value;
  }
  public get currentFailedEqualPasswordRecoverConfirmValue(): boolean {
    return this.failedEqualPasswordRecoverConfirm.value;
  }
  public get currentRecoverConfirmValue(): boolean {
    return this.recoverConfirm.value;
  }
  onRecoverConfirmSubmit() {
    if (this.recoverConfirmForm.invalid) {
      if (this.recoverconfirmf.password1RecoverConfirm.invalid) {
        localStorage.setItem('emptyPassword1RecoverConfirm', JSON.stringify(true));
        this.emptyPassword1RecoverConfirm.next(true);
      }
      if (this.recoverconfirmf.password2RecoverConfirm.invalid) {
        localStorage.setItem('emptyPassword2RecoverConfirm', JSON.stringify(true));
        this.emptyPassword2RecoverConfirm.next(true);
      }
      localStorage.setItem('failedPasswordRecoverConfirm', JSON.stringify(false));
      this.failedPasswordRecoverConfirm.next(false);
      localStorage.setItem('failedInternalRecoverConfirm', JSON.stringify(false));
      this.failedInternalRecoverConfirm.next(false);
      localStorage.setItem('failedEqualPasswordRecoverConfirm', JSON.stringify(false));
      this.failedEqualPasswordRecoverConfirm.next(false);
      return;
    }
    if (this.recoverconfirmf.password1RecoverConfirm.value !== this.recoverconfirmf.password2RecoverConfirm.value){
      localStorage.setItem('emptyPassword1RecoverConfirm', JSON.stringify(false));
      this.emptyPassword1RecoverConfirm.next(false);
      localStorage.setItem('emptyPassword2RecoverConfirm', JSON.stringify(false));
      this.emptyPassword2RecoverConfirm.next(false);
      localStorage.setItem('failedPasswordRecoverConfirm', JSON.stringify(false));
      this.failedPasswordRecoverConfirm.next(false);
      localStorage.setItem('failedInternalRecoverConfirm', JSON.stringify(false));
      this.failedInternalRecoverConfirm.next(false);
      localStorage.setItem('failedEqualPasswordRecoverConfirm', JSON.stringify(true));
      this.failedEqualPasswordRecoverConfirm.next(true);
      this.recoverconfirmf.password1RecoverConfirm.reset();
      this.recoverconfirmf.password2RecoverConfirm.reset();
      return;
    }
    localStorage.setItem('loadingRecoverConfirm', JSON.stringify(true));
    this.loadingRecoverConfirm.next(true);
    if (this.recoverconfirmf.password1RecoverConfirm.value && this.recoverconfirmf.password2RecoverConfirm.value) {
      this.authenticationService.recoverConfirm(this.email, this.token,
        this.recoverconfirmf.password1RecoverConfirm.value)
        .subscribe(
          data => {
            localStorage.setItem('failedPasswordRecoverConfirm', JSON.stringify(false));
            this.failedPasswordRecoverConfirm.next(false);
            localStorage.setItem('failedInternalRecoverConfirm', JSON.stringify(false));
            this.failedInternalRecoverConfirm.next(false);
            localStorage.setItem('loadingRecoverConfirm', JSON.stringify(false));
            this.loadingRecoverConfirm.next(false);
            localStorage.setItem('recoverConfirm', JSON.stringify(true));
            this.recoverConfirm.next(true);
            localStorage.setItem('failedExpiredRecoverConfirm', JSON.stringify(false));
            this.failedExpiredRecoverConfirm.next(false);
            localStorage.setItem('failedEqualPasswordRecoverConfirm', JSON.stringify(false));
            this.failedEqualPasswordRecoverConfirm.next(false);
            this.recoverConfirmForm.reset();
          },
          error => {
            localStorage.setItem('loadingRecoverConfirm', JSON.stringify(false));
            this.loadingRecoverConfirm.next(false);
            localStorage.setItem('emptyPassword1RecoverConfirm', JSON.stringify(false));
            this.emptyPassword1RecoverConfirm.next(false);
            if (error.error.message === 'Invalid password!') {
              localStorage.setItem('failedPasswordRecoverConfirm', JSON.stringify(true));
              this.failedPasswordRecoverConfirm.next(true);
              localStorage.setItem('failedInternalRecoverConfirm', JSON.stringify(false));
              this.failedInternalRecoverConfirm.next(false);
              localStorage.setItem('failedExpiredRecoverConfirm', JSON.stringify(false));
              this.failedExpiredRecoverConfirm.next(false);
              localStorage.setItem('failedEqualPasswordRecoverConfirm', JSON.stringify(false));
              this.failedEqualPasswordRecoverConfirm.next(false);
              this.recoverconfirmf.password1RecoverConfirm.reset();
              this.recoverconfirmf.password2RecoverConfirm.reset();
            }
            else {
              if (error.error.message === 'Recover confirmation link is expired.') {
                localStorage.setItem('failedPasswordRecoverConfirm', JSON.stringify(false));
                this.failedPasswordRecoverConfirm.next(false);
                localStorage.setItem('failedInternalRecoverConfirm', JSON.stringify(false));
                this.failedInternalRecoverConfirm.next(false);
                localStorage.setItem('failedExpiredRecoverConfirm', JSON.stringify(true));
                this.failedExpiredRecoverConfirm.next(true);
                localStorage.setItem('failedEqualPasswordRecoverConfirm', JSON.stringify(false));
                this.failedEqualPasswordRecoverConfirm.next(false);
                this.recoverConfirmForm.reset();
              } else {
                if (error.error.message === 'Invalid password!'){
                  localStorage.setItem('failedPasswordRecoverConfirm', JSON.stringify(true));
                  this.failedPasswordRecoverConfirm.next(true);
                  localStorage.setItem('failedInternalRecoverConfirm', JSON.stringify(false));
                  this.failedInternalRecoverConfirm.next(false);
                  localStorage.setItem('failedExpiredRecoverConfirm', JSON.stringify(false));
                  this.failedExpiredRecoverConfirm.next(true);
                  localStorage.setItem('failedEqualPasswordRecoverConfirm', JSON.stringify(false));
                  this.failedEqualPasswordRecoverConfirm.next(false);
                  this.recoverConfirmForm.reset();
                }
                else {
                  localStorage.setItem('failedPasswordRecoverConfirm', JSON.stringify(false));
                  this.failedPasswordRecoverConfirm.next(false);
                  localStorage.setItem('failedInternalRecoverConfirm', JSON.stringify(true));
                  this.failedInternalRecoverConfirm.next(true);
                  localStorage.setItem('failedExpiredRecoverConfirm', JSON.stringify(false));
                  this.failedExpiredRecoverConfirm.next(false);
                  localStorage.setItem('failedEqualPasswordRecoverConfirm', JSON.stringify(false));
                  this.failedEqualPasswordRecoverConfirm.next(false);
                  this.recoverConfirmForm.reset();
                }
              }
            }
            localStorage.setItem('recoverConfirm', JSON.stringify(false));
            this.recoverConfirm.next(false);
          });
    }
  }

  public setTitle( newTitle: string) {
    this.titleService.setTitle( newTitle );
  }
}
