import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {ConfirmationService} from '../confirmation.service';
import {BooleanService} from '../boolean.service';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.css']
})
export class ConfirmationComponent implements OnInit {
  public email: string;
  public token: string;
  public enabled: boolean;
  public expired: boolean;
  public verified: boolean;

  constructor(private route: ActivatedRoute, private confirmationService: ConfirmationService,
              public booleanService: BooleanService, private titleService: Title) {
    booleanService.resetLoginBooleans();
    booleanService.resetRecoverBooleans();
    booleanService.resetRecoverConfirmBooleans();
    booleanService.resetSigninBooleans();
  }

  ngOnInit(): void {
    this.setTitle('UGent Newstracker - Registratie');
    this.email = this.route.snapshot.paramMap.get('email');
    this.token = this.route.snapshot.paramMap.get('token');
    this.confirmationService.confirm(this.email, this.token).subscribe(data => {
      this.enabled = true;
    },
      error => {
        if (error.error.message === 'Registration confirmation link is expired.'){
          this.expired = true;
        }
        if (error.error.message === 'Registration confirmation was called with the wrong credentials.'){
          this.enabled = false;
        }
        if (error.error.message === 'Emailadress was already verified.'){
          this.verified = true;
        }
      });
  }
  public setTitle( newTitle: string) {
    this.titleService.setTitle( newTitle );
  }
}
