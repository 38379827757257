import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BooleanService {

  constructor() { }
  public resetLoginBooleans(): void{
    localStorage.setItem('loadingLogin', JSON.stringify(false));
    localStorage.setItem('emptyEmailLogin', JSON.stringify(false));
    localStorage.setItem('emptyPasswordLogin', JSON.stringify(false));
    localStorage.setItem('failedLogin', JSON.stringify(false));
  }
  public resetSigninBooleans(): void{
    localStorage.setItem('loadingSignup', JSON.stringify(false));
    localStorage.setItem('emptyEmailSignup', JSON.stringify(false));
    localStorage.setItem('emptyPassword1Signup', JSON.stringify(false));
    localStorage.setItem('emptyPassword2Signup', JSON.stringify(false));
    localStorage.setItem('emptyFirstnameSignup', JSON.stringify(false));
    localStorage.setItem('emptyLastnameSignup', JSON.stringify(false));
    localStorage.setItem('emptyAkkoordSignup', JSON.stringify(false));
    localStorage.setItem('emptyInteresseSignup', JSON.stringify(false));
    localStorage.setItem('failedExistingSignup', JSON.stringify(false));
    localStorage.setItem('failedInternalSignup', JSON.stringify(false));
    localStorage.setItem('failedPasswordSignup', JSON.stringify(false));
    localStorage.setItem('failedEqualPasswordSignup', JSON.stringify(false));
    localStorage.setItem('accountCreated', JSON.stringify(false));
  }
  public resetRecoverBooleans(): void{
    localStorage.setItem('loadingRecover', JSON.stringify(false));
    localStorage.setItem('emptyEmailRecover', JSON.stringify(false));
    localStorage.setItem('failedExistingRecover', JSON.stringify(false));
    localStorage.setItem('failedInternalRecover', JSON.stringify(false));
    localStorage.setItem('recovertokenCreated', JSON.stringify(false));
    localStorage.setItem('failedMalformedRecover', JSON.stringify(false));
  }
  public resetRecoverConfirmBooleans(): void{
    localStorage.setItem('loadingRecoverConfirm', JSON.stringify(false));
    localStorage.setItem('emptyPassword1RecoverConfirm', JSON.stringify(false));
    localStorage.setItem('emptyPassword2RecoverConfirm', JSON.stringify(false));
    localStorage.setItem('failedPasswordRecoverConfirm', JSON.stringify(false));
    localStorage.setItem('failedEqualPasswordRecoverConfirm', JSON.stringify(false));
    localStorage.setItem('failedEqualPasswordRecoverConfirm', JSON.stringify(false));
    localStorage.setItem('failedInternalRecoverConfirm', JSON.stringify(false));
    localStorage.setItem('recoverConfirm', JSON.stringify(false));
  }
}
