<!doctype html>
<html lang="en">
<head>
  <title>UGent Newstracker - Pasword recovery</title>
</head>
<body>
<nav class="navbar navbar-expand-lg fixed-top navbar-dark bg-company-grey">
  <a class="navbar-brand" href="#"></a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <a class="navbar-brand" target="_blank" href="https://www.ugent.be/" style="padding:0px;">
    <img src="assets/ugentlogo.png" width="163" height="130">
  </a>
  <a class="navbar-brand" target="_blank" href="https://ddcmsite.ugent.be/" style="padding:0px;">
    <img src="assets/ddcmlogo.png" width="272" height="60">
  </a>
  <div class="collapse navbar-collapse text-right" id="navbarright">
    <ul class="navbar-nav ml-auto flex-nowrap">
      <li class="nav-item">
        <a class="nav-link" href="/login" (click)="booleanService.resetLoginBooleans()">Home</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="/project" (click)="booleanService.resetLoginBooleans()">Over dit project</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="/intro" (click)="booleanService.resetLoginBooleans()">Hoe werkt het?</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="/signin" (click)="booleanService.resetSigninBooleans()">Maak een account!</a>
      </li>
    </ul>
  </div>
</nav>
<div class="card-body" *ngIf="(currentRecoverConfirmValue===false) && (enabled===true)">
  <h5 class="card-header">Geef nieuw paswoord op</h5>
  <form [formGroup]="recoverConfirmForm" (ngSubmit)="onRecoverConfirmSubmit()">
    <div class="form-group">
      <label for="password1RecoverConfirm">Paswoord</label>
      <input type="password" id="password1RecoverConfirm" [readonly]="currentLoadingRecoverConfirmValue===true" formControlName="password1RecoverConfirm" class="form-control" [ngClass]="{ 'is-invalid': (currentEmptyPassword1RecoverConfirmValue && recoverconfirmf.password1RecoverConfirm.errors) || currentFailedEqualPasswordRecoverConfirmValue || currentFailedPasswordRecoverConfirmValue}" />
      <div *ngIf="currentEmptyPassword1RecoverConfirmValue && recoverconfirmf.password1RecoverConfirm.errors" class="invalid-feedback-recover-confirm">
      </div>
    </div>
    <div class="form-group">
      <label for="password2RecoverConfirm">Herhaal paswoord</label>
      <input type="password" id="password2RecoverConfirm" [readonly]="currentLoadingRecoverConfirmValue===true" formControlName="password2RecoverConfirm" class="form-control" [ngClass]="{ 'is-invalid': (currentEmptyPassword2RecoverConfirmValue && recoverconfirmf.password2RecoverConfirm.errors) || currentFailedEqualPasswordRecoverConfirmValue || currentFailedPasswordRecoverConfirmValue}" />
        <div *ngIf="currentEmptyPassword1RecoverConfirmValue && recoverconfirmf.password1RecoverConfirm.errors" class="invalid-feedback-recover-confirm">
        <div style="color:red" *ngIf="currentFailedPasswordRecoverConfirmValue" class="failedPasswordRecoverConfirm">Paswoord moet op zijn minst 8 karakters bevatten en bestaan uit een combinatie van kleine letters, hoofdletters en cijfers!</div>
      </div>
    </div>
    <div style="color:red" *ngIf="currentFailedEqualPasswordRecoverConfirmValue && !currentEmptyPassword1RecoverConfirmValue && !currentEmptyPassword2RecoverConfirmValue" class="failedPasswordRecoverConfirm">Paswoorden moeten gelijk zijn aan elkaar!</div>
    <button [disabled]="currentLoadingRecoverConfirmValue" class="btn btn-primary">
      <span *ngIf="currentLoadingRecoverConfirmValue" class="spinner-border spinner-border-sm mr-1"></span>
      Herstel je paswoord!
    </button>
    <div style="color:red" *ngIf="currentFailedInternalRecoverConfirmValue" class="failedInternalRecoverConfirm">Interne fout opgetreden, gelieve opnieuw te proberen.</div>
    <div style="color:red" *ngIf="currentFailedExpiredRecoverConfirmValue" class="failedExpiredRecoverConfirm">Recover link is niet meer geldig. Gelieve via de homepagina opnieuw een nieuw paswoord aan te vragen.</div>
  </form>
</div>
<div *ngIf="(currentRecoverConfirmValue===true) && (enabled===true)" class="recoverconfirm">Paswoord werd succesvol gereset! Ga naar de homepage om opnieuw in te loggen.</div>
<p *ngIf="enabled===false">Ongeldige aanvraag.</p>
<p *ngIf="expired===true">Link is vervallen. Vraag via de homepage een nieuw paswoord aan.</p>
</body>
</html>
