import { Component, OnInit } from '@angular/core';
import {AuthService} from '../auth.service';
import {Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {BooleanService} from '../boolean.service';

@Component({
  selector: 'app-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.css']
})
export class IntroComponent implements OnInit {
  email: string;
  firstname: string;
  lastname: string;
  hasLoggedIn: boolean;
  hasFeedbackCompleted: boolean;
  allArticlesCompleted: boolean;
  feedbackStage: number;
  totalCount: number;
  imageLoaded: boolean;
  subjectiefChosen: boolean;
  objectiefChosen: boolean;
  toevoegingChosen: boolean;
  verwijderingChosen: boolean;
  spellingChosen: boolean;
  neutraalChosen: boolean;
  updateChosen: boolean;
  verplaatsingChosen: boolean;
  objectivesChosen: number[];
  subjectivesChosen: number[];

  constructor(public authService: AuthService, private router: Router,
              private titleService: Title, public booleanService: BooleanService) {
    if (!authService.currentUserValue) {
      booleanService.resetLoginBooleans();
      booleanService.resetRecoverBooleans();
      booleanService.resetRecoverConfirmBooleans();
      localStorage.setItem('loadingSignup', JSON.stringify(false));
      localStorage.setItem('emptyEmailSignup', JSON.stringify(false));
      localStorage.setItem('emptyPassword1Signup', JSON.stringify(false));
      localStorage.setItem('emptyPassword2Signup', JSON.stringify(false));
      localStorage.setItem('emptyFirstnameSignup', JSON.stringify(false));
      localStorage.setItem('emptyLastnameSignup', JSON.stringify(false));
      localStorage.setItem('emptyInteresseSignup', JSON.stringify(false));
      localStorage.setItem('emptyAkkoordSignup', JSON.stringify(false));
      if (!localStorage.getItem('failedExistingSignup')) {
        localStorage.setItem('failedExistingSignup', JSON.stringify(false));
      }
      if (!localStorage.getItem('failedInternalSignup')) {
        localStorage.setItem('failedInternalSignup', JSON.stringify(false));
      }
      if (!localStorage.getItem('failedPasswordSignup')) {
        localStorage.setItem('failedPasswordSignup', JSON.stringify(false));
      }
      if (!localStorage.getItem('failedEqualPasswordSignup')) {
        localStorage.setItem('failedEqualPasswordSignup', JSON.stringify(false));
      }
      if (!localStorage.getItem('accountCreated')) {
        localStorage.setItem('accountCreated', JSON.stringify(false));
      }
    }
    this.subjectiefChosen = false;
    this.objectiefChosen = false;
    this.toevoegingChosen = false;
    this.verwijderingChosen = false;
    this.neutraalChosen = false;
    this.updateChosen = false;
    this.verplaatsingChosen = false;
    this.spellingChosen = false;
    this.objectivesChosen = [];
    this.subjectivesChosen = [];
  }

  ngOnInit(): void {
    this.setTitle('UGent Newstracker - Hoe werkt het?');
    this.imageLoaded = false;
    if (this.authService.currentUserValue) {
      this.getTooluser();
    }
  }
  clickAcceptButton(){
    this.authService.setHasLoggedIn(this.authService.currentUserValue.email).subscribe(data => {
      this.router.navigate([this.authService.feedbackUrl]);
    });
  }

  getTooluser(): void {
    this.email = this.authService.currentUserValue.email;
    this.authService.getUser(this.email).subscribe(data => {
      this.firstname = data.firstname;
      this.lastname = data.lastname;
      this.hasLoggedIn = data.hasLoggedIn;
      this.hasFeedbackCompleted = data.hasFeedbackCompleted;
      this.allArticlesCompleted = data.allArticlesCompleted;
      this.feedbackStage = data.feedbackStage;
      this.totalCount = data.totalCount;
      this.imageLoaded = true;
    });
  }

  onLogoutClick() {
    this.authService.logout().subscribe(a => {
      localStorage.setItem('submittedLogin', JSON.stringify(false));
      localStorage.setItem('loadingLogin', JSON.stringify(false));
      localStorage.setItem('failedLogin', JSON.stringify(false));
      localStorage.setItem('submittedSignup', JSON.stringify(false));
      localStorage.setItem('loadingSignup', JSON.stringify(false));
      localStorage.setItem('failedSignup', JSON.stringify(false));
      localStorage.setItem('accountCreated', JSON.stringify(false));
      this.router.navigate([this.authService.loginUrl]);
    });
  }

  public setTitle( newTitle: string) {
    this.titleService.setTitle( newTitle );
  }

  getTicket(): string {
    if (this.totalCount >= 0) {
      if (this.totalCount < 50) {
        return 'assets/ticket_' + ((50 - this.getRemaining()) * 2).toString() + '.png';
      }
      else{
        return 'assets/ticket_' + ((25 - this.getRemaining()) * 4).toString() + '.png';
      }
    }
    else{
      return 'assets/ticket_0.png';
    }
  }
  getFullTicketBlack(): string {
    return 'assets/ticket_black.png';
  }
  getFullTicketWhite(): string {
    return 'assets/ticket_100.png';
  }
  getRange(nrTickets: number): number[] {
    const ticketRange = [];
    for (let i = 0; i < nrTickets; i++){
      ticketRange[i] = i;
    }
    return ticketRange;
  }
  getRemaining(): number {
    if (this.totalCount < 50){
      return 50 - this.totalCount;
    }
    if ((this.totalCount === 50) || (this.totalCount > 50 && ((this.totalCount - 50) % 25) === 0)){
      return 25;
    }
    else {
      return Math.ceil((this.totalCount - 50) / 25) * 25 - (this.totalCount - 50);
    }
  }
  getNrTickets(): number {
    if (this.totalCount < 50) {
      return 0;
    } else {
      return Math.floor((this.totalCount - 50) / 25) + 1;
    }
  }
  getImageLoaded(): boolean {
    return this.imageLoaded;
  }
  clickCategory(categoryType: string) {
    switch (categoryType) {
      case 'objectief': {
        this.objectiefChosen = !this.objectiefChosen;
        this.objectivesChosen = [];
        break;
      }
      case 'subjectief': {
        this.subjectiefChosen = !this.subjectiefChosen;
        this.subjectivesChosen = [];
        break;
      }
      case 'toevoeging': {
        this.toevoegingChosen = !this.toevoegingChosen;
        break;
      }
      case 'verwijdering': {
        this.verwijderingChosen = !this.verwijderingChosen;
        break;
      }
      case 'herformulering': {
        this.neutraalChosen = !this.neutraalChosen;
        break;
      }
      case 'update': {
        this.updateChosen = !this.updateChosen;
        break;
      }
      case 'spelling': {
        this.spellingChosen = !this.spellingChosen;
        break;
      }
      case 'verplaatsing': {
        this.verplaatsingChosen = !this.verplaatsingChosen;
        break;
      }
    }
  }
  clickSubcategory(subcategory: string): void {
    const subcategoryDict = {'foutieve naam': 1, 'foutieve functietitel': 2, 'foutieve leeftijd': 3,
    'foutief adres': 4, 'foutief geciteerd': 5, 'foutief aantal': 6, 'foutief tijdstip': 7, 'foutieve datum': 8,
    'foutieve locatie': 9, 'niet-representatieve (tussen)titel': 10, 'informatie gesensationaliseerd': 11,
    'informatie te ingetogen geformuleerd': 12, 'essentiële informatie afwezig': 13, 'misleidende cijfers': 14,
    'uit de context getrokken citaat': 15};

    if (subcategoryDict[subcategory] <= 9) {
      if (this.objectivesChosen.indexOf(subcategoryDict[subcategory]) >= 0) {
        this.objectivesChosen = this.objectivesChosen.filter(a => a !== subcategoryDict[subcategory]);
      } else {
        this.objectivesChosen.push(subcategoryDict[subcategory]);
      }
    }
    else {
      if (this.subjectivesChosen.indexOf(subcategoryDict[subcategory]) >= 0) {
        this.subjectivesChosen = this.subjectivesChosen.filter(a => a !== subcategoryDict[subcategory]);
      } else {
        this.subjectivesChosen.push(subcategoryDict[subcategory]);
      }
    }
  }

}
