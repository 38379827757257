<!doctype html>
<html lang="en">
<head>
  <title>UGent Newstracker - Over dit project</title>
</head>
<body>
<nav class="navbar navbar-expand-lg fixed-top navbar-dark bg-company-grey">
  <a class="navbar-brand" href="#"></a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <a class="navbar-brand" target="_blank" href="https://www.ugent.be/" style="padding:0px;">
    <img src="assets/ugentlogo.png" width="163" height="130">
  </a>
  <a class="navbar-brand" target="_blank" href="https://ddcmsite.ugent.be/" style="padding:0px;">
    <img src="assets/ddcmlogo.png" width="272" height="60">
  </a>
  <div class="collapse navbar-collapse text-right" *ngIf="getImageLoaded() || !authService.currentUserValue" id="navbarright">
    <div class="abs-center-x" *ngIf="allArticlesCompleted===false && authService.currentUserValue && this.hasLoggedIn===true && this.hasFeedbackCompleted===true" style="display:inline-block" name="ticket">
      <div *ngIf="getNrTickets() < 10" style="display:inline; color:white">
        <ng-container *ngFor="let number of getRange(getNrTickets());">
          <img [src]="getFullTicketWhite()" width="40" height="40">
        </ng-container>
      </div>
      <div *ngIf="getNrTickets() >= 10" style="display:inline; color:white">
        ...
        <ng-container *ngFor="let number of getRange(10);">
          <img [src]="getFullTicketWhite()" width="40" height="40">
        </ng-container>
        ...
      </div>
      <br>
      <img [src]="getTicket()" />
      <p class="completed">Nog {{getRemaining()}} artikels te gaan tot lotje {{getNrTickets() + 1}}!</p>
    </div>
    <ul class="navbar-nav ml-auto flex-nowrap">
      <li class="nav-item"  *ngIf="authService.currentUserValue && this.hasLoggedIn===true">
        <a class="nav-link" href="/articles">Nieuw artikelpaar</a>
      </li>
      <li class="nav-item" *ngIf="!authService.currentUserValue">
        <a class="nav-link" href="/login" (click)="booleanService.resetLoginBooleans()">Home</a>
      </li>
      <li class="nav-item" *ngIf="authService.currentUserValue">
        <a class="nav-link" href="/profile" target="_blank">{{this.firstname}} {{this.lastname}}</a>
      </li>
      <li class="nav-item">
        <a class="nav-link active" href="/project">Over dit project<span class="sr-only">(current)</span></a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="/intro">Hoe werkt het?</a>
      </li>
      <li class="nav-item" *ngIf="!authService.currentUserValue">
        <a class="nav-link" href="/signin" (click)="booleanService.resetSigninBooleans()">Maak een account!</a>
      </li>
      <li class="nav-item" *ngIf="authService.currentUserValue">
        <a class="nav-link" href="#" (click)="onLogoutClick()">Uitloggen</a>
      </li>
    </ul>
  </div>
</nav>
<div class="jumbotron jumbotron-fluid" name="under-centered-vid">
  <h2 align="center">Wie zijn wij?</h2>
  <div>
    <img style="margin-left:25%;display:inline-block;" src="assets/yoram.jpg" width="130" height="130">
    <p style="margin-left:3%;display:inline-block;top:50px;position:relative"><a target="_blank" href="https://telefoonboek.ugent.be/nl/people/802002977835"><b>Yoram Timmerman</b></a> studeerde in 2018 af aan de Universiteit Gent als Master of Science in de Computerwetenschappen.
      Sindsdien is hij er actief als doctoraatsonderzoeker bij de DDCM (Database, Document en Content Management) onderzoeksgroep van de vakgroep TELIN.
      Zijn onderzoek focust zich vooral op operationele meetprocedures voor het meten van datakwaliteit en het toepassen van het meetprocedures voor datakwaliteit in praktische omstandigheden, zoals het onderzoeken van de kwaliteit van online nieuws.</p>
  </div>
  <br>
  <hr>
  <br>
  <div>
    <img style="margin-left:25%;display:inline-block;" src="assets/antoon.png" width="130" height="152">
    <p style="margin-left:3%;display:inline-block;top:50px;position:relative"><a target="_blank" href="https://telefoonboek.ugent.be/nl/people/802000047526"><b>Antoon Bronselaer</b></a> studeerde in 2006 af aan de Universiteit Gent als Master of Science in de Computerwetenschappen, en behaalde er vervolgens in 2010 ook een doctortitel.
      Sindsdien is hij er actief als professor bij de DDCM (Database, Document en Content Management) onderzoeksgroep van de vakgroep TELIN.
      Zijn onderzoek focust zich vooral op datakwaliteit en data integratie.</p>
  </div>
</div>
  <br>
  <div style="margin-top:4%;">
    <div class="abs-center-x">
  <h2 style="margin-left:90px;display:inline-block;" align="center">Waarom hebben we jouw input nodig?</h2>
    <p style="top:10px;position:relative;" align="left">In dit project willen we grondig analyseren hoe de mogelijkheid om online nieuwsartikels aan te passen gebruikt wordt in Vlaanderen. Welk soort aanpassingen gebeuren er? Hoeveel aanpassingen gebeuren er aan artikels? ... Bovendien willen we nagaan of het mogelijk is dergelijke aanpassingen aan online nieuwsartikels op een automatische manier te classificeren. Op die manier willen we het gebruik van aanpassingen aan nieuws transparanter maken.</p>
  <p style="top:5px;position:relative;" align="left">Om onze doelen te bereiken legden we een grote dataset aan van aanpassingen van online nieuws gepubliceerd door verschillende Vlaamse nieuwswebsites. Het is echter onmogelijk om voor ons al deze aanpassingen zelf te analyseren. <b>Daarom hebben we jouw hulp nodig! En je hoeft het niet zomaar te doen, want wie weet win je wel een cinematicket! <br> <br> Meer weten? Klik dan zeker op een van onderstaande plus-icoontjes!</b></p>
      <br>
  <div class="abs-center-x" align="center">
    <h5 style="display:inline-block;" align="center">Achtergrond bij het project</h5>
    <img class="plus" src="assets/plus.png" *ngIf="inputChosen===false" style="margin-left:4px;" width="15" height="15" (click)="clickCategory('input')">
    <img class="min" src="assets/minus.png" *ngIf="inputChosen===true" style="margin-left:4px;" width="15" height="15" (click)="clickCategory('input')">
  </div>
  <p style="top:40px;position:relative;" align="center"><i>Wat proberen we precies te bereiken?</i></p>
  <div style="top:70px;position:relative;" *ngIf="inputChosen===true">
    <br>
  <p >Zoals je ongetwijfeld zelf ook al weet, is <b>de manier waarop mensen nieuws consumeren de afgelopen jaren bijzonder sterk veranderd</b>. In een tijd die ondertussen lang vervlogen lijkt, waren de papieren kranten en de journaals de belangrijkste (en vaak ook enige) media die mensen gebruikten om op de hoogte te blijven van wat er gebeurde in de wereld.
  Dit zorgde ervoor dat er vaste tijdstippen waren waarop de consument nieuws kon raadplegen: iedere ochtend viel de krant in de brievenbus, iedere avond om 19u was er het journaal. Wat de lezer en kijker bovendien te lezen of te zien kreeg, was niet veranderbaar.
  Artikels die in een papieren krant gedrukt werden, konden bijvoorbeeld niet meer aangepast worden nadat de krant was gedrukt.</p>
  <br>
  <p>Vandaag de dag loopt nieuwsproductie en -consumptie echter helemaal anders. Verschillende onderzoeken en monitoren toonden reeds aan dat <b>online nieuwswebsites tegenwoordig een bijzonder belangrijke rol spelen in de nieuwsvergaring van mensen</b>.
    De dynamiek van een online nieuwsartikel is echter helemaal anders dan die van een gedrukt krantenartikel. In de online nieuwswereld is er immers geen sprake van deadlines. <b>Nieuws kan worden gepubliceerd vanaf het moment dat het binnenkomt.</b> Dat zorgt ervoor dat er een zekere wedijver bestaat tussen verschillende nieuwswebsites om een bepaalde gebeurtenis zo rap mogelijk te publiceren. </p>

  <p>Daarnaast is het ook zo dat, opnieuw in tegenstelling tot geprinte krantenartikels, de inhoud van een gepubliceerd online nieuwsartikel niet vast is. <b>Naargelang nieuwe informatie binnenkomt, fouten ontdekt worden ... kunnen reeds gepubliceerde online artikels immers nog gemakkelijk aangepast worden.
      Dit wordt ook wel de "vloeibaarheid" van online nieuws genoemd.</b> Tot nu toe is er echter bijzonder weinig informatie over hoe dit aanpassen van online nieuwsartikels precies gebeurd. Zeker in Vlaanderen is daar tot op heden, voor zover wij weten, geen onderzoek naar uitgevoerd.
    Bovendien is het onduidelijk wat de invloed van deze fluïditeit van online nieuws en de constante publicatiedruk is op de uiteindelijke kwaliteit van de gepubliceerde artikels.</p>
  <p>In dit project is het daarom onze bedoeling antwoorden te bieden op twee cruciale vragen met betrekking tot online nieuws en hun aanpassingen:</p>
  <p><b>1. Hoe kan vloeibaar nieuws in Vlaanderen worden gekarakteriseerd?</b></p>
  <p>Met deze vraag proberen we in eerste instantie te onderzoeken op welke manier aanpassingen aan krantenartikels vandaag de dag door online nieuwswebsites gebruikt worden. Welk soort aanpassingen worden gedaan?
  Hoe vaak worden artikels aangepast? Wat is de gemiddelde tijdsspanne waarin een artikel wordt aangepast? Hangt het aanpassingsgedrag af van het onderwerp van het artikel? ...</p>
  <p><b>2. Met welke accuraatheid en efficiëntie kan een algoritme nieuwsupdates classificeren en mogelijks foutieve informatie detecteren?</b></p>
  <p>Aan de hand van manueel geannoteerde aanpassingen willen we in tweede instantie ook nagaan in welke mate het mogelijk is dergelijke aanpassingen op een automatische manier te kunnen classificeren. Een dergelijk algoritme zou bijzonder relevant kunnen zijn in het kader van het detecteren van foutieve informatie in krantenartikels, en in het transparanter maken van aanpassingen aan nieuws.</p>
  <br>
  </div>
    <hr style="top:55px;position:relative;">
  <div class="abs-center-x" style="top:60px;position:relative;" align="center">
    <h5 style="display:inline-block;" align="center">Dataverzameling</h5>
    <img class="plus" src="assets/plus.png" *ngIf="dataChosen===false" style="margin-left:4px;" width="15" height="15" (click)="clickCategory('data')">
    <img class="min" src="assets/minus.png" *ngIf="dataChosen===true" style="margin-left:4px;" width="15" height="15" (click)="clickCategory('data')">
  </div>
    <p style="margin-left:36%;top:55px;position:relative;"><i>Hoe werden de data verzameld?</i></p>
    <div style="top:70px;position:relative;" *ngIf="dataChosen===true">
      <p>Om de doelstellingen van het project, zoals ze hierboven worden geformuleerd, te bereiken, startten we in april 2019 met het monitoren van zes belangrijke Vlaamse nieuwswebsites (<a href="https://www.vrtnws.be">VRT NWS</a>, <a href="https://www.knack.be">Knack</a>, <a href="https://www.hln.be">HLN</a>, <a href="https://www.nieuwsblad.be">Het Nieuwsblad</a>, <a href="https://www.demorgen.be">De Morgen</a> en <a href="https://www.standaard.be">De Standaard</a>).
      Hierbij worden alle artikels die gepubliceerd worden door de verschillende websites geregistreerd, samen met het tijdstip van originele publicatie. In de 24 uur die volgen op de originele publicatie van een artikel, wordt de pagina waarop het artikel te vinden is op frequente basis opnieuw bezocht om te kijken of er wijzigingen aan het artikel zijn gebeurd in de tussenperiode.
      Indien inderdaad een wijziging gebeurde aan het artikel sinds de laatste keer dat dit artikel werd geraadpleegd, wordt een nieuwe versie van het artikel bijgehouden samen met het tijdstip waarop deze nieuwe versie voor het eerst werd tegengekomen. Op die manier hebben we in het voorbije anderhalve jaar een heel grote verzameling aan aanpassingen aan online nieuws in Vlaanderen opgebouwd.</p>
    <br>
    </div>
    <hr style="top:55px;position:relative;">
    <div class="abs-center-x" style="top:60px;position:relative;" align="center">
      <h5 style="display:inline-block;" align="center">Wat kan jij doen?</h5>
      <img class="plus" src="assets/plus.png" *ngIf="oproepChosen===false" style="margin-left:4px;" width="15" height="15" (click)="clickCategory('oproep')">
      <img class="min" src="assets/minus.png" *ngIf="oproepChosen===true" style="margin-left:4px;" width="15" height="15" (click)="clickCategory('oproep')">
    </div>
    <p style="margin-left:30%;top:55px;position:relative;"><i>Waarom hebben we precies jouw hulp nodig?</i></p>
    <div style="top:70px;position:relative;" *ngIf="oproepChosen===true">
      <p>Door het registreren van heel veel gepubliceerde versies van online artikels in het laatste anderhalve jaar hebben we ondertussen een bijzonder grote dataset opgebouwd.
        Om een goed idee te krijgen van welk soort aanpassingen typisch gebeuren aan online nieuwsartikels, moeten heel wat van deze aanpassingen manueel geclassificeerd worden. Voor een klein team van onderzoekers is dit echter onbegonnen werk. <br><br><b>Daarom dat we jouw hulp nodig hebben!</b> Eenmaal je een account hebt aangemaakt, krijg je op dit platform telkens twee opeenvolgende versies van eenzelfde artikel te zien. Hierbij zijn de aanpassingen telkens duidelijk aangegeven in rood of groen.
      Als gebruiker kan jij opgeven om welk type aanpassing het hier volgens jou gaat. Als veel gebruikers dit doen, krijgen we dus met andere woorden een grote hoeveelheid aan geannoteerde aanpassingen in ons bezit. Op die manier krijgen we een zeer goed idee van de types aanpassingen die aan Vlaams online nieuws gebeuren.
      Bovendien kunnen we deze kennisbasis vervolgens ook heel goed gebruiken om te proberen een geautomatiseerd classificatiesysteem te bouwen. Dit zou ons verderhelpen in de strijd tegen misinformatie en desinformatie. Je bijdrage is dus alleszins bijzonder nuttig!</p>
    <br>
    </div>
    <hr style="top:55px;position:relative;">
    <div class="abs-center-x" style="top:60px;position:relative;" align="center">
      <h5 style="display:inline-block;" align="center">Wat zit er voor jou in?</h5>
      <img class="plus" src="assets/plus.png" *ngIf="voordeelChosen===false" style="margin-left:4px;" width="15" height="15" (click)="clickCategory('voordeel')">
      <img class="min" src="assets/minus.png" *ngIf="voordeelChosen===true" style="margin-left:4px;" width="15" height="15" (click)="clickCategory('voordeel')">
    </div>
    <p style="margin-left:35%;top:55px;position:relative;"><i>Je hoeft het niet zomaar te doen...</i></p>
    <div style="top:70px;position:relative;" *ngIf="voordeelChosen===true">
      <p>Eerst en vooral zouden we je ontzettend dankbaar zijn als je ons zou willen helpen door een aantal aanpassingen te categoriseren. Daarnaast krijg je ook de kans om mee te werken aan wetenschappelijk onderzoek en om op die manier de wetenschap verder te helpen!
        Maar er is meer...
        <br>
        <br>
        Om je extra te motiveren, <b>zullen er op het einde van dit project ook 40 cinematickets verloot worden onder de deelnemers. Deze worden verloot onder alle deelnemers die minstens 50 artikels volledig afgewerkt hebben.</b> Haal je dit cijfer, dan verdien je een lotje in de pot waaruit de winnende lotjes worden getrokken. <b>Voor iedere 25 artikels die je bovenop deze 50 eerste artikels afwerkt, verdien je bovendien een extra lotje.</b> 75 volledig afgewerkte artikels geeft je dus recht op 2 lotjes, 100 afgewerkte artikels op 3 lotjes. Zo kan je dus snel je kans om te winnen vergroten!
        <br>
        Als je een of meerdere cinematickets hebt gewonnen, zullen wij je na afloop van het project contacteren via jouw emailadres.

      </p>
      <br>
    </div>
    <hr style="top:55px;position:relative;">
    <div class="abs-center-x" style="top:60px;position:relative;" align="center">
      <h5 style="display:inline-block;" align="center">Wat met mijn gegevens en privacy?</h5>
      <img class="plus" src="assets/plus.png" *ngIf="privacyChosen===false" style="margin-left:4px;" width="15" height="15" (click)="clickCategory('privacy')">
      <img class="min" src="assets/minus.png" *ngIf="privacyChosen===true" style="margin-left:4px;" width="15" height="15" (click)="clickCategory('privacy')">
    </div>
    <p style="margin-left:30%;top:55px;position:relative;"><i>Welke informatie wordt waar opgeslagen?</i></p>
    <div style="top:70px;position:relative;" *ngIf="privacyChosen===true">
      <p>De persoonlijke gegevens (e-mailadres, voornaam, achternaam en paswoord) en de aanpassingen aan artikels die jij aangeeft op dit platform zullen vertrouwelijk en met respect worden behandeld.
        Bij het aanmaken van een account op dit platform, en nadien bij het inloggen, dien je gebruik te maken van je e-mailadres.
        De reden hiervoor is dat het voor verdere analyses belangrijk is te weten welke aanpassingen door dezelfde persoon werden gedaan. Dankzij het gebruik van een e-mailadres kan iedereen eenvoudig een account aanmaken, en zijn wij in staat de verschillende gebruikersprofielen te onderscheiden.
        Op het einde van het onderzoek (wanneer de website offline wordt gehaald) hebben wij je e-mailadres niet meer nodig (aangezien je niet meer moet inloggen). Op dit moment zullen de e-mailadressen uit de verzamelde data verwijderd en vervangen door een anoniem id dat niet meer gelinkt kan worden aan het originele e-mailadres. Jouw geregistreerde input wordt niet gedeeld met of gebruikt voor commerciële doeleinden, maar kunnen wel worden verwerkt door de onderzoeksgroep DDCM in verdere wetenschappelijke publicaties, zoals artikels, rapporten en boeken.
        <br>
        <br>
        In overeenstemming met de Belgische wet van 8 december 1992 en de Belgische wet van 22 augustus 2002, respecteren wij jouw persoonlijke levenssfeer. Als de resultaten van de studie worden gepubliceerd, blijft je identiteit vertrouwelijke informatie. De Data Protection Officer kan je desgewenst meer informatie verschaffen over de bescherming van je persoonsgegevens.
        Contactgegevens: mevrouw Hanne Elsen, tel: +32 9 264 95 17 of privacy@ugent.be, Campus Ufo, Rectoraat 2, Sint-Pietersnieuwstraat 25, 9000 Gent
        <br>
        <br>
        Het onderzoeksteam heeft toegang tot de data die je op dit platform genereert. Deze data worden beveiligd opgeslagen, zodanig dat niemand anders toegang heeft tot jouw voortgang op dit platform.
      </p>
    <br>
    </div>
    <hr style="top:55px;position:relative;">
    <div class="abs-center-x" style="top:60px;position:relative;" align="center">
      <h5 style="display:inline-block;" align="center">Kan ik mijn deelname uitstellen of stopzetten?</h5>
      <img class="plus" src="assets/plus.png" *ngIf="stoppenChosen===false" style="margin-left:4px;" width="15" height="15" (click)="clickCategory('stoppen')">
      <img class="min" src="assets/minus.png" *ngIf="stoppenChosen===true" style="margin-left:4px;" width="15" height="15" (click)="clickCategory('stoppen')">
    </div>
    <p style="margin-left:36%;top:55px;position:relative;"><i>Wat als het toch niks voor jou is...</i></p>
    <div style="top:70px;position:relative;" *ngIf="stoppenChosen===true">
      <p>Wanneer je je deelname wenst stop te zetten, kan je dit doen zonder dat je daarvoor een reden moet opgeven. Je kan je terugtrekken uit het onderzoek tot en met 1 november 2021.
        Neem daartoe contact op met één van de onderzoekers die beneden op deze pagina vermeld staan. Indien je dit wenst, zullen we al je data verwijderen, zodat je data zeker niet worden meegenomen in verdere analyses.
        Je krijgt bovendien de gelegenheid om tijdens en na het onderzoek nog verdere vragen te stellen aan de onderzoekers.
        </p>
    <br>
    </div>
    <hr style="top:55px;position:relative;">
    <div class="abs-center-x" style="top:60px;position:relative;" align="center">
      <h5 style="display:inline-block;" align="center">Zijn er kosten verbonden aan mijn deelname?</h5>
      <img class="plus" src="assets/plus.png" *ngIf="kostenChosen===false" style="margin-left:4px;" width="15" height="15" (click)="clickCategory('kosten')">
      <img class="min" src="assets/minus.png" *ngIf="kostenChosen===true" style="margin-left:4px;" width="15" height="15" (click)="clickCategory('kosten')">
    </div>
    <p style="margin-left:40%;top:55px;position:relative;"><i>Zijn er ook nadelen?</i></p>
    <div style="top:70px;position:relative;" *ngIf="kostenChosen===true">
      <p>Uiteraard hoef je niets te betalen voor je deelname. Het aanmaken van een account is volledig gratis. Meer zelfs, zoals hierboven reeds aangehaald kan je met je deelname zelfs iets verdienen!</p>
  <br>
  </div>
<hr style="top:55px;position:relative;">
<div class="abs-center-x" style="top:60px;position:relative;" align="center">
  <h5 style="display:inline-block;" align="center">Kan ik de resultaten van de studie inkijken?</h5>
  <img class="plus" src="assets/plus.png" *ngIf="interesseChosen===false" style="margin-left:4px;" width="15" height="15" (click)="clickCategory('interesse')">
  <img class="min" src="assets/minus.png" *ngIf="interesseChosen===true" style="margin-left:4px;" width="15" height="15" (click)="clickCategory('interesse')">
</div>
<p style="margin-left:40%;top:55px;position:relative;"><i>Ik ben geïnteresseerd!</i></p>
<div style="top:70px;position:relative;" *ngIf="interesseChosen===true">
  <p> Dat kan zeker! Bij het aanmaken van je account dien je hiertoe enkel maar het corresponderende vakje aan te vinken!
    Indien gewenst, zullen we je, wanneer deze beschikbaar zijn, op de hoogte houden van de resultaten en wetenschappelijke publicaties die het gevolg zijn van dit onderzoek.
    Heb je je nadien bedacht? Stuur een mailtje naar één van de onderzoekers en we passen jouw wensen aan.
  </p>
  <br>
</div>
<hr style="top:55px;position:relative;">
<div class="abs-center-x" style="top:60px;position:relative;" align="center">
  <h5 style="display:inline-block;" align="center">Wie financiert dit onderzoek?</h5>
  <img class="plus" src="assets/plus.png" *ngIf="financienChosen===false" style="margin-left:4px;" width="15" height="15" (click)="clickCategory('financien')">
  <img class="min" src="assets/minus.png" *ngIf="financienChosen===true" style="margin-left:4px;" width="15" height="15" (click)="clickCategory('financien')">
</div>
<p style="margin-left:38%;top:55px;position:relative;"><i>En wie keurde het goed?</i></p>
<div style="top:70px;position:relative;" *ngIf="financienChosen===true">
  <p> Deze studie werd goedgekeurd door de Ethische Commissie van de faculteit Letteren en Wijsbegeerte. In geen geval dien je de goedkeuring door deze commissie te beschouwen als een aanzet tot deelname aan deze studie.
    Het onderzoek wordt gefinancierd door het Bijzonder Onderzoeksfonds (BOF).
  </p>
  <br>
  </div>
  </div>
  </div>
</body>
</html>
