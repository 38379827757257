import { Component, OnInit } from '@angular/core';
import {AuthService} from '../auth.service';
import {Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {BooleanService} from '../boolean.service';
import {BehaviorSubject} from 'rxjs';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.css']
})
export class ProjectComponent implements OnInit {
  email: string;
  firstname: string;
  lastname: string;
  hasLoggedIn: boolean;
  hasFeedbackCompleted: boolean;
  allArticlesCompleted: boolean;
  feedbackStage: number;
  totalCount: number;
  imageLoaded: boolean;
  inputChosen: boolean;
  oproepChosen: boolean;
  dataChosen: boolean;
  voordeelChosen: boolean;
  stoppenChosen: boolean;
  kostenChosen: boolean;
  privacyChosen: boolean;
  interesseChosen: boolean;
  financienChosen: boolean;

  constructor(public authService: AuthService, private router: Router,
              private titleService: Title, public booleanService: BooleanService) {
    if (!authService.currentUserValue) {
      booleanService.resetLoginBooleans();
      booleanService.resetRecoverBooleans();
      booleanService.resetRecoverConfirmBooleans();
      localStorage.setItem('loadingSignup', JSON.stringify(false));
      localStorage.setItem('emptyEmailSignup', JSON.stringify(false));
      localStorage.setItem('emptyPassword1Signup', JSON.stringify(false));
      localStorage.setItem('emptyPassword2Signup', JSON.stringify(false));
      localStorage.setItem('emptyFirstnameSignup', JSON.stringify(false));
      localStorage.setItem('emptyLastnameSignup', JSON.stringify(false));
      localStorage.setItem('emptyInteresseSignup', JSON.stringify(false));
      localStorage.setItem('emptyAkkoordSignup', JSON.stringify(false));
      if (!localStorage.getItem('failedExistingSignup')) {
        localStorage.setItem('failedExistingSignup', JSON.stringify(false));
      }
      if (!localStorage.getItem('failedInternalSignup')) {
        localStorage.setItem('failedInternalSignup', JSON.stringify(false));
      }
      if (!localStorage.getItem('failedPasswordSignup')) {
        localStorage.setItem('failedPasswordSignup', JSON.stringify(false));
      }
      if (!localStorage.getItem('failedEqualPasswordSignup')) {
        localStorage.setItem('failedEqualPasswordSignup', JSON.stringify(false));
      }
      if (!localStorage.getItem('accountCreated')) {
        localStorage.setItem('accountCreated', JSON.stringify(false));
      }
    }
    this.inputChosen = false;
    this.oproepChosen = false;
    this.dataChosen = false;
    this.voordeelChosen = false;
    this.stoppenChosen = false;
    this.kostenChosen = false;
    this.privacyChosen = false;
    this.interesseChosen = false;
    this.financienChosen = false;
  }

  ngOnInit(): void {
    this.setTitle('UGent Newstracker - Over dit project');
    this.imageLoaded = false;
    if (this.authService.currentUserValue) {
      this.getTooluser();
    }
  }

  getTooluser(): void {
    this.email = this.authService.currentUserValue.email;
    this.authService.getUser(this.email).subscribe(data => {
      this.firstname = data.firstname;
      this.lastname = data.lastname;
      this.hasLoggedIn = data.hasLoggedIn;
      this.hasFeedbackCompleted = data.hasFeedbackCompleted;
      this.allArticlesCompleted = data.allArticlesCompleted;
      this.feedbackStage = data.feedbackStage;
      this.totalCount = data.totalCount;
      this.imageLoaded = true;
    });
  }

  onLogoutClick() {
    this.authService.logout().subscribe(a => {
      localStorage.setItem('submittedLogin', JSON.stringify(false));
      localStorage.setItem('loadingLogin', JSON.stringify(false));
      localStorage.setItem('failedLogin', JSON.stringify(false));
      localStorage.setItem('submittedSignup', JSON.stringify(false));
      localStorage.setItem('loadingSignup', JSON.stringify(false));
      localStorage.setItem('failedSignup', JSON.stringify(false));
      localStorage.setItem('accountCreated', JSON.stringify(false));
      this.router.navigate([this.authService.loginUrl]);
    });
  }

  public setTitle( newTitle: string) {
    this.titleService.setTitle( newTitle );
  }

  getTicket(): string {
    if (this.totalCount >= 0) {
      if (this.totalCount < 50) {
        return 'assets/ticket_' + ((50 - this.getRemaining()) * 2).toString() + '.png';
      }
      else{
        return 'assets/ticket_' + ((25 - this.getRemaining()) * 4).toString() + '.png';
      }
    }
    else{
      return 'assets/ticket_0.png';
    }
  }
  getFullTicketBlack(): string {
    return 'assets/ticket_black.png';
  }
  getFullTicketWhite(): string {
    return 'assets/ticket_100.png';
  }
  getRange(nrTickets: number): number[] {
    const ticketRange = [];
    for (let i = 0; i < nrTickets; i++){
      ticketRange[i] = i;
    }
    return ticketRange;
  }
  getRemaining(): number {
    if (this.totalCount < 50){
      return 50 - this.totalCount;
    }
    if ((this.totalCount === 50) || (this.totalCount > 50 && ((this.totalCount - 50) % 25) === 0)){
      return 25;
    }
    else {
      return Math.ceil((this.totalCount - 50) / 25) * 25 - (this.totalCount - 50);
    }
  }
  getNrTickets(): number {
    if (this.totalCount < 50) {
      return 0;
    } else {
      return Math.floor((this.totalCount - 50) / 25) + 1;
    }
  }
  getImageLoaded(): boolean {
    return this.imageLoaded;
  }

  clickCategory(categoryType: string) {
    switch (categoryType) {
      case 'input': {
        this.inputChosen = !this.inputChosen;
        break;
      }
      case 'oproep': {
        this.oproepChosen = !this.oproepChosen;
        break;
      }
      case 'data': {
        this.dataChosen = !this.dataChosen;
        break;
      }
      case 'voordeel': {
        this.voordeelChosen = !this.voordeelChosen;
        break;
      }

      case 'stoppen': {
        this.stoppenChosen = !this.stoppenChosen;
        break;
      }

      case 'kosten': {
        this.kostenChosen = !this.kostenChosen;
        break;
      }

      case 'privacy': {
        this.privacyChosen = !this.privacyChosen;
        break;
      }

      case 'interesse': {
        this.interesseChosen = !this.interesseChosen;
        break;
      }

      case 'financien': {
        this.financienChosen = !this.financienChosen;
        break;
      }
    }
  }

}
